import store from 'store';

import { ICexPair } from 'types/pairs';

import { ORDER_SIDE, ORDER_STATUS, ORDER_BOT } from 'constant/filters';
import { TOTAL_TOKEN_ID } from 'constant/numbers';
import { EFilterColor } from 'constant/filters';
import { IFilterSchemeExtended, EFilterEntity, EFilterFormatter } from '../common';

export const cexPairOrdersFiltersScheme = (
  pair: ICexPair,
): Record<string, IFilterSchemeExtended> => {
  const isAdmin = store.getState().auth.isAdmin;

  return {
    range: {
      type: EFilterEntity.date_range,
      label: 'Time range',
      name: 'range',
      field: 'range',
      single: true,
    },
    cex_order_ids: {
      type: EFilterEntity.multi_search,
      label: 'Order ids',
      name: 'cex_order_ids',
      field: 'cex_order_ids',
      single: true,
    },
    side: {
      type: EFilterEntity.colors_multi_select,
      formatter: EFilterFormatter.colors_multi_select,
      label: 'Side',
      name: 'sides',
      field: 'sides',
      listMultiColors: ORDER_SIDE,
      single: true,
    },
    statuses: {
      type: EFilterEntity.colors_multi_select,
      formatter: EFilterFormatter.colors_multi_select,
      label: 'Statuses',
      name: 'statuses',
      field: 'statuses',
      listMultiColors: ORDER_STATUS,
      single: true,
    },
    amounts: {
      name: 'amounts',
      label: 'Amount',
      filters: [
        {
          label: pair.token_base.symbol,
          settedLabel: `Amount ${pair.token_base.symbol}`,
          name: 'base_amount',
          type: EFilterEntity.number,
          field: 'amounts',
          tokenId: pair.token_base.id,
          decimals: 18,
          formatter: EFilterFormatter.bn_number_token,
        },
        {
          label: pair.token_quote.symbol,
          settedLabel: `Amount ${pair.token_quote.symbol}`,
          name: 'quote_amount',
          type: EFilterEntity.number,
          field: 'amounts',
          tokenId: pair.token_quote.id,
          decimals: 18,
          formatter: EFilterFormatter.bn_number_token,
        },
        {
          label: 'Total USD',
          settedLabel: `Total USD amount`,
          name: 'amount_usd',
          type: EFilterEntity.number,
          field: 'amounts',
          tokenId: TOTAL_TOKEN_ID,
          decimals: 18,
          formatter: EFilterFormatter.bn_number_token,
        },
      ],
    },
    ...(isAdmin
      ? {
          users: {
            type: EFilterEntity.user_multi_select,
            formatter: EFilterFormatter.user_multi_select,
            label: 'Users',
            name: 'users',
            field: 'user_ids',
            single: true,
          },
          accounts: {
            type: EFilterEntity.cex_accounts_multi_select,
            formatter: EFilterFormatter.cex_accounts_multi_select,
            label: 'Accounts',
            name: 'accounts',
            field: 'account_ids',
            single: true,
          },
        }
      : {}),
  };
};

export const newCexPairOrdersFiltersScheme = (): Record<string, IFilterSchemeExtended> => {
  const isAdmin = store.getState().auth.isAdmin;

  return {
    range: {
      type: EFilterEntity.date_range,
      label: 'Time range',
      name: 'range',
      field: 'range',
      single: true,
    },
    side: {
      type: EFilterEntity.colors_single_select,
      formatter: EFilterFormatter.colors_single_select,
      label: 'Side',
      name: 'sides',
      field: 'sides',
      listSingleColors: ORDER_SIDE,
      single: true,
    },
    statuses: {
      type: EFilterEntity.colors_multi_select,
      formatter: EFilterFormatter.colors_multi_select,
      label: 'Statuses',
      name: 'statuses',
      field: 'statuses',
      listMultiColors: ORDER_STATUS,
      single: true,
    },
    ...(isAdmin
      ? {
          bot: {
            type: EFilterEntity.colors_multi_select,
            formatter: EFilterFormatter.colors_multi_select,
            label: 'Bot',
            name: 'bot',
            field: 'bot',
            listMultiColors: [
              ...ORDER_BOT,
              //TODO remove and put it as another bot from common bots array
              {
                value: 'regular',
                label: 'Regular',
                color: EFilterColor.blue_2,
              },
            ],
            single: true,
          },
          accounts: {
            type: EFilterEntity.cex_accounts_multi_select,
            formatter: EFilterFormatter.cex_accounts_multi_select,
            label: 'Accounts',
            name: 'accounts',
            field: 'account_ids',
            single: true,
          },
        }
      : {}),
  };
};
