import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const WalletIcon: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors['gray-1'],
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.74805 14.5625C7.74805 14.1828 8.05585 13.875 8.43555 13.875H11.1855C11.5652 13.875 11.873 14.1828 11.873 14.5625C11.873 14.9422 11.5652 15.25 11.1855 15.25H8.43555C8.05585 15.25 7.74805 14.9422 7.74805 14.5625Z"
        fill={color}
      />
      <path d="M5 10.4375H18.75V11.8125H5V10.4375Z" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.95911 7H14.7909C15.3443 6.99999 15.8011 6.99998 16.1731 7.03038C16.5596 7.06196 16.9148 7.12972 17.2485 7.29973C17.7659 7.56338 18.1866 7.98408 18.4503 8.50153C18.6203 8.83519 18.688 9.19043 18.7196 9.57687C18.75 9.94894 18.75 10.4057 18.75 10.9591V14.0409C18.75 14.5943 18.75 15.0511 18.7196 15.4231C18.688 15.8096 18.6203 16.1648 18.4503 16.4985C18.1866 17.0159 17.7659 17.4366 17.2485 17.7003C16.9148 17.8703 16.5596 17.938 16.1731 17.9696C15.8011 18 15.3443 18 14.7909 18H8.95912C8.4057 18 7.94895 18 7.57687 17.9696C7.19043 17.938 6.83519 17.8703 6.50153 17.7003C5.98408 17.4366 5.56338 17.0159 5.29973 16.4985C5.12972 16.1648 5.06196 15.8096 5.03038 15.4231C4.99998 15.0511 4.99999 14.5943 5 14.0409V10.9591C4.99999 10.4057 4.99998 9.94894 5.03038 9.57687C5.06196 9.19043 5.12972 8.83519 5.29973 8.50153C5.56338 7.98408 5.98408 7.56338 6.50153 7.29973C6.83519 7.12972 7.19043 7.06196 7.57687 7.03038C7.94894 6.99998 8.40569 6.99999 8.95911 7ZM7.68884 8.40082C7.38743 8.42544 7.23329 8.47008 7.12576 8.52487C6.86704 8.65669 6.65669 8.86704 6.52487 9.12576C6.47008 9.23329 6.42544 9.38743 6.40082 9.68884C6.37554 9.99828 6.375 10.3986 6.375 10.9875V14.0125C6.375 14.6014 6.37554 15.0017 6.40082 15.3112C6.42544 15.6126 6.47008 15.7667 6.52487 15.8742C6.65669 16.133 6.86704 16.3433 7.12576 16.4751C7.23329 16.5299 7.38743 16.5746 7.68884 16.5992C7.99828 16.6245 8.3986 16.625 8.9875 16.625H14.7625C15.3514 16.625 15.7517 16.6245 16.0612 16.5992C16.3626 16.5746 16.5167 16.5299 16.6242 16.4751C16.883 16.3433 17.0933 16.133 17.2251 15.8742C17.2799 15.7667 17.3246 15.6126 17.3492 15.3112C17.3745 15.0017 17.375 14.6014 17.375 14.0125V10.9875C17.375 10.3986 17.3745 9.99828 17.3492 9.68884C17.3246 9.38743 17.2799 9.23329 17.2251 9.12576C17.0933 8.86704 16.883 8.65669 16.6242 8.52487C16.5167 8.47008 16.3626 8.42544 16.0612 8.40082C15.7517 8.37554 15.3514 8.375 14.7625 8.375H8.9875C8.3986 8.375 7.99828 8.37554 7.68884 8.40082Z"
        fill={color}
      />
    </svg>
  );
};

export { WalletIcon };
