import React, { useMemo } from 'react';
import { isNil } from 'lodash';
import dayjs from 'dayjs';
import cn from 'classnames';

import {
  IFilterValue,
  EFilterEntity,
  IFilterColorSelectItem,
  IUserFlterItem,
} from 'types/filters/common';
import { ICexAccount } from 'types/accounts';

import './style.scss';

interface IDisplayFilter {
  id: string;
  name: string;
  value: string;
}

interface ISettedFilterProps {
  isFilterSetted: boolean;
  settedFilters: IFilterValue[];
}

const shortenText = (text: string) => {
  if (text.length <= 10) return text;

  return `${text.slice(0, 4)}...${text.slice(-4)}`;
};

const SettedFilters: React.FC<ISettedFilterProps> = ({ isFilterSetted, settedFilters }) => {
  const settedFiltersArray = useMemo<IDisplayFilter[]>(() => {
    return settedFilters
      .map(el => {
        if (!el.scheme) return null;

        switch (el.type) {
          case EFilterEntity.boolean: {
            const value = el.value?.value;

            if (isNil(value)) return null;

            return {
              id: el.id,
              name: el.scheme.settedLabel ?? el.scheme.label,
              value: value.toString(),
            };
          }
          case EFilterEntity.colors_multi_select: {
            const values = el.value?.value as IFilterColorSelectItem[] | undefined;

            if (!values || values.length === 0) return null;

            return {
              id: el.id,
              name: el.scheme.settedLabel ?? el.scheme.label,
              value: values.map(el => el.label).join(', '),
            };
          }
          case EFilterEntity.colors_single_select: {
            const value = el.value?.value as IFilterColorSelectItem | null;

            if (!value) return null;

            return {
              id: el.id,
              name: el.scheme.settedLabel ?? el.scheme.label,
              value: value.label,
            };
          }
          case EFilterEntity.date_range: {
            const value = el.value?.value as string | undefined;

            if (!value) return null;

            return {
              id: el.id,
              name: el.scheme.settedLabel ?? el.scheme.label,
              value: value
                .split('-')
                .map(el => dayjs(Number(el) * 1000).format('L LT'))
                .join(' - '),
            };
          }
          case EFilterEntity.multi_search: {
            const value = el.value?.value as string[] | undefined;

            if (!value) return null;

            const valueLength = value.length;

            return {
              id: el.id,
              name: el.scheme.settedLabel ?? el.scheme.label,
              value:
                value
                  .slice(0, 2)
                  .map(el => shortenText(el))
                  .join(', ') + (valueLength > 2 ? '...' : ''),
            };
          }
          case EFilterEntity.user_multi_select: {
            const value = el.value?.value as IUserFlterItem[] | undefined;

            if (!value) return null;

            return {
              id: el.id,
              name: el.scheme.settedLabel ?? el.scheme.label,
              value: value.map(el => el.login).join(', '),
            };
          }
          case EFilterEntity.cex_accounts_multi_select: {
            const value = el.value?.value as ICexAccount[] | undefined;

            if (!value) return null;

            return {
              id: el.id,
              name: el.scheme.settedLabel ?? el.scheme.label,
              value: value.map(el => el.notes).join(', '),
            };
          }
          case EFilterEntity.number:
          case EFilterEntity.natural_number: {
            const value = el.value?.value as string | undefined;

            if (!value || isNaN(Number(value))) return null;

            return {
              id: el.id,
              name: el.scheme.settedLabel ?? el.scheme.label,
              value: el.value?.connection + value,
            };
          }
          default:
            return null;
        }
      })
      .filter(el => el !== null) as IDisplayFilter[];
  }, [settedFilters]);

  return (
    <div className={cn('mm-setted-filters-row', { '_with-filters': isFilterSetted })}>
      {(!isFilterSetted || settedFiltersArray.length === 0) && (
        <span className="no-filters">No filters setted</span>
      )}
      {isFilterSetted &&
        settedFiltersArray.map(el => (
          <div className="setted-filter-item" key={el.id}>
            <span className="setted-filter-name">{el.name}:&nbsp;</span>{' '}
            <span className="setted-filter-value">{el.value}</span>
          </div>
        ))}
    </div>
  );
};

export { SettedFilters };
