import React, { useState, useCallback, useContext } from 'react';
import { useTypedSelector } from 'store';

import { AutoRefresh, Panel } from 'ui';
import { IPanelProps } from 'types/pairs';
import { CEX_NEW_ORDERS_RELOAD } from 'constant/reload';
import { CexPairContext } from 'context/CexPairContext';

import { DocumentIcon } from 'assets/icons';

import './style.scss';

const CexPairOrdersTable = React.lazy(() =>
  import('tables').then(module => ({ default: module.NewCexPairOrdersTable })),
);

const NewOrderHistory: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(initialOpened ?? false);
  const { accountsLoading } = useContext(CexPairContext);

  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);
    },
    [setIsOpened],
  );

  return (
    <Panel
      icon={DocumentIcon}
      title="New Order history"
      className="_table-container"
      initialOpened={initialOpened}
      onChange={handleSwitch}
      headerNode={
        isPanelOpened && (
          <div className="mm-cex-new-order-history-panel">
            <AutoRefresh
              busEvent={CEX_NEW_ORDERS_RELOAD}
              tooltipText="Auto refresh"
              pairId={cexPair.id}
              cacheName="cex-order-history"
            />
          </div>
        )
      }
    >
      {!accountsLoading && <CexPairOrdersTable />}
    </Panel>
  );
};

export { NewOrderHistory };
