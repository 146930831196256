import { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import { boolean, string, object } from 'yup';
import { useTypedDispatch } from 'store';

import { useDexBotConfig } from 'hooks/dex';
import { IDexPair } from 'types/pairs';
import { IDexBotSettings, EDexBot } from 'types/bots';
import { setAlertState, dropAlertState } from 'store/slices/ui';

interface IUseBotSettingsModalProps {
  pair: IDexPair;
  botSettings?: IDexBotSettings;
  onSave: () => void;
  onClose: () => void;
  bot: EDexBot;
}

export const useBotSettingsModal = ({
  pair,
  botSettings,
  onSave,
  onClose,
  bot,
}: IUseBotSettingsModalProps) => {
  const dispatch = useTypedDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [edited, setEdited] = useState<boolean>(false);

  const { updateBotConfig } = useDexBotConfig();

  const validationSchema = object({
    slippage_percent: string()
      .required('"Slippage" field is required')
      .test(
        'slippage_percent',
        '"Slippage" must pe percent value',
        (value: string | undefined) => !isNaN(Number(value)) && Number(value) <= 100,
      ),
    reserve_wallets_priority: string(),
    send_private_transactions: boolean(),
    is_enabled: boolean(),
  });

  const handleEditSettings = async ({
    slippage_percent,
    send_private_transactions,
    reserve_wallets_priority,
    is_enabled,
  }: {
    slippage_percent: string;
    send_private_transactions: boolean;
    reserve_wallets_priority: 'default' | 'random';
    is_enabled: boolean;
  }) => {
    setLoading(true);

    await updateBotConfig({
      pairId: pair.id,
      bot: bot,
      body: {
        ...botSettings,
        is_enabled,
        slippage_percent,
        send_private_transactions,
        reserve_wallets_priority,
      },
      onSuccess: () => {
        onClose();
        onSave();
        dispatch(
          setAlertState({
            type: 'success-img',
            text: 'Successfully changed bot settings!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      },
    });

    setLoading(false);
  };

  const { handleSubmit, setFieldValue, values, errors, touched } = useFormik({
    initialValues: {
      slippage_percent: botSettings?.slippage_percent ? botSettings.slippage_percent : '0.3',
      send_private_transactions: botSettings?.send_private_transactions! ?? false,
      reserve_wallets_priority: botSettings?.reserve_wallets_priority ?? 'default',
      is_enabled: botSettings?.is_enabled! ?? false,
    },
    onSubmit: handleEditSettings,
    validationSchema,
  });

  const handleSetFieldValue = useCallback(
    (
      field: string,
      value: any,
      options: { triggerEdit?: boolean; shouldValidate?: boolean } = {
        triggerEdit: false,
        shouldValidate: undefined,
      },
    ) => {
      if (options.triggerEdit) {
        setEdited(true);
      }

      setFieldValue(field, value, options.shouldValidate);
    },
    [setFieldValue],
  );

  useEffect(() => {
    const handleSetFormError = () => {
      const keys = Object.keys(errors);

      for (const key of keys) {
        const keyWithType = key as keyof typeof errors;

        if (errors[keyWithType] && touched[keyWithType]) {
          setFormError(errors[keyWithType]);
          return;
        }
      }

      setFormError(undefined);
    };

    handleSetFormError();
  }, [errors, touched]);

  return {
    validationSchema,
    loading,
    values,
    handleSubmit,
    setFieldValue: handleSetFieldValue,
    formError,
    edited,
  };
};
