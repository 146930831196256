import { ApiBot } from 'api';
import { useCallback, useEffect, useState } from 'react';
import { useTypedSelector } from 'store';
import { IBotStates } from 'types/bots';

const INITIAL_STATES: IBotStates = {
  order_book: { status: false },
  smart_liquidity: { status: false },
  buy_sell: { status: false },
  order_manager: { status: false },
};

const useBotStates = () => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);

  const [states, setStates] = useState<IBotStates>(INITIAL_STATES);
  const [loading, setLoading] = useState<boolean>(true);

  const load = useCallback(async () => {
    if (!cexPair?.id) return;

    try {
      setLoading(true);

      const { data, isSuccess } = await ApiBot.getBotStates({ pairId: cexPair.id });

      setStates(isSuccess ? data : INITIAL_STATES);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }, [cexPair?.id]);

  useEffect(() => {
    setStates(INITIAL_STATES);
    load();
  }, [load]);

  return { states, loading, load };
};

export { useBotStates };
