import React, { FC, InputHTMLAttributes, memo, useMemo } from 'react';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

import './toggleRadio.scss';

type Props = {
  label?: string;
  titles: string[];
  color?: 'primary';
  labelProps?: React.HTMLAttributes<HTMLLabelElement>;
} & Exclude<InputHTMLAttributes<HTMLInputElement>, 'type' | 'id' | 'value' | 'checked'>;

const ToggleRadioComponent: FC<Props> = ({
  titles,
  color = 'primary',
  disabled,
  label,
  labelProps,
  ...props
}) => {
  const innerTitles = useMemo(() => titles.map(el => ({ label: el, id: uuid() })), [titles]);

  return (
    <div>
      {label && (
        <div className={cn(disabled ? 'text-gray-2' : 'text-primary', 'mb-1.5 text-sm')}>
          {label}
        </div>
      )}
      <div
        className={cn('mm-toggle-radio', `mm-toggle-radio-${color}`, props.className, {
          'mm-toggle-radio--disabled': disabled,
        })}
      >
        {innerTitles.map(({ label, id }) => (
          <React.Fragment key={id}>
            <input
              {...props}
              disabled={disabled}
              id={id}
              type="radio"
              value={label}
              checked={props.value === label}
            />
            <label
              className={cn(
                'mm-toggle-radio__text',
                `mm-toggle-radio__text--${color}`,
                labelProps?.className,
                {
                  'mm-toggle-radio__text--selected': props.value === label,
                  'mm-toggle-radio__text--disabled': disabled && props.value === label,
                  '!cursor-not-allowed': disabled,
                },
              )}
              htmlFor={id}
            >
              <span className="mm-toggle-radio__text-node">{label}</span>
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const ToggleRadio = memo(ToggleRadioComponent);

export { ToggleRadio };
