import { useTypedSelector } from 'store';

import { convertTokenCEX } from 'utils';
import { formatFiat } from 'utils/formats';

interface IConvertedCurrenciesArgs {
  amount: string;
  selectedCurrency: string;
}

const useConvertedCurrencies = ({ amount, selectedCurrency }: IConvertedCurrenciesArgs) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const convertTo = [
    { symbol: cexPair.token_base.symbol, decimals: 18 },
    { symbol: cexPair.token_quote.symbol, decimals: 18 },
  ].filter(el => el.symbol !== selectedCurrency);

  return convertTo
    .map(el => {
      const converted = convertTokenCEX({
        from: selectedCurrency,
        to: el.symbol,
        amount,
        pair: cexPair,
      });

      return formatFiat(converted, el.decimals, '') + ' ' + el.symbol;
    })
    .join(' / ');
};

export { useConvertedCurrencies };
