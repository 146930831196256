import React from 'react';

const SureIcon: React.FC = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="url(#paint0_linear_878_13517)" />
      <path
        d="M44.7073 59.0732C44.7073 57.0732 45.1463 55.2195 46.0244 53.5122C46.9024 51.8049 47.9756 50.3415 49.2439 49.1219C50.5122 47.8536 51.7805 46.6585 53.0488 45.5366C54.3171 44.3658 55.3902 43.1219 56.2683 41.8049C57.1463 40.439 57.5854 39.0244 57.5854 37.561C57.5854 35.5122 56.878 33.9512 55.4634 32.878C54.0488 31.7561 52.1463 31.1951 49.7561 31.1951C47.4634 31.1951 45.4878 31.7805 43.8293 32.9512C42.1707 34.1219 40.9756 35.7805 40.2439 37.9268L33 33.8293C34.3659 30.3659 36.561 27.7073 39.5854 25.8537C42.6585 23.9512 46.122 23 49.9756 23C54.3171 23 58.0732 24.2439 61.2439 26.7317C64.4146 29.2195 66 32.6585 66 37.0488C66 39.0976 65.561 41.0244 64.6829 42.8293C63.8049 44.5854 62.7317 46.0976 61.4634 47.3658C60.1951 48.6341 58.9268 49.878 57.6585 51.0976C56.3902 52.2683 55.3171 53.5366 54.439 54.9024C53.561 56.2683 53.122 57.6585 53.122 59.0732H44.7073ZM52.8293 74.439C51.7561 75.5122 50.4634 76.0488 48.9512 76.0488C47.439 76.0488 46.1463 75.5122 45.0732 74.439C44 73.3658 43.4634 72.0732 43.4634 70.561C43.4634 69.0488 43.9756 67.7561 45 66.6829C46.0732 65.6097 47.3902 65.0732 48.9512 65.0732C50.4634 65.0732 51.7561 65.6097 52.8293 66.6829C53.9024 67.7561 54.439 69.0488 54.439 70.561C54.439 72.0732 53.9024 73.3658 52.8293 74.439Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_878_13517"
          x1="-5.58793e-07"
          y1="50"
          x2="100"
          y2="50"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A3AFF" />
          <stop offset="1" stopColor="#6D3AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { SureIcon };
