import { useState, useRef } from 'react';
import {
  FloatingPortal,
  FloatingArrow,
  useFloating,
  useRole,
  useHover,
  offset,
  shift,
  Placement,
  autoUpdate,
  useDismiss,
  useFocus,
  useInteractions,
  arrow,
} from '@floating-ui/react';

import './index.scss';

export enum TOOLTIP_STYLES {
  common = 'mm_tooltip_common',
}

interface ITooltipProps {
  triggerProps?: React.HTMLAttributes<HTMLDivElement>;
  contentProps?: React.HTMLAttributes<HTMLDivElement>;
  arrowProps?: React.RefAttributes<SVGSVGElement>;
  trigger: React.ReactNode;
  content?: React.ReactNode;
  options: { placement: Placement | undefined; offset: number };
  style?: TOOLTIP_STYLES;
}

const Tooltip: React.FC<ITooltipProps> = ({
  trigger,
  triggerProps,
  content,
  contentProps,
  arrowProps,
  style,
  options,
}) => {
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    placement: options.placement,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(options.offset), shift({ padding: 5 }), arrow({ element: arrowRef })],
  });

  const hover = useHover(context);
  const dissmiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const focus = useFocus(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, dissmiss, role, focus]);

  return (
    <>
      <div
        className={(style ?? TOOLTIP_STYLES.common).concat('__trigger')}
        ref={refs.setReference}
        {...triggerProps}
        {...getReferenceProps()}
      >
        {trigger}
      </div>
      {isOpen && content && (
        <FloatingPortal>
          <div
            className={(style ?? TOOLTIP_STYLES.common).concat('__content')}
            ref={refs.setFloating}
            {...contentProps}
            style={{ ...floatingStyles, zIndex: 1001 }}
            {...getFloatingProps()}
          >
            <FloatingArrow
              className={(style ?? TOOLTIP_STYLES.common).concat('__arrow')}
              {...arrowProps}
              ref={arrowRef}
              context={context}
            />
            {content}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export { Tooltip };
