import React, { lazy, useMemo } from 'react';
import { useTypedSelector } from 'store';

import { Panel, BotEnableStatus } from 'ui';
import { EDexBot } from 'types/bots';
import { IPanelProps } from 'types/pairs';

import { SmartLiquidityBotIcon } from 'assets/icons';

const PanelContent = lazy(() => import('./Content'));

const SmartLiquidityBot: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const pairId = useTypedSelector(store => store.pairs.selectedDexPair)!.id;
  const pairConnectedBots = useTypedSelector(
    store => store.pairConnectedBots.pairsConnectedBots[pairId],
  );

  const isBotEnabled = useMemo(
    () => (pairConnectedBots ? pairConnectedBots[EDexBot.sl_bot] : undefined),
    [pairConnectedBots],
  );

  return (
    <Panel
      title="Smart liquidity algorithm"
      icon={SmartLiquidityBotIcon}
      initialOpened={initialOpened}
      onChange={setIsOpened}
      headerNode={
        isBotEnabled !== undefined && (
          <BotEnableStatus status={isBotEnabled ? 'enabled' : 'disabled'} />
        )
      }
    >
      <PanelContent />
    </Panel>
  );
};

export { SmartLiquidityBot };
