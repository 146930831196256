import React from 'react';
import cn from 'classnames';

import { ISpinnerSize } from '../types';

import './spinner.scss';

interface ISpinner {
  size?: ISpinnerSize;
  className?: string;
}

const Spinner: React.FC<ISpinner> = ({ size = 'medium', className }) => {
  return <div className={cn(className, 'mm-common-spinner', `mm-common-spinner-${size}`)} />;
};

export { Spinner };
