import { useCallback, useContext, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTypedSelector } from 'store';

import { ICexOrderbookGridBotTaskExtended, ICexOrderbookGridBotTask } from 'types/bots';
import { ICexAccount } from 'types/accounts';
import { CexPairContext } from 'context/CexPairContext';
import { bn, humanizeBn } from 'tools';
import { convertTokenCEX } from 'utils';

interface IForm {
  startPriceMode: 'manual' | 'last_price' | 'mid_spread';
  account: ICexAccount | null;
  active: boolean;
  id: number;
  starting_price: string;
  spread_percentage: string;
  asks_order_number: string;
  bids_order_number: string;
  asks_min_amount: string;
  asks_max_amount: string;
  bids_min_amount: string;
  bids_max_amount: string;
  dynamic_mode_enabled: boolean;
  price_delta: string;
  counter_orders_limit: string;
  step_size: string;
}

const validationSchema = Yup.object({
  startPriceMode: Yup.string(),
  account: Yup.object().required('Field is required'),
  active: Yup.boolean(),
  id: Yup.number(),
  starting_price: Yup.number().when('startPriceMode', {
    is: 'start_price',
    then: schema => schema.required('Field is required').moreThan(0, 'Field is required'),
  }),
  spread_percentage: Yup.number()
    .required('Field is required')
    .moreThan(0, 'Should be more than 0'),
  asks_order_number: Yup.number()
    .required('Field is required')
    .moreThan(0, 'Should be more than 0')
    .max(40, 'Should be less than or equal 40'),
  bids_order_number: Yup.number()
    .required('Field is required')
    .moreThan(0, 'Should be more than 0')
    .max(40, 'Should be less than or equal 40'),
  asks_min_amount: Yup.number().required('Field is required').moreThan(0, 'Should be more than 0'),
  asks_max_amount: Yup.number()
    .required('Field is required')
    .moreThan(0, 'Should be more than 0')
    .test(
      'max_amount',
      'Should be more than min value',
      function (max_amount: number | string | undefined) {
        const min_amount = this.options.context?.asks_min_amount;

        if (isNaN(Number(min_amount)) || isNaN(Number(max_amount))) return true;

        return bn(max_amount).gte(bn(min_amount));
      },
    ),
  bids_min_amount: Yup.number().required('Field is required').moreThan(0, 'Should be more than 0'),
  bids_max_amount: Yup.number()
    .required('Field is required')
    .moreThan(0, 'Should be more than 0')
    .test(
      'max_amount',
      'Should be more than min value',
      function (max_amount: number | string | undefined) {
        const min_amount = this.options.context?.bids_min_amount;

        if (isNaN(Number(min_amount)) || isNaN(Number(max_amount))) return true;

        return bn(max_amount).gte(bn(min_amount));
      },
    ),
  step_size: Yup.number()
    .required('Field is required')
    .moreThan(0, 'Should be more than 0')
    .test(
      'step_size',
      'Should be less than spread percentage',
      function (step_size: number | string | undefined) {
        const spread_percentage = this.options.context?.spread_percentage;

        if (isNaN(Number(spread_percentage)) || isNaN(Number(step_size))) return true;

        return Number(spread_percentage) > Number(step_size);
      },
    ),
  price_delta: Yup.number().test(
    'price_delta',
    'Field is required',
    function (price_delta: number | string | undefined) {
      const dynamic_mode_enabled = this.options.context?.dynamic_mode_enabled;

      return dynamic_mode_enabled ? Number(price_delta) > 0 : true;
    },
  ),
  counter_orders_limit: Yup.number().test(
    'counter_orders_limit',
    'Field is required',
    function (counter_orders_limit: number | string | undefined) {
      const dynamic_mode_enabled = this.options.context?.dynamic_mode_enabled;

      return dynamic_mode_enabled ? Number(counter_orders_limit) >= 0 : true;
    },
  ),
  dynamic_mode_enabled: Yup.boolean(),
});

interface IUseTaskModalProps {
  onSubmit: (task: ICexOrderbookGridBotTask) => void;
  task?: ICexOrderbookGridBotTaskExtended;
  id: number;
}

const useTaskModal = ({ onSubmit: onSubmitProp, task, id }: IUseTaskModalProps) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const availableCurrencies = useMemo(
    () => [cexPair.token_base.symbol, cexPair.token_quote.symbol],
    [cexPair.token_base.symbol, cexPair.token_quote.symbol],
  );

  const [selectedAskCurrency, setSelectedAskCurrency] = useState(availableCurrencies[1]);
  const [selectedBidCurrency, setSelectedBidCurrency] = useState(availableCurrencies[1]);

  const { accounts, accountsLoading } = useContext(CexPairContext);

  const initialValues = useMemo<IForm>(() => {
    if (task) {
      return {
        startPriceMode: task.starting_price_mode,
        account: accounts.find(el => el.id === task.account_id) ?? null,
        active: task.active,
        id,
        starting_price: task.starting_price,
        spread_percentage: task.spread_percentage,
        asks_order_number: task.ask_grid_config.order_number.toString(),
        bids_order_number: task.bid_grid_config.order_number.toString(),
        asks_min_amount: task.ask_grid_config.size_min,
        asks_max_amount: task.ask_grid_config.size_max,
        bids_min_amount: task.bid_grid_config.size_min,
        bids_max_amount: task.bid_grid_config.size_max,
        dynamic_mode_enabled: task.dynamic_mode.enabled,
        counter_orders_limit: task.dynamic_mode.counter_orders_limit.toString(),
        price_delta: task.dynamic_mode.price_delta,

        step_size: task.step_size,
      };
    }

    return {
      startPriceMode: 'last_price',
      account: null,
      active: true,
      id,
      starting_price: '',
      spread_percentage: '',
      asks_order_number: '10',
      bids_order_number: '10',
      asks_min_amount: '',
      asks_max_amount: '',
      bids_min_amount: '',
      bids_max_amount: '',
      dynamic_mode_enabled: false,
      counter_orders_limit: '',
      price_delta: '',

      step_size: '',
    };
  }, [id, task, accounts]);

  const onSubmit = useCallback(
    async ({
      startPriceMode,
      account,
      asks_min_amount,
      asks_max_amount,
      bids_min_amount,
      bids_max_amount,
      asks_order_number,
      bids_order_number,
      spread_percentage,
      starting_price,
      id,
      active,
      step_size,
      dynamic_mode_enabled,
      counter_orders_limit,
      price_delta,
    }: IForm) => {
      if (!account) return;

      try {
        setLoading(true);

        onSubmitProp({
          account_id: account.id,
          active,
          id,
          pair_id: cexPair.id,
          starting_price:
            startPriceMode === 'last_price' || startPriceMode === 'mid_spread'
              ? '0'
              : starting_price,
          starting_price_mode: startPriceMode,
          spread_percentage: spread_percentage,
          ask_grid_config: {
            order_number: Number(asks_order_number),
            size_min: humanizeBn(
              convertTokenCEX({
                from: selectedAskCurrency,
                to: cexPair.token_quote.symbol,
                amount: asks_min_amount,
                pair: cexPair,
              }),
              18,
            ),
            size_max: humanizeBn(
              convertTokenCEX({
                from: selectedAskCurrency,
                to: cexPair.token_quote.symbol,
                amount: asks_max_amount,
                pair: cexPair,
              }),
              18,
            ),
          },
          bid_grid_config: {
            order_number: Number(bids_order_number),
            size_min: humanizeBn(
              convertTokenCEX({
                from: selectedBidCurrency,
                to: cexPair.token_quote.symbol,
                amount: bids_min_amount,
                pair: cexPair,
              }),
              18,
            ),
            size_max: humanizeBn(
              convertTokenCEX({
                from: selectedBidCurrency,
                to: cexPair.token_quote.symbol,
                amount: bids_max_amount,
                pair: cexPair,
              }),
              18,
            ),
          },
          dynamic_mode: {
            counter_orders_limit: counter_orders_limit === '' ? 0 : Number(counter_orders_limit),
            enabled: dynamic_mode_enabled,
            price_delta: price_delta === '' ? '0' : price_delta,
          },
          step_size: step_size,
        });
      } finally {
        setLoading(false);
      }
    },
    [onSubmitProp, cexPair, selectedAskCurrency, selectedBidCurrency],
  );

  const { handleSubmit, setFieldValue, values, touched, errors } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const _setFieldValue = useCallback(
    (field: keyof IForm, value: any) => {
      setIsEdited(true);

      setFieldValue(field, value);
    },
    [setFieldValue],
  );

  return {
    accounts,
    accountsLoading,
    isEdited,
    loading,
    handleSubmit,
    setFieldValue: _setFieldValue,
    values,
    touched,
    errors,
    availableCurrencies,
    selectedAskCurrency,
    selectedBidCurrency,
    setSelectedAskCurrency,
    setSelectedBidCurrency,
  };
};

export { useTaskModal };
