import React from 'react';
import { useTypedSelector } from 'store';

import { IDexBalanceBotTask } from 'types/bots';
import { bn, bnFrom, addBignumbers, divideBignumbers, multiplyBignumbers } from 'tools/math';
import { formatToken } from 'utils/formats';

interface TotalAmountProps {
  original: IDexBalanceBotTask;
}

export const TotalAmount: React.FC<TotalAmountProps> = ({ original }) => {
  const { add_base_holder_options, add_quote_holder_options, type } = original;
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const options = add_base_holder_options ?? add_quote_holder_options;

  const minAmount = options?.min_amount ? options.min_amount : 0;
  const maxAmount = options?.max_amount ? options.max_amount : 0;
  const decimals =
    type === 'add_quote_holder' ? dexPair.token_quote.decimals : dexPair.token_base.decimals;

  const summarisedValue = addBignumbers(
    [bnFrom(minAmount), decimals],
    [bnFrom(maxAmount), decimals],
  );

  const averageValue = divideBignumbers([summarisedValue, decimals], [bn(2), 18]);

  const totalAmount = multiplyBignumbers([averageValue, decimals], [bn(1), 18]);

  const displayedTotalAmount = formatToken(totalAmount, 18);

  return (
    <div>
      &#126;
      {displayedTotalAmount}{' '}
      {original.type === 'add_base_holder' ? dexPair.token_base.symbol : dexPair.token_quote.symbol}
    </div>
  );
};
