import React from 'react';
import { isNil } from 'lodash';
import cn from 'classnames';

import { Spinner } from 'ui';

import './style.scss';

interface ITableWrapperProps {
  loading: boolean;
  records?: any[];
  children?: React.ReactNode;
  hasMore: boolean;
  heightLimit?: number; //px
  cRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  notFoundText?: string;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
}

const TableWrapper: React.FC<ITableWrapperProps> = ({
  children,
  loading,
  records,
  hasMore,
  heightLimit,
  cRef,
  className,
  notFoundText = 'Records not found',
  onScroll,
}) => {
  return (
    <div
      ref={cRef}
      onScroll={onScroll}
      className={cn('mm-table-wrapper-container', 'scrollable', className)}
      style={{ maxHeight: heightLimit, overflow: !isNil(heightLimit) ? 'auto' : 'visible' }}
    >
      {loading && (records === undefined || records.length === 0) ? (
        <div className="mm-table-wrapper-loading" style={{ height: heightLimit }}>
          <Spinner size="medium" />
        </div>
      ) : records === undefined && !loading && !hasMore ? (
        <div className="mm-table-wrapper-no-info">You get error while loading table records</div>
      ) : records && records.length === 0 && !loading ? (
        <div className="mm-table-wrapper-no-info">{notFoundText}</div>
      ) : (
        children
      )}
    </div>
  );
};

export { TableWrapper };
