import React, { memo, useState } from 'react';
import cn from 'classnames';
import { useDebounce, useDeepCompareEffect } from 'react-use';

import { CHARTS_COLORS } from 'constant/charts';

import './style.scss';

type Item = { label: string; enabled: boolean; value: any };

interface IItemSelectorProps {
  selected: Item[];
  setSelected: (item: Item[]) => void;
  layout?: {
    flexDirection?: 'column' | 'row';
    rowHeight?: number;
    height?: string;
  };
  colorfulLabels?: boolean;
}

const ItemSelector: React.FC<IItemSelectorProps> = ({
  selected,
  setSelected,
  layout = { flexDirection: 'row' },
  colorfulLabels = false,
}) => {
  const [selectedInner, setSelectedInner] = useState<Item[]>(selected);

  useDeepCompareEffect(() => {
    setSelectedInner(selected);
  }, [selected]);

  useDebounce(
    () => {
      setSelected(selectedInner);
    },
    300,
    [selectedInner],
  );

  return (
    <div
      className="mm-item-selector"
      style={{
        flexDirection: layout.flexDirection,
        gap: layout.flexDirection === 'row' ? '16px' : '0',
        justifyContent: layout.flexDirection === 'column' ? 'space-around' : undefined,
        height: layout.height,
      }}
    >
      {selectedInner.map((item, idx) => (
        <div
          key={item.label}
          style={{ height: layout.rowHeight }}
          className={cn('item-item', { selected: item.enabled })}
          onClick={() => {
            setSelectedInner(v => {
              const value = v.map(oldItem => {
                if (oldItem.value === item.value) return { ...oldItem, enabled: !oldItem.enabled };
                return oldItem;
              });
              return value;
            });
          }}
        >
          <div
            className="pin"
            style={{
              backgroundColor: item.enabled ? `${CHARTS_COLORS[idx]}` : '#B2B2C2',
            }}
          />
          <span
            className="label"
            style={{
              color: item.enabled ? (colorfulLabels ? CHARTS_COLORS[idx] : '#fafafa') : '#B2B2C2',
            }}
          >
            {item.label}
          </span>
        </div>
      ))}
    </div>
  );
};

const memoItemSelector = memo(ItemSelector);

export { memoItemSelector as ItemSelector };
