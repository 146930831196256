import { isNil } from 'lodash';

import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse } from '../apiBase';
import { IWallet } from './models';

class ApiWallets extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiWallets | undefined;
  private static instance = (() => {
    if (ApiWallets.__instance === undefined) {
      ApiWallets.__instance = new ApiWallets();
    }
    return ApiWallets.__instance;
  })();

  public static getWallets = async ({
    limit,
    lastSeenId,
    balance_usd,
    transactions_count,
    not_connected,
  }: {
    limit: number;
    lastSeenId?: number;
    balance_usd?: string[];
    transactions_count?: string[];
    not_connected?: boolean;
  }) => {
    try {
      const urlParams = new URLSearchParams();
      urlParams.append('limit', limit.toString());
      lastSeenId && urlParams.append('last_seen_id', lastSeenId.toString());

      !isNil(not_connected) && urlParams.append('not_connected', not_connected.toString());

      if (balance_usd) {
        for (const _balance_usd of balance_usd) {
          urlParams.append('balance_usd', _balance_usd);
        }
      }

      if (transactions_count) {
        for (const _tx_count of transactions_count) {
          urlParams.append('transactions_count', _tx_count);
        }
      }

      const response = await ApiWallets.instance.get<
        IBaseResponse<{ has_next: boolean; items: IWallet[] }>
      >('/wallets?' + urlParams.toString());

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{ has_next: boolean; items: IWallet[] }>({ error });
    }
  };

  public static createWallets = async ({ count }: { count: number }) => {
    try {
      const response = await ApiWallets.instance.post<IBaseResponse<any>>('/wallets', { count });

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };
}

export { ApiWallets };
