import { FC, memo, ReactNode } from 'react';

import './createTaskForm.scss';

type Props = {
  children: ReactNode;
};

export const CreateTaskFormComponent: FC<Props> = ({ children }) => (
  <div className="mm-create-task-form">{children}</div>
);

const CreateTaskForm = memo(CreateTaskFormComponent);

export { CreateTaskForm };
