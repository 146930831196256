import React, { useCallback, useMemo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  offset,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react';

import { IFilterColorSelectItem } from 'types/filters/common';
import { tailwindConfig } from 'tailwind-config';

import { ArrowDropDown, CloseIcon } from 'assets/icons';
import '../style.scss';
import './colorsMultiSelect.scss';

interface IProps {
  value: IFilterColorSelectItem[];
  setValue: (v: IFilterColorSelectItem[]) => void;
  items: IFilterColorSelectItem[];
}

const Value: React.FC<{ color: string; label: string; onDelete: () => void }> = ({
  color,
  label,
  onDelete,
}) => {
  const [showClose, setShowClose] = useState<boolean>(false);

  const bgColor = color.replace(')', ', 0.2)').replace('rgb', 'rgba');

  const handleDelete = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      onDelete();
    },
    [onDelete],
  );

  return (
    <div
      className="colorized-multi-select-value"
      onMouseEnter={() => setShowClose(true)}
      onMouseLeave={() => setShowClose(false)}
      style={{ backgroundColor: bgColor, color }}
    >
      <div className="colorized-value-container">
        <span className={cn('label', { shorten: showClose })}>{label}</span>
        {showClose && (
          <button className="value-delete" onClick={handleDelete}>
            <CloseIcon color={color} />
          </button>
        )}
      </div>
    </div>
  );
};

const ColorsMultiSelectField: React.FC<IProps> = ({ setValue, value, items }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const isEmpty = useMemo(() => value.length === 0, [value]);

  const { refs, context, floatingStyles } = useFloating({
    open: isOpened,
    onOpenChange: setIsOpened,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
            width: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([dismiss, role]);

  const innerItems = useMemo(
    () => items.filter(el => !value.find(value => value.value === el.value)),
    [items, value],
  );

  const handleSelectItem = useCallback(
    (item: IFilterColorSelectItem) => {
      setValue([...value].concat(item));
    },
    [value, setValue],
  );

  const handleDeleteItem = useCallback(
    (item: IFilterColorSelectItem) => {
      setValue([...value].filter(el => el.value !== item.value));
    },
    [value, setValue],
  );

  return (
    <div className="mm-filter-colors-multi-select-field">
      <div
        className="mm-filter-field-container"
        tabIndex={0}
        ref={refs.setReference}
        aria-labelledby="select-label"
        aria-autocomplete="none"
        {...getReferenceProps()}
        onClick={() => setIsOpened(v => !v)}
      >
        {isEmpty && <span className="mm-filter-field-placeholder">Select</span>}
        {!isEmpty && (
          <div className="selected-container">
            <div className="selected-values-row">
              {value.slice(0, 3).map(val => (
                <Value
                  key={val.value}
                  color={val.color}
                  label={val.label}
                  onDelete={() => handleDeleteItem(val)}
                />
              ))}
            </div>
            {value.length > 3 && <div className="remain-count">+{value.length - 3}</div>}
          </div>
        )}
        <motion.div animate={isOpened ? { rotate: 180 } : undefined}>
          <ArrowDropDown color={tailwindConfig.theme.colors['black-1']} />
        </motion.div>
      </div>
      <AnimatePresence>
        {isOpened && (
          <FloatingPortal>
            <FloatingFocusManager context={context} modal={false}>
              <div
                ref={refs.setFloating}
                style={floatingStyles}
                className="mm-filter-select-field__dropdown colors-multi-select-dropdown scrollable"
                {...getFloatingProps()}
              >
                {value.length !== 0 && (
                  <div className="selected-values">
                    {value.map(val => (
                      <Value
                        key={val.value}
                        color={val.color}
                        label={val.label}
                        onDelete={() => handleDeleteItem(val)}
                      />
                    ))}
                  </div>
                )}
                {innerItems.length > 0 &&
                  innerItems.map((item, i) => (
                    <div
                      className="dropdown__item"
                      key={i}
                      role="option"
                      tabIndex={-1}
                      {...getItemProps({
                        onClick() {
                          handleSelectItem(item);
                        },
                        onKeyDown(event) {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                            handleSelectItem(item);
                          }

                          if (event.key === ' ') {
                            event.preventDefault();
                            handleSelectItem(item);
                          }
                        },
                      })}
                    >
                      <span style={{ color: item.color }}>{item.label}</span>
                    </div>
                  ))}
              </div>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </div>
  );
};

export { ColorsMultiSelectField };
