import React, { createContext, useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTypedSelector } from 'store';
import axios, { CancelTokenSource } from 'axios';
import { BigNumber } from '@ethersproject/bignumber';
import { Time } from 'lightweight-charts';
import { uniq } from 'lodash';
import dayjs from 'dayjs';

import { ApiDexSLBot } from 'api';
import { useDexCandlesByTimeframe, useDexBotConfig } from 'hooks/dex';
import { EDexBot, IDexSLBotConfig, IDexSLSimulationOptions } from 'types/bots';
import { ATR_PERIOD } from 'constant/numbers';
import { SL_TIMEFRAMES } from 'types/dex-sl-bot';
import { parseDurationToSeconds } from 'utils';
import { durationToMs } from 'utils/duration';
import { calculateATRs } from 'utils/calculates';
import { formatFiat, formatToken } from 'utils/formats';
import { dexDailyFees } from 'utils/daily';
import { IKattanaDexChartResponse } from 'api/apiCharts/models';
import { IDexName, dexConfigs } from 'web3';
import {
  IDexSLBotTabs,
  IDexSLConfigClientOptions,
  IChartFormInfo,
  IPreset,
  IDexSLShortPreset,
  INTERVAL_DEFAULT,
  PERIOD_DEFAULT,
  SEED_DEFAULT,
  DEFAULT_TRADE_SIZE,
  DEFAULT_TRADE_FREQUENCY,
  DEFAULT_VOLATILITY,
} from 'types/dex-sl-bot';
import {
  bn,
  bnFrom,
  humanizeBn,
  divideBignumbers,
  addBignumbers,
  multiplyBignumbers,
} from 'tools/math';

export interface IOrganicVolume {
  dailyVolume: BigNumber;
  dailyVolumeUsd: BigNumber;
  dailyFee: {
    base: {
      amount: BigNumber;
      usd: BigNumber;
    };
    fee: {
      amount: BigNumber;
      usd: BigNumber;
    };
    total: {
      usd: BigNumber;
    };
  };
}

interface ISimulationRecord {
  time: Time;
  open: number;
  close: number;
  low: number;
  high: number;
  volume: number;
}

const initialChartFormInfo: IChartFormInfo = {
  pooledBaseToken: '',
  pooledFeeToken: '',
  poolLiquidity: '',
  tokenPrice: '',
  volumeDaily: '',
  feesDaily: '',
  baseAmount: '',
  quoteAmount: '',
  dexFeePercentage: 0,
  dexFee: '',
};

interface IDexSmartLiquidityContext {
  selectedTab: { get: IDexSLBotTabs; set: (tab: IDexSLBotTabs) => void };
  chartLoading: boolean;
  presetLoading: { get: boolean; set: (v: boolean) => void };
  botSettings: IDexSLBotConfig | undefined;
  initialConfig: IDexSLSimulationOptions | undefined;
  initialOptions: {
    get: IDexSLConfigClientOptions | undefined;
    set: (v: IDexSLConfigClientOptions | undefined) => void;
  };
  currentConfig: {
    get: IDexSLSimulationOptions | undefined;
    set: (v: IDexSLSimulationOptions | undefined) => void;
  };
  organicDailyVolumes: IOrganicVolume[];
  presetsShort: IDexSLShortPreset[] | undefined;
  presets: Record<number, IPreset> | undefined;
  selectedPresetId: { get: number | undefined; set: (v: number | undefined) => void };
  handleLoadBotSettings: () => Promise<void>;
  handleLoadPresets: () => Promise<void>;
  handleLoadPreset: (id: number) => Promise<IPreset | undefined>;
  handleSaveSLConfig: (config?: IDexSLSimulationOptions) => Promise<void> | undefined;
  loadSimulation: () => Promise<void>;
  errorMessage: string | undefined;
  chartErrorMessage: { type: 'error' | 'warning'; text: string } | undefined;

  //quick start
  tradeFrequencyCoefficient: { get: number; set: (v: number) => void };
  tradeSizeCoefficient: { get: number; set: (v: number) => void };
  volatilityCoefficient: { get: number; set: (v: number) => void };
  useAdditionalReserves: { get: boolean; set: (v: boolean) => void };
  manualPooledBase: { get: string; set: (v: string) => void };
  manualPooledQuote: { get: string; set: (v: string) => void };
  CancelTokenRef: React.MutableRefObject<CancelTokenSource | undefined>;
  organicCandles: Record<string, IKattanaDexChartResponse>;

  //chart info
  simulationRecords: ISimulationRecord[];
  chartFormInfo: IChartFormInfo;
}

export const DexSmartLiquidityContext = createContext<IDexSmartLiquidityContext>({
  selectedTab: { get: 'quick-start', set: () => {} },
  chartLoading: false,
  presetLoading: { get: false, set: () => {} },
  botSettings: undefined,
  initialConfig: undefined,
  initialOptions: { get: undefined, set: () => {} },
  currentConfig: { get: undefined, set: () => {} },
  organicDailyVolumes: [],
  presetsShort: undefined,
  presets: undefined,
  selectedPresetId: { get: undefined, set: () => {} },
  handleLoadBotSettings: async () => {},
  handleLoadPresets: async () => {},
  handleLoadPreset: async () => undefined,
  handleSaveSLConfig: async () => undefined,
  loadSimulation: async () => {},
  errorMessage: undefined,
  chartErrorMessage: undefined,

  //quick start
  tradeFrequencyCoefficient: { get: 0, set: () => {} },
  tradeSizeCoefficient: { get: 0, set: () => {} },
  volatilityCoefficient: { get: 0, set: () => {} },
  useAdditionalReserves: { get: false, set: () => {} },
  manualPooledBase: { get: '', set: () => {} },
  manualPooledQuote: { get: '', set: () => {} },
  CancelTokenRef: {} as React.MutableRefObject<CancelTokenSource | undefined>,
  organicCandles: {},

  //chart info
  simulationRecords: [],
  chartFormInfo: initialChartFormInfo,
});

interface IDexSmartLiquidityContextProviderProps {
  children?: React.ReactNode;
}

export const DexSmartLiquidityContextProvider: React.FC<IDexSmartLiquidityContextProviderProps> = ({
  children,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const CancelTokenRef = useRef<CancelTokenSource>();

  const [organicTimeframes, setOrganicTimeframes] = useState<string[]>([]);

  useEffect(() => {
    CancelTokenRef.current = axios.CancelToken.source();
  }, []);

  const { getBotConfig, updateBotConfig } = useDexBotConfig();
  const [botSettings, setBotSettings] = useState<IDexSLBotConfig | undefined>(undefined);
  const [initialConfig, setInitialConfig] = useState<IDexSLSimulationOptions | undefined>(
    undefined,
  );
  const [initialOptions, setInitialOptions] = useState<IDexSLConfigClientOptions | undefined>(
    undefined,
  );
  const [currentConfig, setCurrentConfig] = useState<IDexSLSimulationOptions | undefined>(
    undefined,
  );

  const [presetsShort, setPresetsShort] = useState<IDexSLShortPreset[] | undefined>(undefined);
  const [selectedPresetId, setSelectedPresetId] = useState<number | undefined>(undefined);
  const [presets, setPresets] = useState<Record<number, IPreset> | undefined>(undefined);

  const [selectedTab, setSelectedTab] = useState<IDexSLBotTabs>('quick-start');
  const [chartLoading, setChartLoading] = useState<boolean>(false);
  const [presetLoading, setPresetLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [chartErrorMessage, setChartErrorMessage] = useState<
    { type: 'warning' | 'error'; text: string } | undefined
  >(undefined);

  const [tradeFrequencyCoefficient, setTradeFrequencyCoefficient] =
    useState<number>(DEFAULT_TRADE_FREQUENCY);
  const [tradeSizeCoefficient, setTradeSizeCoefficient] = useState<number>(DEFAULT_TRADE_SIZE);
  const [volatilityCoefficient, setVolatilityCoefficient] = useState<number>(DEFAULT_VOLATILITY);

  //additional reserves
  const [useAdditionalReserves, setUseAdditionalReserves] = useState<boolean>(false);
  const [manualPooledBase, setManualPooledBase] = useState<string>('');
  const [manualPooledQuote, setManualPooledQuote] = useState<string>('');

  // chart info
  const [simulationRecords, setSimulationRecords] = useState<ISimulationRecord[]>([]);
  const [chartFormInfo, setChartFormInfo] = useState<IChartFormInfo>(initialChartFormInfo);

  const handleChangeConfig = useCallback(
    (newConfig: IDexSLSimulationOptions | undefined, modify = true) => {
      if (newConfig === undefined) {
        if (modify) {
          setCurrentConfig(undefined);
        }
        return undefined;
      }

      const newConfigResult = newConfig;

      if (selectedTab === 'quick-start') {
        try {
          const maxPause = parseDurationToSeconds(
            newConfigResult.config.random_strategy.max_pause_between_trades,
          );
          const minPause = parseDurationToSeconds(
            newConfigResult.config.random_strategy.min_pause_between_trades,
          );

          const averagePause = (minPause + maxPause) / 2;

          let newPeriod = newConfigResult.period;
          if (averagePause < 20) {
            newPeriod = '72h';
          } else if (averagePause < 60) {
            newPeriod = '336h';
          } else if (averagePause < 300) {
            newPeriod = '672h';
          }

          newConfigResult.period = newPeriod;
        } catch (error) {}
      }

      if (modify) {
        setCurrentConfig(newConfigResult);
      }
      return newConfigResult;
    },
    [selectedTab],
  );

  const loadSimulation = useCallback(async () => {
    if (!currentConfig || !currentConfig.config) {
      setChartErrorMessage({ type: 'warning', text: 'Chart has been simulated with empty config' });
      return;
    }

    try {
      setChartLoading(true);
      setChartErrorMessage(undefined);
      setSimulationRecords([]);

      const options: any = { ...currentConfig, pair_id: dexPair.id };

      const isManualBPooledValid = manualPooledBase !== '' && !isNaN(Number(manualPooledBase));
      const isManualQPooledValid = manualPooledQuote !== '' && !isNaN(Number(manualPooledQuote));

      if (useAdditionalReserves && isManualBPooledValid && isManualQPooledValid) {
        options.dex = dexPair.dex;

        //TODO remove this case
        if (options.dex === 'uniswap_v3:arbitrum_one' || options.dex === 'uniswap_v3:polygon') {
          options.dex = 'uniswap_v2';
        }
        if (options.dex === 'pancakeswap_v3:bsc') {
          options.dex = 'pancakeswap_v2';
        }

        options.reserve_base = bn(manualPooledBase, dexPair.token_base.decimals).toString();
        options.reserve_quote = bn(manualPooledQuote, dexPair.token_quote.decimals).toString();
      }

      const { isSuccess, errorMessage, data } = await ApiDexSLBot.getSLSimulationResult(
        {
          options,
        },
        { cancelToken: CancelTokenRef.current?.token ?? undefined },
      );

      setChartLoading(false);

      if (!isSuccess && !axios.isCancel(CancelTokenRef.current?.token.reason)) {
        setChartErrorMessage({ type: 'error', text: errorMessage });
        return;
      }

      if (data) {
        setCurrentConfig(v => (v ? { ...v, seed: Number(data.actual_seed) } : v));

        const _simulationRecords = data.items.map(el => ({
          time: Math.floor(dayjs(el.time).valueOf() / 1000) as Time,
          open: Number(el.open),
          close: Number(el.close),
          low: Number(el.low),
          high: Number(el.high),
          volume: Number(humanizeBn(bn(el.volume), dexPair.token_base.decimals ?? 18)),
        }));

        setSimulationRecords(_simulationRecords);

        const _dexPercentage = dexConfigs[dexPair.dex as IDexName]?.fee ?? 0;

        const _dexPercentageString = (_dexPercentage / 100).toFixed(6);

        const dexFeeTokens = multiplyBignumbers(
          [bnFrom(data.volumes.day), dexPair.token_base.decimals],
          [bn(_dexPercentageString, dexPair.token_fee.decimals), dexPair.token_fee.decimals],
        );
        const dexFeeUsd = multiplyBignumbers(
          [dexFeeTokens, 18],
          [bnFrom(dexPair.token_base.price_usd), 6],
        );

        setChartFormInfo({
          pooledBaseToken: formatToken(bnFrom(data.token_base.amount), dexPair.token_base.decimals),
          pooledFeeToken: formatToken(
            bnFrom(data.token_quote.amount),
            dexPair.token_quote.decimals,
          ),
          poolLiquidity: formatFiat(
            addBignumbers(
              [bnFrom(data.token_base.amount_usd), 6],
              [bnFrom(data.token_quote.amount_usd), 6],
            ),
            18,
            '$',
          ),
          tokenPrice: `${formatToken(
            bnFrom(data.token_base.price),
            dexPair.token_quote.decimals,
          )} / ${formatFiat(bnFrom(data.token_base.price_usd), 6, '$')}`,
          volumeDaily: `${formatToken(bnFrom(data.volumes.day), dexPair.token_base.decimals)} ${
            dexPair.token_base.symbol
          } / ${formatFiat(
            multiplyBignumbers(
              [bnFrom(data.volumes.day), dexPair.token_base.decimals],
              [bnFrom(data.token_base.price_usd), 6],
            ),
            18,
            '$',
          )}`,
          feesDaily: `${formatToken(bnFrom(data.fees.day), dexPair.token_fee.decimals)} ${
            dexPair.token_fee.symbol
          } / ${formatFiat(
            multiplyBignumbers(
              [bnFrom(data.fees.day), dexPair.token_fee.decimals],
              [bnFrom(dexPair.token_fee.price_usd), 6],
            ),
            18,
            '$',
          )}`,
          baseAmount: `${formatToken(
            bnFrom(data.base_amounts.min),
            dexPair.token_base.decimals,
          )} / ${formatToken(
            bnFrom(data.base_amounts.avg),
            dexPair.token_base.decimals,
          )} / ${formatToken(bnFrom(data.base_amounts.max), dexPair.token_base.decimals)}`,
          quoteAmount: `${formatToken(
            bnFrom(data.quote_amounts.min),
            dexPair.token_quote.decimals,
          )} / ${formatToken(
            bnFrom(data.quote_amounts.avg),
            dexPair.token_quote.decimals,
          )} / ${formatToken(bnFrom(data.quote_amounts.max), dexPair.token_quote.decimals)}`,
          dexFeePercentage: _dexPercentage,
          dexFee: `${formatToken(dexFeeTokens)} ${dexPair.token_base.symbol} / ${formatFiat(
            dexFeeUsd,
          )}`,
        });
      }
    } catch (error) {
      if (axios.isCancel(error) || axios.isCancel(CancelTokenRef.current?.token.reason)) {
        return;
      }
      setChartErrorMessage({ type: 'error', text: 'Chart configuration suddenly failed...' });
    } finally {
      setChartLoading(false);
    }
  }, [currentConfig, dexPair, manualPooledBase, manualPooledQuote, useAdditionalReserves]);

  const handleLoadBotSettings = useCallback(async () => {
    const botConfig = await getBotConfig({ pairId: dexPair.id, bot: EDexBot.sl_bot });

    setBotSettings(botConfig);

    if (botConfig.wt_options) {
      botConfig.wt_options.client_options = undefined;
    }

    const config = handleChangeConfig(
      botConfig.wt_options
        ? {
            config: {
              ...botConfig.wt_options,
            },
            period: PERIOD_DEFAULT,
            interval: INTERVAL_DEFAULT,
            seed: SEED_DEFAULT,
          }
        : undefined,
      false,
    );

    setInitialConfig(config);
    setCurrentConfig(config);
    setInitialOptions(botConfig?.wt_options?.client_options ?? undefined);

    const _organicTimeframes = uniq(
      [...(botConfig.wt_options?.organic_volumes_tasks ?? [])].map(el => el.options.time_frame),
    );
    setOrganicTimeframes(
      _organicTimeframes
        .map(timeframe => {
          const find = SL_TIMEFRAMES.find(el => durationToMs(el.value) === durationToMs(timeframe));

          if (find) {
            return find.value;
          } else return null;
        })
        .filter(el => el !== null) as string[],
    );
  }, [dexPair, getBotConfig, handleChangeConfig]);

  const handleLoadPresets = useCallback(async () => {
    try {
      const { isSuccess, errorMessage, data } = await ApiDexSLBot.getSLPresets();

      if (isSuccess && data) {
        setPresetsShort(data.items);
        setPresets(undefined);
      } else {
        setErrorMessage(errorMessage ?? undefined);
        setPresetsShort(undefined);
        setPresets(undefined);
      }

      setSelectedPresetId(-1);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleLoadPreset = useCallback(
    async (id: number) => {
      if (presets && presets[id]) return presets[id];

      try {
        setPresetLoading(true);

        const { errorMessage, isSuccess, data } = await ApiDexSLBot.getSLPreset(id);

        setPresetLoading(false);

        if (isSuccess && data) {
          setPresets(presets => {
            return {
              ...presets,
              [id]: {
                ...data.options,
              },
            };
          });
          setErrorMessage(undefined);

          return {
            ...data.options,
          };
        } else {
          setErrorMessage(errorMessage ?? undefined);
          return undefined;
        }
      } catch (error) {
        console.log(error);
        setErrorMessage('Failed to download single preset...');
        setPresetLoading(false);
      }
    },
    [presets],
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleSaveSLConfig = useCallback(
    (config?: IDexSLSimulationOptions) => {
      if (!config) return;

      if (isSaving) return;

      setIsSaving(true);

      // set timeout is to momentarily update tables and config state, to not waiting for config saving
      // when config saving fails - it trigger onError which bring all data back to previous status
      setTimeout(() => {
        setInitialConfig(config);
        setCurrentConfig(config);
      }, 0);

      const startInitialConfig = initialConfig;
      const startCurrentConfig = currentConfig;

      return updateBotConfig({
        pairId: dexPair.id,
        bot: EDexBot.sl_bot,
        body: {
          is_enabled: botSettings?.is_enabled ?? false,
          send_private_transactions: botSettings?.send_private_transactions ?? false,
          reserve_wallets_priority: botSettings?.reserve_wallets_priority ?? 'default',
          slippage_percent: botSettings?.slippage_percent ?? '0.3',
          wt_options: {
            ...config.config,
            client_options:
              selectedTab === 'advanced'
                ? undefined
                : {
                    trade_size_coefficient: tradeSizeCoefficient,
                    volatility_coefficient: volatilityCoefficient,
                    trade_frequency_coefficient: tradeFrequencyCoefficient,
                  },
          },
        },
        onSuccess: () => {
          setIsSaving(false);
        },
        onError: () => {
          setInitialConfig(startInitialConfig);
          setCurrentConfig(startCurrentConfig);
          setIsSaving(false);
        },
      });
    },
    [
      isSaving,
      currentConfig,
      initialConfig,
      botSettings,
      dexPair.id,
      selectedTab,
      tradeFrequencyCoefficient,
      tradeSizeCoefficient,
      volatilityCoefficient,
      updateBotConfig,
    ],
  );

  const { candles } = useDexCandlesByTimeframe({
    dexPair: useMemo(() => dexPair, [dexPair]),
    period: ATR_PERIOD,
    timeframes: useMemo(() => organicTimeframes, [organicTimeframes]),
  });

  const organicVolumesReduced = useMemo(
    () =>
      (initialConfig?.config.organic_volumes_tasks ?? []).map(el => ({
        timeframe: el.options.time_frame,
        min_volume: el.options.min_volume,
        max_volume: el.options.max_volume,
        min_transactions_in_series: el.options.min_transactions_in_series,
        max_transactions_in_series: el.options.max_transactions_in_series,
        min_pause_between_series: el.options.min_pause_between_series,
        max_pause_between_series: el.options.max_pause_between_series,
      })),
    [initialConfig],
  );

  const [organicDailyVolumes, setOrganicDailyVolumes] = useState<IOrganicVolume[]>([]);

  const calculateOranicDailyVolumes = useCallback(() => {
    const _organicDailyVolumes = organicVolumesReduced.map(val => {
      const timeframe =
        SL_TIMEFRAMES.find(el => durationToMs(el.value) === durationToMs(val.timeframe))?.value ??
        val.timeframe;

      const sequence = candles[timeframe] ?? [];
      const ATRS = calculateATRs({ candles: sequence, period: ATR_PERIOD });
      const decimals = dexPair.token_base.decimals;

      const avgVolume = divideBignumbers(
        [addBignumbers([bnFrom(val.min_volume), decimals], [bnFrom(val.max_volume), decimals]), 18],
        [bn(2), 18],
      );

      const ATRsVolume = ATRS.reduce(
        (acc, val) => acc.add(multiplyBignumbers([bn(val), 18], [avgVolume, 18])),
        bn(0),
      );

      const timeframesProportion =
        (24 * 60 * 60 * 1000) / (durationToMs(timeframe) * Math.abs(sequence.length - ATR_PERIOD));

      const dailyVolume = multiplyBignumbers([ATRsVolume, 18], [bn(timeframesProportion), 18]);

      const dailyVolumeUsd = multiplyBignumbers(
        [dailyVolume, 18],
        [bnFrom(dexPair.token_base.price_usd), 6],
      );

      //daily txs
      const avgTxs = divideBignumbers(
        [
          addBignumbers(
            [bn(val.min_transactions_in_series, 18), 18],
            [bn(val.max_transactions_in_series, 18), 18],
          ),
          18,
        ],
        [bn(2), 18],
      );

      const avgPause = divideBignumbers(
        [
          addBignumbers(
            [bn(durationToMs(val.max_pause_between_series)), 18],
            [bn(durationToMs(val.min_pause_between_series)), 18],
          ),
          18,
        ],
        [bn(2), 18],
      );

      const dailySeriesBN = avgPause.eq(bn(0))
        ? bn(0)
        : multiplyBignumbers(
            [divideBignumbers([bn(24 * 60 * 60 * 1000), 18], [avgPause, 18]), 18],
            [avgTxs, 18],
          );

      const dailyTxs = Math.floor(Number(humanizeBn(dailySeriesBN, 18)));

      return {
        dailyVolume,
        dailyVolumeUsd,
        dailyFee: dexDailyFees({ daily_txs: dailyTxs, daily_volume: dailyVolume, dexPair }),
      };
    });

    setOrganicDailyVolumes(_organicDailyVolumes);
  }, [organicVolumesReduced, candles, dexPair]);

  useDeepCompareEffect(() => {
    calculateOranicDailyVolumes();
  }, [organicVolumesReduced]);

  return (
    <DexSmartLiquidityContext.Provider
      value={{
        selectedTab: { get: selectedTab, set: setSelectedTab },
        chartLoading,
        presetLoading: { get: presetLoading, set: setPresetLoading },
        chartFormInfo,
        botSettings,
        initialConfig,
        initialOptions: { get: initialOptions, set: setInitialOptions },
        currentConfig: { get: currentConfig, set: handleChangeConfig },
        simulationRecords,
        organicDailyVolumes,
        presetsShort,
        presets,
        selectedPresetId: { get: selectedPresetId, set: setSelectedPresetId },
        handleLoadBotSettings,
        handleLoadPresets,
        handleLoadPreset,
        handleSaveSLConfig,
        loadSimulation,
        errorMessage,
        chartErrorMessage,

        //quick start
        tradeFrequencyCoefficient: {
          get: tradeFrequencyCoefficient,
          set: setTradeFrequencyCoefficient,
        },
        tradeSizeCoefficient: { get: tradeSizeCoefficient, set: setTradeSizeCoefficient },
        volatilityCoefficient: { get: volatilityCoefficient, set: setVolatilityCoefficient },
        useAdditionalReserves: { get: useAdditionalReserves, set: setUseAdditionalReserves },
        manualPooledBase: { get: manualPooledBase, set: setManualPooledBase },
        manualPooledQuote: { get: manualPooledQuote, set: setManualPooledQuote },
        CancelTokenRef,
        organicCandles: candles,
      }}
    >
      {children}
    </DexSmartLiquidityContext.Provider>
  );
};
