import React, { HTMLAttributes, useCallback } from 'react';
import { useTypedDispatch } from 'store';
import cn from 'classnames';

import { setCopyPopupShow } from 'store/slices/ui';
import { addressShortView, addressView } from 'utils';
import { calculateScanLink } from 'utils/calculates';
import { copyToClipboard } from 'utils/clipboard';
import { ENetwork } from 'web3';

import { LinkSmall } from 'assets/icons';
import './style.scss';

interface ITableAddressChipProps extends HTMLAttributes<HTMLDivElement> {
  address: string;
  withScanLink?: boolean;
  network?: ENetwork;
  addressType?: 'address' | 'transaction' | 'token';
  format?: 'short' | 'medium';
  className?: string;
}

const TableAddressChip: React.FC<ITableAddressChipProps> = ({
  address,
  withScanLink = true,
  network,
  addressType,
  format = 'medium',
  className,
  ...rest
}) => {
  const dispatch = useTypedDispatch();

  const handleCopyAddress = useCallback(async () => {
    try {
      await copyToClipboard(address);
      dispatch(setCopyPopupShow(true));
    } catch (error) {
      console.log('error: ', error);
    }
  }, [address, dispatch]);

  return (
    <div {...rest} className={cn({ [className ?? '']: !!className }, 'mm-table-address-chip')}>
      <span className="mm-table-address-chip__address" onClick={handleCopyAddress}>
        {format === 'medium' ? addressView(address) : addressShortView(address)}
      </span>
      {withScanLink && (
        <a
          className="mm-table-address-chip__scan-link"
          href={calculateScanLink({
            address,
            network: network ?? ENetwork.bsc,
            addressType: addressType ?? 'address',
          })}
          target="_blank"
          rel="noreferrer"
        >
          <LinkSmall />
        </a>
      )}
    </div>
  );
};

export { TableAddressChip };
