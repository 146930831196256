import React, { useContext, useMemo } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { isNil } from 'lodash';
import { useTypedSelector } from 'store';

import { CexPairContext } from 'context/CexPairContext';
import { ICexOrderbookGridBotTaskExtended } from 'types/bots';
import { StaticTable } from 'tables/common';
import {
  CountOfOrders,
  DistOrders,
  OrderAmount,
  Spread,
  StartPrice,
  Status,
  Account,
} from './columns';
import { ExtendedRow } from '../ExtendedRow';

import './style.scss';

const Table: React.FC = () => {
  const {
    orderbook: {
      sortedTasks,
      extendedRow,
      setExtendedRow,
      enableTask,
      deleteTask,
      loading,
      taskEnablingLoading,
      update,
    },
  } = useContext(CexPairContext);

  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const columns = useMemo<ColumnDef<ICexOrderbookGridBotTaskExtended>[]>(
    () => [
      {
        header: () => <div>Account</div>,
        id: 'account',
        cell: ({ row: { original } }) => <Account task={original} cexPair={cexPair} />,
      },
      {
        header: () => <div>Start price</div>,
        id: 'start-price',
        cell: ({ row: { original } }) => <StartPrice task={original} />,
      },
      {
        header: () => <div>Spread</div>,
        id: 'spread',
        cell: ({ row: { original } }) => <Spread task={original} />,
      },
      {
        header: () => <div>Order amount</div>,
        id: 'order-amount',
        cell: ({ row: { original } }) => <OrderAmount task={original} />,
      },
      {
        header: () => <div>Count of orders</div>,
        id: 'count-of-orders',
        cell: ({ row: { original } }) => <CountOfOrders task={original} />,
      },
      {
        header: () => <div>Dist between orders</div>,
        id: 'dist-between-orders',
        cell: ({ row: { original } }) => <DistOrders task={original} />,
      },
      {
        header: () => <div style={{ textAlign: 'right' }}>Status</div>,
        id: 'status',
        cell: ({
          row: { original },
          table: {
            options: { meta },
          },
        }) => {
          const _meta = meta as any;

          return (
            <Status
              extendedRow={_meta.extendedRow}
              taskEnablingLoading={_meta.taskEnablingLoading}
              isActive={original.active}
              task={original}
              enableTask={_meta.enableTask}
            />
          );
        },
      },
    ],
    [cexPair],
  );

  const table = useReactTable({
    data: sortedTasks,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      extendedRow,
      taskEnablingLoading: taskEnablingLoading.get,
      enableTask,
    },
  });

  const { rows } = table.getRowModel();

  return (
    <StaticTable className="mm-orderbook-grid-table">
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                  {header.isPlaceholder ? null : (
                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {!loading && rows.length === 0 && (
          <tr>
            <td colSpan={7}>
              <div className="no-active-records">
                <span>There are no tasks</span>
              </div>
            </td>
          </tr>
        )}
        {rows.map(row => {
          return (
            <React.Fragment key={row.index}>
              <tr
                key={row.index}
                className={cn({
                  _selected: extendedRow === row.original.id,
                })}
                onClick={() => setExtendedRow(isNil(extendedRow) ? row.original.id : undefined)}
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
              <AnimatePresence>
                {extendedRow === row.original.id && (
                  <tr className="mm-orderbook-grid-opened-task">
                    <td colSpan={8}>
                      <ExtendedRow
                        rowIdx={row.index}
                        task={row.original}
                        deleteTask={deleteTask}
                        getTasks={update}
                      />
                    </td>
                  </tr>
                )}
              </AnimatePresence>
              <tr className="tr-separator" />
            </React.Fragment>
          );
        })}
      </tbody>
    </StaticTable>
  );
};

export { Table };
