import { useCallback, useState, useEffect, useMemo } from 'react';
import { useTypedSelector } from 'store';

import { ApiWithdrawWhitelist } from 'api';
import { IWithdrawWhitelistItem } from 'api/apiWithdrawWhitelist/models';

const useWithdrawalWhitelist = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const [loading, setLoading] = useState<boolean>(true);

  const [whitelist, setWhitelist] = useState<IWithdrawWhitelistItem[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await ApiWithdrawWhitelist.getWithdrawWhitelist({
        limit: 100,
        offset: 0,
        pairsIds: [dexPair.id.toString()],
        projectIds: [dexPair.project_id.toString()],
      });

      if (data) {
        setWhitelist(data.items ?? []);
        setTotalItems(data.total_items);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [dexPair]);

  useEffect(() => {
    load();
  }, [load]);

  const confirmedWhitelist = useMemo(() => whitelist.filter(el => !!el.confirmed_at), [whitelist]);

  return { whitelist, confirmedWhitelist, setWhitelist, totalItems, loading, load };
};

export { useWithdrawalWhitelist };
