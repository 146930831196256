import { useMemo, useState, useContext, useCallback, useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import { useTypedDispatch, useTypedSelector } from 'store';

import { DexBalanceBotContext } from 'context/DexBalanceBotContext';
import { IDexBalanceBotTask } from 'types/bots';
import { durationToMs } from 'utils/duration';
import { bnFrom, humanizeBn } from 'tools/math';

import { addBoostHoldersTask } from './addBoostHoldersTask';
import { validationSchema } from './validationSchema';

interface IUseCreateNewTaskModalProps {
  onClose?: (balanceBotTask?: IDexBalanceBotTask | undefined) => void;
  task?: IDexBalanceBotTask;
  mode: 'create' | 'edit';
}

export interface IBoostHolderInitialValues {
  type: 'add_base_holder' | 'add_quote_holder';
  min_amount: string;
  max_amount: string;
  min_pause: number | undefined;
  max_pause: number | undefined;
  sourceWallets: number;
  destinationWallets: number;
  buyback_enabled: boolean;
  buyback_min_pause: number | undefined;
  buyback_max_pause: number | undefined;
  buyback_min_swaps: string;
  buyback_max_swaps: string;
}

export const useBoostHolderTask = ({ onClose, task, mode }: IUseCreateNewTaskModalProps) => {
  const dispatch = useTypedDispatch();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const { handleLoadRecords, estimateFee } = useContext(DexBalanceBotContext);
  const [edited, setEdited] = useState<boolean>(false);

  const initialValues: IBoostHolderInitialValues = useMemo(() => {
    if (mode === 'edit' && task && task.type !== 'maintain_fee_balance') {
      const type = task.type;

      const options =
        type === 'add_base_holder' ? task.add_base_holder_options : task.add_quote_holder_options;
      const decimals =
        type === 'add_base_holder' ? dexPair.token_base.decimals : dexPair.token_quote.decimals;

      return {
        ...options,
        type: type,
        min_amount: humanizeBn(bnFrom(options?.min_amount ?? 0), decimals),
        max_amount: humanizeBn(bnFrom(options?.max_amount ?? 0), decimals),
        min_pause: durationToMs(options?.min_pause),
        max_pause: durationToMs(options?.max_pause),
        sourceWallets: options?.source_wallet_ids?.length || 0,
        destinationWallets: task.destination_wallets_count | 0,
        buyback_enabled: options?.buyback_enabled || false,
        buyback_min_pause: durationToMs(options?.buyback_options?.min_pause),
        buyback_max_pause: durationToMs(options?.buyback_options?.max_pause),
        buyback_min_swaps: String(options?.buyback_options?.min_swaps) || '',
        buyback_max_swaps: String(options?.buyback_options?.max_swaps) || '',
      };
    }

    return {
      type: 'add_base_holder',
      min_amount: '',
      max_amount: '',
      min_pause: undefined,
      max_pause: undefined,
      sourceWallets: 0,
      destinationWallets: 0,

      buyback_enabled: false,
      buyback_min_pause: undefined,
      buyback_max_pause: undefined,
      buyback_min_swaps: '',
      buyback_max_swaps: '',
    };
  }, [task, mode, dexPair]);

  const [formError, setFormError] = useState<string | undefined>(undefined);

  const handleAddTask = useCallback(
    (values: IBoostHolderInitialValues): void => {
      addBoostHoldersTask({
        task: {
          ...values,
          dispatch,
          handleLoadRecords,
          pairId: dexPair.id,
          mode,
          ...(task?.id ? { taskId: task?.id } : {}),
          onClose,
          typeTask: values.type,
        },
        dexPair,
      });
    },
    [dexPair, dispatch, handleLoadRecords, mode, onClose, task],
  );

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    validateForm,
  }: FormikProps<IBoostHolderInitialValues> = useFormik<IBoostHolderInitialValues>({
    initialValues,
    onSubmit: handleAddTask,
    validationSchema,
  });

  const handleSetFieldValue = useCallback(
    (
      field: string,
      value: any,
      options: { triggerEdit?: boolean; shouldValidate?: boolean } = {
        triggerEdit: false,
        shouldValidate: undefined,
      },
    ) => {
      if (options.triggerEdit) {
        setEdited(true);
      }
      setFieldValue(field, value, options.shouldValidate);
    },
    [setFieldValue],
  );

  useEffect(() => {
    const handleSetFormError = () => {
      const keys = Object.keys(errors);

      for (const key of keys) {
        const keyWithType = key as keyof typeof errors;

        if (errors[keyWithType] && touched[keyWithType]) {
          setFormError(`${errors[keyWithType]}` || undefined);
          return;
        }
      }

      setFormError(undefined);
    };

    handleSetFormError();
  }, [errors, touched]);

  return {
    initialValues,
    values,
    handleSubmit,
    setFieldValue: handleSetFieldValue,
    formError,
    validateForm,
    edited,
    estimateFee,
  };
};
