import React, { useState, useCallback } from 'react';
import { useTypedSelector } from 'store';

import { Panel, AutoRefresh, TableToolbar, FiltersPopup } from 'ui';
import { PAIR_TRANSACTIONS_RELOAD } from 'constant/reload';
import { IPanelProps } from 'types/pairs';
import { EDexPairFilters } from 'types/filters';
import { EExchange } from 'web3';

import { DocumentIcon } from 'assets/icons';

const DexPairTransactionsTable = React.lazy(() =>
  import('tables').then(module => ({ default: module.DexPairTransactionsTable })),
);

const Transactions: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(initialOpened ?? false);

  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);
    },
    [setIsOpened],
  );

  return (
    <Panel
      title="Transactions"
      icon={DocumentIcon}
      initialOpened={initialOpened}
      className="_table-container"
      onChange={handleSwitch}
      headerNode={
        !!isPanelOpened && (
          <div className="w-full flex flex-row items-center justify-end">
            <AutoRefresh
              busEvent={PAIR_TRANSACTIONS_RELOAD}
              tooltipText="Autorefresh transactions"
              pairId={pair.id}
              cacheName="transactions"
            />
          </div>
        )
      }
    >
      <TableToolbar
        customFilters={
          <FiltersPopup exchange={EExchange.dex} type={EDexPairFilters.transactions} />
        }
      />
      <DexPairTransactionsTable />
    </Panel>
  );
};

export { Transactions };
