import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { ICexOrder } from 'types/orders';
import { formatToken } from 'utils/formats';
import { bn, bnFrom } from 'tools/math';

interface IFilledTotalProps {
  order: ICexOrder;
}

const FilledTotal = React.memo(({ order }: IFilledTotalProps) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const quoteTokenSymbol = useMemo(() => cexPair.token_quote.symbol, [cexPair]);
  const decimals = useMemo(() => 18, []);

  const quoteAmountInTrades = useMemo(
    () =>
      order.trades
        ?.filter(el => el.token.symbol === quoteTokenSymbol && !el.is_fee)
        .reduce((acc, val) => acc.add(bnFrom(val.amount).abs()), bn(0)) ?? bn(0),
    [order, quoteTokenSymbol],
  );

  const totalQuoteAmount = useMemo(() => bnFrom(order.quote_amount), [order]);

  return (
    <div style={{ textAlign: 'left', textOverflow: 'ellipsis', minWidth: 0, overflow: 'hidden' }}>
      {quoteAmountInTrades.gt(bn(0)) && (
        <>
          <span
            className="text-primary"
            id={`${order.id}-filled-total-tooltip`}
            style={{ cursor: 'default' }}
          >
            {formatToken(quoteAmountInTrades, decimals)} /{' '}
          </span>
          <span className="text-secondary">
            {formatToken(totalQuoteAmount, decimals)} {cexPair.token_quote.symbol}
          </span>
        </>
      )}
    </div>
  );
});

FilledTotal.displayName = 'FilledTotal';

export { FilledTotal };
