import { useEffectOnce } from 'react-use';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { useTypedSelector, useTypedDispatch } from 'store';

import { bnFrom } from 'tools';
import { formatToken } from 'utils/formats';
import { updateCexPairPrice } from 'store/slices/pairs';

import { RefreshIcon } from 'assets/icons';
import './style.scss';

const CexLastPrice = () => {
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const updatePairLoading = useTypedSelector(store => store.pairs.updatePairLoading);

  useEffectOnce(() => {
    dispatch(updateCexPairPrice({ pair: cexPair }));
  });

  const handleUpdate = () => {
    if (updatePairLoading) return;

    dispatch(updateCexPairPrice({ pair: cexPair }));
  };

  return (
    <div className="cex-last-price w-full py-2 px-4 rounded-md bg-gray-3/50 flex flex-row justify-between">
      <div className="flex flex-row items-center gap-1">
        <span className="font-bold text-black-1/90">{cexPair.symbol}</span>
        <span className="text-sm text-gray-1">
          {formatToken(bnFrom(cexPair.token_base.price_usd), 6)}
        </span>
      </div>
      <motion.button
        onClick={handleUpdate}
        className={cn('cursor-pointer text-gray-1', { _loading: updatePairLoading })}
      >
        <RefreshIcon className="w-4 h-4" />
      </motion.button>
    </div>
  );
};

export { CexLastPrice };
