import { BigNumber } from '@ethersproject/bignumber';

import { formatNumberToLocale } from './formatNumberToLocale';
import { BILLION, MILLION, THOUSAND } from 'constant/numbers';
import { humanizeBn } from 'tools/math';

interface IFormatFiatOptions {
  zeroWithoutMinus?: boolean;
}

const roundNumber = (number: number | string, places: number) => {
  return Math.round((Number(number) + Number.EPSILON) * 10 ** places) / 10 ** places;
};

export const formatFiat = (
  bn: BigNumber,
  decimals = 18,
  symbol = '$',
  options?: IFormatFiatOptions,
): string => {
  const stringAmount = humanizeBn(bn, decimals);
  const stringAmountPrefix = stringAmount.slice(0, 1) === '-' ? '-' : '';
  const absStringAmount = stringAmount.replace('-', '');

  const amountArray = absStringAmount.split('.');
  const numberAmount = Number(stringAmount);

  if (Math.abs(numberAmount) >= BILLION) {
    return (
      stringAmountPrefix +
      symbol +
      formatNumberToLocale(roundNumber(Number(amountArray[0]) / BILLION, 2)) +
      'b'
    );
  }

  if (Math.abs(numberAmount) >= MILLION) {
    return (
      stringAmountPrefix +
      symbol +
      formatNumberToLocale(roundNumber(Number(amountArray[0]) / MILLION, 2)) +
      'm'
    );
  }

  if (Math.abs(numberAmount) >= THOUSAND) {
    return (
      stringAmountPrefix +
      symbol +
      formatNumberToLocale(roundNumber(Number(amountArray[0]) / THOUSAND, 2)) +
      'k'
    );
  }

  if (numberAmount < 0.0001 && numberAmount > 0) {
    return `< ${symbol}0.0001`;
  }

  if (numberAmount > -0.0001 && numberAmount < 0) {
    return `> -${symbol}0.0001`;
  }

  if (Math.abs(numberAmount) < 0.1 && Math.abs(numberAmount) >= 0.0001) {
    return stringAmountPrefix + symbol + formatNumberToLocale(roundNumber(absStringAmount, 4));
  }

  const zeroWithoutMinus = options && options.zeroWithoutMinus;

  if (zeroWithoutMinus && Number(Number(absStringAmount).toFixed(2)) === 0) {
    return symbol + formatNumberToLocale(roundNumber(absStringAmount, 2));
  }

  return stringAmountPrefix + symbol + formatNumberToLocale(roundNumber(absStringAmount, 2));
};
