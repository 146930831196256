import React, { createContext } from 'react';

import { useAccounts, useOrderbook, useBuySell, useBotStates, useOrderManager } from 'hooks/cex';
import { ICexAccount, IAccountBalances } from 'types/accounts';
import { IBotStates } from 'types/bots';

interface IAccountWithBalance extends ICexAccount, Partial<IAccountBalances> {}

interface ICexPairContext {
  accounts: IAccountWithBalance[];
  setAccounts: (acc: IAccountWithBalance[]) => void;
  updateAccounts: () => Promise<void>;
  accountsLoading: boolean;
  orderbook: ReturnType<typeof useOrderbook>;
  buySell: ReturnType<typeof useBuySell>;
  orderManager: ReturnType<typeof useOrderManager>;
  botStates: IBotStates;
  botStatesLoading: boolean;
  updateBotStates: () => Promise<void>;
}

export const CexPairContext = createContext<ICexPairContext>({
  accounts: [],
  setAccounts: () => {},
  updateAccounts: async () => {},
  accountsLoading: false,
  orderbook: {} as ReturnType<typeof useOrderbook>,
  buySell: {} as ReturnType<typeof useBuySell>,
  orderManager: {} as ReturnType<typeof useOrderManager>,
  botStates: {
    buy_sell: { status: false },
    order_book: { status: false },
    smart_liquidity: { status: false },
    order_manager: { status: false },
  },
  botStatesLoading: false,
  updateBotStates: async () => {},
});

const CexPairContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { accounts, setAccounts, load: updateAccounts, loading: accountsLoading } = useAccounts();
  const { states: botStates, load: updateBotStates, loading: botStatesLoading } = useBotStates();

  const orderbook = useOrderbook({ accounts, updateBotStates });
  const buySell = useBuySell({ accounts, updateBotStates });
  const orderManager = useOrderManager({ accounts, updateBotStates });

  return (
    <CexPairContext.Provider
      value={{
        accounts,
        setAccounts,
        updateAccounts,
        accountsLoading,
        orderbook,
        buySell,
        botStates,
        orderManager,
        updateBotStates,
        botStatesLoading,
      }}
    >
      {children}
    </CexPairContext.Provider>
  );
};

export default CexPairContextProvider;
