import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

import { LocalStorage } from 'tools';
import { EDexPairFilters, ECexPairFilters, EGeneralFilters, IFilters } from 'types/filters';
import { FILTERS_KEY } from 'constant/cache';

interface IPayload {
  cex?: {
    pairId?: number;
    type: ECexPairFilters;
  };
  dex?: {
    pairId?: number;
    type: EDexPairFilters;
  };
  general?: EGeneralFilters;
}

export const clearFiltersReducer: CaseReducer<IFilters, PayloadAction<IPayload>> = (
  state,
  { payload },
) => {
  const { dex, cex, general } = payload;

  if (general && state[general]) {
    state[general]!.filters = [];
    LocalStorage.set(FILTERS_KEY, state);
    return;
  }

  const pairId = cex ? cex.pairId : dex ? dex.pairId : undefined;
  const type = dex ? dex.type : cex ? cex.type : undefined;
  const key = cex ? 'cex' : 'dex';

  if (isNil(pairId) || isNil(type) || !state[key][pairId]) return;

  state[key][pairId]!.updated_at = new Date(Date.now()).toISOString();

  //@ts-ignore
  state[key][pairId]!.filters[type] = {
    filters: [],
    //@ts-ignore
    setted: state[key][pairId]!.filters[type].setted,
  };

  LocalStorage.set(FILTERS_KEY, state);
};
