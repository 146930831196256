import React, { ReactElement, useCallback } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { useTypedSelector } from 'store';

import './radioBox.scss';

interface IRadioBoxProps {
  text: string;
  renderText?: (text: string) => JSX.Element;
  className?: string;
  checked: boolean;
  onChange?: () => void;
  pairIcon?: ReactElement;
}

const RadioBox: React.FC<IRadioBoxProps> = ({
  text,
  renderText,
  className,
  checked,
  onChange,
  pairIcon,
}) => {
  const palette = useTypedSelector(store => store.ui.selectedThemeColors);

  const handleChange = useCallback((): void => {
    if (onChange) onChange();
  }, [onChange]);

  return (
    <div className={cn('mm-radio-box', { [className || '']: className })} onClick={handleChange}>
      <input type="radio" checked={checked} onChange={handleChange} />
      {!renderText && (
        <>
          {pairIcon}
          <motion.span
            animate={{
              color: checked ? palette.primary : palette.gray_1,
            }}
            className="mm-radio-box__text"
          >
            {text}
          </motion.span>
        </>
      )}
      {renderText && renderText(text)}
    </div>
  );
};

export { RadioBox };
