import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useListNavigation,
  FloatingFocusManager,
  offset,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react';

import { EExchange } from 'web3';
import { IUserFlterItem } from 'types/filters/common';
import { Checkbox } from 'ui';
import { ApiUsers, ApiBot } from 'api';
import { tailwindConfig } from 'tailwind-config';

import { ArrowDropDown } from 'assets/icons';
import './style.scss';

interface IUsersMultiSelectFieldProps {
  value: IUserFlterItem[];
  setValue: (v: IUserFlterItem[]) => void;
  exchange?: EExchange | undefined;
}

const UsersMultiSelectField: React.FC<IUsersMultiSelectFieldProps> = ({
  setValue,
  value,
  exchange,
}) => {
  const [users, setUsers] = useState<IUserFlterItem[]>([]);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const isEmpty = useMemo(() => value.length === 0, [value]);

  const setupUsers = useCallback(async () => {
    try {
      const [resultUsers, resultBots] = await Promise.allSettled([
        ApiUsers.getAllUsers({ limit: 1000, offset: 0 }),
        ApiBot.getBots({ limit: 1000, offset: 0, types: exchange ? [exchange] : undefined }),
      ]);

      const _users = resultUsers.status === 'fulfilled' ? resultUsers.value.data?.items ?? [] : [];
      const _bots = resultBots.status === 'fulfilled' ? resultBots.value.data?.items ?? [] : [];

      const _agregatedBots = _bots.map<IUserFlterItem>(el => ({
        id: el.id,
        login: el.name,
        role: 'bot',
      }));

      setUsers(_agregatedBots.concat(_users));
    } catch (e) {}
  }, [exchange]);

  useEffect(() => {
    setupUsers();
  }, [setupUsers]);

  const { refs, context, floatingStyles } = useFloating({
    open: isOpened,
    onOpenChange: setIsOpened,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            width: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    listNav,
    click,
  ]);

  const handleSelect = useCallback(
    (item: IUserFlterItem) => {
      let newSelectedItems = [...value];

      const itemSelected = value.find(el => el.id === item.id);

      if (itemSelected) {
        newSelectedItems = newSelectedItems.filter(el => el.id !== item.id);
      } else {
        newSelectedItems.push(item);
      }

      setValue(newSelectedItems);
    },
    [setValue, value],
  );

  return (
    <div className="mm-filter-users-multi-select-field">
      <div
        className="mm-filter-field-container"
        tabIndex={0}
        ref={refs.setReference}
        aria-labelledby="select-label"
        aria-autocomplete="none"
        {...getReferenceProps()}
      >
        {isEmpty && <span className="mm-filter-field-placeholder">Select users</span>}
        {!isEmpty && (
          <span className="mm-filter-field-value">{value.map(el => el.login).join(', ')}</span>
        )}
        <motion.div animate={isOpened ? { rotate: 180 } : undefined}>
          <ArrowDropDown color={tailwindConfig.theme.colors['black-1']} />
        </motion.div>
      </div>
      {isOpened && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className="mm-filter-select-field__dropdown mm-filter-users-multi-select-field-dropdown scrollable"
              {...getFloatingProps()}
            >
              {users.map((user, i) => {
                const isItemSelected = !!value.find(el => el.id === user.id);

                return (
                  <div
                    className="user-dropdown-item"
                    key={i}
                    ref={node => {
                      listRef.current[i] = node;
                    }}
                    role="option"
                    tabIndex={i === activeIndex ? 0 : -1}
                    style={{
                      background:
                        i === activeIndex ? tailwindConfig.theme.colors['light-gray-1'] : '',
                    }}
                    {...getItemProps({
                      onClick() {
                        handleSelect(user);
                      },
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(user);
                        }

                        if (event.key === ' ') {
                          event.preventDefault();
                          handleSelect(user);
                        }
                      },
                    })}
                  >
                    <Checkbox checked={isItemSelected} onChange={() => handleSelect(user)} />
                    <span>{user.login}</span>
                    {user.role === 'bot' && <div className="bot-label">bot</div>}
                  </div>
                );
              })}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
};

export { UsersMultiSelectField };
