import React, { memo } from 'react';
import { motion } from 'framer-motion';
import { useTypedSelector } from 'store';

import { Tooltip, TableAddressChip } from 'ui';
import { ITransfer } from 'api/apiTransactions/models';
import { ENetwork } from 'web3';
import { formatFiat, formatToken } from 'utils/formats';
import { bn, bnFrom, humanizeBn } from 'tools/math';
import { tailwindConfig } from 'tailwind-config';

import { ArrowToIcon, ArrowFromIcon } from 'assets/icons';

const ExtendedTr: React.FC<{ transfer: ITransfer; network: ENetwork }> = memo(
  ({ transfer, network }) => {
    const isAdmin = useTypedSelector(store => store.auth.isAdmin);

    return (
      <motion.tr
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2 } }}
        className="_is-extended-children"
      >
        <motion.td layout></motion.td>
        <motion.td layout></motion.td>
        <motion.td layout>
          <TableAddressChip
            address={transfer.wallet.address}
            addressType={'address'}
            network={network}
            format={'short'}
          />
        </motion.td>
        {isAdmin && <motion.td layout></motion.td>}
        <motion.td layout></motion.td>
        <motion.td layout>
          {!transfer.is_fee && (
            <>
              <div className="transfer-amount">
                <Tooltip
                  trigger={
                    <span>{formatToken(bnFrom(transfer.amount), transfer.token.decimals)}</span>
                  }
                  content={formatFiat(bnFrom(transfer.amount_usd), 6)}
                  options={{ offset: 10, placement: 'top' }}
                />
                {bnFrom(transfer.amount).gte(bn(0)) && (
                  <ArrowToIcon color={tailwindConfig.theme.colors['successful-1']} />
                )}
                {bnFrom(transfer.amount).lt(bn(0)) && (
                  <ArrowFromIcon color={tailwindConfig.theme.colors['red-1']} />
                )}
              </div>
            </>
          )}
        </motion.td>
        <motion.td layout>{transfer.token.symbol}</motion.td>
        <motion.td layout></motion.td>
        <motion.td layout style={{ textAlign: 'right' }}>
          {transfer.is_fee
            ? humanizeBn(bnFrom(transfer.amount), transfer.token.decimals) +
              ' ' +
              transfer.token.symbol
            : ''}
        </motion.td>
      </motion.tr>
    );
  },
);

export { ExtendedTr };
