import React from 'react';

import { IPanelProps } from 'types/pairs';

import Content from './Content';

const OrderbookGrid: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  return <Content initialOpened={initialOpened} setIsOpened={setIsOpened} />;
};

export { OrderbookGrid };
