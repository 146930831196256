import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTypedSelector, useTypedDispatch } from 'store';

import { ApiBot } from 'api';
import { ICexBuySellBotTask, ICexBuySellBotTaskExtended } from 'types/bots';
import { IAccountBalances, ICexAccount } from 'types/accounts';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { bn, bnFrom, Bus, humanizeBn, divideBignumbers } from 'tools';
import { STOP_RELOAD_PREFIX, PAIR_CEX_BUYSELL_BOT_RELOAD } from 'constant/reload';

interface IBuySellReturn {
  tasks: ICexBuySellBotTaskExtended[];
  extendedRow: number | undefined;
  setExtendedRow: (v: number | undefined) => void;
  taskEnablingLoading: { get: number | undefined; set: (v: number | undefined) => void };
  tasksLoading: boolean;
  getTasks: () => Promise<void>;
  deleteTask: ({ taskId }: { taskId: number }) => Promise<void>;
  enableTask: ({
    task,
    active,
  }: {
    task: ICexBuySellBotTaskExtended;
    active: boolean;
  }) => Promise<void>;
}

interface IAccountsWithBalance extends ICexAccount, Partial<IAccountBalances> {}

const extendedTaskToRaw = (task: ICexBuySellBotTaskExtended) => {
  return {
    ...task,
    account: undefined,
    avaliableBase: undefined,
    avaliableQuote: undefined,
    neededQuote: undefined,
    neededBase: undefined,
    lackBase: undefined,
    lackQuote: undefined,
  };
};

const useBuySell = ({
  accounts,
  updateBotStates,
}: {
  accounts: IAccountsWithBalance[];
  updateBotStates: () => void;
}): IBuySellReturn => {
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const [tasksLoading, setTasksLoading] = useState<boolean>(true);
  const [extendedRow, setExtendedRow] = useState<number | undefined>(undefined);
  const [taskEnablingLoading, setTaskEnablingLoading] = useState<number | undefined>(undefined);

  const [apiTasks, setApiTasks] = useState<ICexBuySellBotTask[]>([]);

  const getTasks = useCallback(
    async (shadowLoading = false) => {
      if (!cexPair?.id) return;
      if (!isAdmin) return;

      try {
        if (!shadowLoading) {
          setTasksLoading(true);
          setExtendedRow(undefined);
        }

        const { isSuccess, data } = await ApiBot.getCexBuySellConfig({ pairId: cexPair.id });

        if (isSuccess) {
          setApiTasks((data ?? []).sort((a, b) => b.id - a.id));
        }
      } catch (error) {
      } finally {
        if (!shadowLoading) {
          setTasksLoading(false);
        }
      }
    },
    [cexPair?.id, isAdmin],
  );

  const deleteTask = useCallback(
    async ({ taskId }: { taskId: number }) => {
      if (!cexPair?.id) return;

      try {
        setExtendedRow(undefined);
        const { isSuccess, errorMessage } = await ApiBot.deleteCexBuySellTask({
          pairId: cexPair?.id,
          taskId,
        });

        if (isSuccess) {
          setApiTasks(v => v.filter(el => el.id !== taskId));
          updateBotStates();
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        }
      } catch (error) {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    },
    [dispatch, updateBotStates, cexPair?.id],
  );

  const enableTask = useCallback(
    async ({ task, active }: { task: ICexBuySellBotTaskExtended; active: boolean }) => {
      try {
        setTaskEnablingLoading(task.id);
        const newTaskOptions = { ...extendedTaskToRaw(task), active };

        const { isSuccess, errorMessage } = await ApiBot.updateCexBuySellTask({
          task: newTaskOptions,
        });

        if (isSuccess) {
          setApiTasks(v => {
            const newTasks = [...v];
            const idx = newTasks.findIndex(el => el.id === task.id);

            if (newTasks[idx]) {
              newTasks[idx] = { ...newTasks[idx], active };
            }

            return newTasks.sort((a, b) => b.id - a.id);
          });

          updateBotStates();
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        }
      } catch (error) {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      } finally {
        setTaskEnablingLoading(undefined);
      }
    },
    [dispatch, updateBotStates],
  );

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  const handleUpdate = useCallback(async () => {
    const startTime = Date.now();

    try {
      await getTasks(true);
    } catch (e) {}

    const endTime = Date.now();

    if (endTime - startTime >= 1500) {
      Bus.emit(STOP_RELOAD_PREFIX + PAIR_CEX_BUYSELL_BOT_RELOAD);
    } else {
      setTimeout(() => {
        Bus.emit(STOP_RELOAD_PREFIX + PAIR_CEX_BUYSELL_BOT_RELOAD);
      }, 1500 - (endTime - startTime));
    }
  }, [getTasks]);

  useEffect(() => {
    Bus.on(PAIR_CEX_BUYSELL_BOT_RELOAD, handleUpdate);

    return () => {
      Bus.off(PAIR_CEX_BUYSELL_BOT_RELOAD, handleUpdate);
    };
  }, [handleUpdate]);

  const tasks = useMemo(() => {
    return apiTasks.map(task => {
      const account = accounts.find(el => el.id === task.account_id);

      const { avaliableBase, avaliableQuote } = {
        avaliableBase: bn(
          Number(account?.base_balance ?? 0) - Number(account?.base_balance_locked ?? 0),
          18,
        ),
        avaliableQuote: bn(
          Number(account?.quote_balance ?? 0) - Number(account?.quote_balance_locked ?? 0),
          18,
        ),
      };

      const price = cexPair
        ? Number(
            humanizeBn(
              divideBignumbers(
                [bnFrom(cexPair.token_base.price_usd), 6],
                [bnFrom(cexPair.token_quote.price_usd), 6],
              ),
              18,
            ),
          )
        : 0;

      const neededQuote =
        task.side === 'buy'
          ? bn(price * (Number(task.total_amount) - Number(task.progress)))
          : bn('0');

      const neededBase =
        task.side === 'buy' ? bn('0') : bn(Number(task.total_amount) - Number(task.progress), 18);

      const lackBase = avaliableBase.lt(neededBase);
      const lackQuote = avaliableQuote.lt(neededQuote);

      return {
        ...task,
        account,
        avaliableBase,
        avaliableQuote,
        neededQuote,
        neededBase,
        lackBase,
        lackQuote,
      };
    });
  }, [cexPair, apiTasks, accounts]);

  return {
    tasks,
    extendedRow,
    setExtendedRow,
    taskEnablingLoading: { get: taskEnablingLoading, set: setTaskEnablingLoading },
    tasksLoading,
    getTasks,
    deleteTask,
    enableTask,
  };
};

export { useBuySell };
