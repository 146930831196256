import { useCallback, useMemo, useState } from 'react';
import { useTypedSelector } from 'store';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { flushSync } from 'react-dom';
import { v4 as uuid } from 'uuid';
import {
  useFloating,
  offset,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingPortal,
  size,
  autoUpdate,
  autoPlacement,
} from '@floating-ui/react';

import { Bus } from 'tools';
import { useFilters } from 'store/slices/filters/hooks';
import { EExchange } from 'web3';
import { ECexPairFilters, EDexPairFilters, EGeneralFilters } from 'types/filters';
import { IFilterScheme } from 'types/filters/common';
import FiltersList from '../FiltersList';
import { SettedFilters } from '../SettedFilters';

import { FilterIcon } from 'assets/icons';
import './style.scss';

interface IFilterPopupProps<V> {
  exchange?: V;
  general?: EGeneralFilters;
  type?: V extends EExchange.cex ? ECexPairFilters : EDexPairFilters;
}

function FiltersPopup<V extends EExchange>({ exchange, general, type }: IFilterPopupProps<V>) {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair);
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);
  const palette = useTypedSelector(store => store.ui.selectedThemeColors);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const id = useMemo(() => uuid(), []);

  const { scheme, pairFilters, isFilterSetted, clearFilters, saveFilters, settedPairFilters } =
    useFilters({
      general,
      cex:
        exchange === EExchange.cex && type
          ? { pairId: cexPair?.id, type: type as ECexPairFilters }
          : undefined,
      dex:
        exchange === EExchange.dex && type
          ? { pairId: dexPair?.id, type: type as EDexPairFilters }
          : undefined,
    });

  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

  const { refs, context, floatingStyles } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      autoPlacement({ allowedPlacements: ['bottom-start', 'top-start'], padding: 5 }),
      offset(10),
      shift({ padding: 5 }),
      size({
        apply({ availableHeight }) {
          flushSync(() => {
            setMaxHeight(availableHeight);
          });
        },
      }),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const handleAddFilter = useCallback(() => {
    Bus.emit(`${id}-FILTERS-ADD`);
  }, [id]);

  const handleFilter = useCallback(() => {
    setIsOpen(false);
    saveFilters();

    setTimeout(() => {
      if (general) {
        Bus.emit(`${general}:FILTER`);
      } else {
        Bus.emit(`${exchange}:${type}:FILTER`);
      }
    }, 100);
  }, [saveFilters, exchange, type, general]);

  const handleClearFilters = useCallback(() => {
    setIsOpen(false);
    clearFilters();

    setTimeout(() => {
      saveFilters();
      setTimeout(() => {
        if (general) {
          Bus.emit(`${general}:FILTER`);
        } else {
          Bus.emit(`${exchange}:${type}:FILTER`);
        }
      }, 100);
    }, 0);
  }, [clearFilters, saveFilters, exchange, type, general]);

  const isAddFilterDisabled = useMemo(() => {
    if (!scheme) return true;

    const singleFiltersCount = Object.values(scheme).filter(
      filter => (filter as IFilterScheme).single,
    ).length;

    const hasNoMultiple = singleFiltersCount === Object.values(scheme).length;

    return hasNoMultiple && pairFilters.length === singleFiltersCount;
  }, [pairFilters, scheme]);

  return (
    <>
      <div>
        <motion.div
          whileHover={{ filter: 'brightness(80%)' }}
          className={cn('mm-filter-popup-trigger-button', { is_setted: isFilterSetted })}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <FilterIcon />
          <span>Filters</span>
        </motion.div>
        <SettedFilters isFilterSetted={isFilterSetted} settedFilters={settedPairFilters} />
      </div>
      {isOpen && (
        <FloatingPortal>
          <div
            className="mm-filters-popup-container"
            ref={refs.setFloating}
            style={{ ...floatingStyles, maxHeight }}
            {...getFloatingProps()}
          >
            <div className="filters-header">
              <span className="filters-title">Filters</span>
              <div className="filter-header-right">
                <motion.button
                  whileHover={{ backgroundColor: palette.light_gray_2 }}
                  className="clear-filters"
                  onClick={handleClearFilters}
                >
                  Clear all
                </motion.button>
              </div>
            </div>
            <FiltersList id={id} exchange={exchange} general={general} type={type} />
            <div className="filters-footer">
              <motion.button
                whileHover={
                  !isAddFilterDisabled ? { backgroundColor: palette.light_gray_2 } : undefined
                }
                className={cn('filters-add-filter', { disabled: isAddFilterDisabled })}
                onClick={handleAddFilter}
                disabled={isAddFilterDisabled}
              >
                <span>+ Add filter</span>
              </motion.button>
              <motion.button
                whileHover={{ backgroundColor: palette.primary_dark }}
                className="filters-start-filter"
                onClick={handleFilter}
              >
                <span>Filter</span>
              </motion.button>
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
}

export default FiltersPopup;
