import React, { createContext, useCallback, useState, useMemo, useContext } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTypedSelector } from 'store';

import { ApiStatistic, ApiOrders } from 'api';
import { getPeriodToAPI } from 'utils/charts';
import { ChartsControlsContext } from './ChartsControlsContext';
import { ChartsLoadContext } from './ChartsLoadContext';

interface IPriceContext {
  lastPrice: string;
  change24h: string;
  spreadPercentage: string;
  high24h: string;
  low24h: string;
  baseVolume: string;
  quoteVolume: string;
}

export const PriceContext = createContext<IPriceContext>({
  lastPrice: '',
  change24h: '',
  spreadPercentage: '',
  high24h: '',
  low24h: '',
  baseVolume: '',
  quoteVolume: '',
});

export const PriceContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const { period, startDate, endDate, selectedAccounts, accountsToAPI } =
    useContext(ChartsControlsContext);
  const { orders: activeOrders } = useContext(ChartsLoadContext);

  const [lastPrice, setLastPrice] = useState<string>('');
  const [change24h, setChange24h] = useState<string>('');
  const [spreadPercentage, setSpreadPercentage] = useState<string>('');
  const [high24h, setHigh24h] = useState<string>('');
  const [low24h, setlLow24h] = useState<string>('');
  const [baseVolume, setBaseVolume] = useState<string>('');
  const [quoteVolume, setQuoteVolume] = useState<string>('');

  const periodToAPI = useMemo(() => getPeriodToAPI(period.get), [period.get]);

  const getPricesInfo = useCallback(async () => {
    try {
      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;

      const [{ data: tickerData }] = await Promise.all([
        ApiStatistic.getCexTickerInfo({
          symbol,
          cex,
        }),
      ]);

      setLastPrice(tickerData?.last_price ?? '');
      setChange24h(tickerData?.change24h ?? '');
      setSpreadPercentage(tickerData?.spread_percentage ?? '');
      setHigh24h(tickerData?.high24h ?? '');
      setlLow24h(tickerData?.low24h ?? '');
      setBaseVolume(tickerData?.base_volume ?? '');
      setQuoteVolume(tickerData?.quote_volume ?? '');
    } catch (error) {
      console.log('error: ', error);
    }
  }, [cexPair]);

  useDeepCompareEffect(() => {
    getPricesInfo();
  }, [cexPair, accountsToAPI, selectedAccounts, startDate, endDate, periodToAPI]);

  return (
    <PriceContext.Provider
      value={{
        lastPrice,
        change24h,
        high24h,
        low24h,
        baseVolume,
        quoteVolume,
        spreadPercentage,
      }}
    >
      {children}
    </PriceContext.Provider>
  );
};
