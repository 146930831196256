import React, { useState, useEffect, useRef, useCallback } from 'react';
import cn from 'classnames';
import { useTypedSelector } from 'store';

import { Tooltip } from 'ui';
import { DEFAULT_REFRESH_INTERVAL } from 'constant/tables';
import { STOP_RELOAD_PREFIX } from 'constant/reload';
import { Bus } from 'tools';
import { EExchange } from 'web3';
import { IPairConfig } from 'store/slices/pair_configs';
import { usePairConfig } from 'store/slices/pair_configs/hooks';

import { RefreshIcon } from 'assets/icons';
import './style.scss';

type ICacheName =
  | 'statistic'
  | 'wallets'
  | 'transactions'
  | 'cex-accounts'
  | 'cex-statistic'
  | 'cex-order-history'
  | 'balance-bot'
  | 'buysell-bot'
  | 'cex-buysell-bot'
  | 'cex-order-manager';

interface IAutoRefreshProps {
  busEvent: string;
  tooltipText: string;
  pairId?: number;
  cacheName?: ICacheName;
}

const AutoRefresh: React.FC<IAutoRefreshProps> = ({ busEvent, tooltipText, pairId, cacheName }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair);

  const { pairConfig, setPairConfig } = usePairConfig({
    exchange: dexPair ? EExchange.dex : EExchange.cex,
    id: pairId,
  });

  const cacheNameToValue = useCallback(
    (cacheName?: ICacheName) => {
      if (pairId === undefined || !cacheName) return false;

      const mapper: Record<ICacheName, boolean> = {
        statistic: !!pairConfig.statisticAutorefresh,
        wallets: !!pairConfig.walletsAutorefresh,
        transactions: !!pairConfig.transactionsAutorefresh,
        'cex-accounts': !!pairConfig.cexAccountsAutorefresh,
        'cex-statistic': !!pairConfig.cexStatisticAutorefresh,
        'balance-bot': !!pairConfig.balanceBotAutorefresh,
        'buysell-bot': !!pairConfig.buysellBotAutorefresh,
        'cex-order-history': !!pairConfig.cexOrdersAutorefresh,
        'cex-buysell-bot': !!pairConfig.cexBuysellBotAutorefresh,
        'cex-order-manager': !!pairConfig.cexOrderManagerAutorefresh,
      };

      return mapper[cacheName] ?? false;
    },
    [pairConfig, pairId],
  );

  const handleSetPairConfigValue = useCallback(
    (value: boolean) => {
      if (pairId === undefined || !cacheName) return;

      const newConfig = { ...pairConfig };

      const cacheNameToKey: Record<ICacheName, keyof IPairConfig> = {
        statistic: 'statisticAutorefresh',
        wallets: 'walletsAutorefresh',
        transactions: 'transactionsAutorefresh',
        'cex-accounts': 'cexAccountsAutorefresh',
        'cex-statistic': 'cexStatisticAutorefresh',
        'balance-bot': 'balanceBotAutorefresh',
        'buysell-bot': 'buysellBotAutorefresh',
        'cex-order-history': 'cexOrdersAutorefresh',
        'cex-buysell-bot': 'cexBuysellBotAutorefresh',
        'cex-order-manager': 'cexOrderManagerAutorefresh',
      };

      //@ts-ignore
      newConfig[cacheNameToKey[cacheName]] = value;

      setPairConfig(pairId, newConfig);
    },
    [setPairConfig, pairId, cacheName, pairConfig],
  );

  const [isActive, setIsActive] = useState<boolean>(cacheNameToValue(cacheName));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const refreshIntervalRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    setIsActive(cacheNameToValue(cacheName));
  }, [pairConfig, cacheNameToValue, cacheName]);

  useEffect(() => {
    if (isActive) {
      Bus.emit(busEvent);
      setIsLoading(true);

      refreshIntervalRef.current = setInterval(() => {
        Bus.emit(busEvent);
        setIsLoading(true);
      }, DEFAULT_REFRESH_INTERVAL);
    }

    return () => {
      clearInterval(refreshIntervalRef.current);
    };
  }, [isActive, busEvent]);

  useEffect(() => {
    const handleDropLoading = () => {
      setIsLoading(false);
    };

    Bus.on(STOP_RELOAD_PREFIX + busEvent, handleDropLoading);

    return () => {
      Bus.off(STOP_RELOAD_PREFIX + busEvent, handleDropLoading);
    };
  }, [busEvent]);

  const handleToggleRefresh = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const newIsActive = !isActive;

      setIsActive(newIsActive);
      handleSetPairConfigValue(newIsActive);
    },
    [isActive, handleSetPairConfigValue],
  );

  return (
    <div className="mm-auto-refresh">
      <Tooltip
        trigger={
          <button
            title="Autorefresh"
            className={cn('refresh', {
              _active: isActive,
              _loading: isLoading,
            })}
            onClick={handleToggleRefresh}
          >
            <RefreshIcon />
          </button>
        }
        content={tooltipText}
        options={{ offset: 10, placement: 'top' }}
      />
    </div>
  );
};

export { AutoRefresh };
