class LocalStorageService {
  static get<T>(key: string) {
    return (
      localStorage.getItem(key) && localStorage.getItem(key) !== 'undefined'
        ? JSON.parse(localStorage.getItem(key)!)
        : undefined
    ) as T | undefined;
  }

  static set(key: string, value: unknown) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log('error: ', error);
    }
  }

  static remove(key: string) {
    return localStorage.removeItem(key);
  }

  static clear() {
    return localStorage.clear();
  }
}

export { LocalStorageService as LocalStorage };
