import React, { useState, useCallback, useContext, useMemo } from 'react';

import { BotEnableStatus, Panel } from 'ui';
import { CexPairContext } from 'context/CexPairContext';
import { Content as ContentComp } from './components';

import { TriangleWarning, ColumnChartIcon } from 'assets/icons';

import './style.scss';

const Content: React.FC<{
  initialOpened?: boolean;
  setIsOpened?: (v: boolean) => any;
}> = ({ initialOpened, setIsOpened }) => {
  const {
    orderbook: { tasks, update },
    updateAccounts,
    botStates,
    botStatesLoading,
  } = useContext(CexPairContext);
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(initialOpened ?? false);

  const handleSwitch = useCallback(
    async (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);

      if (opened) {
        Promise.all([updateAccounts(), update()]);
      }
    },
    [setIsOpened, update, updateAccounts],
  );

  const isWarning = useMemo(
    () =>
      tasks && tasks.length !== 0
        ? !!tasks.find(el => (el.lackBase || el.lackQuote) && el.active && el.account)
        : false,
    [tasks],
  );

  return (
    <Panel
      title="Orderbook grid"
      className="cex-orderbook-grid-panel"
      icon={ColumnChartIcon}
      initialOpened={initialOpened}
      onChange={handleSwitch}
      headerNode={
        <div className="warning-container">
          {botStates.order_book && !botStatesLoading && (
            <BotEnableStatus status={botStates.order_book.status ? 'enabled' : 'disabled'} />
          )}
          {isWarning && (
            <>
              <div className="warning">
                <TriangleWarning />
                <span>Warning!</span>
              </div>
            </>
          )}
        </div>
      }
    >
      {isPanelOpened && <ContentComp />}
    </Panel>
  );
};

export default Content;
