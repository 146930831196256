import React, { createContext, useCallback, useState, useMemo, useContext, useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTypedSelector } from 'store';

import { ApiStatistic } from 'api';
import { getPeriodToAPI } from 'utils/charts';
import { CexPairContext } from 'context/CexPairContext';
import { ChartsControlsContext } from './ChartsControlsContext';

interface IBalancesContext {
  balancesDelta: {
    ending_balance_delta: {
      base: {
        amount: string;
        amount_usd: string;
        coin: string;
      };
      quote: {
        amount: string;
        amount_usd: string;
        coin: string;
      };
    };
    starting_balance_delta: {
      base: {
        amount: string;
        amount_usd: string;
        coin: string;
      };
      quote: {
        amount: string;
        amount_usd: string;
        coin: string;
      };
    };
  };
}

export const BalancesContext = createContext<IBalancesContext>({
  balancesDelta: {
    ending_balance_delta: {
      base: {
        amount: '',
        amount_usd: '',
        coin: '',
      },
      quote: {
        amount: '',
        amount_usd: '',
        coin: '',
      },
    },
    starting_balance_delta: {
      base: {
        amount: '',
        amount_usd: '',
        coin: '',
      },
      quote: {
        amount: '',
        amount_usd: '',
        coin: '',
      },
    },
  },
});

export const BalancesContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const { accounts } = useContext(CexPairContext);
  const { period, startDate, endDate, selectedAccounts, accountsToAPI } =
    useContext(ChartsControlsContext);

  const [balancesDelta, setBalancesDelta] = useState<{
    ending_balance_delta: {
      base: { amount: string; amount_usd: string; coin: string };
      quote: { amount: string; amount_usd: string; coin: string };
    };
    starting_balance_delta: {
      base: { amount: string; amount_usd: string; coin: string };
      quote: { amount: string; amount_usd: string; coin: string };
    };
  }>({
    ending_balance_delta: {
      base: { amount: '', amount_usd: '', coin: '' },
      quote: { amount: '', amount_usd: '', coin: '' },
    },
    starting_balance_delta: {
      base: { amount: '', amount_usd: '', coin: '' },
      quote: { amount: '', amount_usd: '', coin: '' },
    },
  });

  const periodToAPI = useMemo(() => getPeriodToAPI(period.get), [period.get]);

  const getBalances = useCallback(async () => {
    try {
      if (accounts.length === 0) {
        return;
      }

      const account_id = accountsToAPI(selectedAccounts.get).array;

      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;
      const period = periodToAPI;

      const [{ data: cexBalancesDeltaData }] = await Promise.all([
        ApiStatistic.geCexBalancesDelta({
          symbol,
          cex,
          account_id,
          period,
          startDate: startDate.get,
          endDate: endDate.get,
        }),
      ]);

      setBalancesDelta(v => ({
        ...v,
        ...(cexBalancesDeltaData?.starting_balance
          ? { starting_balance_delta: cexBalancesDeltaData?.starting_balance }
          : {}),
        ...(cexBalancesDeltaData?.ending_balance
          ? { ending_balance_delta: cexBalancesDeltaData?.ending_balance }
          : {}),
      }));
    } catch (error) {
      console.log('error: ', error);
    }
  }, [periodToAPI, cexPair, startDate, endDate, accounts, selectedAccounts, accountsToAPI]);

  useDeepCompareEffect(() => {
    getBalances();
  }, [cexPair, accountsToAPI, startDate, endDate, periodToAPI]);

  return (
    <BalancesContext.Provider
      value={{
        balancesDelta,
      }}
    >
      {children}
    </BalancesContext.Provider>
  );
};
