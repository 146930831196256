import React from 'react';

import { ICexOrderbookGridBotTask } from 'types/bots';

interface IProps {
  task: ICexOrderbookGridBotTask;
}

const Spread = React.memo(({ task }: IProps) => {
  return <div>{task.spread_percentage}%</div>;
});

export { Spread };
