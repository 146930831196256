import React from 'react';

import { IIconProps } from './types';

const CloseIcon: React.FC<IIconProps> = ({ className, onClick }) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      className={className ?? undefined}
    >
      <path
        d="M17.4697 19.0303C17.7626 19.3232 18.2374 19.3232 18.5303 19.0303C18.8232 18.7374 18.8232 18.2626 18.5303 17.9697L17.4697 19.0303ZM12 12.5L11.4697 11.9697C11.1768 12.2626 11.1768 12.7374 11.4697 13.0303L12 12.5ZM18.5303 7.03033C18.8232 6.73744 18.8232 6.26256 18.5303 5.96967C18.2374 5.67678 17.7626 5.67678 17.4697 5.96967L18.5303 7.03033ZM18.5303 17.9697L12.5303 11.9697L11.4697 13.0303L17.4697 19.0303L18.5303 17.9697ZM12.5303 13.0303L18.5303 7.03033L17.4697 5.96967L11.4697 11.9697L12.5303 13.0303Z"
        fill="currentColor"
      />
      <path
        d="M6.53033 5.96967C6.23744 5.67678 5.76256 5.67678 5.46967 5.96967C5.17678 6.26256 5.17678 6.73744 5.46967 7.03033L6.53033 5.96967ZM12 12.5L12.5303 13.0303C12.8232 12.7374 12.8232 12.2626 12.5303 11.9697L12 12.5ZM5.46967 17.9697C5.17678 18.2626 5.17678 18.7374 5.46967 19.0303C5.76256 19.3232 6.23744 19.3232 6.53033 19.0303L5.46967 17.9697ZM5.46967 7.03033L11.4697 13.0303L12.5303 11.9697L6.53033 5.96967L5.46967 7.03033ZM11.4697 11.9697L5.46967 17.9697L6.53033 19.0303L12.5303 13.0303L11.4697 11.9697Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { CloseIcon };
