import duration from 'duration-js';

export const durationToMs = (durationString?: string): number => {
  try {
    if (!durationString) return 0;

    return duration.parse(durationString).milliseconds();
  } catch (error) {
    return 0;
  }
};
