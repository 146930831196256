import { memo, FC, useState, useCallback, useMemo } from 'react';
import { useTypedSelector } from 'store';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { Button, Modal, ErrorText, Switcher } from 'ui';
import { DurationField, InputField, SelectField } from 'fields';
import { CreateTaskForm } from 'ui/forms';
import { useBoostHolderTask } from './useBoostHolderModal/useBoostHolderTask';

import { IDexBalanceBotTask } from 'types/bots';
import { IDexPair } from 'types/pairs';
import { ConnectWalletsToBoostHoldersModal } from '../ConnectWalletsToBoostHoldersModal';
import { TotalAmount } from 'pages/DexPair/BalanceBot/components/HolderBalanceTask/components/Table/columns';
import { formatFiat, formatToken } from 'utils/formats';
import { bn, bnFrom, divideBignumbers, multiplyBignumbers } from 'tools/math';

import './style.scss';

const TOKENS = (pair: IDexPair) => [
  { label: pair.token_base.symbol, value: 'add_base_holder' },
  { label: pair.token_quote.symbol, value: 'add_quote_holder' },
];

dayjs.extend(duration);

interface IDexBoostHoldersModal {
  onClose: (balanceBotTask?: IDexBalanceBotTask | undefined) => void;
  mode: 'create' | 'edit';
  task?: IDexBalanceBotTask;
}

export const DexBoostHoldersModal: FC<IDexBoostHoldersModal> = memo(({ onClose, mode, task }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const [isOpenWalletsModal, setIsOpenWalletsModal] = useState<
    'source' | 'destination' | undefined
  >(undefined);

  const { values, handleSubmit, setFieldValue, formError, edited, estimateFee } =
    useBoostHolderTask({
      onClose,
      task,
      mode,
    });

  const decimals = dexPair.token_base.decimals;
  const isCreateMode = mode === 'create';

  const currentSwapPriceUsd =
    estimateFee?.current_pair?.transactions_count &&
    estimateFee.current_pair?.transactions_count >= 5
      ? estimateFee?.current_pair.avg_amount_usd
      : estimateFee?.all_pairs.avg_amount_usd;

  const currentSwapPriceToken = divideBignumbers(
    [bnFrom(currentSwapPriceUsd), decimals],
    [bnFrom(dexPair.token_fee.price_usd), decimals],
  );

  const multipliedFeeCostUsd = multiplyBignumbers(
    [bnFrom(currentSwapPriceUsd), decimals],
    [bnFrom(values.destinationWallets), 1],
  );

  const feeCostUsd = values.buyback_enabled
    ? multiplyBignumbers([bnFrom(multipliedFeeCostUsd), decimals], [bn(2), 18])
    : multipliedFeeCostUsd;

  const multipliedFeeCostToken = multiplyBignumbers(
    [bnFrom(currentSwapPriceToken), decimals],
    [bnFrom(values.destinationWallets), 1],
  );

  const feeCostToken = !values.buyback_enabled
    ? multiplyBignumbers([bnFrom(multipliedFeeCostToken), decimals], [bn(2), 18])
    : multipliedFeeCostToken;

  const requiredTime =
    values.min_pause || values.max_pause || values.destinationWallets
      ? dayjs
          .duration(
            (((values.min_pause ?? 0) + (values.max_pause ?? 0)) / 2) * values.destinationWallets,
            'milliseconds',
          )
          .humanize()
      : '-';

  const dailyVolumePeriod = ((values.min_pause || 0) + (values.max_pause || 0)) / 2;
  const dailyVolumeCount = (24 * 60 * 60 * 1000) / dailyVolumePeriod;
  const dailySummariseVolume = (+values.min_amount + +values.max_amount) / 2;

  const dailyVolume =
    isFinite(dailyVolumeCount) && dailySummariseVolume
      ? `${dailyVolumeCount * dailySummariseVolume} ${
          TOKENS(dexPair).find(el => el.value === values.type)?.label
        }`
      : '-';

  const feeCostValue = values.destinationWallets
    ? `${formatToken(feeCostToken, decimals)} ${dexPair.token_fee.symbol} /
  ${formatFiat(feeCostUsd)}`
    : '-';

  const handleOpenSourceWallets = useCallback(() => {
    setIsOpenWalletsModal('source');
  }, []);

  const handleOpenDestinationWallets = useCallback(() => {
    setIsOpenWalletsModal('destination');
  }, []);

  const handleCloseConnectWalletsModal = useCallback(() => {
    setIsOpenWalletsModal(undefined);
  }, []);

  const isSelectedTokenBase = useMemo(() => values.type === 'add_base_holder', [values]);

  const amountToUsdPrice = useCallback(
    (amount: string) => {
      if (isNaN(Number(amount)) || Number(amount) === 0) return bn(0);

      const amountBn = bn(amount);

      if (isSelectedTokenBase) {
        const tokenPriceBn = bnFrom(dexPair.token_base.price_usd);

        return multiplyBignumbers([amountBn, 18], [tokenPriceBn, 6]);
      }

      if (!isSelectedTokenBase) {
        const tokenPriceBn = bnFrom(dexPair.token_quote.price_usd);

        return multiplyBignumbers([amountBn, 18], [tokenPriceBn, 6]);
      }

      return bn(0);
    },
    [isSelectedTokenBase, dexPair],
  );

  const quotePriceInBase = useMemo(() => {
    const baseUsdPrice = bnFrom(dexPair.token_base.price_usd);
    const quoteUsdPrice = bnFrom(dexPair.token_quote.price_usd);

    const price = divideBignumbers([quoteUsdPrice, 6], [baseUsdPrice, 6]);

    return price;
  }, [dexPair]);

  return (
    <>
      <Modal
        title={`${capitalize(mode)} task`}
        edited={edited}
        className="mm-create-add-holder-task-modal"
        onClose={onClose}
      >
        <CreateTaskForm>
          <div className="form-inner scrollable">
            <div className="mm-add-pair-modal">
              <div>
                <SelectField
                  items={TOKENS(dexPair)}
                  itemToString={v => v.label}
                  label="Token"
                  onSelectItem={v =>
                    v ? setFieldValue('type', v.value, { triggerEdit: true }) : undefined
                  }
                  selectedItem={TOKENS(dexPair).find(el => el.value === values.type)}
                />
                <div className="inputs-row">
                  <InputField
                    type="decimal-number"
                    label="Min amount"
                    decimals={
                      isSelectedTokenBase
                        ? dexPair.token_base.decimals
                        : dexPair.token_quote.decimals
                    }
                    setValue={value => setFieldValue('min_amount', value, { triggerEdit: true })}
                    comment={
                      Number(values.min_amount) > 0
                        ? `${
                            isSelectedTokenBase
                              ? formatToken(bn(values.min_amount), 18)
                              : formatToken(
                                  multiplyBignumbers(
                                    [quotePriceInBase, 18],
                                    [bn(values.min_amount), 18],
                                  ),
                                )
                          } ${dexPair.token_base.symbol} / ${formatFiat(
                            amountToUsdPrice(values.min_amount),
                            18,
                          )}`
                        : undefined
                    }
                    value={values.min_amount}
                  />
                  <InputField
                    type="decimal-number"
                    label="Max amount"
                    decimals={
                      isSelectedTokenBase
                        ? dexPair.token_base.decimals
                        : dexPair.token_quote.decimals
                    }
                    comment={
                      Number(values.max_amount) > 0
                        ? `${
                            isSelectedTokenBase
                              ? formatToken(bn(values.max_amount), 18)
                              : formatToken(
                                  multiplyBignumbers(
                                    [quotePriceInBase, 18],
                                    [bn(values.max_amount), 18],
                                  ),
                                )
                          } ${dexPair.token_base.symbol} / ${formatFiat(
                            amountToUsdPrice(values.max_amount),
                            18,
                          )}`
                        : undefined
                    }
                    setValue={value => setFieldValue('max_amount', value, { triggerEdit: true })}
                    value={values.max_amount}
                  />
                </div>
              </div>

              <div className="inputs-row">
                <DurationField
                  label="Min pause"
                  value={values.min_pause}
                  setValue={v => setFieldValue('min_pause', v, { triggerEdit: true })}
                />
                <DurationField
                  label="Max pause"
                  value={values.max_pause}
                  setValue={v => setFieldValue('max_pause', v, { triggerEdit: true })}
                />
              </div>
              {!isCreateMode && (
                <>
                  <InputField
                    type="decimal-number"
                    label="From"
                    value={
                      values.sourceWallets === 0
                        ? 'All wallets'
                        : `${values.sourceWallets || 0} wallets`
                    }
                    readonly
                    nodeRight={
                      <button onClick={handleOpenSourceWallets} className="wallets-count-text">
                        Choose wallets
                      </button>
                    }
                  />

                  <InputField
                    type="decimal-number"
                    label="To"
                    value={`${values.destinationWallets || 0} wallets`}
                    readonly
                    nodeRight={
                      <button onClick={handleOpenDestinationWallets} className="wallets-count-text">
                        Choose wallets
                      </button>
                    }
                  />
                </>
              )}

              <div className="inputs-row">
                <div className="text-primary font-bold mb-1.5 text-sm">Reverse swap (buyback)</div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Switcher
                    value={values.buyback_enabled}
                    onChange={value =>
                      setFieldValue('buyback_enabled', value, { triggerEdit: true })
                    }
                  />
                </div>
              </div>
              {values.buyback_enabled && (
                <>
                  <div className="inputs-row">
                    <DurationField
                      label="Min pause"
                      value={values.buyback_min_pause}
                      setValue={v => setFieldValue('buyback_min_pause', v, { triggerEdit: true })}
                    />
                    <DurationField
                      label="Max pause"
                      value={values.buyback_max_pause}
                      setValue={v => setFieldValue('buyback_max_pause', v, { triggerEdit: true })}
                    />
                  </div>

                  <div className="text-primary font-bold mb-1.5 text-sm">
                    Allocate ${dexPair.token_fee.symbol} for swaps:
                  </div>
                  <div className="inputs-row">
                    <InputField
                      type="natural-number"
                      label="Min"
                      value={values.buyback_min_swaps}
                      setValue={value =>
                        setFieldValue('buyback_min_swaps', value, { triggerEdit: true })
                      }
                    />
                    <InputField
                      type="natural-number"
                      label="Max"
                      value={values.buyback_max_swaps}
                      setValue={value =>
                        setFieldValue('buyback_max_swaps', value, { triggerEdit: true })
                      }
                    />
                  </div>
                </>
              )}
            </div>
            <div className="add-holder-container">
              <div className="add-holder-container__indicator">
                <span>Total amount:</span>
                <span>
                  {task ? <TotalAmount original={task as IDexBalanceBotTask} /> : <div>-</div>}
                </span>
              </div>
              <div className="add-holder-container__indicator">
                <span>Duration:</span>
                <span>{requiredTime}</span>
              </div>
              <div className="add-holder-container__indicator">
                <span>Daily volume:</span>
                <span>{dailyVolume} </span>
              </div>
              <div className="add-holder-container__indicator">
                <span>Fee costs:</span>
                <span> {feeCostValue}</span>
              </div>
            </div>
          </div>
          <div className="mm-new-buy-sell-bot-task-actions">
            {formError && <ErrorText>{formError}</ErrorText>}
            <Button onClick={handleSubmit}>
              {isCreateMode ? 'Create task and connect wallets' : 'Save'}
            </Button>
          </div>
        </CreateTaskForm>
      </Modal>

      {isOpenWalletsModal && (
        <ConnectWalletsToBoostHoldersModal
          task={task}
          walletType={isOpenWalletsModal}
          onClose={handleCloseConnectWalletsModal}
          currentTaskValue={values}
        />
      )}
    </>
  );
});
