import React, { useCallback, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import cn from 'classnames';

import { IChartRange, mapChartRangeToCaption, mapChartRangeToRangeAPI } from 'types/charts';
import { RangeCalendar } from 'ui/RangeCalendar/RangeCalendar';

import { CalendarIcon } from 'assets/icons';
import './index.scss';

dayjs.extend(duration);

interface IChartRangeControllerProps {
  supportedRanges: IChartRange[];
  className?: string;
  selectedRange?: IChartRange | undefined;
  setSelectedRange?: (v: IChartRange | undefined) => void;
  startDate?: number | undefined;
  endDate?: number | undefined;
  setStartDate?: (v: number | undefined) => void;
  setEndDate?: (v: number | undefined) => void;
  disabled?: boolean;
}

const ChartRangeController: React.FC<IChartRangeControllerProps> = ({
  supportedRanges,
  className,
  selectedRange,
  setSelectedRange,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  disabled = false,
}) => {
  const [calendarOpened, setCalendarOpened] = useState<boolean>(false);

  const handleSetDefaultRange = useCallback(
    (newRange: IChartRange) => {
      if (setSelectedRange) {
        setSelectedRange(newRange);
      }

      if (setStartDate) {
        setStartDate(undefined);
      }
      if (setEndDate) {
        setEndDate(undefined);
      }
    },
    [setStartDate, setEndDate, setSelectedRange],
  );

  const calendarIsSelected = useMemo(() => {
    return startDate !== undefined && endDate !== undefined;
  }, [startDate, endDate]);

  const handleSelectCalendarRange = useCallback(
    ([date1, date2]: [Dayjs | null, Dayjs | null]) => {
      if (setStartDate) {
        if (date1) {
          setStartDate(date1?.valueOf());
        } else {
          setStartDate(undefined);
        }
      }
      if (setEndDate) {
        if (date2) {
          setEndDate(date2?.valueOf());
        } else {
          setEndDate(undefined);
        }
      }
      if (setSelectedRange) {
        setSelectedRange(undefined);
      }
    },
    [setStartDate, setEndDate, setSelectedRange],
  );

  return (
    <>
      <div className={cn({ [className ?? '']: !!className }, 'mm-chart-range-controller-wrapper')}>
        <div className="mm-chart-range-controller__default-ranges">
          {supportedRanges.map(el => {
            const caption = mapChartRangeToCaption(el);
            const value = mapChartRangeToRangeAPI(el);

            const rangeIsSelected = value === selectedRange;
            const isDisabled = disabled;

            return (
              <div
                key={el}
                onClick={() => !isDisabled && handleSetDefaultRange(value)}
                className={cn('range', {
                  _is_selected: !!rangeIsSelected,
                  _is_disabled: isDisabled,
                })}
              >
                {caption}
              </div>
            );
          })}
        </div>
        <div
          className={cn('mm-chart-range-controller__custom-range', {
            '_is-selected': !!calendarIsSelected,
            '_is-disabled': disabled,
          })}
          onClick={() => !disabled && setCalendarOpened(v => !v)}
        >
          <CalendarIcon className={cn('calendar-icon', { selected: calendarIsSelected })} />
        </div>
      </div>
      {calendarOpened && (
        <RangeCalendar
          initialEndDate={endDate ? dayjs(endDate) : null}
          initialStartDate={startDate ? dayjs(startDate) : null}
          onClose={() => setCalendarOpened(false)}
          title={'Select range'}
          handleFilter={handleSelectCalendarRange}
          withTime
        />
      )}
    </>
  );
};

export default ChartRangeController;
