import { web3Configs, ENetwork } from 'web3';

export const dextoolsLink = ({
  network,
  pairAddress,
}: {
  network: ENetwork;
  pairAddress: string;
}) => {
  const { dextoolsSlug } = web3Configs[network];

  if (!dextoolsSlug) return undefined;

  const url = `https://www.dextools.io/app/en/${dextoolsSlug}/pair-explorer/${pairAddress}`;

  return url;
};
