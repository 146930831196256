import React, { HTMLAttributes } from 'react';
import cn from 'classnames';

import './button.scss';

interface IButtonLoadingProps extends HTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'error';
  disabled?: boolean;
  type?: 'submit';
  size?: 'small' | 'big';
}

const ButtonLoading: React.FC<IButtonLoadingProps> = ({
  color = 'primary',
  type,
  disabled = false,
  size = 'big',
  className,
  ...rest
}) => {
  return (
    <button
      disabled={disabled}
      className={cn(
        'mm-button',
        'mm-button-with-loader',
        `mm-button-${color}`,
        {
          'mm-button-disabled': disabled,
        },
        `mm-button-${size}`,
        { [className ?? '']: className },
      )}
      type={type}
      {...rest}
    >
      <div className="mm-button-with-loader__loader-wrapper">
        <div className="mm-button-with-loader__loader" />
      </div>
    </button>
  );
};

export { ButtonLoading };
