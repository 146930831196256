import React, { useCallback, useState, HTMLAttributes, useRef, useMemo, useEffect } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  offset,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react';
import { useTypedSelector } from 'store';

import { Checkbox } from 'ui';
import { useTheme } from 'hooks/utility';
import {
  fieldLabelFocused,
  fieldLabelNotFocusedFilled,
  fieldLabelNotFocusedNotFilled,
} from 'constant/fields';
import { tailwindConfig } from 'tailwind-config';
import { FieldHints } from '../../components';

import { ArrowDropDown } from 'assets/icons';

import './style.scss';

interface IBaseMultiSelectFieldProps<V extends { id: string | number }>
  extends HTMLAttributes<HTMLInputElement> {
  onSelectItems: (newItems: V[]) => void;
  items: V[];
  selectedItems: V[];
  selectedItemsText?: string;
  renderItem: (item: V) => React.ReactNode;
  comment?: string;
  errorMessage?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

function BaseMultiSelectField<V extends { id: string | number }>({
  comment,
  errorMessage,
  placeholder,
  label,
  disabled = false,
  onClick,
  className,
  id,
  items,
  selectedItems,
  selectedItemsText,
  onSelectItems,
  renderItem,
  ...rest
}: IBaseMultiSelectFieldProps<V>) {
  const palette = useTypedSelector(store => store.ui.selectedThemeColors);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { isDark } = useTheme();

  useEffect(() => {
    if (disabled) {
      setIsOpened(false);
    }
  }, [disabled]);

  const { refs, context, floatingStyles } = useFloating({
    placement: 'bottom-start',
    open: disabled ? false : isOpened,
    onOpenChange: disabled ? () => {} : setIsOpened,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    listNav,
    click,
  ]);

  const handleSelect = useCallback(
    (item: V) => {
      let newSelectedItems = [...selectedItems];

      const itemSelected = selectedItems.find(el => el.id === item.id);

      if (itemSelected) {
        newSelectedItems = newSelectedItems.filter(el => el.id !== item.id);
      } else {
        newSelectedItems.push(item);
      }

      onSelectItems(newSelectedItems);
    },
    [selectedItems, onSelectItems],
  );

  const value = useMemo(() => (selectedItemsText ? selectedItemsText : ''), [selectedItemsText]);

  const inputId = useMemo(() => {
    return id ?? `mm-select-${uuid()}`;
  }, [id]);

  const lineStyles = useMemo(() => {
    let borderColor = 'rgba(127, 145, 187, 0.2)';

    if (errorMessage !== undefined) {
      borderColor = palette.red_1;
    } else if (isOpened) {
      borderColor = palette.primary;
    }

    return { borderBottom: `2px solid ${borderColor}` };
  }, [isOpened, errorMessage, palette]);

  const labelStyles = useMemo(() => {
    const fieldFilled = value !== undefined && value !== null && value !== '';
    const fieldFocused = isOpened;

    if (fieldFocused) return fieldLabelFocused(!!errorMessage);

    if (!fieldFocused && fieldFilled) return fieldLabelNotFocusedFilled(!!errorMessage);

    return fieldLabelNotFocusedNotFilled;
  }, [value, isOpened, errorMessage]);

  return (
    <>
      <div
        className={cn({ [className ?? '']: className }, 'mm-base-multi-select-field', {
          '_non-label': !label || label === '',
        })}
        {...rest}
      >
        <div
          className={cn('mm-base-multi-select-field__container', {
            _disabled: disabled,
          })}
          tabIndex={0}
          ref={refs.setReference}
          aria-labelledby="select-label"
          aria-autocomplete="none"
          {...getReferenceProps()}
        >
          <div className="mm-base-multi-select-field__inner">
            <div className="mm-base-multi-select-field__inner__input-holder">
              <input
                className={cn('mm-base-multi-select-field__inner__input', {
                  _focused: isOpened,
                  '_non-label': !label || label === '',
                })}
                value={value}
                id={inputId}
                autoComplete="off"
                type="text"
                placeholder={placeholder}
                readOnly={true}
                disabled={disabled}
              />
              {label ? (
                <motion.label
                  initial={fieldLabelNotFocusedNotFilled}
                  animate={labelStyles}
                  className={cn('mm-base-multi-select-field__inner__label', {
                    _disabled: !!disabled,
                  })}
                  htmlFor={inputId}
                >
                  {label}
                </motion.label>
              ) : null}
            </div>
            <button className={cn('arrow-angle-down', { _opened: isOpened })}>
              <ArrowDropDown />
            </button>
          </div>
          <motion.div
            animate={lineStyles}
            transition={{ ease: 'easeInOut', duration: 0.4 }}
            className={'mm-base-multi-select-field__line'}
          />
        </div>
        <FieldHints comment={comment} errorMessage={errorMessage} />
      </div>
      {isOpened && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className="mm-base-multi-select-field__dropdown scrollable"
              {...getFloatingProps()}
            >
              {items.map((item, i) => {
                const isItemSelected = !!selectedItems.find(el => el.id === item.id);

                return (
                  <div
                    className="mm-base-multi-select-field__dropdown__item"
                    key={i}
                    ref={node => {
                      listRef.current[i] = node;
                    }}
                    role="option"
                    tabIndex={i === activeIndex ? 0 : -1}
                    style={{
                      background:
                        i === activeIndex
                          ? tailwindConfig.theme.colors[isDark ? 'gray-3' : 'primary-light']
                          : '',
                    }}
                    {...getItemProps({
                      onClick() {
                        handleSelect(item);
                      },
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(item);
                        }

                        if (event.key === ' ') {
                          event.preventDefault();
                          handleSelect(item);
                        }
                      },
                    })}
                  >
                    <Checkbox checked={isItemSelected} onChange={() => handleSelect(item)} />
                    {renderItem(item)}
                  </div>
                );
              })}
              {items.length === 0 && <div className="dropdown__not-found">Not found...</div>}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}

export { BaseMultiSelectField };
