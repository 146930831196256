import { ApiPairs } from 'api';
import { useCallback, useEffect, useMemo } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { isNil } from 'lodash';

import { EExchange } from 'web3';
import { setPairConfig, IPairConfig } from '..';

interface IUsePairConfigProps {
  exchange: EExchange;
  id?: number;
}

const usePairConfig = ({ exchange, id }: IUsePairConfigProps) => {
  const dispatch = useTypedDispatch();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const pairConfig = useTypedSelector(store => store.pairConfigs.configs[id ?? 0]);

  const updatePairPoolCreated = useCallback(
    async (pairConfig: IPairConfig, id: number) => {
      if (!dexPair) return;

      try {
        const { data } = await ApiPairs.getPairTicker({
          address: dexPair.address,
          network: dexPair.network,
        });

        if (data && data[0]) {
          dispatch(
            setPairConfig({ id, config: { ...pairConfig, poolCreated: data[0].pool_created } }),
          );
        } else {
          dispatch(setPairConfig({ id, config: { ...pairConfig, poolCreated: 0 } }));
        }
      } catch (error) {
        console.log('error');
      }
    },
    [dexPair, dispatch],
  );

  useEffect(() => {
    if (isNil(pairConfig?.poolCreated) && !isNil(id) && id !== 0 && exchange === EExchange.dex) {
      updatePairPoolCreated(pairConfig, id);
    }
  }, [pairConfig, exchange, id, updatePairPoolCreated]);

  const handleSetPairConfig = useCallback(
    (id: number, config: IPairConfig) => {
      dispatch(setPairConfig({ id, config }));
    },
    [dispatch],
  );

  const value = useMemo(
    () => ({ pairConfig: pairConfig ?? {}, setPairConfig: handleSetPairConfig }),
    [pairConfig, handleSetPairConfig],
  );

  return value;
};

export default usePairConfig;
