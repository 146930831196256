import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const LinkSmall: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors['gray-1'],
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      className={className ?? undefined}
    >
      <path
        d="M6.6361 4.61328H4.52722C4.23604 4.61328 4 4.84933 4 5.1405V11.4671C4 11.7583 4.23604 11.9944 4.52722 11.9944H10.8539C11.145 11.9944 11.3811 11.7583 11.3811 11.4671V9.35827"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3999 7.07605C11.3999 7.40742 11.6685 7.67605 11.9999 7.67605C12.3313 7.67605 12.5999 7.40742 12.5999 7.07605L11.3999 7.07605ZM11.9999 4L12.5999 4C12.5999 3.84087 12.5367 3.68826 12.4241 3.57574C12.3116 3.46321 12.159 3.4 11.9999 3.4V4ZM8.92383 3.4C8.59246 3.4 8.32383 3.66863 8.32383 4C8.32383 4.33137 8.59246 4.6 8.92383 4.6V3.4ZM12.5999 7.07605L12.5999 4L11.3999 4L11.3999 7.07605L12.5999 7.07605ZM11.9999 3.4H8.92383V4.6H11.9999V3.4Z"
        fill={color}
      />
      <path
        d="M7.88433 7.26507C7.65002 7.49939 7.65002 7.87928 7.88433 8.1136C8.11864 8.34791 8.49854 8.34791 8.73286 8.1136L7.88433 7.26507ZM11.8089 5.03755C12.0432 4.80323 12.0432 4.42333 11.8089 4.18902C11.5746 3.9547 11.1947 3.9547 10.9604 4.18902L11.8089 5.03755ZM8.73286 8.1136L11.8089 5.03755L10.9604 4.18902L7.88433 7.26507L8.73286 8.1136Z"
        fill={color}
      />
    </svg>
  );
};

export { LinkSmall };
