import { web3Configs, ENetwork } from 'web3';

const ADDRESS_TEXT = '/address/';
const TX_TEXT = '/tx/';
const TOKEN_TEXT = '/token/';

export const calculateScanLink = ({
  address,
  network,
  addressType,
}: {
  address: string;
  network: ENetwork;
  addressType: 'transaction' | 'address' | 'token';
}): string => {
  let slug = '';
  if (addressType === 'transaction') {
    slug = TX_TEXT;
  } else if (addressType === 'token') {
    slug = TOKEN_TEXT;
  } else if (addressType === 'address') {
    slug = ADDRESS_TEXT;
  }

  const scanUrl = web3Configs[network].scanUrl;

  return scanUrl + slug + address;
};
