import React from 'react';

import './connectNewWalletsToPairForm.scss';

interface IConnectNewWalletsToPairFormProps {
  children: React.ReactNode;
}

const ConnectNewWalletsToPairForm: React.FC<IConnectNewWalletsToPairFormProps> = ({ children }) => {
  return <div className="mm-connect-new-wallets-to-pair-form">{children}</div>;
};

export { ConnectNewWalletsToPairForm };
