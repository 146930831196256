import { useCallback } from 'react';
import { isNil } from 'lodash';

import { ApiBalanceBot } from 'api';
import { filtersToValues } from 'utils/filters';
import { IFilterValue } from 'types/filters/common';
import { TOTAL_TOKEN_ID } from 'constant/numbers';

const useDexBoostHoldersWallets = () => {
  const getter = useCallback(
    async ({
      limit,
      lastSeenId,
      filters,
      wallets,
      task_id,
      is_connected,
      exclude_source,
    }: {
      limit: number;
      lastSeenId: number;
      filters: IFilterValue[];
      wallets: 'source' | 'destination';
      task_id: number;
      is_connected: boolean;
      exclude_source?: boolean | undefined;
    }) => {
      const filterValues = filtersToValues({
        filters: filters,
      });

      const balance = filterValues.balance as {
        connection: string;
        value: string;
        tokenId: number | undefined;
        name: string;
      }[];
      const volume = filterValues.volume as {
        connection: string;
        value: string;
        tokenId: number | undefined;
        name: string;
      }[];
      const transactions_count = filterValues.transactions_count ?? undefined;

      const balanceSerialized: string[] = [];
      const balanceUsdSerialized: string[] = [];

      if (balance) {
        for (const _balance of balance) {
          if (isNil(_balance.tokenId)) continue;

          const val = `token${_balance.tokenId}${_balance.connection}${_balance.value}`;

          if (_balance.tokenId === TOTAL_TOKEN_ID) {
            balanceUsdSerialized.push(val);
          } else {
            balanceSerialized.push(val);
          }
        }
      }

      const volumeSerialized: string[] = [];
      const volumeUsdSerialized: string[] = [];

      if (volume) {
        for (const _volume of volume) {
          if (isNil(_volume.tokenId)) continue;

          const val = `token${_volume.tokenId}${_volume.connection}${_volume.value}`;

          if (_volume.tokenId === TOTAL_TOKEN_ID) {
            volumeUsdSerialized.push(val);
          } else {
            volumeSerialized.push(val);
          }
        }
      }

      const params = {
        balance_bot_id: task_id,
        lastSeenId,
        limit,
        not_connected: (!is_connected).toString(),
        balance: balanceSerialized,
        balance_usd: balanceUsdSerialized,
        volume: volumeSerialized,
        volume_usd: volumeUsdSerialized,
        transactions_count,
        exclude_source,
      };

      const { isSuccess, errorMessage, data } = await (wallets === 'source'
        ? ApiBalanceBot.getSourceBalanceBotTasksById(params)
        : ApiBalanceBot.getDestinationWalletsTasksById(params));

      return { isSuccess, errorMessage, data };
    },
    [],
  );

  return getter;
};

export { useDexBoostHoldersWallets };
