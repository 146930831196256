import { BigNumber } from '@ethersproject/bignumber';

export const ZERO = BigNumber.from(0);

export const BILLION = 1000000000;
export const MILLION = 1000000;
export const THOUSAND = 1000;

export const TOTAL_TOKEN_ID = 1;
export const ATR_PERIOD = 10;
export const CORS_ERROR_CODE = -1;
