import React, { useEffect, Suspense, lazy } from 'react';
import { usePrevious } from 'react-use';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store';
import { withApplicationLayout } from 'tools';

import { SessionLogoutModal, Spinner } from 'ui';
import { useTheme } from 'hooks/utility';
import { ROUTE_PATHS } from 'constant/routes';
import { IUserRole } from 'api/apiUsers/models';

import DexPair from 'pages/DexPair/DexPair';
import CexPair from 'pages/CexPair/CexPair';

const Project = lazy(() => import('pages/Project/Project'));
const Wallets = lazy(() => import('pages/Wallets/Wallets'));
const Accounts = lazy(() => import('pages/Account/Accounts'));
const Authentication = lazy(() => import('pages/Authentication/Authentication'));
const Arbitrage = lazy(() => import('pages/Arbitrage/Arbitrage'));

interface IPrivateRouteProps {
  children: React.ReactNode;
  roles: IUserRole[];
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children, roles }) => {
  const navigate = useNavigate();

  const user = useTypedSelector(store => store.auth.user);

  useEffect(() => {
    if (!user) navigate(ROUTE_PATHS.login);

    if (user && !roles.includes(user.role)) {
      navigate('/');
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <>{children}</>;
};

const Routing: React.FC = () => {
  const location = useLocation();
  const userIsAuthenticated = useTypedSelector(store => Boolean(store.auth.jwt));
  const userRole = useTypedSelector(store => store.auth.user?.role);

  const prevPathname = usePrevious(location.pathname);

  useTheme();

  if (!userIsAuthenticated) {
    return (
      <Suspense>
        <Routes>
          <Route
            path={ROUTE_PATHS.login}
            element={<Authentication prevPathname={prevPathname} />}
          />
          <Route path="*" element={<Navigate to={ROUTE_PATHS.login} replace />} />
        </Routes>
      </Suspense>
    );
  }

  return withApplicationLayout(
    <Suspense>
      <SessionLogoutModal />
      <Suspense
        fallback={
          <div className="flex items-center justify-center p-6">
            <Spinner size="medium" />
          </div>
        }
      >
        <Routes>
          <Route
            path={ROUTE_PATHS.wallets}
            element={
              <PrivateRoute roles={['admin']}>
                <Wallets />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.accounts}
            element={
              <PrivateRoute roles={['admin']}>
                <Accounts />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_PATHS.project}
            element={
              <PrivateRoute roles={['admin']}>
                <Project />
              </PrivateRoute>
            }
          />
          <Route path={ROUTE_PATHS.dexPair} element={<DexPair />} />
          <Route path={ROUTE_PATHS.cexPair} element={<CexPair />} />
          <Route path={ROUTE_PATHS.arbitrage} element={<Arbitrage />} />
          {userRole === 'admin' ? (
            <Route path="*" element={<Navigate to={ROUTE_PATHS.wallets} replace />} />
          ) : (
            <>
              <Route index element={<></>} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
        </Routes>
      </Suspense>
    </Suspense>,
  );
};

export { Routing };
