import React, { useCallback, useEffect, useMemo, useRef, useState, HTMLAttributes } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import {
  useFloating,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  offset,
  size,
  autoUpdate,
  FloatingPortal,
  useFocus,
} from '@floating-ui/react';
import { useTypedSelector } from 'store';

import { KEYCODES } from 'constant/keyboard';
import {
  fieldLabelFocused,
  fieldLabelNotFocusedFilled,
  fieldLabelNotFocusedNotFilled,
} from 'constant/fields';
import {
  durationStringToPossibleList,
  parseDuration,
  parseDurationShortString,
  parseMilliseconds,
} from './utils';

import { FieldHints } from '../components';
import { TimerIcon } from 'assets/icons';

import './style.scss';

interface IDurationFieldProps extends HTMLAttributes<HTMLInputElement> {
  comment?: string;
  errorMessage?: string;
  value: number | undefined | null;
  setValue: (v: number | null) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  readonly?: boolean;
  zeroable?: boolean;
}

const DurationField: React.FC<IDurationFieldProps> = ({
  value,
  setValue,
  label,
  placeholder = '1H, 30Min, 15S',
  errorMessage,
  comment,
  disabled = false,
  readonly = false,
  onChange,
  onBlur,
  zeroable = false,
  className,
  id,
  ...rest
}) => {
  const palette = useTypedSelector(store => store.ui.selectedThemeColors);
  const listRef = useRef<Array<HTMLElement | null>>([]);

  const [localValue, setLocalValue] = useState<string>('');
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const inputId = useMemo(() => {
    return id ?? `mm-duration-${uuid()}`;
  }, [id]);

  const lineStyles = useMemo(() => {
    let borderColor = 'rgba(127, 145, 187, 0.2)';

    if (errorMessage !== undefined) {
      borderColor = palette.red_1;
    } else if (isFocused) {
      borderColor = palette.primary;
    }

    return { borderBottom: `2px solid ${borderColor}` };
  }, [isFocused, errorMessage, palette]);

  const labelStyles = useMemo(() => {
    const fieldFilled = localValue !== '';
    const fieldFocused = isFocused;

    if (fieldFocused) return fieldLabelFocused(!!errorMessage);

    if (!fieldFocused && fieldFilled) return fieldLabelNotFocusedFilled(!!errorMessage);

    return fieldLabelNotFocusedNotFilled;
  }, [isFocused, errorMessage, localValue]);

  const { refs, context, floatingStyles } = useFloating({
    placement: 'bottom-start',
    open: disabled ? false : isOpened,
    onOpenChange: disabled ? () => {} : setIsOpened,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const focus = useFocus(context, { enabled: !disabled && !readonly });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    listNav,
    focus,
  ]);

  const fillLocalInputResult = useCallback(
    (value: string | number) => {
      const unparsedString = parseMilliseconds(value);

      const parsedDuration = parseDuration(unparsedString);

      const { years, months, weeks, days, hours, minutes, seconds, milliseconds } = parsedDuration;

      setLocalValue(
        zeroable && Number(value) === 0
          ? '0s'
          : parseDurationShortString({
              years,
              months,
              weeks,
              days,
              hours,
              minutes,
              seconds,
              milliseconds,
            }),
      );
    },
    [zeroable],
  );

  const proposedList = useMemo(() => {
    return durationStringToPossibleList(localValue)
      .filter(el => el.stringValue !== '')
      .filter(el => (zeroable ? true : el.msValue !== 0));
  }, [localValue, zeroable]);

  const handleSelectDuration = useCallback(
    (seconds: number) => {
      setIsFocused(false);
      setIsOpened(false);

      setValue(seconds);
      fillLocalInputResult(seconds);
    },
    [fillLocalInputResult, setValue],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(null);
      setLocalValue(event.currentTarget.value);

      if (onChange) {
        onChange(event);
      }
    },
    [setValue, onChange],
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event);
      }

      setIsFocused(false);
    },
    [onBlur],
  );

  useEffect(() => {
    if (value !== undefined && value !== null) {
      fillLocalInputResult(value);
    }
  }, [value, fillLocalInputResult]);

  const inputElementKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.keyCode === KEYCODES.Enter && !!proposedList[0] && isFocused) {
        handleSelectDuration(proposedList[0].msValue);
      }
    },
    [proposedList, isFocused, handleSelectDuration],
  );

  return (
    <>
      <div className={cn({ [className ?? '']: className }, 'mm-duration-field')} {...rest}>
        <div
          className={cn('mm-duration-field__container', {
            _disabled: disabled,
          })}
          tabIndex={0}
          ref={refs.setReference}
          aria-labelledby="select-label"
          aria-autocomplete="none"
          {...getReferenceProps()}
        >
          <div className="mm-duration-field__inner">
            <div className="mm-duration-field__inner__input-holder">
              <input
                key={inputId}
                className="mm-duration-field__inner__input"
                value={localValue}
                id={inputId}
                type="text"
                autoComplete="off"
                placeholder={placeholder}
                readOnly={readonly}
                disabled={disabled}
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={inputElementKeyDown}
              />
              {label ? (
                <motion.label
                  initial={fieldLabelNotFocusedNotFilled}
                  animate={labelStyles}
                  className={cn('mm-duration-field__inner__label', { _disabled: !!disabled })}
                  htmlFor={inputId}
                >
                  {label}
                </motion.label>
              ) : null}
            </div>
            <label htmlFor={inputId}>
              <TimerIcon
                className={cn('duration-timer-icon', {
                  _focused: isFocused,
                  _error: !!errorMessage,
                })}
              />
            </label>
          </div>
          <motion.div
            animate={lineStyles}
            transition={{ ease: 'easeInOut', duration: 0.4 }}
            className={'mm-duration-field__line'}
          />
        </div>
        <FieldHints comment={comment} errorMessage={errorMessage} />
      </div>
      {isOpened && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            modal={false}
            initialFocus={-1}
            returnFocus={false}
          >
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className="mm-duration-field__dropdown scrollable"
              {...getFloatingProps()}
            >
              {proposedList.map((duration, i) => (
                <div
                  className="mm-duration-field__dropdown__item"
                  key={i}
                  ref={node => {
                    listRef.current[i] = node;
                  }}
                  role="option"
                  tabIndex={i === activeIndex ? 0 : -1}
                  aria-selected={i === selectedIndex && i === activeIndex}
                  style={{
                    background: i === activeIndex ? palette.primary_light : '',
                  }}
                  {...getItemProps({
                    onClick() {
                      handleSelectDuration(duration.msValue);
                    },
                    onKeyDown(event) {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleSelectDuration(duration.msValue);
                      }

                      if (event.key === ' ') {
                        event.preventDefault();
                        handleSelectDuration(duration.msValue);
                      }
                    },
                  })}
                >
                  <span>{duration.stringValue}</span>
                </div>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

export { DurationField };
