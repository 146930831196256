import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const DocumentIcon: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors['gray-1'],
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <path
        d="M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8368 5H8.85714C7.51027 5 6.83684 5 6.41842 5.3755C6 5.75101 6 6.35537 6 7.5641V13.9744C6 15.1831 6 15.7875 6.41842 16.163C6.83684 16.5385 7.51027 16.5385 8.85714 16.5385H13.1429C14.4897 16.5385 15.1632 16.5385 15.5816 16.163C16 15.7875 16 15.1831 16 13.9744V8.73617C16 8.47415 16 8.34314 15.9456 8.22534C15.8913 8.10754 15.788 8.0149 15.5816 7.82962L12.847 5.3755C12.6405 5.19023 12.5373 5.09759 12.406 5.0488C12.2748 5 12.1288 5 11.8368 5Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M8.30859 11.1641L12.924 11.1641"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.30859 13.4453L11.3855 13.4453"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.1523 5V7.05128C12.1523 7.53477 12.1523 7.77652 12.3401 7.92672C12.5278 8.07692 12.83 8.07692 13.4344 8.07692H15.9985"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export { DocumentIcon };
