import React from 'react';

import { Modal, Button, ButtonLoading } from 'ui';

import { useUDPairModal } from './useU&DPairModal/useU&DPairModal';
import { PairForm } from 'ui/forms';
import { EExchange } from 'web3';
import { InputField } from 'fields';

interface IUDPairModalProps {
  onClose: () => void;
  onOpen: () => void;
  pairId: number;
  projectId: number;
  pairSymbol: string;
  initialNotes: string;
  exchange: EExchange;
}

const UDPairModal: React.FC<IUDPairModalProps> = ({
  onClose,
  onOpen,
  pairId,
  projectId,
  pairSymbol,
  initialNotes,
  exchange,
}) => {
  const { loading, onDeletePair, values, errors, touched, handleSubmit, setFieldValue } =
    useUDPairModal({
      onClose,
      onOpen,
      pairId,
      projectId,
      pairSymbol,
      initialNotes,
      exchange,
    });

  return (
    <Modal onClose={onClose} title="Trading pair settings">
      <PairForm>
        <InputField
          label="Notes"
          type="text"
          value={values.notes}
          setValue={newNotes => setFieldValue('notes', newNotes ?? '')}
          errorMessage={touched.notes && errors.notes ? errors.notes : undefined}
        />
        {loading && <ButtonLoading />}
        {!loading && (
          <>
            <Button type="button" onClick={handleSubmit}>
              Confirm changing
            </Button>
            <Button type="button" color="error-secondary" onClick={onDeletePair}>
              Delete trading pair
            </Button>
          </>
        )}
      </PairForm>
    </Modal>
  );
};

export { UDPairModal };
