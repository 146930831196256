import { useCallback } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { isNil } from 'lodash';
import { v4 as uuid } from 'uuid';
import { RootStore } from 'store';

import { EDexPairFilters, ECexPairFilters, EGeneralFilters } from 'types/filters';
import { IFilterValue } from 'types/filters/common';

import { setupInitialFilters } from '..';
import { EExchange } from 'web3';

interface IProps {
  general?: EGeneralFilters;
  cex?: {
    pairId: number | undefined;
    type: ECexPairFilters;
  };
  dex?: {
    pairId: number | undefined;
    type: EDexPairFilters;
  };
}

export function useGetFilters({ cex, dex, general }: IProps) {
  const dispatch = useTypedDispatch();

  const pairStateSelector = useCallback(
    (store: RootStore) => {
      if (general) return store.filters[general];

      if (cex) {
        return store.filters.cex[cex.pairId ?? 0];
      }

      if (dex) {
        return store.filters.dex[dex.pairId ?? 0];
      }
    },
    [general, cex, dex],
  );

  const pairState = useTypedSelector(pairStateSelector);

  const getFilters = useCallback((): IFilterValue[] => {
    const initialFilters = [{ id: uuid() }] as IFilterValue[];

    if (general && (!pairState?.filters || (pairState?.filters as any[]).length === 0)) {
      dispatch(
        setupInitialFilters({
          general,
          filters: {
            filters: initialFilters,
            setted: [],
          },
        }),
      );

      return initialFilters;
    }

    if (general && pairState) return (pairState.filters ?? []) as IFilterValue[];

    if (!general && !pairState) {
      const pairId = cex ? cex.pairId : dex ? dex.pairId : undefined;
      const type = cex ? cex.type : dex ? dex.type : undefined;

      if (!isNil(pairId) && !isNil(type)) {
        dispatch(
          setupInitialFilters({
            pairId,
            exchange: cex ? EExchange.cex : EExchange.dex,
            filters: {
              [type]: {
                filters: initialFilters,
                setted: [],
              },
            },
          }),
        );
      }

      return initialFilters;
    }

    const pairId = cex ? cex.pairId : dex ? dex.pairId : undefined;
    const type = cex ? cex.type : dex ? dex.type : undefined;

    if (isNil(pairId) || isNil(type)) return initialFilters;

    //@ts-ignore
    const filtersByType = pairState.filters[type];

    if (!filtersByType) {
      dispatch(
        setupInitialFilters({
          pairId,
          exchange: cex ? EExchange.cex : EExchange.dex,
          //@ts-ignore
          filters: { ...pairState.filters, [type]: { filters: initialFilters, setted: [] } },
        }),
      );

      return initialFilters;
    }

    return filtersByType.filters ?? [];
  }, [dispatch, pairState, general, cex, dex]);

  return getFilters;
}
