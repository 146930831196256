import { useMemo } from 'react';

import { EFilterEntity, IFilterValue } from 'types/filters/common';

import { Numeric } from './Numeric';

interface IFilterConnectionProps {
  filter: IFilterValue;
  type: EFilterEntity | undefined;
  updateFilter: (id: string, filter?: IFilterValue) => void;
}

function FilterConnection({ filter, type, updateFilter }: IFilterConnectionProps) {
  if (!type) return null;

  const content = useMemo(() => {
    const props = {
      filter,
      updateFilter,
    };

    switch (type) {
      case EFilterEntity.natural_number:
        return <Numeric {...props} />;
      case EFilterEntity.number:
        return <Numeric {...props} />;
      default:
        return null;
    }
  }, [filter, type, updateFilter]);

  return content === null ? null : <div className="select-connection-container">{content}</div>;
}

export { FilterConnection };
