import React from 'react';
import { Table } from '@tanstack/react-table';

import { TableCheckbox } from 'ui';
import { IConnectWalletBalanceTaskRow } from 'types/tables/dex/connectWalletBalanceTaskRow';

interface IWalletHeadProps {
  table: Table<IConnectWalletBalanceTaskRow>;
  readOnlyMode: boolean;
}

const WalletsHead: React.FC<IWalletHeadProps> = ({ table, readOnlyMode }) => {
  return (
    <div className="checkbox-area">
      {!readOnlyMode && (
        <TableCheckbox
          id="select-all-to-connect-to-balnce-task"
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      )}
      <span>Wallet address</span>
    </div>
  );
};

export { WalletsHead };
