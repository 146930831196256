import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, axios, IBaseResponse } from '../apiBase';
import { IAuthUserMe, IAuthUser } from './models';

class ApiUsers extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiUsers | undefined;
  private static instance = (() => {
    if (ApiUsers.__instance === undefined) {
      ApiUsers.__instance = new ApiUsers();
    }
    return ApiUsers.__instance;
  })();

  public static getMe = async (jwt: string) => {
    try {
      const response = await axios.get<IBaseResponse<IAuthUserMe>>('/api/users/me', {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<IAuthUserMe>({ error });
    }
  };

  public static getAllUsers = async ({
    limit,
    offset,
    sort_by,
  }: {
    limit: number;
    offset: number;
    sort_by?: string;
  }) => {
    try {
      const urlParams = new URLSearchParams();
      urlParams.append('limit', limit.toString());
      urlParams.append('offset', offset.toString());
      if (sort_by) {
        urlParams.append('sort_by', sort_by);
      }

      const response = await ApiUsers.instance.get<IBaseResponse<{ items: IAuthUser[] }>>(
        '/users' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<{ items: IAuthUser[] }>({ error });
    }
  };
}

export { ApiUsers };
