import { BigNumber, FixedNumber, BigNumberish } from '@ethersproject/bignumber';
import { formatUnits, parseUnits, parseEther } from '@ethersproject/units';

import { ZERO } from 'constant/numbers';
import { trimDecimalZeroes } from 'utils/formats';

/**
 * Converts a human-readable number in string or number format to a BigNumber with specified decimals.
 * @param value - The number in string or number format
 * @param decimals - The number of decimals
 * @returns BigNumber
 */
export const bn = (value?: string | number | null, decimals = 18) => {
  try {
    if (!value || value === '') {
      return BigNumber.from(0);
    }

    if (typeof value === 'number') {
      value = value.toString();
    }

    if (typeof value === 'string' && (value.includes('e') || value.includes('E'))) {
      const numericValue = Number(value);

      if (!isNaN(numericValue)) {
        value = numericValue.toFixed(decimals);
        return parseUnits(value, decimals);
      } else {
        return BigNumber.from(0);
      }
    }

    const [integerPart, fractionalPart = ''] = value!.split('.');
    const fractionalPartPadded = fractionalPart.padEnd(decimals, '0');
    const valueInWei = integerPart + fractionalPartPadded;

    return BigNumber.from(valueInWei);
  } catch (error) {
    console.log('error in function bn: ', error);
    return BigNumber.from(0);
  }
};

/**
 * Takes a string or number in format "1 * 10^n" and convert it to BigNumber
 * @param value The string or number to convert
 * @returns BigNumber
 */
export const bnFrom = (value?: string | number | null | BigNumber) => {
  try {
    if (!value) return BigNumber.from(0);

    return BigNumber.from(value);
  } catch (error) {
    console.log('error in function bnFrom: ', error);
    return BigNumber.from(0);
  }
};

/**
 * Converts a BigNumber to another BigNumber with different decimals.
 * @param value The BigNumber to convert
 * @param fromDecimals The current number of decimals
 * @param toDecimals The target number of decimals
 * @returns BigNumber with the target number of decimals
 */
export const convertBnToDecimals = (
  value: BigNumber,
  fromDecimals: number,
  toDecimals: number,
): BigNumber => {
  if (fromDecimals === toDecimals) {
    return value;
  }

  const difference = toDecimals - fromDecimals;

  if (difference > 0) {
    return value.mul(BigNumber.from(10).pow(difference));
  } else {
    return value.div(BigNumber.from(10).pow(-difference));
  }
};

/**
 * Converts a BigNumber to human number in string format.
 * @param value The BigNumber to humanize
 * @param decimals Number of decimals
 * @returns string
 */
export const humanizeBn = (value: BigNumber, decimals = 18) => {
  try {
    const result = trimDecimalZeroes(formatUnits(value, decimals));

    return result;
  } catch (error) {
    console.log('error in function humanizeBn: ', error);

    return '0';
  }
};

export const convertBigToFixed = (recepient: BigNumber, decimals?: BigNumberish): FixedNumber =>
  FixedNumber.fromValue(recepient, decimals ?? 18);

export const multiplyBignumbers = (
  bn1: [BigNumber, BigNumberish],
  bn2: [BigNumber, BigNumberish],
): BigNumber => {
  const fn = FixedNumber.fromValue(bn1[0], bn1[1]).mulUnsafe(FixedNumber.fromValue(bn2[0], bn2[1]));
  return parseEther(fn._value);
};

export const divideBignumbers = (
  bn1: [BigNumber, BigNumberish],
  bn2: [BigNumber, BigNumberish],
): BigNumber => {
  if (bn2[0].isZero()) return ZERO;

  const fn = FixedNumber.fromValue(bn1[0], bn1[1]).divUnsafe(FixedNumber.fromValue(bn2[0], bn2[1]));
  return parseEther(fn._value);
};

export function addBignumbers(
  bn1: [BigNumber, BigNumberish],
  bn2: [BigNumber, BigNumberish],
): BigNumber {
  const fn = convertBigToFixed(bn1[0], bn1[1]).addUnsafe(convertBigToFixed(bn2[0], bn2[1]));
  return parseEther(fn._value);
}

export function subtractBignumbers(
  bn1: [BigNumber, BigNumberish],
  bn2: [BigNumber, BigNumberish],
): BigNumber {
  const fn = convertBigToFixed(bn1[0], bn1[1]).subUnsafe(convertBigToFixed(bn2[0], bn2[1]));
  return parseEther(fn._value);
}
