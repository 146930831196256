import React from 'react';
import { capitalize } from 'lodash';

import { Modal, Spinner, Button, ToggleRadio, Switcher, CexLastPrice } from 'ui';
import { CreateTaskForm } from 'ui/forms';
import { InputField, SelectField } from 'fields';
import { ICexOrderbookGridBotTask, ICexOrderbookGridBotTaskExtended } from 'types/bots';
import { useConvertedCurrencies } from 'hooks/cex';

import { useTaskModal } from './useTaskModal';

import './style.scss';

interface IModalProps {
  onClose: () => void;
  onSubmit: (task: ICexOrderbookGridBotTask) => void;
  mode: 'create' | 'edit';
  task?: ICexOrderbookGridBotTaskExtended;
  id: number;
}

const CexOrderbookGridTaskModal: React.FC<IModalProps> = ({
  mode,
  onClose,
  onSubmit,
  task,
  id,
}) => {
  const {
    isEdited,
    loading,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    values,
    accounts,
    accountsLoading,
    availableCurrencies,
    selectedAskCurrency,
    selectedBidCurrency,
    setSelectedAskCurrency,
    setSelectedBidCurrency,
  } = useTaskModal({
    onSubmit,
    task,
    id,
  });

  const convertedAskMin = useConvertedCurrencies({
    amount: values.asks_min_amount,
    selectedCurrency: selectedAskCurrency,
  });
  const convertedAskMax = useConvertedCurrencies({
    amount: values.asks_max_amount,
    selectedCurrency: selectedAskCurrency,
  });
  const convertedBidMin = useConvertedCurrencies({
    amount: values.bids_min_amount,
    selectedCurrency: selectedBidCurrency,
  });
  const convertedBidMax = useConvertedCurrencies({
    amount: values.bids_max_amount,
    selectedCurrency: selectedBidCurrency,
  });

  return (
    <Modal
      title={`${capitalize(mode)} task`}
      edited={isEdited}
      className="mm-orderbook-grid-task-modal"
      onClose={onClose}
    >
      <CreateTaskForm>
        <div className="form-inner scrollable">
          <div className="form-container">
            <CexLastPrice />
            <div className="toggle-container">
              <ToggleRadio
                id="start_price_toggle_radio"
                titles={['Last price', 'Mid spread', 'Manual']}
                value={(() => {
                  if (values.startPriceMode === 'last_price') return 'Last price';
                  if (values.startPriceMode === 'manual') return 'Manual';
                  if (values.startPriceMode === 'mid_spread') return 'Mid spread';
                })()}
                onChange={e =>
                  setFieldValue(
                    'startPriceMode',
                    (() => {
                      if (e.target.value === 'Last price') return 'last_price';
                      if (e.target.value === 'Manual') return 'manual';
                      if (e.target.value === 'Mid spread') return 'mid_spread';
                    })(),
                  )
                }
              />
            </div>
            {values.startPriceMode === 'manual' && (
              <InputField
                label="Starting price"
                type="decimal-number"
                id="starting_price"
                decimals={18}
                value={values.starting_price}
                setValue={v => setFieldValue('starting_price', v ? v : '')}
                errorMessage={
                  errors.starting_price && touched.starting_price
                    ? errors.starting_price
                    : undefined
                }
              />
            )}
            <SelectField
              label="Account"
              placeholder="Select account"
              disabled={accountsLoading}
              selectedItem={values.account}
              id="account"
              items={accounts}
              itemToString={a => (a ? a.notes : '')}
              onSelectItem={a => setFieldValue('account', a ? a : null)}
              errorMessage={errors.account && touched.account ? errors.account : undefined}
            />
            <InputField
              label="Spread %"
              type="decimal-number"
              id="spread_percentage"
              value={values.spread_percentage}
              setValue={v => setFieldValue('spread_percentage', v ? v : '')}
              errorMessage={
                errors.spread_percentage && touched.spread_percentage
                  ? errors.spread_percentage
                  : undefined
              }
            />
            <InputField
              label="Ask count of orders"
              type="natural-number"
              id="ask_count_of_orders"
              value={values.asks_order_number}
              setValue={v => setFieldValue('asks_order_number', v ? v : '')}
              errorMessage={
                errors.asks_order_number && touched.asks_order_number
                  ? errors.asks_order_number
                  : undefined
              }
            />
            <div className="header-currencies">
              <div className="currency-title">Min/Max Ask amount in</div>
              <div className="toggle-container">
                <ToggleRadio
                  className="toggle"
                  labelProps={{ className: 'custom-label' }}
                  titles={availableCurrencies}
                  value={selectedAskCurrency}
                  onChange={e => {
                    setSelectedAskCurrency(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="inputs-row">
              <InputField
                label="Ask min amount"
                type="decimal-number"
                id="ask_min_amount"
                decimals={18}
                value={values.asks_min_amount}
                setValue={v => setFieldValue('asks_min_amount', v ? v : '')}
                errorMessage={
                  errors.asks_min_amount && touched.asks_min_amount
                    ? errors.asks_min_amount
                    : undefined
                }
                nodeRight={<span className="input-right-caption">{convertedAskMin}</span>}
              />
              <InputField
                label="Ask max amount"
                type="decimal-number"
                id="ask_max_amount"
                decimals={18}
                value={values.asks_max_amount}
                setValue={v => setFieldValue('asks_max_amount', v ? v : '')}
                errorMessage={
                  errors.asks_max_amount && touched.asks_max_amount
                    ? errors.asks_max_amount
                    : undefined
                }
                nodeRight={<span className="input-right-caption">{convertedAskMax}</span>}
              />
            </div>
            <InputField
              label="Bid count of orders"
              type="natural-number"
              id="bid_count_of_orders"
              value={values.bids_order_number}
              setValue={v => setFieldValue('bids_order_number', v ? v : '')}
              errorMessage={
                errors.bids_order_number && touched.bids_order_number
                  ? errors.bids_order_number
                  : undefined
              }
            />
            <div className="header-currencies">
              <div className="currency-title">Min/Max Bid amount in</div>
              <div className="toggle-container">
                <ToggleRadio
                  className="toggle"
                  labelProps={{ className: 'custom-label' }}
                  titles={availableCurrencies}
                  value={selectedBidCurrency}
                  onChange={e => {
                    setSelectedBidCurrency(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="inputs-row">
              <InputField
                label="Bid min amount"
                type="decimal-number"
                id="bid_min_amount"
                decimals={18}
                value={values.bids_min_amount}
                setValue={v => setFieldValue('bids_min_amount', v ? v : '')}
                errorMessage={
                  errors.bids_min_amount && touched.bids_min_amount
                    ? errors.bids_min_amount
                    : undefined
                }
                nodeRight={<span className="input-right-caption">{convertedBidMin}</span>}
              />
              <InputField
                label="Bid max amount"
                type="decimal-number"
                id="bid_max_amount"
                decimals={18}
                value={values.bids_max_amount}
                setValue={v => setFieldValue('bids_max_amount', v ? v : '')}
                errorMessage={
                  errors.bids_max_amount && touched.bids_max_amount
                    ? errors.bids_max_amount
                    : undefined
                }
                nodeRight={<span className="input-right-caption">{convertedBidMax}</span>}
              />
            </div>
            <InputField
              label="Distance between orders %"
              type="decimal-number"
              id="step_size"
              value={values.step_size}
              setValue={v => setFieldValue('step_size', v ? v : '')}
              errorMessage={errors.step_size && touched.step_size ? errors.step_size : undefined}
            />
            <div className="dynamic-mode">
              <span className="dynamic-mode-caption">Dynamic mode</span>
              <Switcher
                value={values.dynamic_mode_enabled}
                onChange={v => setFieldValue('dynamic_mode_enabled', v)}
                id="dynamic-mode"
              />
            </div>
            <div className="inputs-row">
              <InputField
                label="Counter orders limit"
                type="natural-number"
                id="counter_orders_limit"
                value={values.counter_orders_limit}
                setValue={v => setFieldValue('counter_orders_limit', v ? v : '')}
                errorMessage={
                  errors.counter_orders_limit && touched.counter_orders_limit
                    ? errors.counter_orders_limit
                    : undefined
                }
              />
              <InputField
                label="Price delta %"
                type="decimal-number"
                id="price_delta"
                value={values.price_delta}
                setValue={v => setFieldValue('price_delta', v ? v : '')}
                errorMessage={
                  errors.price_delta && touched.price_delta ? errors.price_delta : undefined
                }
              />
            </div>
          </div>
        </div>
        <div className="mm-orderbook-grid-bot-task-actions">
          {loading && (
            <div className="spinner-container">
              <Spinner size="small" />
            </div>
          )}
          {!loading && (
            <Button onClick={handleSubmit} type="button">
              {mode === 'create' ? 'Create task' : 'Save'}
            </Button>
          )}
        </div>
      </CreateTaskForm>
    </Modal>
  );
};

export { CexOrderbookGridTaskModal };
