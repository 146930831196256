import React, { useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import {
  useFloating,
  useClick,
  useDismiss,
  useInteractions,
  FloatingFocusManager,
  offset,
  size,
  FloatingPortal,
} from '@floating-ui/react';
import cn from 'classnames';

import { logout } from 'store/slices/auth';
import { setTheme } from 'store/slices/ui';
import { ETheme } from 'types/theme';
import { useTheme } from 'hooks/utility';

import { ExitIcon, MoreVerticalIcon, RefreshIcon } from 'assets/icons';
import './style.scss';

const MoreActionsMenu: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { isDark } = useTheme();
  const selectedTheme = useTypedSelector(store => store.ui.selectedTheme);

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const { refs, context, floatingStyles } = useFloating({
    placement: 'bottom-end',
    open: isOpened,
    onOpenChange: setIsOpened,
    middleware: [
      offset(10),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click]);

  return (
    <>
      <button
        className="mm-more-actions-menu-trigger"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <MoreVerticalIcon />
      </button>
      {isOpened && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              className={cn('mm-more-actions-menu-list', { _is_dark: isDark })}
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <button
                className="item"
                onClick={() => {
                  dispatch(setTheme(selectedTheme === ETheme.dark ? ETheme.light : ETheme.dark));
                  setIsOpened(false);
                }}
              >
                <RefreshIcon />
                <span>Switch theme</span>
              </button>
              <button className="item" onClick={() => dispatch(logout())}>
                <ExitIcon />
                <span>Logout</span>
              </button>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

export { MoreActionsMenu };
