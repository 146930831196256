import React, { useCallback } from 'react';
import cn from 'classnames';

import { IDexName, ECexName, dexConfigs, cexConfigs } from 'web3';
import { BaseSelectField } from '../base/BaseSelectField';

import './style.scss';

interface IExchange {
  label: string;
  exchange: IDexName | ECexName;
}

interface ISelectExchangeFieldProps {
  label?: string;
  placeholder?: string;
  items: IExchange[];
  selectedItem?: IExchange;
  onSelectItem: (v: IExchange | undefined) => void;
  disabled?: boolean;
  errorMessage?: string;
  comment?: string;
}

const SelectExchangeField: React.FC<ISelectExchangeFieldProps> = ({
  label,
  placeholder,
  items,
  selectedItem,
  onSelectItem,
  errorMessage,
  comment,
  disabled = false,
}) => {
  const renderItem = useCallback(
    ({ exchange, label }: IExchange) => {
      const isItemSelected = selectedItem && exchange === selectedItem.exchange;

      const config =
        exchange in ECexName ? cexConfigs[exchange as ECexName] : dexConfigs[exchange as IDexName];

      if (!config) return null;

      return (
        <div className={cn('mm-select-exchange-field-item', { _selected: isItemSelected })}>
          {config.icon && <img src={config.icon} className="exchange-image" />}
          <span>{label}</span>
        </div>
      );
    },
    [selectedItem],
  );

  return (
    <BaseSelectField
      label={label}
      placeholder={placeholder}
      items={items}
      disabled={disabled}
      selectedItem={selectedItem}
      onSelectItem={onSelectItem}
      renderItem={renderItem}
      renderSelectedItem={renderItem}
      errorMessage={errorMessage}
      comment={comment}
    />
  );
};

export { SelectExchangeField };
