export enum ENewCexBot {
  smart_liquidity = 'smart_liquidity',
  buy_sell = 'buy_sell',
  orderbook_grid = 'orderbook_grid',
}

export type INewCexBot = { label: string; value: string };

export const CEX_BOT_LIST: { label: string; value: ENewCexBot }[] = [
  { label: 'Smart liquidity', value: ENewCexBot.smart_liquidity },
  { label: 'Buy sell', value: ENewCexBot.buy_sell },
  { label: 'Orderbook grid', value: ENewCexBot.orderbook_grid },
];
