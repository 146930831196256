import React, { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { capitalize } from 'lodash';

import { Button, Modal, ButtonLoading, ToggleRadio } from 'ui';
import { ConnectNewWalletsToPairForm } from 'ui/forms';
import { ConnectWalletBalanceTaskTable } from 'tables';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { ApiBalanceBot } from 'api';
import { IBalanceBotWallet } from 'api/apiBalanceBot/models';
import { DexBalanceBotContext } from 'context/DexBalanceBotContext';
import { useDexBoostHoldersWalletsRecords } from 'hooks/tables/dex';
import { useFilters } from 'store/slices/filters/hooks';
import { EDexPairFilters } from 'types/filters';
import { bn } from 'tools/math';

import './style.scss';

interface IConnectWalletsToBoostHoldersModal {
  onClose: (selectedRecords?: IBalanceBotWallet[]) => void;
  walletType: 'source' | 'destination';
  initialConnected?: 'connected' | 'disconnected';
  disableToggler?: boolean;
  currentTaskValue?: any;
  readOnlyMode?: boolean;
  task: any;
  activeWalletsStep?: number;
  includeSteps?: boolean;
}

const ConnectWalletsToBoostHoldersModal: React.FC<IConnectWalletsToBoostHoldersModal> = ({
  onClose,
  walletType,
  readOnlyMode,
  initialConnected = 'connected',
  disableToggler = false,
  task,
  currentTaskValue,
  activeWalletsStep,
  includeSteps,
}) => {
  const taskId = useMemo(() => task.id, [task]);
  const taskType = useMemo(() => task.type, [task]);

  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const dispatch = useTypedDispatch();
  const [isConnected, setIsConnected] = useState<boolean>(initialConnected === 'connected');

  const { saveFilters, clearFilters } = useFilters({
    dex: {
      pairId: dexPair.id,
      type: EDexPairFilters.boostHoldersWallets,
    },
  });

  const mode = useMemo(() => (isConnected ? 'disconnect' : 'connect'), [isConnected]);

  const { records, tableRecords, selectedRecords, rowSelection, hasMore, loading, loadMore } =
    useDexBoostHoldersWalletsRecords({
      task_id: taskId,
      wallets: walletType,
      is_connected: isConnected,
      taskType: taskType,
    });

  useLayoutEffect(() => {
    clearFilters();
    saveFilters({ update: true });

    //eslint-disable-next-line
  }, [includeSteps]);

  const { handleLoadRecords } = useContext(DexBalanceBotContext);

  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const handleAppendAccounts = useCallback(async () => {
    try {
      if (!taskId) return null;

      setRequestLoading(true);

      const selectedWallets = selectedRecords.map(wallet => wallet.id);

      const updatedBody =
        walletType === 'source'
          ? {
              id: taskId,
              pair_id: dexPair.id,
              type: taskType,
              ...(taskType === 'add_base_holder' || taskType === 'add_quote_holder'
                ? {
                    [taskType === 'add_base_holder'
                      ? 'add_base_holder_options'
                      : 'add_quote_holder_options']: {
                      enabled: currentTaskValue.enabled,
                      buyback_enabled: currentTaskValue.buyback_enabled,
                      ...(currentTaskValue.buyback_enabled
                        ? {
                            buyback_options: {
                              interval: '10s',
                              min_pause: `${currentTaskValue.buyback_min_pause}ms`,
                              max_pause: `${currentTaskValue.buyback_max_pause}ms`,
                              min_swaps: +currentTaskValue.buyback_min_swaps,
                              max_swaps: +currentTaskValue.buyback_max_swaps,
                            },
                          }
                        : {}),
                      min_amount: bn(currentTaskValue.min_amount).toString(),
                      max_amount: bn(currentTaskValue.max_amount).toString(),
                      min_pause: `${currentTaskValue.min_pause}ms`,
                      max_pause: `${currentTaskValue.max_pause}ms`,
                      source_wallet_ids: [
                        ...(mode === 'connect'
                          ? (currentTaskValue.source_wallet_ids || []).concat(selectedWallets)
                          : (currentTaskValue.source_wallet_ids || []).filter(
                              (el: number) => !selectedWallets.find(wallet => wallet === el),
                            )),
                      ],
                    },
                  }
                : {
                    maintain_fee_balance_options: {
                      ...currentTaskValue,
                      interval: `${currentTaskValue.interval}ms`,
                      source_wallet_ids: [
                        ...(mode === 'connect'
                          ? (task.maintain_fee_balance_options.source_wallet_ids || []).concat(
                              selectedWallets,
                            )
                          : (task.maintain_fee_balance_options.source_wallet_ids || []).filter(
                              (el: number) => !selectedWallets.find(wallet => wallet === el),
                            )),
                      ],
                    },
                  }),
            }
          : undefined;

      const { isSuccess, errorMessage } = await (walletType === 'source'
        ? ApiBalanceBot.updateSourceWalletsTasksById({
            balance_bot_id: taskId,
            body: updatedBody,
          })
        : mode === 'connect'
        ? ApiBalanceBot.updateDestinationWalletsTasksById({
            balance_bot_id: taskId,
            body: {
              wallet_ids: selectedWallets,
            },
          })
        : ApiBalanceBot.deleteDestinationWalletsTasksById({
            balance_bot_id: taskId,
            body: {
              wallet_ids: selectedWallets,
            },
          }));

      if (isSuccess) {
        onClose();
        handleLoadRecords();
        dispatch(
          setAlertState({
            type: 'success',
            text: `You have successfully ${mode === 'connect' ? 'connected' : 'disconnected'} ${
              selectedRecords.length
            } wallets to pair`,
            onClose: () => {
              dispatch(dropAlertState());
            },
            onSubmit: () => {
              onClose();
              dispatch(dropAlertState());
            },
          }),
        );
      } else {
        onClose();

        dispatch(
          setAlertState({
            type: 'failed-img',
            text: String(errorMessage),
            onClose: () => {
              dispatch(dropAlertState());
            },
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestLoading(false);
    }
  }, [
    dexPair,
    selectedRecords,
    task,
    taskId,
    mode,
    taskType,
    currentTaskValue,
    walletType,
    dispatch,
    handleLoadRecords,
    onClose,
  ]);

  return (
    <Modal
      title={
        readOnlyMode
          ? `Wallets ${walletType === 'source' ? 'from' : 'to'}`
          : `Choose wallets ${walletType === 'source' ? 'from' : 'to'}`
      }
      className="mm-cex-account-modal"
      onClose={onClose}
    >
      <ConnectNewWalletsToPairForm>
        <div className="connect-new-wallets-to-pair-modal-content">
          {!readOnlyMode && (
            <ToggleRadio
              disabled={disableToggler}
              titles={['Connected', 'Not connected']}
              name="connected-toggle"
              value={isConnected ? 'Connected' : 'Not connected'}
              onChange={e => {
                if (e.target.value === 'Connected') {
                  setIsConnected(true);
                } else {
                  setIsConnected(false);
                }
              }}
            />
          )}
          <ConnectWalletBalanceTaskTable
            mode={mode}
            readOnlyMode={readOnlyMode}
            records={tableRecords}
            rowSelection={rowSelection}
            hasMore={hasMore}
            loading={loading}
            loadMore={loadMore}
            selectedRecords={selectedRecords}
          />
        </div>
        {includeSteps ? (
          <div style={{ display: 'flex', gap: '40px' }}>
            <Button onClick={() => onClose(selectedRecords)}>
              {activeWalletsStep === 2 ? capitalize(mode) : 'Next'}
            </Button>
          </div>
        ) : (
          !requestLoading &&
          !readOnlyMode && (
            <Button disabled={selectedRecords.length === 0} onClick={handleAppendAccounts}>
              {capitalize(mode)}
            </Button>
          )
        )}
        {requestLoading && <ButtonLoading />}
      </ConnectNewWalletsToPairForm>
    </Modal>
  );
};

export { ConnectWalletsToBoostHoldersModal };
