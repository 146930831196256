import { useEffect, useState, useCallback } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useAccount, useChainId, useReadContract } from 'wagmi';
import { getBalance } from '@wagmi/core';
import { wagmiConfig } from 'web3/wagmi';
import { useTypedSelector } from 'store';

import { ERC20 } from 'abi';
import { chains } from 'web3';

export function useERC20Balance(
  tokenAddress: string | undefined,
): [BigNumber | null, boolean, string | null, () => void] {
  const { address } = useAccount();
  const chainId = useChainId();
  const { data: erc20BalanceCall, error: erc20BalanceError } = useReadContract({
    address: tokenAddress as any,
    chainId: chainId,
    abi: ERC20,
    functionName: 'balanceOf',
    args: [address],
  });

  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const [balance, setBalance] = useState<BigNumber | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getNativeBalance = useCallback(async () => {
    if (!address || !chainId) throw new Error('Connect metamask to watch your balances in wallet');

    if (!Object.values(chains).includes(chainId))
      throw new Error('Change your network to appropriate');

    const { value } = await getBalance(wagmiConfig, { address });

    return BigNumber.from(value);
  }, [address, chainId]);

  const init = useCallback(async () => {
    try {
      if (typeof tokenAddress !== 'string' || tokenAddress.length !== 42) {
        setBalance(null);
        return;
      }

      setLoading(true);

      const baseToken = dexPair.token_base.address.toLowerCase();
      const quoteToken = dexPair.token_quote.address.toLowerCase();

      const isMainAsset = ![baseToken, quoteToken].includes(tokenAddress.toLowerCase());

      const balance = isMainAsset
        ? await getNativeBalance()
        : BigNumber.from(erc20BalanceCall ?? 0);

      setBalance(balance);
    } catch (e) {
      console.error(e);
      setError((e as string).toString());
      setBalance(null);
    } finally {
      setLoading(false);
    }
  }, [tokenAddress, getNativeBalance, erc20BalanceCall, dexPair]);

  useEffect(() => {
    init();
  }, [init]);

  return [balance, loading, error, init];
}
