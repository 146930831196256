import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { LocalStorage } from 'tools';
import { ECexPairFilters, EDexPairFilters, EGeneralFilters, IFilters } from 'types/filters';
import { FILTERS_KEY } from 'constant/cache';

interface IDexPairValue {
  pairId?: number;
  type: EDexPairFilters;
}

interface ICexPairValue {
  pairId?: number;
  type: ECexPairFilters;
}

interface IPayload {
  id: string;
  filter?: any;
  dex?: IDexPairValue;
  cex?: ICexPairValue;
  general?: EGeneralFilters;
}

const update = (filter: any, id: string, filters?: any[]) => {
  const newFilters = [...(filters ?? [])];

  const findFilterIdx = newFilters.findIndex(el => el.id === id);

  if (findFilterIdx === -1) return newFilters.concat(filter);

  newFilters[findFilterIdx] = filter;

  return newFilters;
};

export const updateFilterReducer: CaseReducer<IFilters, PayloadAction<IPayload>> = (
  state,
  { payload },
) => {
  const { id, filter, cex, dex, general } = payload;

  const updatedAt = new Date(Date.now()).toISOString();

  if (general) {
    state[general].filters = update(filter, id, state[general]?.filters);

    LocalStorage.set(FILTERS_KEY, state);
    return;
  }

  const key = cex ? 'cex' : 'dex';
  const type = cex ? cex.type : dex ? dex.type : undefined;
  const pairId = cex ? cex.pairId : dex ? dex.pairId : undefined;

  if (!pairId || !state[key][pairId] || !type) return;

  state[key][pairId]!.updated_at = updatedAt;

  //@ts-ignore
  state[key][pairId].filters[type].filters = update(
    filter,
    id,
    //@ts-ignore
    state[key][pairId].filters[type].filters,
  );

  LocalStorage.set(FILTERS_KEY, state);
};
