import { motion } from 'framer-motion';

import { EExchange } from 'web3';
import { IFilterSchemeExtended, IFilterValue } from 'types/filters/common';

import { FilterConnection } from '../FilterConnection';
import { FilterValue } from '../FilterValue';
import { FilterSelectType } from '../FilterSelectType';

import { TrashIcon } from 'assets/icons';

interface IFilterItemProps {
  filter: IFilterValue;
  filters: IFilterValue[];
  scheme: Record<string, IFilterSchemeExtended>;
  updateFilter: (id: string, filter?: IFilterValue) => void;
  deleteFilter: (id: string) => void;
  exchange?: EExchange | undefined;
}

function FilterItem({
  filter,
  filters,
  scheme,
  updateFilter,
  deleteFilter,
  exchange,
}: IFilterItemProps) {
  return (
    <div className="mm-filter-item_container">
      <div className="filter-fields">
        <FilterSelectType
          filters={filters}
          filter={filter}
          scheme={scheme}
          updateFilter={updateFilter}
        />
        <FilterConnection filter={filter} type={filter.type} updateFilter={updateFilter} />
        <FilterValue
          filter={filter}
          type={filter.type}
          updateFilter={updateFilter}
          exchange={exchange}
        />
      </div>
      <motion.button className="delete-filter" onClick={() => deleteFilter(filter.id)}>
        <TrashIcon />
      </motion.button>
    </div>
  );
}

export default FilterItem;
