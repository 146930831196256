import React from 'react';
import { useAccount } from 'wagmi';

import { Button } from 'ui';
import { useMetamaskAuth } from 'hooks/utility';

import './connectWalletButton.scss';

const ConnectWalletButton: React.FC = () => {
  const { address, isConnected } = useAccount();
  const { connect } = useMetamaskAuth();

  if (!address || !isConnected) {
    return (
      <div className="mm-connect-wallet-button">
        <Button onClick={connect} size="small">
          Connect wallet
        </Button>
      </div>
    );
  }

  return null;
};

export { ConnectWalletButton };
