import { useState, useCallback, useEffect, useMemo, useDeferredValue } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useReadContract } from 'wagmi';
import { useTypedSelector, useTypedDispatch } from 'store';

import { IForm } from './models';
import { UNISWAP_FEES } from 'constant/fees';
import { ROUTE_PATHS } from 'constant/routes';
import { ERC20Pair } from 'abi';
import { ApiCexPairs, ApiPairs } from 'api';
import { getCexs } from 'store/slices/dictionary';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { addNewPairToProject } from 'store/slices/projects';
import { EExchange, web3Configs } from 'web3';
import { IDex } from 'api/apiDictionary/models';
import { isAddress } from 'utils';

const useAddPairModal = ({
  projectId,
  onClose,
  onOpen,
  tradingType,
  dex,
}: {
  projectId: number;
  onClose: () => void;
  onOpen: () => void;
  tradingType: EExchange;
  dex?: IDex;
}) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const cexs = useTypedSelector(store => store.dictionary.cexs);
  const [edited, setEdited] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        notes: Yup.string().max(100, 'Notes field is too long'),
        fee: Yup.number(),
        cex: Yup.object({}).nullable(),
        baseToken: dex
          ? Yup.string().test(
              'baseToken',
              'Enter valid token address or fill pair address',
              (v: string | undefined) => isAddress(v),
            )
          : Yup.string().required('Enter token symbol'),
        quoteToken: dex
          ? Yup.string().test(
              'baseToken',
              'Enter valid token address or fill pair address',
              (v: string | undefined) => isAddress(v),
            )
          : Yup.string().required('Enter token symbol'),
        pairAddress: dex
          ? Yup.string().when(['baseToken', 'quoteToken'], {
              is: (baseToken: string, quoteToken: string) => baseToken === '' && quoteToken === '',
              then: schema =>
                schema.test('pairAddress', 'Enter valid token address', (v: string | undefined) =>
                  isAddress(v),
                ),
            })
          : Yup.string(),
      }),
    [dex],
  );

  const INITIAL_VALUES = useMemo<IForm>(
    () => ({
      notes: '',
      cex: tradingType === EExchange.cex && cexs && cexs.length !== 0 ? cexs[0] : null,
      fee: UNISWAP_FEES[0].value,
      pairAddress: '',
      baseToken: '',
      quoteToken: '',
    }),
    [tradingType, cexs],
  );

  const addPair = useCallback(
    async ({ cex, fee, notes, baseToken, quoteToken }: IForm) => {
      setLoading(true);

      const _cex = cex?.cex ?? 'gate';
      const _dex = dex ? dex.dex : 'uniswap_v2';

      const _fee =
        dex?.dex === 'uniswap_v3' ||
        dex?.dex === 'uniswap_v3:arbitrum_one' ||
        dex?.dex === 'uniswap_v3:polygon' ||
        dex?.dex === 'uniswap_v3:base' ||
        dex?.dex === 'pancakeswap_v3:bsc'
          ? fee
          : undefined;

      const { isSuccess, data, errorMessage } = await ApiPairs.addPair({
        id: projectId,
        dex: _dex,
        cex: _cex,
        tradingType,
        fee: _fee,
        notes,
        token_base: baseToken,
        token_quote: quoteToken,
        swap_gas_limit: 1000000,
      });

      if (!isSuccess) {
        onClose();
        setLoading(false);
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
              onOpen();
            },
          }),
        );
        return;
      }

      if (isSuccess) {
        const isDex = tradingType === EExchange.dex;

        const { isSuccess, data: pairData } = isDex
          ? await ApiPairs.getDexPairById(Number(data.id))
          : await ApiCexPairs.getCexPairById(Number(data.id));

        onClose();
        setLoading(false);

        if (isSuccess) {
          dispatch(
            addNewPairToProject({
              pair: pairData,
              type: tradingType,
            }),
          );
          dispatch(
            setAlertState({
              type: 'success-img',
              text: `Created new pair: ${pairData.symbol}`,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
          navigate(
            generatePath(isDex ? ROUTE_PATHS.dexPair : ROUTE_PATHS.cexPair, {
              projectId: projectId.toString(),
              pairId: pairData.id.toString(),
            }),
          );
        }
      }
    },
    [navigate, projectId, dispatch, onClose, onOpen, tradingType, dex],
  );

  const { values, touched, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: addPair,
    enableReinitialize: true,
  });

  const pairAddress = useDeferredValue(values.pairAddress);

  const {
    data: token0,
    isLoading: isLoadingToken0,
    error: errorToken0,
  } = useReadContract({
    abi: ERC20Pair,
    address: isAddress(pairAddress) ? (pairAddress as any) : undefined,
    chainId: dex ? web3Configs[dex.network].chainId : undefined,
    functionName: 'token0',
  });

  const {
    data: token1,
    isLoading: isLoadingToken1,
    error: errorToken1,
  } = useReadContract({
    abi: ERC20Pair,
    address: isAddress(pairAddress) ? (pairAddress as any) : undefined,
    chainId: dex ? web3Configs[dex.network].chainId : undefined,
    functionName: 'token1',
  });

  const setFormField = useCallback(
    (key: keyof IForm, value: any) => {
      if (loading) return;

      setEdited(true);
      setFieldValue(key, value);
    },
    [loading, setFieldValue],
  );

  useDebounce(
    () => {
      const token_base = token0 as string | undefined;
      const token_quote = token1 as string | undefined;

      if (token_base && token_base !== '') {
        setFormField('baseToken', token_base);
      }

      if (token_quote && token_quote !== '') {
        setFormField('quoteToken', token_quote);
      }
    },
    300,
    [token0, token1],
  );

  useEffect(() => {
    if (tradingType === EExchange.cex) {
      if (!cexs) {
        dispatch(getCexs());
      }
    }
  }, [tradingType, cexs, dispatch]);

  const pairParsingLoading = isLoadingToken0 || isLoadingToken1;
  const pairParsingError = errorToken0 ?? errorToken1 ?? undefined;

  return {
    cexs,
    values,
    errors,
    touched,
    setFormField,
    handleSubmit,
    loading,
    pairParsingLoading,
    pairParsingError,
    edited,
  };
};

export { useAddPairModal };
