import store from 'store';

import { IDexPair } from 'types/pairs';
import { TX_ACTIONS, TX_STATUSES } from 'constant/filters';
import { IFilterSchemeExtended, EFilterEntity, EFilterFormatter } from '../common';

export const dexPairTransactionsFiltersScheme = (
  pair: IDexPair,
): Record<string, IFilterSchemeExtended> => {
  const isAdmin = store.getState().auth.isAdmin;

  return {
    range: {
      type: EFilterEntity.date_range,
      label: 'Time range',
      name: 'range',
      field: 'range',
      single: true,
    },
    hashes: {
      type: EFilterEntity.multi_search,
      label: 'Tx hashes',
      name: 'hashes',
      field: 'hashes',
      single: true,
    },
    wallets: {
      type: EFilterEntity.multi_search,
      label: 'Wallets',
      name: 'wallets',
      field: 'wallets',
      single: true,
    },
    actions: {
      type: EFilterEntity.colors_multi_select,
      formatter: EFilterFormatter.colors_multi_select,
      label: 'Actions',
      name: 'actions',
      field: 'actions',
      listMultiColors: TX_ACTIONS,
      single: true,
    },
    statuses: {
      type: EFilterEntity.colors_multi_select,
      formatter: EFilterFormatter.colors_multi_select,
      label: 'Statuses',
      name: 'statuses',
      field: 'statuses',
      listMultiColors: TX_STATUSES,
      single: true,
    },
    amounts: {
      name: 'amounts',
      label: 'Amount',
      filters: [
        {
          label: pair.token_base.symbol,
          settedLabel: `Amount ${pair.token_base.symbol}`,
          name: 'baseAmount',
          type: EFilterEntity.number,
          field: 'amounts',
          tokenId: pair.token_base.id,
          decimals: pair.token_base.decimals,
          formatter: EFilterFormatter.bn_number_token,
        },
        {
          label: pair.token_quote.symbol,
          settedLabel: `Amount ${pair.token_quote.symbol}`,
          name: 'quoteAmount',
          type: EFilterEntity.number,
          field: 'amounts',
          tokenId: pair.token_quote.id,
          decimals: pair.token_quote.decimals,
          formatter: EFilterFormatter.bn_number_token,
        },
        {
          label: pair.token_fee.symbol,
          settedLabel: `Amount ${pair.token_fee.symbol}`,
          name: 'feeAmount',
          type: EFilterEntity.number,
          field: 'amounts',
          tokenId: pair.token_fee.id,
          decimals: pair.token_fee.decimals,
          formatter: EFilterFormatter.bn_number_token,
        },
      ],
    },
    ...(isAdmin
      ? {
          users: {
            type: EFilterEntity.user_multi_select,
            formatter: EFilterFormatter.user_multi_select,
            label: 'Users',
            name: 'users',
            field: 'user_ids',
            single: true,
          },
        }
      : {}),
  };
};
