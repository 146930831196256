import { useCallback } from 'react';
import { useConnect, useDisconnect } from 'wagmi';
import { useTypedDispatch } from 'store';

import { setAlertState, dropAlertState } from 'store/slices/ui';

interface IUseMetamaskAuthReturn {
  connect: () => Promise<void>;
  disconnect: () => void;
}

const useMetamaskAuth = (): IUseMetamaskAuthReturn => {
  const { connectAsync, connectors } = useConnect();
  const { disconnect: deactivate } = useDisconnect();

  const dispatch = useTypedDispatch();

  const connect = useCallback(async () => {
    localStorage.setItem('market-maker/web3-auth-method', 'metamask');

    try {
      const connector = connectors.find(el => el.type === 'injected');

      if (!connector) throw new Error('Connector not found');

      await connectAsync({
        connector,
      });
    } catch (error) {
      console.log('error: ', error);

      dispatch(
        setAlertState({
          type: 'failed',
          text: (error as unknown as string).toString(),
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => dispatch(dropAlertState()),
        }),
      );
    }
  }, [dispatch, connectAsync, connectors]);

  const disconnect = useCallback(() => {
    localStorage.removeItem('market-maker/web3-auth-method');
    deactivate();
  }, [deactivate]);

  return { connect, disconnect };
};

export { useMetamaskAuth };
