import React, { useContext } from 'react';
import { useTypedSelector } from 'store';
import { PairWalletsContext } from 'context/PairWalletsContext/PairWalletsContext';

const TokenQuoteHead: React.FC = React.memo(() => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const { totalBalances } = useContext(PairWalletsContext);

  return (
    <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
      <span>{pair?.token_quote.symbol}</span>
      <span>({totalBalances.quote})</span>
    </div>
  );
});

export default TokenQuoteHead;
