import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';

import { ApiBot } from 'api';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { ICexOrderbookGridBotTask, ICexOrderbookGridBotTaskExtended } from 'types/bots';
import { ICexAccount, IAccountBalances } from 'types/accounts';
import { bn, bnFrom, divideBignumbers, humanizeBn } from 'tools';

interface IOrderbookReturn {
  tasks: ICexOrderbookGridBotTaskExtended[];
  sortedTasks: ICexOrderbookGridBotTaskExtended[];
  extendedRow: number | undefined;
  setExtendedRow: (v: number | undefined) => void;
  loading: boolean;
  taskEnablingLoading: { get: number | undefined; set: (v: number | undefined) => void };
  taskModalOpened: ICexOrderbookGridBotTaskExtended | boolean;
  setTaskModalOpened: (v: ICexOrderbookGridBotTaskExtended | boolean) => void;
  enableTask: ({
    task,
    active,
  }: {
    task: ICexOrderbookGridBotTaskExtended;
    active: boolean;
  }) => Promise<void>;
  deleteTask: ({ taskId }: { taskId: number }) => Promise<void>;
  createUpdateTask: (task: ICexOrderbookGridBotTask) => Promise<void>;
  update: () => Promise<void>;
}

interface IAccountsWithBalance extends ICexAccount, Partial<IAccountBalances> {}

const extendedTaskToRaw = (task: ICexOrderbookGridBotTaskExtended) => {
  return {
    ...task,
    account: undefined,
    avaliableBase: undefined,
    avaliableQuote: undefined,
    neededQuote: undefined,
    neededBase: undefined,
    lackBase: undefined,
    lackQuote: undefined,
  };
};

const useOrderbook = ({
  accounts,
  updateBotStates,
}: {
  accounts: IAccountsWithBalance[];
  updateBotStates: () => void;
}): IOrderbookReturn => {
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);

  const [apiTasks, setApiTasks] = useState<ICexOrderbookGridBotTask[]>([]);
  const [extendedRow, setExtendedRow] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [taskEnablingLoading, setTaskEnablingLoading] = useState<number | undefined>(undefined);
  const [taskModalOpened, setTaskModalOpened] = useState<
    ICexOrderbookGridBotTaskExtended | boolean
  >(false);

  const enableTask = useCallback(
    async ({ task, active }: { task: ICexOrderbookGridBotTaskExtended; active: boolean }) => {
      try {
        setTaskEnablingLoading(task.id);

        const newTasks = [...apiTasks];
        const findIndex = newTasks.findIndex(el => el.id === task.id);

        if (findIndex >= 0) {
          newTasks[findIndex] = { ...extendedTaskToRaw(task), active };
        }

        const { isSuccess, errorMessage } = await ApiBot.updateOrderbookTask({
          task: { ...extendedTaskToRaw(task), active },
        });
        if (isSuccess) {
          setApiTasks(v => {
            const newTasks = [...v];
            const idx = newTasks.findIndex(el => el.id === task.id);

            if (newTasks[idx]) {
              newTasks[idx] = {
                ...newTasks[idx],
                active,
              };
            }
            updateBotStates();

            return newTasks;
          });
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        }
      } catch (error) {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      } finally {
        setTaskEnablingLoading(undefined);
      }
    },
    [dispatch, updateBotStates, apiTasks],
  );

  const deleteTask = useCallback(
    async ({ taskId }: { taskId: number }) => {
      if (!cexPair?.id) return;

      try {
        setExtendedRow(undefined);

        const { isSuccess, errorMessage } = await ApiBot.deleteOrderbookTask({
          pairId: cexPair.id,
          taskId,
        });

        if (isSuccess) {
          const newTasks = [...apiTasks].filter(el => el.id !== taskId);
          setApiTasks(newTasks);

          updateBotStates();
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        }
      } catch (error) {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    },
    [dispatch, updateBotStates, cexPair?.id, apiTasks],
  );

  const update = useCallback(async () => {
    if (!cexPair?.id) return;
    if (!isAdmin) return;

    try {
      setLoading(true);

      const { data, isSuccess, errorMessage } = await ApiBot.getOrderbookConfig({
        pairId: cexPair.id,
      });
      if (isSuccess) {
        setApiTasks(data ?? []);
      } else {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }, [cexPair?.id, isAdmin, dispatch]);

  const createUpdateTask = useCallback(
    async (_task: ICexOrderbookGridBotTask) => {
      const task = _task;

      try {
        const find = apiTasks.find(el => el.id === task.id);

        const mode = find ? 'edit' : 'create';

        const newTasks = [...apiTasks];
        let isSuccess = false;
        let errorMessage = undefined;

        if (mode === 'create') {
          const {
            isSuccess: _isSuccess,
            data,
            errorMessage: _errorMessage,
          } = await ApiBot.addOrderbookTask({
            task,
          });
          isSuccess = _isSuccess;
          errorMessage = _errorMessage;

          if (data) {
            newTasks.push({ ...task, id: data.id });
          }
        } else {
          const { isSuccess: _isSuccess, errorMessage: _errorMessage } =
            await ApiBot.updateOrderbookTask({
              task,
            });
          isSuccess = _isSuccess;
          errorMessage = _errorMessage;

          const findIndex = newTasks.findIndex(el => el.id === task.id);
          newTasks[findIndex] = task;
        }

        if (isSuccess) {
          setApiTasks(newTasks);
          setTaskModalOpened(false);
          dispatch(
            setAlertState({
              type: 'success',
              text:
                mode === 'create'
                  ? 'You have successfully created new task!'
                  : 'You have successfully edit task!',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );

          updateBotStates();
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        }
      } catch (error) {
        console.log('error: ', error);
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    },
    [dispatch, updateBotStates, apiTasks],
  );

  useEffect(() => {
    update();
  }, [update]);

  const tasks = useMemo(() => {
    return apiTasks.map(task => {
      const account = accounts.find(el => el.id === task.account_id);

      const { avaliableBase, avaliableQuote } = {
        avaliableBase: bn(
          Number(account?.base_balance ?? 0) - Number(account?.base_balance_locked ?? 0),
          18,
        ),
        avaliableQuote: bn(
          Number(account?.quote_balance ?? 0) - Number(account?.quote_balance_locked ?? 0),
          18,
        ),
      };

      const neededQuote = bn(
        Number(task.bid_grid_config.size_max) * task.bid_grid_config.order_number,
        18,
      );

      const price = cexPair
        ? Number(
            humanizeBn(
              divideBignumbers(
                [bnFrom(cexPair.token_base.price_usd), 6],
                [bnFrom(cexPair.token_quote.price_usd), 6],
              ),
              18,
            ),
          )
        : 0;

      const neededBase =
        price === 0
          ? bn('0', 18)
          : bn(
              (Number(task.ask_grid_config.size_max) * task.ask_grid_config.order_number) /
                (price +
                  price *
                    (Number(task.spread_percentage) / 100 +
                      task.ask_grid_config.order_number * (Number(task.step_size) / 100))),
              18,
            );

      const lackBase = avaliableBase.lt(neededBase);
      const lackQuote = avaliableQuote.lt(neededQuote);

      return {
        ...task,
        account,
        avaliableBase,
        avaliableQuote,
        neededQuote,
        neededBase,
        lackBase,
        lackQuote,
      };
    });
  }, [apiTasks, accounts, cexPair]);

  const sortedTasks = useMemo(() => tasks.sort((a, b) => b.id - a.id), [tasks]);

  return {
    tasks,
    sortedTasks,
    extendedRow,
    setExtendedRow,
    loading,
    taskEnablingLoading: { get: taskEnablingLoading, set: setTaskEnablingLoading },
    taskModalOpened,
    setTaskModalOpened,
    enableTask,
    deleteTask,
    createUpdateTask,
    update,
  };
};

export { useOrderbook };
