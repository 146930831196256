import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import * as Sentry from '@sentry/react';

import { App } from 'pages/App';

import './index.scss';

if (window.location.host !== 'localhost:3001') {
  Sentry.init({
    dsn: 'https://e93cab77646c21c79d0fd4f7a207a1d7@o4506705898242048.ingest.us.sentry.io/4507208141701120',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        createRoutesFromChildren,
        matchRoutes,
        useLocation,
        useNavigationType,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
