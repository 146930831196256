import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useListNavigation,
  FloatingFocusManager,
  offset,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react';
import { useTypedSelector } from 'store';

import { Checkbox } from 'ui';
import { ApiCexAccounts } from 'api';
import { ICexAccount } from 'types/accounts';

import { ArrowDropDown } from 'assets/icons';
import './style.scss';

interface ICexAccountsMultiSelectFieldProps {
  value: ICexAccount[];
  setValue: (v: ICexAccount[]) => void;
}

const CexAccountsMultiSelectField: React.FC<ICexAccountsMultiSelectFieldProps> = ({
  setValue,
  value,
}) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const palette = useTypedSelector(store => store.ui.selectedThemeColors);
  const [accounts, setAccounts] = useState<ICexAccount[]>([]);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const isEmpty = useMemo(() => value.length === 0, [value]);

  const setupAccounts = useCallback(async () => {
    try {
      const resultAccounts = await ApiCexAccounts.getAccounts({
        limit: 1000,
        pairId: cexPair.id,
      });

      setAccounts(resultAccounts.data?.items ?? []);
    } catch (e) {}
  }, [cexPair.id]);

  useEffect(() => {
    setupAccounts();
  }, [setupAccounts]);

  const { refs, context, floatingStyles } = useFloating({
    open: isOpened,
    onOpenChange: setIsOpened,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            width: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    listNav,
    click,
  ]);

  const handleSelect = useCallback(
    (item: ICexAccount) => {
      let newSelectedItems = [...value];

      const itemSelected = value.find(el => el.id === item.id);

      if (itemSelected) {
        newSelectedItems = newSelectedItems.filter(el => el.id !== item.id);
      } else {
        newSelectedItems.push(item);
      }

      setValue(newSelectedItems);
    },
    [setValue, value],
  );

  return (
    <div className="mm-filter-cex-accounts-multi-select-field">
      <div
        className="mm-filter-field-container"
        tabIndex={0}
        ref={refs.setReference}
        aria-labelledby="select-label"
        aria-autocomplete="none"
        {...getReferenceProps()}
      >
        {isEmpty && <span className="mm-filter-field-placeholder">Select accounts</span>}
        {!isEmpty && (
          <span className="mm-filter-field-value">{value.map(el => el.notes).join(', ')}</span>
        )}
        <motion.div animate={isOpened ? { rotate: 180 } : undefined}>
          <ArrowDropDown color={palette.black_1} />
        </motion.div>
      </div>
      {isOpened && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className="mm-filter-select-field__dropdown mm-filter-cex-accounts-multi-select-field-dropdown scrollable"
              {...getFloatingProps()}
            >
              {accounts.map((account, i) => {
                const isItemSelected = !!value.find(el => el.id === account.id);

                return (
                  <div
                    className="account-dropdown-item"
                    key={i}
                    ref={node => {
                      listRef.current[i] = node;
                    }}
                    role="option"
                    tabIndex={i === activeIndex ? 0 : -1}
                    style={{
                      background: i === activeIndex ? palette.light_gray_1 : '',
                    }}
                    {...getItemProps({
                      onClick() {
                        handleSelect(account);
                      },
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(account);
                        }

                        if (event.key === ' ') {
                          event.preventDefault();
                          handleSelect(account);
                        }
                      },
                    })}
                  >
                    <Checkbox checked={isItemSelected} onChange={() => handleSelect(account)} />
                    <span>{account.notes}</span>
                  </div>
                );
              })}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
};

export { CexAccountsMultiSelectField };
