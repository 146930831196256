import { useState, useMemo, useCallback, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';
import { useTypedDispatch, useTypedSelector } from 'store';

import { useScrollPagination } from 'hooks/utility';
import { useFilters } from 'store/slices/filters/hooks';
import { IBalanceBotWallet } from 'api/apiBalanceBot/models';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { bnFrom, Bus } from 'tools';
import { EExchange } from 'web3';
import { EDexPairFilters } from 'types/filters';
import { formatFiat, formatToken } from 'utils/formats';
import { IConnectWalletBalanceTaskRow } from 'types/tables/dex/connectWalletBalanceTaskRow';

import { useDexBoostHoldersWallets } from '../api';

interface IUseDexBoostHoldersWalletsRecords {
  wallets: 'source' | 'destination';
  task_id: number;
  is_connected: boolean;
  taskType: 'add_base_holder' | 'maintain_fee_balance';
}

const useDexBoostHoldersWalletsRecords = ({
  task_id,
  wallets,
  is_connected,
  taskType,
}: IUseDexBoostHoldersWalletsRecords) => {
  const dispatch = useTypedDispatch();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { settedPairFilters } = useFilters({
    dex: {
      pairId: dexPair.id,
      type: EDexPairFilters.boostHoldersWallets,
    },
  });

  const [virtualRecords, setVirtualRecords] = useState<IBalanceBotWallet[]>([]);

  const getWallets = useDexBoostHoldersWallets();

  const getRecords = useCallback(
    async ({
      limit,
      lastSeenId,
    }: {
      limit: number;
      lastSeenId: number;
    }): Promise<{ records: IBalanceBotWallet[]; has_more: boolean } | undefined> => {
      try {
        const { isSuccess, errorMessage, data } = await getWallets({
          lastSeenId,
          limit,
          filters: settedPairFilters,
          task_id,
          wallets,
          is_connected,
          exclude_source:
            taskType === 'add_base_holder' && wallets == 'destination' ? true : undefined,
        });

        if (isSuccess && data) {
          return {
            records: data.items,
            has_more: data.has_next,
          };
        } else {
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
          return undefined;
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    [dispatch, getWallets, settedPairFilters, task_id, wallets, is_connected, taskType],
  );

  const {
    hasMore,
    loading,
    loadMore,
    remount,
    records,
    setRecords,
    rowSelection,
    setRowSelection,
    selectedRecords,
  } = useScrollPagination({
    limit: 200,
    getRecords,
    remountLoad: true,
  });

  useUpdateEffect(() => {
    remount();
  }, [is_connected]);

  useEffect(() => {
    const onFilter = () => {
      remount();
    };

    Bus.on(`${EExchange.dex}:${EDexPairFilters.boostHoldersWallets}:FILTER`, onFilter);

    return () => {
      Bus.off(`${EExchange.dex}:${EDexPairFilters.boostHoldersWallets}:FILTER`, onFilter);
    };
  }, [remount]);

  const tableRecords = useMemo(
    () =>
      records?.map(record => {
        const addressCol = { address: record.address };

        const baseToken = record.tokens.find(item => item.token.id === dexPair.token_base.id)!;
        const tokenBaseCol = {
          value: formatToken(bnFrom(baseToken.balance), baseToken.token.decimals),
        };

        const quoteToken = record.tokens.find(item => item.token.id === dexPair.token_quote.id)!;
        const tokenQuoteCol = {
          value: formatToken(bnFrom(quoteToken?.balance), quoteToken.token.decimals),
        };

        const feeToken = record.tokens.find(item => item.token.id === dexPair.token_fee.id)!;
        const tokenFeeCol = {
          value: formatToken(bnFrom(feeToken.balance), feeToken.token.decimals),
        };

        const totalToken = record.tokens.find((item: any) => item.token.symbol === 'TOTAL');
        const totalCol = { value: formatFiat(bnFrom(totalToken?.balance_usd), 6) };

        return {
          addressCol,
          tokenBaseCol,
          tokenQuoteCol,
          tokenFeeCol,
          totalCol,
          id: record.id,
        } as IConnectWalletBalanceTaskRow;
      }) ?? [],
    [records, dexPair],
  );

  const result = useMemo(
    () => ({
      records: { get: records, set: setRecords },
      virtualRecords: { get: virtualRecords, set: setVirtualRecords },
      tableRecords,
      hasMore,
      loading,
      loadMore,
      rowSelection: { get: rowSelection, set: setRowSelection },
      selectedRecords,
    }),
    [
      records,
      setRecords,
      hasMore,
      loading,
      loadMore,
      selectedRecords,
      rowSelection,
      setRowSelection,
      virtualRecords,
      setVirtualRecords,
      tableRecords,
    ],
  );

  return result;
};

export { useDexBoostHoldersWalletsRecords };
