import { ICexChartsPoint } from 'api/apiCharts/models';
import { ICexChartPoint } from 'types/charts';

interface ICexBalancePointSerialized {
  time: number;
  balance_base: number;
  balance_base_usd: number;
  balance_quote: number;
  balance_quote_usd: number;
  balance_total_in_usd: number;
}

export interface IPairChartItem {
  date: number;
  close: number;
  high: number;
  low: number;
  open: number;
  volume: number;
}

export const serializeBalanceRecords = (records?: ICexChartsPoint[]) => {
  const serializer = (records: ICexChartsPoint[]) =>
    [...records].map(
      ({ balance: { base, base_usd, quote, quote_usd, total_in_usd }, timestamp }) => {
        const point = {
          time: Math.floor(timestamp / 1000),
          balance_base: Number(base),
          balance_base_usd: Number(base_usd),
          balance_quote: Number(quote),
          balance_quote_usd: Number(quote_usd),
          balance_total_in_usd: Number(total_in_usd),
        };

        return point;
      },
    );

  return serializer(records ?? []);
};

export const serializeInternalVolumesRecords = (records?: ICexChartsPoint[]) => {
  const serializer = (records: ICexChartsPoint[]) =>
    [...records].map(({ timestamp, kline: { our_volume } }) => {
      const point = {
        time: Math.floor(timestamp / 1000),
        value: Number(our_volume),
      };

      return point;
    });

  return serializer(records ?? []);
};

const sliceBalancesToArrays = (balancePoints: ICexBalancePointSerialized[]) => {
  const baseBalance: { time: number; balance: number; balance_usd: number }[] = [];
  const quoteBalance: { time: number; balance: number; balance_usd: number }[] = [];
  const totalUsdBalance: { time: number; value: number }[] = [];

  for (const balancePoint of balancePoints) {
    const {
      balance_base,
      balance_base_usd,
      balance_quote,
      balance_quote_usd,
      balance_total_in_usd,
      time,
    } = balancePoint;

    baseBalance.push({ time, balance: balance_base, balance_usd: balance_base_usd });
    quoteBalance.push({ time, balance: balance_quote, balance_usd: balance_quote_usd });
    totalUsdBalance.push({ time, value: balance_total_in_usd });
  }

  return {
    baseBalance,
    quoteBalance,
    totalUsdBalance,
  };
};

export const handleCharts = ({ points }: { points?: ICexChartsPoint[] }) => {
  try {
    const pointsClone = [...(points ?? [])];

    const balances = serializeBalanceRecords(pointsClone);
    const _internalVolumes = serializeInternalVolumesRecords(pointsClone);

    const pairData = pointsClone.map(el => ({
      date: Math.floor(el.timestamp / 1000),
      open: Number(el.kline.open),
      close: Number(el.kline.close),
      high: Number(el.kline.high),
      low: Number(el.kline.low),
      volume: Number(el.kline.volume),
    }));

    return {
      pairData,
      internalVolumes: _internalVolumes,
      ...sliceBalancesToArrays(balances),
    };
  } catch (error) {
    console.log('error: ', error);
  }
};

export const handleNewCharts = ({ points }: { points?: ICexChartsPoint[] }) => {
  try {
    return points?.map(point => ({
      time: Math.floor(point.timestamp / 1000),
      open: Number(point.kline.open),
      close: Number(point.kline.close),
      high: Number(point.kline.high),
      low: Number(point.kline.low),
      volume: Number(point.kline.volume),
      baseBalance: Number(point.balance.base),
      baseBalanceUsd: Number(point.balance.base_usd),
      quoteBalane: Number(point.balance.quote),
      quoteBalanceUsd: Number(point.balance.quote_usd),
      balanceTotalUsd: Number(point.balance.total_in_usd),
      internalVolume: Number(point.kline.our_volume),
    }));
  } catch (error) {
    console.log('error: ', error);
  }
};

export const mergeCandles = (
  oldCandles: ICexChartPoint[],
  newCandles: ICexChartPoint[],
): ICexChartPoint[] => {
  const merged: ICexChartPoint[] = [];
  let i = 0;
  let j = 0;

  while (i < oldCandles.length && j < newCandles.length) {
    if (oldCandles[i].time < newCandles[j].time) {
      merged.push(oldCandles[i]);
      i++;
    } else if (oldCandles[i].time > newCandles[j].time) {
      merged.push(newCandles[j]);
      j++;
    } else {
      merged.push(newCandles[j]);
      i++;
      j++;
    }
  }

  while (i < oldCandles.length) {
    merged.push(oldCandles[i]);
    i++;
  }

  while (j < newCandles.length) {
    merged.push(newCandles[j]);
    j++;
  }

  return merged;
};
