import React, { useState, useCallback } from 'react';
import { useTypedSelector } from 'store';

import { AutoRefresh, Panel } from 'ui';
import { IPanelProps } from 'types/pairs';
import { CEX_ORDERS_RELOAD } from 'constant/reload';

import { DocumentIcon } from 'assets/icons';

const CexPairOrdersTable = React.lazy(() =>
  import('tables').then(module => ({ default: module.CexPairOrdersTable })),
);

const OrderHistory: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(initialOpened ?? false);

  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);
    },
    [setIsOpened],
  );

  return (
    <Panel
      icon={DocumentIcon}
      title="Order history"
      className="_table-container"
      initialOpened={initialOpened}
      onChange={handleSwitch}
      headerNode={
        isPanelOpened && (
          <div className="w-full flex flex-row items-center justify-end">
            <AutoRefresh
              busEvent={CEX_ORDERS_RELOAD}
              tooltipText="Auto refresh"
              pairId={cexPair.id}
              cacheName="cex-order-history"
            />
          </div>
        )
      }
    >
      <CexPairOrdersTable />
    </Panel>
  );
};

export { OrderHistory };
