import React from 'react';

import './errorText.scss';

type IErrorTextProps = {
  children?: string;
  viewPlacehodler?: boolean;
  renderText?: (text: string) => React.ReactNode;
};

const ErrorText: React.FC<IErrorTextProps> = ({
  children,
  renderText,
  viewPlacehodler = false,
}) => {
  if (children) {
    return <span className="mm-error-text">{renderText ? renderText(children) : children}</span>;
  }

  if (!children && viewPlacehodler)
    return <span className="mm-error-text__placeholder">{renderText ? renderText('-') : '-'}</span>;

  return null;
};

export { ErrorText };
