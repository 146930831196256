import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiStatistic } from 'api';

import { IDexPair, ICexPair } from 'types/pairs';
import { normalizeNumber } from 'utils/formats';

interface IPairsSliceState {
  selectedDexPair: IDexPair | null;
  selectedCexPair: ICexPair | null;
  pairError: boolean;
  pairLoading: boolean;
  updatePairLoading: boolean;
}

const pairsSliceInitialState: IPairsSliceState = {
  selectedDexPair: null,
  selectedCexPair: null,
  pairError: false,
  pairLoading: false,
  updatePairLoading: false,
};

const updateCexPairPrice = createAsyncThunk(
  'pairs/update-cex-price',
  async ({ pair }: { pair: ICexPair }, thunkApi) => {
    try {
      thunkApi.dispatch(setUpdatePairLoading(true));

      const { data: tickerData } = await ApiStatistic.getCexTickerInfo({
        cex: pair.cex,
        symbol: `${pair.token_base.symbol}-${pair.token_quote.symbol}`,
      });

      const priceFormatted = tickerData
        ? normalizeNumber(Number(tickerData.last_price) * 10 ** 6).split('.')[0]
        : '0';

      const price = tickerData ? priceFormatted : pair.token_base.price_usd;

      thunkApi.dispatch(
        setSelectedCexPair({
          ...pair,
          token_base: { ...pair.token_base, price_usd: price },
          token_quote: { ...pair.token_quote, price_usd: (10 ** 6).toString() },
        }),
      );
    } catch (error) {
      console.log(error);
    } finally {
      thunkApi.dispatch(setUpdatePairLoading(false));
    }
  },
);

const pairsSlice = createSlice({
  name: 'pairs',
  initialState: pairsSliceInitialState as IPairsSliceState,
  reducers: {
    setSelectedDexPair: (state, { payload }: { payload: IDexPair | null }) => {
      state.selectedDexPair = payload;
      state.selectedCexPair = null;
      state.pairError = false;
    },
    setSelectedCexPair: (state, { payload }: { payload: ICexPair | null }) => {
      state.selectedCexPair = payload;
      state.selectedDexPair = null;
      state.pairError = false;
    },
    setPairError: (state, { payload }: { payload: boolean }) => {
      state.pairError = payload;
    },
    setPairLoading: (state, { payload }: { payload: boolean }) => {
      state.pairLoading = payload;
    },
    setUpdatePairLoading: (state, { payload }: { payload: boolean }) => {
      state.updatePairLoading = payload;
    },
  },
});

export { updateCexPairPrice };
export const {
  setSelectedDexPair,
  setSelectedCexPair,
  setPairError,
  setPairLoading,
  setUpdatePairLoading,
} = pairsSlice.actions;

export default pairsSlice.reducer;
