import { isNil } from 'lodash';

import { IDexBalanceBotTask } from 'types/bots';
import { IRange } from 'web3';
import { AApiBase, IBaseResponse } from '../apiBase';
import { mapError } from '../apiBase/mapError';
import { mapResponse } from '../apiBase/mapResponse';
import { IGetBalanceBotResponse, IGetHoldersStatisticResponse, IBalanceBotWallet } from './models';

class ApiBalanceBot extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiBalanceBot | undefined;
  private static instance = (() => {
    if (ApiBalanceBot.__instance === undefined) {
      ApiBalanceBot.__instance = new ApiBalanceBot();
    }
    return ApiBalanceBot.__instance;
  })();

  public static getHoldersStatistic = async ({
    pairId,
    ranges,
  }: {
    pairId: number;
    ranges: (IRange | string)[];
  }) => {
    try {
      const urlParams = new URLSearchParams();
      for (const range of ranges) {
        urlParams.append('range', range.toString());
      }

      const response = await ApiBalanceBot.instance.get<
        IBaseResponse<IGetHoldersStatisticResponse>
      >(`/pairs/${pairId}/holders-statistics` + '?' + urlParams.toString());

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IGetHoldersStatisticResponse>({ error });
    }
  };

  //////// V2 Balance Bot requests
  public static getPairBalanceBotTasks = async ({ pairs_ids }: { pairs_ids: number }) => {
    //TODO remove hardcoded params
    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pairs_ids', `${pairs_ids}`);
      urlParams.append('limit', '1000');

      const response = await ApiBalanceBot.instance.get<IBaseResponse<IGetBalanceBotResponse>>(
        `/balancebot-tasks?` +
          urlParams.toString() +
          `&sort_by=type%3Adesc&sort_by=is_active%3Adesc&sort_by=created_at%3Adesc&`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static getPairBalanceBotTasksById = async ({ task_id }: { task_id: number }) => {
    try {
      const response = await ApiBalanceBot.instance.get<IBaseResponse<IDexBalanceBotTask>>(
        `/balancebot-tasks/${task_id}`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IDexBalanceBotTask>({ error });
    }
  };

  public static getEstimateFeeById = async ({ pairs_id }: { pairs_id: number }) => {
    try {
      const response = await ApiBalanceBot.instance.get<IBaseResponse<any>>(
        `/pairs/${pairs_id}/estimate-fee`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static addPairBalanceBotTasks = async ({ body }: { body: any }) => {
    try {
      const response = await ApiBalanceBot.instance.post<IBaseResponse<{ id: number }>>(
        `/balancebot-tasks`,
        body,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{ id: number }>({ error });
    }
  };

  public static updatePairBalanceBotTasks = async ({
    taskId,
    body,
  }: {
    taskId: number;
    body: any;
  }) => {
    try {
      const response = await ApiBalanceBot.instance.patch<IBaseResponse<any>>(
        `/balancebot-tasks/${taskId}`,
        body,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static getSourceBalanceBotTasksById = async ({
    balance_bot_id,
    limit,
    lastSeenId,
    not_connected,
    balance,
    balance_usd,
    volume,
    volume_usd,
    transactions_count,
  }: {
    balance_bot_id: number;
    limit?: number;
    lastSeenId?: number;
    not_connected: string;
    balance?: string[] | undefined;
    balance_usd?: string[] | undefined;
    volume?: string[] | undefined;
    volume_usd?: string[] | undefined;
    transactions_count?: string[] | undefined;
  }) => {
    try {
      const urlParams = new URLSearchParams();

      limit && urlParams.append('limit', limit.toString());
      lastSeenId && urlParams.append('last_seen_id', lastSeenId.toString());
      not_connected && urlParams.append('not_connected', not_connected.toString());

      if (balance) {
        for (const _balance of balance) {
          urlParams.append('balance', _balance);
        }
      }
      if (balance_usd) {
        for (const _balance of balance_usd) {
          urlParams.append('balance_usd', _balance);
        }
      }

      if (volume) {
        for (const _volume of volume) {
          urlParams.append('volume', _volume);
        }
      }
      if (volume_usd) {
        for (const _volume of volume_usd) {
          urlParams.append('volume_usd', _volume);
        }
      }

      if (transactions_count) {
        for (const _tx_count of transactions_count) {
          urlParams.append('transactions_count', _tx_count);
        }
      }

      const response = await ApiBalanceBot.instance.get<
        IBaseResponse<{ has_next: boolean; items: IBalanceBotWallet[] }>
      >(`/balancebot-tasks/${balance_bot_id}/source-wallets` + '?' + urlParams.toString());

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{ has_next: boolean; items: IBalanceBotWallet[] }>({
        error,
      });
    }
  };

  public static getDestinationWalletsTasksById = async ({
    balance_bot_id,
    limit,
    lastSeenId,
    not_connected,
    balance,
    balance_usd,
    volume,
    volume_usd,
    transactions_count,
    exclude_source,
  }: {
    balance_bot_id: number;
    limit?: number;
    lastSeenId?: number;
    not_connected: string;
    balance?: string[] | undefined;
    balance_usd?: string[] | undefined;
    volume?: string[] | undefined;
    volume_usd?: string[] | undefined;
    transactions_count?: string[] | undefined;
    exclude_source?: boolean | undefined;
  }) => {
    try {
      const urlParams = new URLSearchParams();

      limit && urlParams.append('limit', limit.toString());
      lastSeenId && urlParams.append('last_seen_id', lastSeenId.toString());
      not_connected && urlParams.append('not_connected', not_connected.toString());

      if (balance) {
        for (const _balance of balance) {
          urlParams.append('balance', _balance);
        }
      }
      if (balance_usd) {
        for (const _balance of balance_usd) {
          urlParams.append('balance_usd', _balance);
        }
      }

      if (volume) {
        for (const _volume of volume) {
          urlParams.append('volume', _volume);
        }
      }
      if (volume_usd) {
        for (const _volume of volume_usd) {
          urlParams.append('volume_usd', _volume);
        }
      }

      if (transactions_count) {
        for (const _tx_count of transactions_count) {
          urlParams.append('transactions_count', _tx_count);
        }
      }

      !isNil(exclude_source) && urlParams.append('exclude_source', exclude_source.toString());

      const response = await ApiBalanceBot.instance.get<
        IBaseResponse<{ has_next: boolean; items: IBalanceBotWallet[] }>
      >(`/balancebot-tasks/${balance_bot_id}/destination-wallets` + '?' + urlParams.toString());

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{ has_next: boolean; items: IBalanceBotWallet[] }>({
        error,
      });
    }
  };
  public static updateSourceWalletsTasksById = async ({
    balance_bot_id,
    body,
  }: {
    balance_bot_id: number;
    body: any;
  }) => {
    try {
      const response = await ApiBalanceBot.instance.patch<IBaseResponse<any>>(
        `/balancebot-tasks/${balance_bot_id}`,
        body,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static updateDestinationWalletsTasksById = async ({
    balance_bot_id,
    body,
  }: {
    balance_bot_id: number;
    body: any;
  }) => {
    try {
      const response = await ApiBalanceBot.instance.put<
        IBaseResponse<{ has_next: boolean; items: IBalanceBotWallet[] }>
      >(`/balancebot-tasks/${balance_bot_id}/destination-wallets`, body);

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{ has_next: boolean; items: IBalanceBotWallet[] }>({
        error,
      });
    }
  };

  public static deleteDestinationWalletsTasksById = async ({
    balance_bot_id,
    body,
  }: {
    balance_bot_id: number;
    body: {
      wallet_ids: number[];
    };
  }) => {
    try {
      const response = await ApiBalanceBot.instance.delete<IBaseResponse<any>>(
        `/balancebot-tasks/${balance_bot_id}/destination-wallets`,
        { data: body },
      );

      return mapResponse<any>({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({
        error,
      });
    }
  };
}

export { ApiBalanceBot };
