import { useCallback, useMemo } from 'react';
import { useTypedSelector, RootStore } from 'store';

import { ECexPairFilters, EDexPairFilters, EGeneralFilters } from 'types/filters';

interface IProps {
  general?: EGeneralFilters;
  cex?: {
    pairId: number | undefined;
    type: ECexPairFilters;
  };
  dex?: {
    pairId: number | undefined;
    type: EDexPairFilters;
  };
}

export function useIsFiltersSet({ general, cex, dex }: IProps) {
  const filtersSelector = useCallback(
    (store: RootStore) => {
      if (general) {
        return store.filters[general]?.setted ?? [];
      }

      if (cex) {
        return store.filters.cex[cex.pairId ?? 0]?.filters?.[cex.type]?.setted ?? [];
      }

      if (dex) {
        return store.filters.dex[dex.pairId ?? 0]?.filters?.[dex.type]?.setted ?? [];
      }
    },
    [general, cex, dex],
  );

  const filters = useTypedSelector(filtersSelector);

  const isSetted = useMemo(() => {
    if (!filters) return false;

    for (const field of filters) {
      if (field.value !== undefined) return true;
    }

    return false;
  }, [filters]);

  return isSetted;
}
