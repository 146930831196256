import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { mapError } from '../apiBase/mapError';
import { mapResponse } from '../apiBase/mapResponse';
import { ILoginArgs, ILoginResponse } from './models';

class ApiAuth extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = false;

  private static __instance: ApiAuth | undefined;
  private static instance = (() => {
    if (ApiAuth.__instance === undefined) {
      ApiAuth.__instance = new ApiAuth();
    }
    return ApiAuth.__instance;
  })();

  public static login = async ({ login, password }: ILoginArgs) => {
    try {
      const response = await ApiAuth.instance.post<IBaseResponse<ILoginResponse>>(
        '/auth/sessions',
        {
          login,
          password,
        },
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<ILoginResponse>({ error });
    }
  };

  public static logout = async (): Promise<Response<any>> => {
    try {
      const response = await ApiAuth.instance.delete<IBaseResponse<any>>('/auth/sessions', {
        manualWithHeader: true,
      });

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<any>({ error });
    }
  };
}

export { ApiAuth };
