import { useCallback, useMemo } from 'react';
import cn from 'classnames';

import { BaseMultiSelectField } from '../base/BaseMultiSelectField';

import './style.scss';

interface IMultiSelectFieldProps<V extends { id: string | number }> {
  items: V[];
  itemToString: (item: V) => string;
  selectedItems: V[];
  onSelectItems: (newItems: V[]) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  comment?: string;
}

function MultiSelectField<V extends { id: string | number }>({
  items,
  itemToString,
  selectedItems,
  onSelectItems,
  label,
  placeholder,
  disabled,
  errorMessage,
  comment,
}: IMultiSelectFieldProps<V>) {
  const itemRenderer = useCallback(
    (item: V) => {
      const itemString = itemToString(item);

      const isItemSelected = !!selectedItems.find(el => itemToString(el) === itemString);

      return (
        <div className={cn('mm-multi-select-field__option', { _selected: isItemSelected })}>
          {itemString}
        </div>
      );
    },
    [itemToString, selectedItems],
  );

  const selectedItemsText = useMemo(
    () => selectedItems.map(el => itemToString(el)).join(', '),
    [selectedItems, itemToString],
  );

  return (
    <BaseMultiSelectField
      className="mm-multi-select-field"
      items={items}
      onSelectItems={onSelectItems}
      renderItem={itemRenderer}
      selectedItems={selectedItems}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      errorMessage={errorMessage}
      comment={comment}
      selectedItemsText={selectedItemsText}
    />
  );
}

export { MultiSelectField };
