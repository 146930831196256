import React, { useCallback, useContext } from 'react';
import { motion } from 'framer-motion';
import { isBoolean } from 'lodash';
import { useTypedDispatch } from 'store';

import { Button } from 'ui';
import { ICexOrderbookGridBotTaskExtended } from 'types/bots';
import { CexPairContext } from 'context/CexPairContext';
import { CexOrderbookGridTaskModal } from 'modals';
import { setAlertState, dropAlertState } from 'store/slices/ui';

import './style.scss';

interface IExtendedRowProps {
  task: ICexOrderbookGridBotTaskExtended;
  rowIdx: number;
  deleteTask: ({ taskId }: { taskId: number }) => Promise<void>;
  getTasks: () => Promise<void>;
}

const ExtendedRow: React.FC<IExtendedRowProps> = ({ task, rowIdx, deleteTask }) => {
  const dispatch = useTypedDispatch();
  const {
    orderbook: { createUpdateTask, taskModalOpened, setTaskModalOpened },
  } = useContext(CexPairContext);

  const handleDeleteTask = useCallback(() => {
    dispatch(
      setAlertState({
        type: 'sure',
        text: 'Are you sure that you want to delete this task?',
        onClose: () => dispatch(dropAlertState()),
        onSubmit: () => {
          dispatch(dropAlertState());
          deleteTask({ taskId: task.id });
        },
      }),
    );
  }, [dispatch, deleteTask, task]);

  return (
    <>
      <motion.div
        key={`extended-row-${rowIdx}`}
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        className="mm-orderbook-grid-bot-extended-row"
      >
        <div className="line-separator" />
        <span className="section-title">Settings</span>
        <div className="flex flex-col gap-1">
          <span>
            {'Dynamic mode: '}
            <span className="bold">{task.dynamic_mode.enabled ? 'Enabled' : 'Disabled'}</span>
          </span>
          {task.dynamic_mode.enabled && (
            <>
              <span>
                Counter orders limit:{' '}
                <span className="bold">{task.dynamic_mode.counter_orders_limit}</span>
              </span>
              {'\n'}
              <span>
                Price delta: <span className="bold">{task.dynamic_mode.price_delta}%</span>
              </span>
            </>
          )}
        </div>
        <div className="line-separator" />
        <div className="extended-row__footer">
          <Button
            color="secondary"
            className="edit-task-button"
            onClick={() => setTaskModalOpened(task)}
          >
            Edit task
          </Button>
          <Button color="error-bordered" className="delete-task-button" onClick={handleDeleteTask}>
            Delete task
          </Button>
        </div>
      </motion.div>
      {!isBoolean(taskModalOpened) && (
        <CexOrderbookGridTaskModal
          mode="edit"
          onClose={() => setTaskModalOpened(false)}
          onSubmit={createUpdateTask}
          task={task}
          id={task.id}
        />
      )}
    </>
  );
};

export { ExtendedRow };
