export const PROJECT_STATISTIC_RELOAD = 'reload/project-statistic';
export const PAIR_STATISTIC_RELOAD = 'reload/pair-statistic';
export const PAIR_WALLETS_RELOAD = 'reload/pair-wallets';
export const PAIR_TRANSACTIONS_RELOAD = 'reload/pair-transactions';
export const PAIR_BALANCE_BOT_RELOAD = 'reload/pair-balance-bot';
export const PAIR_BUYSELL_BOT_RELOAD = 'reload/pair-buy-sell-bot';
export const PAIR_CEX_BUYSELL_BOT_RELOAD = 'reload/cex-pair-buysell-bot';
export const PAIR_CEX_ORDER_MANAGER_RELOAD = 'reload/cex-pair-order-manager';

export const CEX_ORDERS_RELOAD = 'reload/cex-pair-orders';
export const CEX_NEW_ORDERS_RELOAD = 'reload/cex-pair-new-orders';

export const PROJECT_WALLETS_RELOAD = 'reload/project-wallets';
export const PROJECT_ACCOUNT_RELOAD = 'reload/project-accounts';

export const STOP_RELOAD_PREFIX = 'stop-reload/';

export const RELOAD_WITHDRAWAL_WHTELIST = 'withdrawal-whitelist-reload';
