import { IDexPair } from 'types/pairs';

import { TOTAL_TOKEN_ID } from 'constant/numbers';
import { IFilterSchemeExtended, EFilterEntity, EFilterFormatter } from '../common';
import { CONNECTED_BOT } from 'constant/filters';

export const dexPairWalletsFiltersScheme = (
  pair: IDexPair,
): Record<string, IFilterSchemeExtended> => ({
  balance: {
    name: 'balance',
    label: 'Balance',
    filters: [
      {
        label: pair.token_base.symbol,
        settedLabel: `Balance ${pair.token_base.symbol}`,
        name: 'baseBalance',
        type: EFilterEntity.number,
        field: 'balance',
        tokenId: pair.token_base.id,
        decimals: pair.token_base.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: pair.token_quote.symbol,
        settedLabel: `Balance ${pair.token_quote.symbol}`,
        name: 'quoteBalance',
        type: EFilterEntity.number,
        field: 'balance',
        tokenId: pair.token_quote.id,
        decimals: pair.token_quote.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: pair.token_fee.symbol,
        settedLabel: `Balance ${pair.token_fee.symbol}`,
        name: 'feeBalance',
        type: EFilterEntity.number,
        field: 'balance',
        tokenId: pair.token_fee.id,
        decimals: pair.token_fee.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: 'Total USD',
        settedLabel: 'Total USD balance',
        name: 'allBalance',
        type: EFilterEntity.number,
        field: 'balance',
        tokenId: TOTAL_TOKEN_ID,
        decimals: 6,
        formatter: EFilterFormatter.bn_number_token,
      },
    ],
  },
  volume: {
    name: 'volume',
    label: 'Volume',
    filters: [
      {
        label: pair.token_base.symbol,
        settedLabel: `Volume ${pair.token_base.symbol}`,
        name: 'baseVolume',
        type: EFilterEntity.number,
        field: 'volume',
        tokenId: pair.token_base.id,
        decimals: pair.token_base.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: pair.token_quote.symbol,
        settedLabel: `Volume ${pair.token_quote.symbol}`,
        name: 'quoteVolume',
        type: EFilterEntity.number,
        field: 'volume',
        tokenId: pair.token_quote.id,
        decimals: pair.token_quote.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: pair.token_fee.symbol,
        settedLabel: `Volume ${pair.token_fee.symbol}`,
        name: 'feeVolume',
        type: EFilterEntity.number,
        field: 'volume',
        tokenId: pair.token_fee.id,
        decimals: pair.token_fee.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: 'Total USD',
        settedLabel: 'Total USD volume',
        name: 'allVolume',
        type: EFilterEntity.number,
        field: 'volume',
        tokenId: TOTAL_TOKEN_ID,
        decimals: 6,
        formatter: EFilterFormatter.bn_number_token,
      },
    ],
  },
  transactions: {
    name: 'transactions',
    label: 'Transactions',
    filters: [
      {
        label: pair.token_base.symbol,
        settedLabel: `Tx ${pair.token_base.symbol}`,
        name: 'baseTransactions',
        type: EFilterEntity.natural_number,
        field: 'transactions_count',
        tokenId: pair.token_base.id,
        formatter: EFilterFormatter.natural_number_token,
      },
      {
        label: pair.token_quote.symbol,
        settedLabel: `Tx ${pair.token_quote.symbol}`,
        name: 'quoteTransactions',
        type: EFilterEntity.natural_number,
        field: 'transactions_count',
        tokenId: pair.token_quote.id,
        formatter: EFilterFormatter.natural_number_token,
      },
      {
        label: pair.token_fee.symbol,
        settedLabel: `Tx ${pair.token_fee.symbol}`,
        name: 'feeTransactions',
        type: EFilterEntity.natural_number,
        field: 'transactions_count',
        tokenId: pair.token_fee.id,
        formatter: EFilterFormatter.natural_number_token,
      },
      {
        label: 'All',
        settedLabel: 'All txs',
        name: 'allTransactions',
        type: EFilterEntity.natural_number,
        field: 'transactions_count',
        tokenId: TOTAL_TOKEN_ID,
        formatter: EFilterFormatter.natural_number_token,
      },
    ],
  },
  availability: {
    name: 'availability',
    type: EFilterEntity.boolean,
    label: 'Available',
    field: 'is_disabled',
    formatter: EFilterFormatter.boolean,
    single: true,
  },
  connectedBots: {
    type: EFilterEntity.colors_multi_select,
    formatter: EFilterFormatter.colors_multi_select,
    label: 'Connected bots',
    name: 'connectedBots',
    field: 'connectedBots',
    listMultiColors: CONNECTED_BOT,
    single: true,
  },
});
