import React, { useCallback, useMemo } from 'react';

import { Switcher, Spinner } from 'ui';
import { ICexOrderbookGridBotTask } from 'types/bots';

import { OpenIcon } from 'assets/icons/OpenIcon';

interface IProps {
  task: ICexOrderbookGridBotTask;
  taskEnablingLoading: number | undefined;
  isActive: boolean;
  extendedRow: number | undefined;
  enableTask: ({
    task,
    active,
  }: {
    task: ICexOrderbookGridBotTask;
    active: boolean;
  }) => Promise<void>;
}

const Status = React.memo(
  ({ enableTask, extendedRow, isActive, task, taskEnablingLoading }: IProps) => {
    const onChangeSwitcher = useCallback(
      (flag: boolean) => {
        if (taskEnablingLoading !== undefined) return;

        enableTask({ task, active: flag });
      },
      [taskEnablingLoading, task, enableTask],
    );

    const isLoading = useMemo(() => taskEnablingLoading === task.id, [taskEnablingLoading, task]);

    return (
      <div className="switcher-cell">
        {isLoading && <Spinner size="mini" />}
        {!isLoading && (
          <div onClick={e => e.stopPropagation()}>
            <Switcher onChange={onChangeSwitcher} value={isActive} />
          </div>
        )}
        <OpenIcon opened={extendedRow !== undefined && extendedRow === task.id} />
      </div>
    );
  },
);

export { Status };
