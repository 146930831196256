import { BigNumber } from '@ethersproject/bignumber';

import { bn, convertBnToDecimals, divideBignumbers } from 'tools/math';

export const calculateDepositAmounts = (
  total: BigNumber,
  balances: { address: string; balance: BigNumber }[],
  decimal: number,
) => {
  const balancesLength = balances.length;
  const dividedBalance = convertBnToDecimals(
    divideBignumbers([total, decimal], [bn(balancesLength), 18]),
    18,
    decimal,
  );

  const withdrawWalletBalances = {} as Record<string, BigNumber>;

  for (const wallet of balances) {
    withdrawWalletBalances[wallet.address] = dividedBalance;
  }

  return withdrawWalletBalances;
};
