import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse } from '../apiBase';
import { ICexAccount } from 'types/accounts';

interface IAccountsResponse {
  has_next: boolean;
  items: ICexAccount[];
}

class ApiAccounts extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiAccounts | undefined;
  private static instance = (() => {
    if (ApiAccounts.__instance === undefined) {
      ApiAccounts.__instance = new ApiAccounts();
    }
    return ApiAccounts.__instance;
  })();

  public static getAccounts = async ({
    limit,
    lastSeenId,
    pairs_ids,
  }: {
    limit: number;
    lastSeenId?: number;
    pairs_ids?: number[];
  }) => {
    try {
      const urlParams = new URLSearchParams();
      urlParams.append('limit', limit.toString());
      lastSeenId && urlParams.append('last_seen_id', lastSeenId.toString());

      if (pairs_ids) {
        for (const pairId of pairs_ids) {
          urlParams.append('pairs_ids', pairId.toString());
        }
      }

      const response = await ApiAccounts.instance.get<IBaseResponse<IAccountsResponse>>(
        '/accounts?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IAccountsResponse>({ error });
    }
  };

  public static addAccount = async ({
    cex,
    expire_at,
    credentials,
    notes,
    proxy,
  }: {
    cex: string;
    expire_at: string;
    credentials: { api_key: string; secret: string };
    notes: string;
    proxy: string;
  }) => {
    try {
      const response = await ApiAccounts.instance.post<IBaseResponse<IAccountsResponse>>(
        '/accounts',
        {
          cex,
          expire_at,
          credentials,
          notes,
          proxy,
        },
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IAccountsResponse>({ error });
    }
  };

  public static deleteAccounts = async ({ accountId }: { accountId: number }) => {
    try {
      const response = await ApiAccounts.instance.delete<IBaseResponse<IAccountsResponse>>(
        `/accounts/${accountId}`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static updateAccount = async ({
    cex,
    expire_at,
    credentials,
    notes,
    proxy,
    accountId,
  }: {
    cex: string;
    expire_at: string;
    credentials: { api_key: string; secret: string };
    notes: string;
    proxy: string;
    accountId: number;
  }) => {
    try {
      const response = await ApiAccounts.instance.patch<IBaseResponse<IAccountsResponse>>(
        `/accounts/${accountId}`,
        {
          cex,
          expire_at,
          credentials,
          notes,
          proxy,
        },
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IAccountsResponse>({ error });
    }
  };
}

export { ApiAccounts };
