import { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { convertTokenDEX } from 'utils';
import { formatFiat } from 'utils/formats';

interface IConvertedCurrenciesArgs {
  amount: string;
  selectedCurrency: string;
  currencies?: string[];
}

const useConvertedCurrencies = ({
  amount,
  selectedCurrency,
  currencies,
}: IConvertedCurrenciesArgs) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const convertTo = useMemo(() => {
    const baseConvert = {
      symbol: dexPair.token_base.symbol,
      decimals: dexPair.token_base.decimals,
    };
    const quoteConvert = {
      symbol: dexPair.token_quote.symbol,
      decimals: dexPair.token_quote.decimals,
    };
    const feeConvert = { symbol: dexPair.token_fee.symbol, decimals: dexPair.token_fee.decimals };
    const usdConvert = { symbol: 'USD', decimals: 18 };

    const records = [baseConvert, feeConvert, usdConvert].filter(
      el => el.symbol !== selectedCurrency,
    );

    if (currencies) {
      const newRecords = [];

      if (currencies.includes(dexPair.token_base.symbol)) newRecords.push(baseConvert);
      if (currencies.includes(dexPair.token_fee.symbol)) newRecords.push(feeConvert);
      if (currencies.includes(dexPair.token_quote.symbol)) newRecords.push(quoteConvert);
      if (currencies.includes('USD')) newRecords.push(usdConvert);

      return newRecords.filter(el => el.symbol !== selectedCurrency);
    }

    return records;
  }, [dexPair, currencies, selectedCurrency]);

  return convertTo
    .map(el => {
      const converted = convertTokenDEX({
        from: selectedCurrency,
        to: el.symbol,
        amount,
        pair: dexPair,
        decimals: el.decimals,
      });

      if (el.symbol === 'USD') return formatFiat(converted, 18, '$');
      return formatFiat(converted, el.decimals, '') + ' ' + el.symbol;
    })
    .join(' / ');
};

export { useConvertedCurrencies };
