import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { ENetwork } from 'web3';
import { AApiBase, IBaseResponse } from '../apiBase';
import { ICex, IDex, IGasPriceResponse } from './models';

class ApiDictionary extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiDictionary | undefined;
  private static instance = (() => {
    if (ApiDictionary.__instance === undefined) {
      ApiDictionary.__instance = new ApiDictionary();
    }
    return ApiDictionary.__instance;
  })();

  public static getDexs = async () => {
    try {
      const response = await ApiDictionary.instance.get<IBaseResponse<{ items: IDex[] }>>('/dexs');

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<{ items: IDex[] }>({ error });
    }
  };

  public static getCexs = async () => {
    try {
      const response = await ApiDictionary.instance.get<IBaseResponse<{ items: ICex[] }>>('/cexs');

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<{ items: ICex[] }>({ error });
    }
  };

  public static getGasPrice = async (network: ENetwork) => {
    try {
      const response = await ApiDictionary.instance.get<IBaseResponse<IGasPriceResponse>>(
        `/networks/${network}/gas-price`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<IGasPriceResponse>({ error });
    }
  };
}

export { ApiDictionary };
