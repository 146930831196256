import { http, createConfig } from 'wagmi';
import { Chain } from 'wagmi/chains';
import { injected } from '@wagmi/connectors';

import { supportedWagmiChains } from './config';

export const wagmiConfig = createConfig({
  chains: supportedWagmiChains.map(el => el.wagmiChain) as unknown as readonly [Chain, ...Chain[]],
  connectors: [injected()],
  transports: {
    ...supportedWagmiChains.reduce(
      (acc, val) => ({ ...acc, [val.wagmiChain.id]: http(val.rpcUrl) }),
      {},
    ),
  },
});
