import React, { useState } from 'react';
import { useDebounce, useWindowSize } from 'react-use';
import { NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';
import { useTypedSelector, useTypedDispatch } from 'store';

import { isTestnet } from 'web3';
import { setIsSidebarOpened } from 'store/slices/ui';
import { Accounts, CreateNewProject, Projects, WalletsSideBar } from './components';

import { DoubleLeftArrowIcon } from 'assets/icons';

import pgLogo from 'assets/images/pg-logo.png';
import pgLogoDev from 'assets/images/pg-logo-dev.png';
import './sidebar.scss';

const Sidebar: React.FC = () => {
  const dispatch = useTypedDispatch();
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const isSidebarOpened = useTypedSelector(store => store.ui.isSidebarOpened);
  const palette = useTypedSelector(store => store.ui.selectedThemeColors);

  const { width } = useWindowSize();
  const [disableCollapse, setDisableCollapse] = useState<boolean>(width >= 1024);

  useDebounce(
    () => {
      if (width >= 1024) {
        setDisableCollapse(true);

        if (!isSidebarOpened) {
          dispatch(setIsSidebarOpened(true));
        }
      } else {
        setDisableCollapse(false);
      }
    },
    300,
    [width],
  );

  return (
    <aside className={cn('mm-sidebar', { _opened: isSidebarOpened })}>
      <motion.section
        className="mm-sidebar__header"
        initial={{ padding: '16px 16px' }}
        animate={{ padding: isSidebarOpened ? '16px 16px' : '16px 8px' }}
      >
        <NavLink className="mm-sidebar__header__left" to={'/'}>
          <img src={isTestnet ? pgLogoDev : pgLogo} alt="" />
          <AnimatePresence>
            {isSidebarOpened && (
              <motion.h2
                initial={{ paddingLeft: 8 }}
                exit={{ scale: 0, paddingLeft: 0, width: 0 }}
                className="mm-sidebar__title"
              >
                111PG
              </motion.h2>
            )}
          </AnimatePresence>
        </NavLink>
        {!disableCollapse && (
          <div className="mm-sidebar__header__right">
            <motion.button
              initial={false}
              whileHover={{ backgroundColor: palette.light_gray_1 }}
              animate={{ backgroundColor: palette.bg_primary, rotate: isSidebarOpened ? 0 : 180 }}
              className="collapse-button"
              onClick={() => dispatch(setIsSidebarOpened(!isSidebarOpened))}
            >
              <DoubleLeftArrowIcon />
            </motion.button>
          </div>
        )}
      </motion.section>
      <div className="mm-sidebar__separator" />
      <AnimatePresence>
        {isSidebarOpened && (
          <motion.section
            exit={{ opacity: 0, width: 0, padding: 0 }}
            className="mm-sidebar__content"
          >
            <div className="mm-sidebar__menu">
              {isAdmin && <WalletsSideBar />}
              {isAdmin && <Accounts />}
              <Projects />
              {isAdmin && <CreateNewProject />}
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </aside>
  );
};

export { Sidebar };
