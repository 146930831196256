import { useState, useCallback, useMemo, Dispatch, SetStateAction } from 'react';
import { useToggle, useUpdateEffect } from 'react-use';

interface IUseScrollPaginationArgs<T> {
  limit: number;
  remountLoad?: boolean;
  getRecords: ({
    offset,
    limit,
    lastSeenId,
  }: {
    offset: number;
    limit: number;
    lastSeenId: number;
  }) => Promise<{ records: T[]; has_more: boolean } | undefined>;
}

interface IUseScrollPaginationReturn<T> {
  loading: boolean;
  setRecords: Dispatch<SetStateAction<T[] | undefined>>;
  records: undefined | T[];
  hasMore: boolean;
  loadMore: () => Promise<T[] | undefined>;
  remount: () => void;
  rowSelection: Record<number, boolean>;
  setRowSelection: Dispatch<SetStateAction<Record<number, boolean>>>;
  selectedRecords: T[];
}

function useScrollPagination<T extends { id: number }>({
  limit,
  remountLoad = false,
  getRecords,
}: IUseScrollPaginationArgs<T>): IUseScrollPaginationReturn<T> {
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [lastSeenId, setLastSeenId] = useState<number>(0);
  const [records, setRecords] = useState<undefined | T[]>(undefined);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});

  const [triggerRemount, toggleRemount] = useToggle(false);

  const selectedRecords = useMemo(() => {
    const selectedRows: T[] = [];

    if (!records) return [];

    for (const key of Object.keys(rowSelection)) {
      selectedRows.push(records[Number(key)]);
    }

    return selectedRows;
  }, [rowSelection, records]);

  const handleLoadMore = useCallback(async () => {
    if (loading) return undefined;

    if (!hasMore) return [];

    try {
      setLoading(true);

      const result = await getRecords({
        limit,
        offset: offset,
        lastSeenId: lastSeenId,
      });

      let newRecordsSlice: T[] = [];

      if (result) {
        newRecordsSlice = result.records ?? [];
        setHasMore(result.has_more ?? false);
        setRecords(rec => (rec ? [...rec].concat(newRecordsSlice) : [...newRecordsSlice]));
        setOffset(offset => offset + limit);
        setLastSeenId(
          newRecordsSlice?.length !== 0 ? newRecordsSlice[newRecordsSlice.length - 1].id : 0,
        );
        return newRecordsSlice;
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.log('useScrollPagination error: ', error);
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, offset, lastSeenId, getRecords, limit]);

  const remount = useCallback(() => {
    setLoading(false);
    setOffset(0);
    setLastSeenId(0);
    setHasMore(true);
    setRecords(undefined);
    setRowSelection({});

    setTimeout(() => {
      if (remountLoad) {
        toggleRemount();
      }
    }, 0);
  }, [remountLoad, toggleRemount]);

  useUpdateEffect(() => {
    handleLoadMore();
  }, [triggerRemount]);

  return {
    loading,
    records,
    hasMore,
    loadMore: handleLoadMore,
    setRecords,
    remount,
    rowSelection,
    setRowSelection,
    selectedRecords,
  };
}

export { useScrollPagination };
