import React from 'react';

import { ICexOrderbookGridBotTask } from 'types/bots';

interface IProps {
  task: ICexOrderbookGridBotTask;
}

const DistOrders = React.memo(({ task }: IProps) => {
  return <div>{task.step_size}%</div>;
});

export { DistOrders };
