import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useDexWalletsRecords } from 'hooks/tables';
import { IPairWallet } from 'api/apiPairs/models';
import { addBignumbers, bn, bnFrom, convertBnToDecimals, humanizeBn } from 'tools';
import { TOTAL_TOKEN_ID } from 'constant/numbers';
import { useTypedSelector } from 'store';
import { formatFiat, formatToken } from 'utils/formats';
import { IWalletTableRow } from 'types/tables/dex/walletTableRow';

interface IPairWalletsContext {
  tableRecords: IWalletTableRow[];
  records: {
    get: IPairWallet[] | undefined;
    set: Dispatch<SetStateAction<IPairWallet[] | undefined>>;
  };
  virtualRecords: {
    get: IWalletTableRow[];
    set: Dispatch<SetStateAction<IWalletTableRow[]>>;
  };
  selectedRecords: IPairWallet[];
  connectedWallets: number;
  loading: boolean;
  hasMore: boolean;
  handleRemount: () => void;
  loadMore: () => void;
  rowSelection: {
    get: Record<number, boolean>;
    set: Dispatch<SetStateAction<Record<number, boolean>>>;
  };
  modals: {
    connectWalletsOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    disconnectWalletsOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    depositOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    withdrawOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    whitelistWithdrawOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    manualTxsOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    changeNetworkOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    withdrawStatusModalOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
  };
  totalBalances: {
    base: string;
    quote: string;
    fee: string;
    usd: string;
  };
}

export const PairWalletsContext = createContext<IPairWalletsContext>({
  tableRecords: [],
  records: { get: undefined, set: () => {} },
  virtualRecords: { get: [], set: () => {} },
  selectedRecords: [],
  connectedWallets: 0,
  loading: false,
  hasMore: true,
  handleRemount: () => {},
  loadMore: () => [],
  rowSelection: {
    get: {},
    set: () => {},
  },
  modals: {
    connectWalletsOpened: { get: false, set: () => {} },
    disconnectWalletsOpened: { get: false, set: () => {} },
    depositOpened: { get: false, set: () => {} },
    withdrawOpened: { get: false, set: () => {} },
    whitelistWithdrawOpened: { get: false, set: () => {} },
    manualTxsOpened: { get: false, set: () => {} },
    changeNetworkOpened: { get: false, set: () => {} },
    withdrawStatusModalOpened: { get: false, set: () => {} },
  },
  totalBalances: {
    base: '',
    quote: '',
    fee: '',
    usd: '',
  },
});

interface IPairWalletsContextProviderProps {
  children?: React.ReactNode;
}

const PairWalletsContextProvider: React.FC<IPairWalletsContextProviderProps> = ({ children }) => {
  const {
    tableRecords,
    records,
    virtualRecords,
    loading,
    hasMore,
    handleRemount,
    loadMore,
    rowSelection,
    selectedRecords,
    connectedWallets,
  } = useDexWalletsRecords();

  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const [connectWalletsOpened, setConnectWalletsOpened] = useState<boolean>(false);
  const [disconnectWalletsOpened, setDisconnectWalletsOpened] = useState<boolean>(false);
  const [depositOpened, setDepositOpened] = useState<boolean>(false);
  const [withdrawOpened, setWithdrawOpened] = useState<boolean>(false);
  const [whitelistWithdrawOpened, setWhitelistWithdrawOpened] = useState<boolean>(false);
  const [manualTxsOpened, setManualTxsOpened] = useState<boolean>(false);
  const [changeNetworkOpened, setChangeNetworkOpened] = useState<boolean>(false);
  const [withdrawStatusModalOpened, setWithdrawStatusModalOpened] = useState<boolean>(false);

  const getTokenValue = useCallback(
    (record: IPairWallet, tokenId: string) =>
      bnFrom(
        record.tokens?.find(token => token.token.id.toString() === tokenId)?.[
          tokenId === TOTAL_TOKEN_ID.toString() ? 'balance_usd' : 'balance'
        ],
      ) ?? bn('0'),
    [],
  );

  const totalBaseBalance = useMemo(() => {
    if (!selectedRecords) return '0.0';

    const sum = selectedRecords.reduce(
      (acum, record) =>
        addBignumbers(
          [acum, 18],
          [getTokenValue(record, dexPair.token_base.id.toString()), dexPair.token_base.decimals],
        ),
      bn('0', dexPair.token_base.decimals),
    );

    return formatToken(sum, 18);
  }, [dexPair.token_base.decimals, dexPair.token_base.id, selectedRecords, getTokenValue]);

  const totalQuoteBalance = useMemo(() => {
    if (!selectedRecords) return '0.0';

    const sum = selectedRecords.reduce(
      (acum, record) =>
        addBignumbers(
          [acum, 18],
          [getTokenValue(record, dexPair.token_quote.id.toString()), dexPair.token_quote.decimals],
        ),
      bn('0'),
    );

    return formatToken(sum, 18);
  }, [dexPair.token_quote.decimals, dexPair.token_quote.id, selectedRecords, getTokenValue]);

  const totalFeeBalance = useMemo(() => {
    if (!selectedRecords) return '0.0';

    const sum = selectedRecords.reduce(
      (acum, record) =>
        addBignumbers(
          [acum, 18],
          [getTokenValue(record, dexPair.token_fee.id.toString()), dexPair.token_fee.decimals],
        ),
      bn('0'),
    );

    return formatToken(sum, 18);
  }, [dexPair.token_fee.decimals, dexPair.token_fee.id, selectedRecords, getTokenValue]);

  const totalUsdBalance = useMemo(() => {
    if (!selectedRecords) return '0$';

    const sum = selectedRecords.reduce(
      (acum, record) =>
        addBignumbers([acum, 18], [getTokenValue(record, TOTAL_TOKEN_ID.toString()), 6]),
      bn('0'),
    );

    return formatFiat(sum, 18);
  }, [selectedRecords, getTokenValue]);

  return (
    <PairWalletsContext.Provider
      value={{
        tableRecords,
        records,
        virtualRecords,
        selectedRecords,
        connectedWallets,
        loading,
        hasMore,
        handleRemount,
        loadMore,
        rowSelection,
        modals: {
          connectWalletsOpened: { get: connectWalletsOpened, set: setConnectWalletsOpened },
          disconnectWalletsOpened: {
            get: disconnectWalletsOpened,
            set: setDisconnectWalletsOpened,
          },
          depositOpened: { get: depositOpened, set: setDepositOpened },
          withdrawOpened: { get: withdrawOpened, set: setWithdrawOpened },
          whitelistWithdrawOpened: {
            get: whitelistWithdrawOpened,
            set: setWhitelistWithdrawOpened,
          },
          manualTxsOpened: { get: manualTxsOpened, set: setManualTxsOpened },
          changeNetworkOpened: { get: changeNetworkOpened, set: setChangeNetworkOpened },
          withdrawStatusModalOpened: {
            get: withdrawStatusModalOpened,
            set: setWithdrawStatusModalOpened,
          },
        },
        totalBalances: {
          base: totalBaseBalance,
          quote: totalQuoteBalance,
          fee: totalFeeBalance,
          usd: totalUsdBalance,
        },
      }}
    >
      {children}
    </PairWalletsContext.Provider>
  );
};

export default PairWalletsContextProvider;
