import { isNil } from 'lodash';

import { IAuthUser } from 'api/apiUsers/models';
import { ICexAccount } from 'types/accounts';
import {
  EFilterNumberConnection,
  IFilterScheme,
  EFilterFormatter,
  IFilterColorSelectItem,
  IFilterValue,
} from 'types/filters/common';
import { bn } from 'tools/math';

export const naturalNumberTokenFormatter = ({
  value,
  connection,
  scheme,
}: {
  value: string;
  connection: EFilterNumberConnection;
  scheme: IFilterScheme;
}) => {
  const tokenId = scheme.tokenId;

  if (isNil(tokenId)) return undefined;
  if (isNaN(Number(value))) return undefined;

  return `token${tokenId}${connection}${value.toString()}`;
};

export const bnTokenFormatter = ({
  value,
  connection,
  scheme,
}: {
  value: string;
  connection: EFilterNumberConnection;
  scheme: IFilterScheme;
}) => {
  const name = scheme.name;
  const decimals = scheme.decimals ?? 18;

  if (isNaN(Number(value))) return undefined;

  return {
    name,
    connection,
    tokenId: scheme.tokenId,
    value: bn(value, decimals).toString(),
  };
};

export const booleanFormatter = ({ value }: { value: boolean }) => {
  return `${value}`;
};

export const colorsMultiSelectFormatter = ({ value }: { value: IFilterColorSelectItem[] }) => {
  return value.map(el => el.value);
};

export const colorsSingleSelectFormatter = ({ value }: { value: IFilterColorSelectItem }) => {
  return value ? value.value : undefined;
};

export const userMultiSelectFormatter = ({ value }: { value: IAuthUser[] }) => {
  return value.map(el => el.id);
};

export const cexAccountsMultiSelectFormatter = ({ value }: { value: ICexAccount[] }) => {
  return value.map(el => el.id);
};

export const filtersFormatter = ({
  filter,
  formatter,
}: {
  filter: IFilterValue;
  formatter: EFilterFormatter | undefined;
}) => {
  const _filterValue = filter?.value;
  const scheme = filter?.scheme;

  if (!_filterValue || !scheme) return {};

  const { value, connection } = _filterValue;

  let result: any = '';

  if (formatter === EFilterFormatter.natural_number_token) {
    result = naturalNumberTokenFormatter({
      value: value as string,
      connection: connection as EFilterNumberConnection,
      scheme,
    });
  } else if (formatter === EFilterFormatter.bn_number_token) {
    result = bnTokenFormatter({
      value: value as string,
      connection: connection as EFilterNumberConnection,
      scheme,
    });
  } else if (formatter === EFilterFormatter.boolean) {
    result = booleanFormatter({
      value: value as boolean,
    });
  } else if (formatter === EFilterFormatter.colors_multi_select) {
    result = colorsMultiSelectFormatter({ value: value as IFilterColorSelectItem[] });
  } else if (formatter === EFilterFormatter.colors_single_select) {
    result = colorsSingleSelectFormatter({ value: value as IFilterColorSelectItem });
  } else if (formatter === EFilterFormatter.user_multi_select) {
    result = userMultiSelectFormatter({ value: value as IAuthUser[] });
  } else if (formatter === EFilterFormatter.cex_accounts_multi_select) {
    result = cexAccountsMultiSelectFormatter({ value: value as ICexAccount[] });
  } else {
    result = value;
  }

  if (!scheme.field) return {};
  else return { [scheme.field]: result };
};
