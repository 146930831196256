import React from 'react';
import { useTypedSelector } from 'store';

import { ICexOrderbookGridBotTask } from 'types/bots';
import { formatToken } from 'utils/formats';
import { bn } from 'tools/math';

interface IProps {
  task: ICexOrderbookGridBotTask;
}

const OrderAmount = React.memo(({ task }: IProps) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  return (
    <div className="orders-amount">
      <div className="orders-count-row">
        <span className="ask">Ask: </span>
        <span>
          {formatToken(bn(task.ask_grid_config.size_min), 18)} -{' '}
          {formatToken(bn(task.ask_grid_config.size_max), 18)} {cexPair.token_quote.symbol}
        </span>
      </div>
      <div className="orders-count-row">
        <span className="bid">Bid: </span>
        <span>
          {' '}
          {formatToken(bn(task.bid_grid_config.size_min), 18)} -{' '}
          {formatToken(bn(task.bid_grid_config.size_max), 18)} {cexPair.token_quote.symbol}
        </span>
      </div>
    </div>
  );
});

export { OrderAmount };
