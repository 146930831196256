import gateIoLogo from 'assets/images/cex/gateIoLogo.svg';
import gateIoLogoWordMark from 'assets/images/cex/gateIoLogoWordMark.svg';
import kuCoinLogo from 'assets/images/cex/kuCoinLogo.svg';
import kuCoinWordMarkIcon from 'assets/images/cex/kuCoinWordMark.svg';
import binaceLogo from 'assets/images/cex/binanceLogo.svg';
import binaceLogoWordMark from 'assets/images/cex/binanceLogoWordMark.svg';
import mexcLogo from 'assets/images/cex/mexcLogo.svg';
import mexcLogoWordMark from 'assets/images/cex/mexcWordMark.svg';
import bitgetLogo from 'assets/images/cex/bitgetLogo.svg';
import bitgetLogoWordMark from 'assets/images/cex/bitgetLogoWordMark.svg';
import okxLogo from 'assets/images/cex/okxLogo.png';
import okxLogoWordMark from 'assets/images/cex/okxLogoWordMark.svg';
import bybitLogo from 'assets/images/cex/bybitLogo.png';
import bybitLogoWordMark from 'assets/images/cex/bybitLogoWordMark.svg';
import huobiLogo from 'assets/images/cex/huobiLogo.svg';
import huobiLogoWordMark from 'assets/images/cex/huobiLogoWordMark.svg';
import bingxLogo from 'assets/images/cex/bingxLogo.png';
import bingxLogoWordMark from 'assets/images/cex/bingxLogoWordMark.png';
import whitebitLogo from 'assets/images/cex/whitebitLogo.png';
import whitebitLogoWordMark from 'assets/images/cex/whitebitLogoWordMark.png';
import bitmartLogo from 'assets/images/cex/bitmartLogo.png';
import bitmartLogoWordMark from 'assets/images/cex/bitmartLogoWordMark.png';
import lbankLogo from 'assets/images/cex/lbankLogo.png';
import lbankLogoWordMark from 'assets/images/cex/lbankLogoWordMark.png';
import coinstoreLogo from 'assets/images/cex/coinbaseLogo.png';
import coinstoreLogoWordMark from 'assets/images/cex/coinstoreLogoWordMark.png';

export enum ECexName {
  gate = 'gate',
  kucoin = 'kucoin',
  mexc = 'mexc',
  binance = 'binance',
  bitget = 'bitget',
  okx = 'okx',
  bybit = 'bybit',
  htx = 'htx',
  bingx = 'bingx',
  whitebit = 'whitebit',
  bitmart = 'bitmart',
  lbank = 'lbank',
  coinstore = 'coinstore',
}

interface IConfig {
  cexLink: (base: string, quote: string) => string;
  icon: string;
  iconText: string;
  withPassphrase: boolean;
}

export const cexConfigs: Record<ECexName, IConfig> = {
  [ECexName.binance]: {
    cexLink: (base: string, quote: string) => `https://www.binance.com/trade/${base}_${quote}`,
    icon: binaceLogo,
    iconText: binaceLogoWordMark,
    withPassphrase: false,
  },
  [ECexName.gate]: {
    cexLink: (base: string, quote: string) => `https://www.gate.io/trade/${base}_${quote}`,
    icon: gateIoLogo,
    iconText: gateIoLogoWordMark,
    withPassphrase: false,
  },
  [ECexName.kucoin]: {
    cexLink: (base: string, quote: string) => `https://www.kucoin.com/trade/${base}-${quote}`,
    icon: kuCoinLogo,
    iconText: kuCoinWordMarkIcon,
    withPassphrase: true,
  },
  [ECexName.mexc]: {
    cexLink: (base: string, quote: string) => `https://mexc.com/exchange/${base}_${quote}`,
    icon: mexcLogo,
    iconText: mexcLogoWordMark,
    withPassphrase: false,
  },
  [ECexName.okx]: {
    cexLink: (base: string, quote: string) => `https://www.okx.com/trade-spot/${base}-${quote}`,
    icon: okxLogo,
    iconText: okxLogoWordMark,
    withPassphrase: true,
  },
  [ECexName.bybit]: {
    cexLink: (base: string, quote: string) => `https://www.bybit.com/trade/spot/${base}/${quote}`,
    icon: bybitLogo,
    iconText: bybitLogoWordMark,
    withPassphrase: false,
  },
  [ECexName.htx]: {
    cexLink: (base: string, quote: string) =>
      `https://www.htx.com/trade/${base.toLowerCase()}_${quote.toLowerCase()}`,
    icon: huobiLogo,
    iconText: huobiLogoWordMark,
    withPassphrase: false,
  },
  [ECexName.bitget]: {
    cexLink: (base: string, quote: string) => `https://www.bitget.com/spot/${base}${quote}`,
    icon: bitgetLogo,
    iconText: bitgetLogoWordMark,
    withPassphrase: true,
  },
  [ECexName.bingx]: {
    cexLink: (base: string, quote: string) =>
      `https://bingx.com/uk-ua/spot/${base.toUpperCase()}${quote.toUpperCase()}`,
    icon: bingxLogo,
    iconText: bingxLogoWordMark,
    withPassphrase: false,
  },
  [ECexName.whitebit]: {
    cexLink: (base: string, quote: string) =>
      `https://whitebit.com/trade/${base.toUpperCase()}-${quote.toUpperCase()}`,
    icon: whitebitLogo,
    iconText: whitebitLogoWordMark,
    withPassphrase: false,
  },
  [ECexName.bitmart]: {
    cexLink: (base: string, quote: string) =>
      `https://www.bitmart.com/trade?symbol=${base.toUpperCase()}_${quote.toUpperCase()}`,
    icon: bitmartLogo,
    iconText: bitmartLogoWordMark,
    withPassphrase: true,
  },
  [ECexName.lbank]: {
    cexLink: (base: string, quote: string) =>
      `https://www.lbank.com/trade/${base.toLowerCase()}_${quote.toLowerCase()}`,
    icon: lbankLogo,
    iconText: lbankLogoWordMark,
    withPassphrase: false,
  },
  [ECexName.coinstore]: {
    cexLink: (base: string, quote: string) => `https://www.coinstore.com/spot/${base}${quote}`,
    icon: coinstoreLogo,
    iconText: coinstoreLogoWordMark,
    withPassphrase: false,
  },
};
