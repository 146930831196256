import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { ApiProjects, ApiPairs } from 'api';

import { ICexPairShort, IDexPairShort } from 'types/pairs';
import { IProject } from 'types/project';
import { IGetByIdProject } from 'api/apiProjects/models';
import { EExchange } from 'web3';

interface IProjectsSliceState {
  projects: null | IProject[];
  selectedProjectInPage: null | IGetByIdProject;
}

const userSliceInitialState: IProjectsSliceState = {
  projects: null,
  selectedProjectInPage: null,
};

const getAllProjectsAndPairs = createAsyncThunk<
  any,
  {
    redirect: ({
      projectId,
      pairPath,
    }: {
      projectId: number | undefined;
      pairPath: string | undefined;
    }) => void;
  },
  any
>('projects/get-projects-n-pairs', async ({ redirect }, thunkApi) => {
  try {
    const { data, isSuccess, errorMessage } = await ApiProjects.getAllProjects({});

    if (isSuccess) {
      let newProjects: IProject[] = data.items
        .sort((a, b) => {
          if (dayjs(a.created_at).valueOf() > dayjs(b.created_at).valueOf()) {
            return 1;
          }

          if (dayjs(a.created_at).valueOf() < dayjs(b.created_at).valueOf()) {
            return -1;
          }

          return 0;
        })
        .map(authProject => ({ ...authProject, dexPairs: [], cexPairs: [] }));

      newProjects = await Promise.all(
        newProjects.map(async project => {
          const { isSuccess, data } = await ApiPairs.getPairsByProjectId({ id: project.id });

          if (!isSuccess) {
            return { ...project, pairs: [] };
          } else {
            const dexPairs = data.items
              .filter(el => el.type === EExchange.dex)
              .map(el => ({ ...el.dex_pair! }));
            const cexPairs = data.items
              .filter(el => el.type === EExchange.cex)
              .map(el => ({ ...el.cex_pair! }));

            return {
              ...project,
              dexPairs,
              cexPairs,
            };
          }
        }),
      );

      const pairs = newProjects[0]
        ? [...(newProjects[0].dexPairs ?? []), ...(newProjects[0].cexPairs ?? [])].sort(
            (a, b) => new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
          )
        : [];

      const pairPath =
        pairs.length > 0
          ? 'dex' in pairs[0]
            ? `/dex-pair/${pairs[0].id}`
            : `/cex-pair/${(pairs[0] as any).id}`
          : undefined;

      redirect({ projectId: newProjects[0]?.id ?? undefined, pairPath });

      return thunkApi.fulfillWithValue(newProjects);
    }

    return thunkApi.rejectWithValue(errorMessage);
  } catch (error) {
    console.log(error);
  }
});

const projectsSlice = createSlice({
  name: 'projects',
  initialState: userSliceInitialState as IProjectsSliceState,
  extraReducers: builder => {
    builder.addCase(
      getAllProjectsAndPairs.fulfilled,
      (store, { payload }: { payload: IProject[] }) => {
        store.projects = payload;
      },
    );
    builder.addCase(getAllProjectsAndPairs.rejected, (store, { payload }) => {
      //TODO
    });
  },
  reducers: {
    addProject: (store, { payload }: { payload: IProject }) => {
      const newProjects = store.projects ? [...store.projects] : [];
      newProjects.push(payload);

      store.projects = newProjects;
    },
    deleteProject: (store, { payload }: { payload: number }) => {
      const newProjects = store.projects
        ? [...store.projects].filter(project => project.id !== payload)
        : [];

      store.projects = newProjects;
    },
    updateProject: (
      store,
      { payload }: { payload: { id: number; newProjectName: string; newProjectNotes: string } },
    ) => {
      const newProjects = store.projects
        ? [...store.projects].map(project => {
            if (project.id === payload.id) {
              return { ...project, notes: payload.newProjectNotes, name: payload.newProjectName };
            }
            return project;
          })
        : [];

      store.projects = newProjects;
    },
    addNewPairToProject: (
      store,
      { payload }: { payload: { pair: IDexPairShort | ICexPairShort; type: EExchange } },
    ) => {
      if (!store.projects) return;

      const newProjects = [...store.projects].map(project => {
        if (project.id === payload.pair.project_id) {
          const newProject = { ...project };

          if (payload.type === EExchange.dex) {
            newProject.dexPairs.push(payload.pair as IDexPairShort);
          }

          if (payload.type === EExchange.cex) {
            newProject.cexPairs.push(payload.pair as ICexPairShort);
          }

          return newProject;
        }
        return project;
      });
      store.projects = newProjects;
    },
    updatePairInProject: (
      store,
      {
        payload,
      }: { payload: { pairId: number; type: EExchange; projectId: number; notes: string } },
    ) => {
      if (!store.projects) return;

      const newProjects = [...store.projects].map(project => {
        if (project.id === payload.projectId) {
          const newProject = { ...project };

          if (payload.type === EExchange.dex) {
            newProject.dexPairs = [...newProject.dexPairs].map(pair => {
              if (pair.id === payload.pairId) {
                return { ...pair, notes: payload.notes };
              }

              return pair;
            });
          }

          if (payload.type === EExchange.cex) {
            newProject.cexPairs = [...newProject.cexPairs].map(pair => {
              if (pair.id === payload.pairId) {
                return { ...pair, notes: payload.notes };
              }

              return pair;
            });
          }

          return newProject;
        }
        return project;
      });
      store.projects = newProjects;
    },
    deletePairFromProject: (
      store,
      { payload }: { payload: { pairId: number; type: EExchange; projectId: number } },
    ) => {
      if (!store.projects) return;

      const newProjects = [...store.projects].map(project => {
        if (project.id === payload.projectId) {
          const newProject = { ...project };

          if (payload.type === EExchange.dex) {
            newProject.dexPairs = [...newProject.dexPairs].filter(
              pair => pair.id !== payload.pairId,
            );
          }

          if (payload.type === EExchange.cex) {
            newProject.cexPairs = [...newProject.cexPairs].filter(
              pair => pair.id !== payload.pairId,
            );
          }

          return newProject;
        }
        return project;
      });
      store.projects = newProjects;
    },
    setSelectedProjectInPage: (store, { payload }: { payload: IGetByIdProject | null }) => {
      store.selectedProjectInPage = payload;
    },
  },
});

export { getAllProjectsAndPairs };
export const {
  addProject,
  deleteProject,
  updateProject,
  addNewPairToProject,
  updatePairInProject,
  deletePairFromProject,
  setSelectedProjectInPage,
} = projectsSlice.actions;

export default projectsSlice.reducer;
