import React, { useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import { useTypedSelector } from 'store';

import { CheckedIcon } from './CheckedIcon';

import './checkbox.scss';

interface ICheckboxProps {
  id?: string;
  checked: boolean;
  onChange?: () => void;
  className?: string | undefined;
  color?: 'primary' | 'secondary';
}

const Checkbox: React.FC<ICheckboxProps> = ({
  id,
  checked,
  onChange,
  className,
  color = 'primary',
}) => {
  const palette = useTypedSelector(store => store.ui.selectedThemeColors);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      if (onChange) onChange();
    },
    [onChange],
  );

  const checkedBgColor = useMemo(
    () => (color === 'primary' ? palette.primary : palette.gray_1),
    [color, palette],
  );

  const internalId = useMemo(() => id ?? uuid(), [id]);

  return (
    <>
      <input
        className="mm-checkbox-input"
        checked={checked}
        type="checkbox"
        id={internalId}
        onChange={handleChange}
        onClick={e => e.stopPropagation()}
      />
      <motion.label
        initial={{
          borderColor: palette.gray_1,
          backgroundColor: palette.bg_primary,
        }}
        animate={{
          borderColor: !checked ? palette.gray_1 : checkedBgColor,
          backgroundColor: !checked ? palette.bg_primary : checkedBgColor,
        }}
        className={cn('mm-checkbox', { [className ?? '']: !!className, _checked: checked })}
        htmlFor={internalId}
        onClick={e => e.stopPropagation()}
      >
        <CheckedIcon />
      </motion.label>
    </>
  );
};

export { Checkbox };
