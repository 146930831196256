import { isNil } from 'lodash';
import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { IRange, ECexName } from 'web3';
import { IAccountBalances } from 'types/accounts';
import { IPnl } from 'types/pnl';
import { AApiBase, IBaseResponse } from '../apiBase';
import { IStatisticToken, IStatisticTransaction } from './models';
import { metricsAxios } from '../apiBase';

interface IStatisticResponse {
  first_trade_time: string;
  transactions: IStatisticTransaction[];
  tokens: IStatisticToken[];
}

class ApiStatistic extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiStatistic | undefined;
  private static instance = (() => {
    if (ApiStatistic.__instance === undefined) {
      ApiStatistic.__instance = new ApiStatistic();
    }
    return ApiStatistic.__instance;
  })();

  public static getStatistic = async ({
    projects_ids,
    pair_ids,
    token_ids,
    ranges,
  }: {
    projects_ids?: number[];
    pair_ids?: number[];
    token_ids?: number[];
    ranges: (IRange | string)[];
  }) => {
    try {
      const urlParams = new URLSearchParams();
      if (projects_ids && projects_ids.length !== 0) {
        for (const projectId of projects_ids) {
          urlParams.append('project_ids', projectId.toString());
        }
      }

      if (pair_ids && pair_ids.length !== 0) {
        for (const pairId of pair_ids) {
          urlParams.append('pair_ids', pairId.toString());
        }
      }
      if (token_ids && token_ids.length !== 0) {
        for (const tokenId of token_ids) {
          urlParams.append('token_ids', tokenId.toString());
        }
      }

      for (const range of ranges) {
        urlParams.append('range', range.toString());
      }

      const response = await ApiStatistic.instance.get<IBaseResponse<IStatisticResponse>>(
        '/statistics' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<IStatisticResponse>({ error });
    }
  };

  public static getCexAvgOrderSize = async ({
    pairId,
    period,
    startDate,
    endDate,
    account_id,
  }: {
    pairId: number;
    period?: string;
    startDate?: number;
    endDate?: number;
    account_id?: number[];
  }) => {
    type Return = {
      base: string;
      quote: string;
    };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pair_id', pairId.toString());
      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }
      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/avg/size' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexOurTradesCount = async ({
    pairId,
    period,
    startDate,
    endDate,
    account_id,
  }: {
    pairId: number;
    period?: string;
    startDate?: number;
    endDate?: number;
    account_id?: number[];
  }) => {
    type Return = {
      count: string;
    };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pair_id', pairId.toString());
      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }
      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/count/our/trade' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexOurTradesTotalVolume = async ({
    pairId,
    period,
    startDate,
    endDate,
    account_id,
  }: {
    pairId: number;
    period?: string;
    startDate?: number;
    endDate?: number;
    account_id?: number[];
  }) => {
    type Return = {
      total: string;
      total_quote: string;
    };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pair_id', pairId.toString());
      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }
      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/total/our/trade' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexTotalFees = async ({
    pairId,
    period,
    startDate,
    endDate,
    account_id,
  }: {
    pairId: number;
    period?: string;
    startDate?: number;
    endDate?: number;
    account_id?: number[];
  }) => {
    type Return = { fee: string };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pair_id', pairId.toString());
      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }
      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/total/our/fee' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexOurIncome = async ({
    pairId,
    period,
    startDate,
    endDate,
    account_id,
  }: {
    pairId: number;
    period?: string;
    startDate?: number;
    endDate?: number;
    account_id?: number[];
  }) => {
    type Return = { income: { base: string; quote: string } };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pair_id', pairId.toString());
      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }
      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/total/our/income' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexTickerInfo = async ({ symbol, cex }: { symbol: string; cex: ECexName }) => {
    type Return = {
      base_volume: string;
      change24h: string;
      high24h: string;
      last_price: string;
      low24h: string;
      quote_volume: string;
      spread_percentage: string;
    };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('symbol', symbol);
      urlParams.append('cex', cex);

      const response = await metricsAxios.get<Return>(
        '/api/v1/market/ticker' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static geCexMarketTotalVolume = async ({
    symbol,
    cex,
    period,
    startDate,
    endDate,
  }: {
    symbol: string;
    cex: ECexName;
    period?: string;
    startDate?: number;
    endDate?: number;
  }) => {
    type Return = {
      total_volume_base: string;
      total_volume_quote: string;
    };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('symbol', symbol);
      urlParams.append('cex', cex);

      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/market/total-volume' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static geCexActualBalance = async ({
    symbol,
    cex,
    account_id,
  }: {
    symbol: string;
    cex: ECexName;
    account_id?: number[];
  }) => {
    try {
      const urlParams = new URLSearchParams();

      urlParams.append('symbol', symbol);
      urlParams.append('cex', cex);

      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get<IAccountBalances>(
        '/api/v1/balance/actual' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<IAccountBalances>({ error });
    }
  };

  public static geCexBalancesDelta = async ({
    symbol,
    cex,
    account_id,
    period,
    startDate,
    endDate,
  }: {
    symbol: string;
    cex: ECexName;
    account_id?: number[];
    period?: string;
    startDate?: number;
    endDate?: number;
  }) => {
    type Return = {
      ending_balance: {
        base: {
          amount: string;
          amount_usd: string;
          coin: string;
        };
        quote: {
          amount: string;
          amount_usd: string;
          coin: string;
        };
      };
      starting_balance: {
        base: {
          amount: string;
          amount_usd: string;
          coin: string;
        };
        quote: {
          amount: string;
          amount_usd: string;
          coin: string;
        };
      };
    };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('symbol', symbol);
      urlParams.append('cex', cex);

      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/balance/delta' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexUptime = async ({ pairId }: { pairId: number }) => {
    type Return = {
      uptime: string;
    };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pair_id', pairId.toString());

      const response = await metricsAxios.get<Return>(
        '/api/v1/uptime/actual' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getBalancePnl = async ({
    pairId,
    symbol,
    account_id,
    cex,
    period,
    startDate,
    endDate,
  }: {
    pairId: number;
    symbol: string;
    account_id: number[];
    cex: string;
    period?: string;
    startDate?: number;
    endDate?: number;
  }) => {
    try {
      const urlParams = new URLSearchParams();

      urlParams.append('cex', cex);
      urlParams.append('symbol', symbol);
      urlParams.append('pair_id', pairId.toString());

      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }

      const response = await metricsAxios.get<IPnl>(
        '/api/v1/balance/pnl' + '?' + urlParams.toString(),
      );
      return mapResponse({ data: response.data });
    } catch (error: unknown) {
      return mapError<IPnl>({ error });
    }
  };
}

export { ApiStatistic };
