export const mempoolConfigMap = {
  'Use additionaly': 'on',
  Only: 'only',
  'Do not use': 'off',
} as const;

export const revertedMempoolConfigMap = {
  on: 'Use additionaly',
  only: 'Only',
  off: 'Do not use',
} as const;

export type MempoolConfigMap = keyof typeof mempoolConfigMap;
