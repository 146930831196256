import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const ArrowToIcon: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors['gray-1'],
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <path
        d="M4.5856 7.67016C4.91697 7.67016 5.1856 7.40153 5.1856 7.07016C5.1856 6.73879 4.91697 6.47016 4.5856 6.47016L4.5856 7.67016ZM1.75717 7.07016L1.15717 7.07016C1.15717 7.22929 1.22039 7.3819 1.33291 7.49443C1.44543 7.60695 1.59804 7.67016 1.75717 7.67016L1.75717 7.07016ZM2.35717 4.24173C2.35717 3.91036 2.08854 3.64173 1.75717 3.64173C1.4258 3.64173 1.15717 3.91036 1.15717 4.24173L2.35717 4.24173ZM4.5856 6.47016L1.75717 6.47016L1.75717 7.67016L4.5856 7.67016L4.5856 6.47016ZM2.35717 7.07016L2.35717 4.24173L1.15717 4.24173L1.15717 7.07016L2.35717 7.07016Z"
        fill={color}
      />
      <path
        d="M7.83844 1.83772C8.07276 1.60341 8.07276 1.22351 7.83844 0.989195C7.60413 0.75488 7.22423 0.75488 6.98991 0.989195L7.83844 1.83772ZM1.33306 6.64605C1.09875 6.88036 1.09875 7.26026 1.33306 7.49458C1.56737 7.72889 1.94727 7.72889 2.18159 7.49458L1.33306 6.64605ZM6.98991 0.989195L1.33306 6.64605L2.18159 7.49458L7.83844 1.83772L6.98991 0.989195Z"
        fill={color}
      />
    </svg>
  );
};

export { ArrowToIcon };
