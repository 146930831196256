import React, { useMemo } from 'react';

import { ICexOrder } from 'types/orders';
import { formatFiat, formatToken } from 'utils/formats';
import { bnFrom, divideBignumbers } from 'tools/math';

interface IPriceProps {
  order: ICexOrder;
}

const Price = React.memo(({ order }: IPriceProps) => {
  const decimals = useMemo(() => 18, []);

  const price = useMemo(() => {
    return divideBignumbers(
      [bnFrom(order.quote_amount), decimals],
      [bnFrom(order.base_amount), decimals],
    );
  }, [decimals, order]);

  const priceUsd = useMemo(() => {
    return divideBignumbers([bnFrom(order.amount_usd), 6], [bnFrom(order.base_amount), decimals]);
  }, [decimals, order]);

  return (
    <div style={{ textAlign: 'left', textOverflow: 'ellipsis', minWidth: 0, overflow: 'hidden' }}>
      <span className="text-primary">{formatToken(price, decimals)} / </span>
      <span className="text-secondary">{formatFiat(priceUsd, decimals)}</span>
    </div>
  );
});

Price.displayName = 'Price';

export { Price };
