import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { last, isNil } from 'lodash';

import { LocalStorage } from 'tools';
import { IFilterValue } from 'types/filters/common';
import { IFilters, EDexPairFilters, ECexPairFilters, EGeneralFilters } from 'types/filters';
import { MAX_CACHED_PAIRS, FILTERS_KEY } from 'constant/cache';
import { EExchange } from 'web3';

type IEnteringFilters =
  | { [key in EDexPairFilters]?: { filters: IFilterValue[]; setted: IFilterValue[] } }
  | { [key in ECexPairFilters]?: { filters: IFilterValue[]; setted: IFilterValue[] } }
  | { filters: IFilterValue[]; setted: IFilterValue[] };

interface IPayload {
  pairId?: number;
  exchange?: EExchange;
  general?: EGeneralFilters;
  filters: IEnteringFilters;
}

export const setupInitialFiltersReducer: CaseReducer<IFilters, PayloadAction<IPayload>> = (
  state,
  { payload },
) => {
  const updatedAt = new Date(Date.now()).toISOString();

  const { pairId, exchange, general, filters } = payload;

  // setuping updated filters state
  const updatedState = { ...state };
  if (!isNil(pairId) && exchange) {
    const key = exchange === EExchange.dex ? 'dex' : 'cex';

    //@ts-ignore
    updatedState[key][pairId] = { updated_at: updatedAt, filters };
  } else if (general) {
    //@ts-ignore
    updatedState[general] = filters;
  }

  state = updatedState;

  // checking pair for filters overflow
  if (!isNil(pairId) && exchange) {
    const key = exchange === EExchange.dex ? 'dex' : 'cex';

    const pairStates = Object.keys(updatedState[key]).map(el => Number(el));

    if (pairStates.length <= MAX_CACHED_PAIRS) {
      LocalStorage.set(FILTERS_KEY, state);
      return;
    }

    const sortedByUpdatedAt = pairStates
      .filter(pairId => !!updatedState[key][pairId]?.updated_at)
      .map(pairId => ({
        pairId,
        updatedAt: updatedState[key][pairId]!.updated_at,
      }))
      .sort((a, b) => {
        const dateA = new Date(a.updatedAt).valueOf();
        const dateB = new Date(b.updatedAt).valueOf();

        if (dateA > dateB) return -1;
        if (dateB < dateA) return 1;
        return 0;
      });

    state[key][last(sortedByUpdatedAt)!.pairId] = undefined;
  }

  //saving result to LS
  LocalStorage.set(FILTERS_KEY, state);
};
