import { IPairWallet, IDexToken } from 'api/apiPairs/models';
import { IWalletToken } from 'api/apiWallets/models';
import { bn, bnFrom, convertBnToDecimals, addBignumbers } from 'tools/math';

export const calculateWalletsTokenBalance = ({
  wallets,
  token,
}: {
  wallets: IPairWallet[];
  token: IDexToken;
}) => {
  const decimals = token.decimals;

  const balance = wallets.reduce((acc, wallet) => {
    if (
      !wallet.tokens ||
      (wallet.tokens && !wallet.tokens.find(walletToken => walletToken.token.id === token.id))
    )
      return acc;

    const walletToken: IWalletToken | undefined = wallet.tokens.find(
      walletToken => walletToken.token.id === token.id,
    );

    const walletTokenBalanceBigNumber = walletToken ? bnFrom(walletToken.balance) : bn(0);

    return addBignumbers([walletTokenBalanceBigNumber, decimals], [acc, 18]);
  }, bn(0));

  return convertBnToDecimals(balance, 18, decimals);
};
