import { ECexName } from 'web3';

interface ICexOrderAccount {
  api_key_masked: string;
  id: number;
  notes: string;
}

interface ICexOrderTrade {
  amount: string;
  amount_usd: string;
  cex_trade_id: string;
  created_at: string;
  is_fee: boolean;
  token: {
    id: number;
    symbol: string;
  };
}

export enum ECexOrderSide {
  buy = 'buy',
  sell = 'sell',
}

export enum ECexOrderStatus {
  active = 'active',
  canceled = 'canceled',
  filled = 'filled',
  partially_filled = 'partially_filled',
  error = 'error',
}

export enum ECexOrderBot {
  organic = 'organic',
  cm = 'cm',
  fg = 'fg',
  order_manager_bot = 'order-manager-bot',
  orderbook = 'orderbook',
  buysell = 'buysell',
  orderbook_dynamic = 'orderbook_dynamic',
  counter_order = 'counter-order',
  abuse_protection = 'abuse_protection',
  arbitrage = 'arbitrage',
}

export interface ICexOrder {
  account: ICexOrderAccount;
  account_id: number;
  amount_usd: string;
  base_amount: string;
  cex: ECexName;
  cex_client_order_id: string;
  cex_order_id: string;
  created_at: string;
  id: number;
  metadata: {
    price?: string;
  };
  pair_id: number;
  project_id: number;
  quote_amount: string;
  sequence_no: number;
  side: ECexOrderSide;
  status: ECexOrderStatus;
  trades?: ICexOrderTrade[];
  updated_at: string;
  user: {
    id: number;
    login: string;
  };
  user_id: number;
}

export interface INewCexOrder {
  account_id: number;
  amount_usd: string;
  base_amount: string;
  base_fee: string;
  bot: string;
  cex: string;
  cex_client_order_id: string;
  cex_order_id: string;
  created_at: string;
  deleted_at: string;
  fact_base_amount: string;
  fact_price: string;
  fact_quote_amount: string;
  id: number;
  locked_until: string;
  metadata: number[];
  pair_id: number;
  price: string;
  project_id: number;
  quote_amount: string;
  quote_fee: string;
  reserved_released: boolean;
  sequence_no: number;
  side: string;
  status: string;
  updated_at: string;
}

export interface ICexOrderTransfer {
  cex_trade_id: string;
  created_at: string;
  tokens: ICexOrderTrade[];
}

export interface ICexOrderAgregatedTrades extends ICexOrder {
  transfers: ICexOrderTransfer[];
}

export interface ICexActiveOrder {
  account_id: number;
  amount_usd: string;
  base_amount: string;
  cex: ECexName;
  bot: string;
  cex_client_order_id: string;
  cex_order_id: string;
  created_at: string;
  deleted_at: string;
  fact_base_amount: string;
  id: number;
  locked_until: string;
  metadata: null;
  pair_id: number;
  project_id: number;
  quote_amount: string;
  reserved_released: boolean;
  sequence_no: number;
  side: ECexOrderSide;
  status: ECexOrderStatus;
  updated_at: string;
  account_notes?: string;
}
