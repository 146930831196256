import { CancelToken } from 'axios';
import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse } from '../apiBase';
import {
  IDexSLBotRandomStrategy,
  IDexSLSimulationOptions,
  IDexSLBotVolumesStrategy,
  IDexSLBotOrganicStrategy,
} from 'types/bots';
import { IDexSLShortPreset, IPreset } from 'types/dex-sl-bot';
import { IDexSLSimulatorResponse } from './models';

interface IDexSLGetPresetsResponse {
  items: IDexSLShortPreset[];
  total_items: number;
}

interface IDexSLGetPresetResponse {
  created_at: string;
  updated_at: string;
  id: number;
  name: string;
  options: {
    random_strategy: IDexSLBotRandomStrategy;
    volumes_strategy?: IDexSLBotVolumesStrategy;
    organic_volumes_tasks?: IDexSLBotOrganicStrategy;
    send_private_transactions: boolean;
    reserve_wallets_priority: 'default' | 'random';
    slippage_percent: string;
  };
}

class ApiDexSLBot extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiDexSLBot | undefined;
  private static instance = (() => {
    if (ApiDexSLBot.__instance === undefined) {
      ApiDexSLBot.__instance = new ApiDexSLBot();
    }
    return ApiDexSLBot.__instance;
  })();

  public static getSLSimulationResult = async (
    {
      options,
    }: {
      options: IDexSLSimulationOptions;
    },
    { cancelToken }: { cancelToken?: CancelToken | undefined },
  ) => {
    try {
      const response = await ApiDexSLBot.instance.post<IBaseResponse<IDexSLSimulatorResponse>>(
        '/simulations/wtbot/random',
        options,
        { cancelToken },
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IDexSLSimulatorResponse>({ error });
    }
  };

  public static getSLPresets = async () => {
    try {
      const urlParams = new URLSearchParams();

      urlParams.append('limit', '10');
      urlParams.append('offset', '0');

      const response = await ApiDexSLBot.instance.get<IBaseResponse<IDexSLGetPresetsResponse>>(
        '/wtpresets' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IDexSLGetPresetsResponse>({ error });
    }
  };

  public static getSLPreset = async (id: number) => {
    try {
      const response = await ApiDexSLBot.instance.get<IBaseResponse<IDexSLGetPresetResponse>>(
        `/wtpresets/${id}`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IDexSLGetPresetResponse>({ error });
    }
  };

  public static updateSLPreset = async ({
    options,
    id,
  }: {
    options: IPreset & {
      name: string;
      send_private_transactions: boolean;
      reserve_wallets_priority: 'default' | 'random';
      slippage_percent: string;
    };
    id: number;
  }) => {
    try {
      const response = await ApiDexSLBot.instance.patch<IBaseResponse<IDexSLGetPresetResponse>>(
        `/wtpresets/${id}`,
        {
          name: options.name,
          options,
        },
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IDexSLGetPresetResponse>({ error });
    }
  };

  public static deleteSLPreset = async (id: number) => {
    try {
      const response = await ApiDexSLBot.instance.delete<IBaseResponse<any>>(`/wtpresets/${id}`);

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static createSLPreset = async ({
    name,
    options,
  }: {
    name: string;
    options: IPreset & {
      send_private_transactions: boolean;
      reserve_wallets_priority: 'default' | 'random';
      slippage_percent: string;
    };
  }) => {
    try {
      const response = await ApiDexSLBot.instance.post<IBaseResponse<any>>(`/wtpresets`, {
        name: name,
        options,
      });

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };
}

export { ApiDexSLBot };
