import React, { useMemo } from 'react';

import { web3Configs, ENetwork } from 'web3';

import './style.scss';

interface INetworkIconProps {
  network: ENetwork;
}

const NetworkIcon: React.FC<INetworkIconProps> = ({ network }) => {
  const config = useMemo(() => web3Configs[network], [network]);

  return <img className="mm-trading-pair-network-icon" alt={config.label} src={config.logo} />;
};

export default NetworkIcon;
