import { ENetwork } from 'web3';
import { ITransfer } from './ITransfer';
import { ETransactionAction } from './eTransactionAction';

export enum ETransactionsStatuses {
  Pending = 'pending',
  Processing = 'processing',
  Successful = 'successful',
  Failed = 'failed',
  Error = 'error',
  Canceled = 'canceled',
}

export interface ITransactionItem {
  action: ETransactionAction;
  block_number: number;
  block_timestamp: number;
  created_at: string;
  hash: string;
  id: number;
  network: ENetwork;
  pair_id: number;
  project_id: number;
  reserved_wallet_id: number;
  status: ETransactionsStatuses;
  transfers?: ITransfer[] | undefined;
  updated_at: string;
  user?: {
    id: number;
    login: string;
  };
  user_id: number;
}
