import React, {
  createContext,
  useCallback,
  useState,
  SetStateAction,
  Dispatch,
  useMemo,
  useContext,
} from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTypedSelector } from 'store';
import { Time } from 'lightweight-charts';

import { getPeriodToAPI } from 'utils/charts';
import { IChartRange } from 'types/charts';
import { ICexAccount } from 'types/accounts';
import { IPnl } from 'types/pnl';
import { ApiStatistic, ApiCharts } from 'api';
import { CexPairContext } from 'context/CexPairContext';
import { ChartsControlsContext } from './ChartsControlsContext';

interface INewMarketStatistics {
  our_volume_base: string;
  our_volume_quote: string;
  ourTradesCount: string;
  avg_base_trade_size: string;
  avg_quote_trade_size: string;
  total_volume_base: string;
  total_volume_quote: string;
  total_base_income: string;
  total_quote_income: string;
  uptime: string;
  balance_pnl: IPnl;
  ending_balance_delta: {
    base: {
      amount: string;
      amount_usd: string;
      coin: string;
    };
    quote: {
      amount: string;
      amount_usd: string;
      coin: string;
    };
  };
  starting_balance_delta: {
    base: {
      amount: string;
      amount_usd: string;
      coin: string;
    };
    quote: {
      amount: string;
      amount_usd: string;
      coin: string;
    };
  };
}

const INITIAL_MARKET_STATISTICS: INewMarketStatistics = {
  our_volume_base: '',
  our_volume_quote: '',
  ourTradesCount: '',
  avg_base_trade_size: '',
  avg_quote_trade_size: '',
  total_volume_base: '',
  total_volume_quote: '',
  total_base_income: '',
  total_quote_income: '',
  uptime: '',
  balance_pnl: {
    net_pnl: '',
    net_pnl_percentage: '',
    realised_pnl: '',
    realised_pnl_percentage: '',
    unrealised_pnl: '',
    unrealised_pnl_percentage: '',
  },
  ending_balance_delta: {
    base: {
      amount: '',
      amount_usd: '',
      coin: '',
    },
    quote: {
      amount: '',
      amount_usd: '',
      coin: '',
    },
  },
  starting_balance_delta: {
    base: {
      amount: '',
      amount_usd: '',
      coin: '',
    },
    quote: {
      amount: '',
      amount_usd: '',
      coin: '',
    },
  },
};

interface IAdditionalContext {
  period: { get: IChartRange | undefined; set: Dispatch<SetStateAction<IChartRange | undefined>> };
  startDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  endDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  loading: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
  selectedAccounts: {
    get: ICexAccount[];
    set: Dispatch<SetStateAction<ICexAccount[]>>;
  };
  balanceSelectedAccounts: {
    get: ICexAccount[];
    set: Dispatch<SetStateAction<ICexAccount[]>>;
  };
  marketStatistics: INewMarketStatistics;
  liquidityPercent: { get: number; set: Dispatch<SetStateAction<number>> };
  liquidityChartLoading: boolean;
  spreadChartPoints: { time: Time; value: number }[];
  liquidityChartPoints: { time: Time; value_in_base: number; value_in_quote: number }[];
  volumeChartPoints: { time: Time; base: number; quote: number }[];
  feeChartPoints: { time: Time; value: number }[];
  deltaVolumesBuyPoints: { time: Time; base_buy: number; quote_buy: number }[];
  deltaVolumesSellPoints: { time: Time; base_sell: number; quote_sell: number }[];
  getLiquidityChart: () => Promise<any>;
  selectedToken: {
    get: 'base' | 'quote';
    set: Dispatch<SetStateAction<'base' | 'quote'>>;
  };
  selectedTokenSymbol: string;
}

export const AdditionalContext = createContext<IAdditionalContext>({
  period: { get: 'ALL', set: () => {} },
  startDate: { get: 0, set: () => {} },
  endDate: { get: 0, set: () => {} },
  loading: { get: false, set: () => {} },
  selectedAccounts: { get: [], set: () => {} },
  balanceSelectedAccounts: {
    get: [],
    set: () => {},
  },
  marketStatistics: INITIAL_MARKET_STATISTICS,
  liquidityPercent: { get: 20, set: () => {} },
  liquidityChartLoading: false,
  spreadChartPoints: [],
  liquidityChartPoints: [],
  volumeChartPoints: [],
  feeChartPoints: [],
  deltaVolumesBuyPoints: [],
  deltaVolumesSellPoints: [],
  getLiquidityChart: async () => {},
  selectedToken: { get: 'base', set: () => {} },
  selectedTokenSymbol: '',
});

interface IAdditionalContextProviderProps {
  children?: React.ReactNode;
}

export const AdditionalContextProvider: React.FC<IAdditionalContextProviderProps> = ({
  children,
}) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const pairId = cexPair.id;
  const { accountsToAPI } = useContext(ChartsControlsContext);

  const [loading, setLoading] = useState<boolean>(false);

  const [period, setPeriod] = useState<IChartRange | undefined>('1D');
  const [startDate, setStartDate] = useState<number | undefined>(undefined);
  const [endDate, setEndDate] = useState<number | undefined>(undefined);
  const [liquidityPercent, setLiquidityPercent] = useState<number>(2);
  const [liquidityChartLoading, setLiquidityChartLoading] = useState<boolean>(false);

  const { accounts } = useContext(CexPairContext);
  const [selectedAccounts, setSelectedAccounts] = useState<ICexAccount[]>([]);
  const [selectedToken, setSelectedToken] = useState<'quote' | 'base'>('quote');
  const [balanceSelectedAccounts, setBalanceSelectedAccounts] = useState<ICexAccount[]>([]);
  const [marketStatistics, setMarketStatistics] =
    useState<INewMarketStatistics>(INITIAL_MARKET_STATISTICS);

  const [spreadChartPoints, setSpreadChartPoints] = useState<{ time: Time; value: number }[]>([]);
  const [liquidityChartPoints, setLiquidityChartPoints] = useState<
    { time: Time; value_in_base: number; value_in_quote: number }[]
  >([]);
  const [volumeChartPoints, setVolumeChartPoints] = useState<
    { time: Time; base: number; quote: number }[]
  >([]);
  const [feeChartPoints, setFeeChartPoints] = useState<{ time: Time; value: number }[]>([]);
  const [deltaVolumesBuyPoints, setDeltaVolumesBuyPoints] = useState<
    { time: Time; base_buy: number; quote_buy: number }[]
  >([]);
  const [deltaVolumesSellPoints, setDeltaVolumesSellPoints] = useState<
    { time: Time; base_sell: number; quote_sell: number }[]
  >([]);

  const periodToAPI = useMemo(() => getPeriodToAPI(period), [period]);

  const selectedTokenSymbol = useMemo(() => {
    return selectedToken === 'base' ? cexPair.token_base.symbol : cexPair.token_quote.symbol;
  }, [selectedToken, cexPair]);

  const getMarketOverview = useCallback(async () => {
    try {
      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;
      const period = periodToAPI;
      const account_id = accountsToAPI(selectedAccounts).optional;

      const [
        { data: tickerData },
        { data: totalVolumeData },
        { data: cexAvgOrderSizeData },
        { data: cexOurTradesCountData },
        { data: cexOurTradesTotalVolumeData },
        { data: cexOurIncome },
        { data: cexUptimeData },
      ] = await Promise.all([
        ApiStatistic.getCexTickerInfo({
          symbol,
          cex,
        }),
        ApiStatistic.geCexMarketTotalVolume({ symbol, cex, period, startDate, endDate }),
        isAdmin
          ? ApiStatistic.getCexAvgOrderSize({
              pairId,
              period,
              startDate,
              endDate,
              account_id,
            })
          : { data: undefined },
        isAdmin
          ? ApiStatistic.getCexOurTradesCount({
              pairId,
              period,
              startDate,
              endDate,
              account_id,
            })
          : { data: undefined },
        isAdmin
          ? ApiStatistic.getCexOurTradesTotalVolume({
              pairId,
              period,
              startDate,
              endDate,
              account_id,
            })
          : { data: undefined },
        isAdmin
          ? ApiStatistic.getCexOurIncome({ pairId, account_id, period, startDate, endDate })
          : { data: undefined },
        isAdmin ? ApiStatistic.getCexUptime({ pairId }) : { data: undefined },
      ]);

      setMarketStatistics(v => ({
        ...v,
        last_price: tickerData?.last_price ?? '',
        total_volume_base: totalVolumeData?.total_volume_base ?? '',
        total_volume_quote: totalVolumeData?.total_volume_quote ?? '',
        avg_base_trade_size: cexAvgOrderSizeData?.base ?? '',
        avg_quote_trade_size: cexAvgOrderSizeData?.quote ?? '',
        ourTradesCount: cexOurTradesCountData?.count ?? '',
        our_volume_base: cexOurTradesTotalVolumeData?.total ?? '',
        our_volume_quote: cexOurTradesTotalVolumeData?.total_quote ?? '',
        total_base_income: cexOurIncome?.income.base ?? '',
        total_quote_income: cexOurIncome?.income.quote ?? '',
        uptime: cexUptimeData?.uptime ?? '',
      }));
    } catch (error) {
      console.log('error: ', error);
    }
  }, [cexPair, startDate, endDate, periodToAPI, pairId, isAdmin, selectedAccounts, accountsToAPI]);

  const getLiquidityChart = useCallback(async () => {
    try {
      setLiquidityChartLoading(true);

      const period = periodToAPI;
      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;

      const { data } = await ApiCharts.getCexStatisticsChartLiquidity({
        cex,
        symbol,
        period,
        startDate,
        endDate,
        percentage: liquidityPercent,
      });

      setLiquidityChartPoints(
        data?.points.map(el => ({
          time: (el.time / 1000) as Time,
          value_in_base: Number(el.value_in_base),
          value_in_quote: Number(el.value_in_quote),
        })) ?? [],
      );
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLiquidityChartLoading(false);
    }

    return {};
  }, [cexPair, endDate, startDate, liquidityPercent, periodToAPI]);

  const getCharts = useCallback(async () => {
    try {
      const period = periodToAPI;
      const account_id = accountsToAPI(selectedAccounts).optional;
      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;

      const [
        { data: cexStatisticsChartSpread },
        { data: cexStatisticsChartFeeData },
        { data: cexStatisticsChartDelta },
        { data: cexStatisticsChartVolume },
        {},
      ] = await Promise.all([
        ApiCharts.getCexStatisticsChartSpread({
          cex,
          symbol,
          period,
          startDate,
          endDate,
        }),
        isAdmin
          ? ApiCharts.getCexStatisticsChartFee({
              pairId,
              account_id,
              period,
              startDate,
              endDate,
            })
          : { data: undefined },
        isAdmin
          ? ApiCharts.getCexStatisticsChartDelta({
              pairId,
              account_id,
              period,
              startDate,
              endDate,
            })
          : { data: undefined },
        isAdmin
          ? ApiCharts.getCexStatisticsChartVolume({
              pairId,
              account_id,
              period,
              startDate,
              endDate,
            })
          : { data: undefined },
        getLiquidityChart(),
      ]);

      setSpreadChartPoints(
        cexStatisticsChartSpread?.points.map(el => ({
          time: (el.time / 1000) as Time,
          value: Number(el.value),
        })) ?? [],
      );

      if (isAdmin) {
        setVolumeChartPoints(
          cexStatisticsChartVolume?.points.map(el => ({
            time: (el.time / 1000) as Time,
            base: Number(el.base),
            quote: Number(el.quote),
          })) ?? [],
        );

        setFeeChartPoints(
          cexStatisticsChartFeeData?.points.map(el => ({
            time: (el.time / 1000) as Time,
            value: Number(el.value),
          })) ?? [],
        );

        setDeltaVolumesBuyPoints(
          cexStatisticsChartDelta?.points.map(el => ({
            time: (el.time / 1000) as Time,
            base_buy: Number(el.buy_value_base),
            quote_buy: Number(el.buy_value_quote),
          })) ?? [],
        );
        setDeltaVolumesSellPoints(
          cexStatisticsChartDelta?.points.map(el => ({
            time: (el.time / 1000) as Time,
            base_sell: -Number(el.sell_value_base),
            quote_sell: -Number(el.sell_value_quote),
          })) ?? [],
        );
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }, [
    accountsToAPI,
    endDate,
    pairId,
    periodToAPI,
    startDate,
    cexPair,
    isAdmin,
    getLiquidityChart,
    selectedAccounts,
  ]);

  const getBalances = useCallback(async () => {
    try {
      if (accounts.length === 0) {
        return;
      }

      const account_id = accountsToAPI(selectedAccounts).array;

      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;
      const period = periodToAPI;

      const [{ data: cexBalancesDeltaData }, { data: cexBalancesPnl }] = await Promise.all([
        ApiStatistic.geCexBalancesDelta({ symbol, cex, account_id, period, startDate, endDate }),
        ApiStatistic.getBalancePnl({
          account_id,
          symbol,
          cex,
          pairId,
          period: period ?? '1D',
          endDate,
          startDate,
        }),
      ]);

      setMarketStatistics(v => ({
        ...v,
        ...(cexBalancesDeltaData?.starting_balance
          ? { starting_balance_delta: cexBalancesDeltaData?.starting_balance }
          : {}),
        ...(cexBalancesDeltaData?.ending_balance
          ? { ending_balance_delta: cexBalancesDeltaData?.ending_balance }
          : {}),
        balance_pnl: cexBalancesPnl ?? ({} as IPnl),
      }));
    } catch (error) {
      console.log('error: ', error);
    }
  }, [pairId, periodToAPI, cexPair, startDate, endDate, accounts, selectedAccounts, accountsToAPI]);

  const getInfo = useCallback(async () => {
    try {
      setLoading(true);

      await Promise.all([getMarketOverview(), getCharts()]);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }, [getCharts, getMarketOverview]);

  useDeepCompareEffect(() => {
    getInfo();
    getBalances();

    //eslint-disable-next-line
  }, [cexPair, accountsToAPI(selectedAccounts).optional, startDate, endDate, periodToAPI]);

  return (
    <AdditionalContext.Provider
      value={{
        period: { get: period, set: setPeriod },
        startDate: { get: startDate, set: setStartDate },
        endDate: { get: endDate, set: setEndDate },
        loading: { get: loading, set: setLoading },
        selectedAccounts: { get: selectedAccounts, set: setSelectedAccounts },
        balanceSelectedAccounts: { get: balanceSelectedAccounts, set: setBalanceSelectedAccounts },
        marketStatistics,
        liquidityPercent: { get: liquidityPercent, set: setLiquidityPercent },
        liquidityChartLoading,
        spreadChartPoints,
        liquidityChartPoints,
        volumeChartPoints,
        feeChartPoints,
        deltaVolumesBuyPoints,
        deltaVolumesSellPoints,
        getLiquidityChart,
        selectedToken: { get: selectedToken, set: setSelectedToken },
        selectedTokenSymbol,
      }}
    >
      {children}
    </AdditionalContext.Provider>
  );
};
