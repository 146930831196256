import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTypedSelector } from 'store';

import { ROUTE_PATHS } from 'constant/routes';

import './style.scss';

const PageNotFound: React.FC = () => {
  const userRole = useTypedSelector(store => store.auth.user?.role);

  const backHref = useMemo(() => {
    if (userRole === 'admin') {
      return ROUTE_PATHS.wallets;
    } else {
      return '/';
    }
  }, [userRole]);

  return (
    <div className="mm-page-not-found">
      <span className="error-code">404</span>
      <span className="info">Page not found...</span>
      <NavLink to={backHref} className="redirect-link">
        Back to app
      </NavLink>
    </div>
  );
};

export { PageNotFound };
