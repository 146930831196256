import { tailwindConfig } from 'tailwind-config';

export const fieldLabelBase = {
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '16px',
  letterSpacing: '-0.078px',
};

export const fieldLabelFocused = (error: boolean) => ({
  ...fieldLabelBase,
  color: error ? tailwindConfig.theme.colors['red-1'] : tailwindConfig.theme.colors.primary,
  top: -16,
  left: 0,
  transform: 'translateY(0%)',
});

export const fieldLabelNotFocusedFilled = (error: boolean) => ({
  ...fieldLabelBase,
  color: error ? tailwindConfig.theme.colors['red-1'] : tailwindConfig.theme.colors['gray-1'],
  top: -16,
  left: 0,
  transform: 'translateY(0%)',
});

export const fieldLabelNotFocusedNotFilled = {
  ...fieldLabelBase,
  fontSize: '16px',
  lineHeight: '20px',
  color: tailwindConfig.theme.colors['gray-1'],
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
};
