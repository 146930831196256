import React, { memo } from 'react';
import { Row } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';

import { CellComponent, CellComponentMemo } from '../CellComponent';
import { AnimatePresence } from 'framer-motion';

interface IRowComponentProps<TableRowRawData extends { id: number }> {
  isChecked?: boolean;
  virtualRow: VirtualItem<Element>;
  row: Row<TableRowRawData>;
  extendedRowId?: number | null;
  extendedRowContent?: React.ReactNode;
  className?: string;
}

const RowComponent = <T extends { id: number }>({
  virtualRow,
  row,
  extendedRowId,
  extendedRowContent,
  className,
}: IRowComponentProps<T>) => {
  const rowIsExtended = row.original.id === extendedRowId;

  return (
    <React.Fragment key={virtualRow.index}>
      <tr
        style={{
          height: `${virtualRow.size}px`,
        }}
        className={className ? className : Number(row.id) % 2 === 0 ? 'tr-colorized' : undefined}
      >
        {row ? (
          row.getVisibleCells().map((cell, idx) => {
            return idx === 0 ? (
              <CellComponent
                key={cell.id}
                columnDef={cell.column.columnDef.cell}
                width={cell.column.getSize()}
                height={virtualRow.size}
                context={cell.getContext()}
              />
            ) : (
              <CellComponentMemo
                key={cell.id}
                columnDef={cell.column.columnDef.cell}
                width={cell.column.getSize()}
                height={virtualRow.size}
                context={cell.getContext()}
              />
            );
          })
        ) : (
          <td style={{ height: `${virtualRow.size}px` }}>-</td>
        )}
      </tr>
      <AnimatePresence>{rowIsExtended && <>{extendedRowContent}</>}</AnimatePresence>
    </React.Fragment>
  );
};

const RowComponentMemo = memo(RowComponent) as typeof RowComponent;

export { RowComponentMemo as RowComponent };
