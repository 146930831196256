import React, { useCallback, useEffect, useRef } from 'react';
import jwtDecode from 'jwt-decode';
import { useTypedDispatch, useTypedSelector } from 'store';

import { setAlertState, dropAlertState } from 'store/slices/ui';
import { logout } from 'store/slices/auth';
import { Button } from 'ui';
import { JWT } from 'types/auth';

const SessionLogoutModal: React.FC = () => {
  const dispatch = useTypedDispatch();
  const jwt = useTypedSelector(store => store.auth.jwt);

  const logoutTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const handleLogout = useCallback(() => {
    dispatch(dropAlertState());
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (jwt) {
      try {
        // milliseconds
        const expiredTime = jwtDecode<JWT>(jwt).exp * 1000;
        const timeoutTime = expiredTime - Date.now();

        if (timeoutTime <= 0) {
          dispatch(
            setAlertState({
              type: 'sure',
              title: 'Session expired',
              text: 'Your session have been expired. You should relogin to Market Maker to continue',
              buttons: <Button onClick={handleLogout}>Relogin</Button>,
            }),
          );
          return;
        }

        logoutTimeoutRef.current = setTimeout(
          () => {
            dispatch(
              setAlertState({
                type: 'sure',
                title: 'Session expired',
                text: 'Your session have been expired. You should relogin to Market Maker to continue',
                buttons: <Button onClick={handleLogout}>Relogin</Button>,
              }),
            );
          },
          timeoutTime > 2000000000 ? 2000000000 : timeoutTime,
        );
      } catch (error) {
        console.log(error);
      }
    }

    return () => {
      clearTimeout(logoutTimeoutRef.current);
    };
  }, [jwt, dispatch, handleLogout]);

  return <></>;
};

export { SessionLogoutModal };
