import React, { useCallback, useContext } from 'react';

import { Button } from 'ui';
import { CexOrderbookGridTaskModal } from 'modals';
import { CexPairContext } from 'context/CexPairContext';

import './style.scss';

const Toolbar: React.FC = () => {
  const {
    orderbook: { createUpdateTask, taskModalOpened, setTaskModalOpened, sortedTasks },
  } = useContext(CexPairContext);

  const handleOpenCreateTask = useCallback(() => {
    setTaskModalOpened(true);
  }, [setTaskModalOpened]);

  return (
    <>
      <div className="mm-cex-buy-sell-bot-toolbar">
        <Button color="transparent" onClick={handleOpenCreateTask}>
          + Create new task
        </Button>
      </div>
      {taskModalOpened === true && (
        <CexOrderbookGridTaskModal
          mode="create"
          onClose={() => setTaskModalOpened(false)}
          onSubmit={createUpdateTask}
          id={(sortedTasks[0]?.id ?? 0) + 1}
        />
      )}
    </>
  );
};

export { Toolbar };
