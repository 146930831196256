import React, { lazy, useCallback, useState } from 'react';
import { useTypedSelector } from 'store';

import { Panel, AutoRefresh } from 'ui';
import { IPanelProps } from 'types/pairs';
import { PAIR_WALLETS_RELOAD } from 'constant/reload';

import { RoundChartIcon } from 'assets/icons';

const PanelContent = lazy(() => import('./Content'));

const Wallets: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(initialOpened ?? false);

  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);
    },
    [setIsOpened],
  );

  return (
    <Panel
      title="Wallets"
      icon={RoundChartIcon}
      initialOpened={initialOpened}
      onChange={handleSwitch}
      headerNode={
        !!isPanelOpened && (
          <div className="w-full flex flex-row items-center justify-end">
            <AutoRefresh
              busEvent={PAIR_WALLETS_RELOAD}
              tooltipText="Auto refresh visible wallets"
              pairId={pair.id}
              cacheName="wallets"
            />
          </div>
        )
      }
    >
      <PanelContent />
    </Panel>
  );
};

export { Wallets };
