import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const AccountsIcon: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors['gray-1'],
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <div>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={handleClick}
      >
        <g clipPath="url(#clip0_4277_36216)">
          <path
            d="M12.0013 7.16406C16.0513 7.16406 19.3346 8.95473 19.3346 11.1641V13.8307C19.3346 16.0401 16.0513 17.8307 12.0013 17.8307C8.0233 17.8307 4.7853 16.1034 4.6713 13.9487L4.66797 13.8307V11.1641C4.66797 8.95473 7.9513 7.16406 12.0013 7.16406ZM12.0013 15.1641C9.5213 15.1641 7.32797 14.4927 6.0013 13.4641V13.8307C6.0013 15.0854 8.58997 16.4974 12.0013 16.4974C15.3413 16.4974 17.8933 15.1441 17.998 13.9094L18.0013 13.8307L18.002 13.4641C16.6753 14.4921 14.482 15.1641 12.0013 15.1641ZM12.0013 8.4974C8.58997 8.4974 6.0013 9.9094 6.0013 11.1641C6.0013 12.4187 8.58997 13.8307 12.0013 13.8307C15.4126 13.8307 18.0013 12.4187 18.0013 11.1641C18.0013 9.9094 15.4126 8.4974 12.0013 8.4974Z"
            fill={color}
          />
        </g>
        <path
          d="M9 22.5H15C20 22.5 22 20.5 22 15.5V9.5C22 4.5 20 2.5 15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={color}
        />
        <defs>
          <clipPath id="clip0_4277_36216">
            <rect width="16" height="16" fill="white" transform="translate(4 4.5)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export { AccountsIcon };
