import { useCallback } from 'react';
import { useTypedDispatch } from 'store';

import { ApiBot } from 'api';
import { EDexBot, IDexBotConfig } from 'types/bots';
import { setAlertState, dropAlertState } from 'store/slices/ui';

const useDexBotConfig = () => {
  const dispatch = useTypedDispatch();

  const getBotConfig = useCallback(async function <V extends EDexBot>({
    pairId,
    bot,
  }: {
    pairId: number;
    bot: V;
  }) {
    const DEFAULT_BOT_CONFIG = {
      is_enabled: false,
      send_private_transactions: false,
      slippage_percent: '0.3',
      reserve_wallets_priority: 'default' as const,
    };

    try {
      const { isSuccess, errorCode, data } = await ApiBot.getDexBotConfig({
        pairId: pairId,
        bot: bot,
      });

      if (isSuccess && data) return data;

      //requested bot not connected to the pair
      if (!isSuccess && errorCode === 14002) {
        await ApiBot.saveDexBotConfig({
          pairId: pairId,
          bot: bot,
          body: DEFAULT_BOT_CONFIG,
        });
      }

      return DEFAULT_BOT_CONFIG as IDexBotConfig<V>;
    } catch (error) {
      console.log('error: ', error);
      return DEFAULT_BOT_CONFIG as IDexBotConfig<V>;
    }
  },
  []);

  const updateBotConfig = useCallback(
    async function <V extends EDexBot>({
      pairId,
      bot,
      body,
      onSuccess = () => {},
      onError = () => {},
    }: {
      pairId: number;
      bot: V;
      body: IDexBotConfig<V>;
      onSuccess?: () => void;
      onError?: () => void;
    }) {
      try {
        const { isSuccess, errorMessage } = await ApiBot.saveDexBotConfig({
          pairId: pairId,
          bot: bot,
          body: body,
        });

        if (!isSuccess) {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
          onError();
          return;
        }

        if (isSuccess) {
          onSuccess();
        }
      } catch (error) {
        console.log('error: ', error);
        onError();
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    },
    [dispatch],
  );

  return { getBotConfig, updateBotConfig };
};

export { useDexBotConfig };
