import { IColorsTheme } from 'types/theme';

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const applyTheme = (theme: IColorsTheme) => {
  const bodyElement = document.body;

  const objectKeys = Object.keys(theme);

  for (const key of objectKeys) {
    const cssKey = '--' + key.replaceAll('_', '-');
    const valueHex = theme[key as keyof IColorsTheme];
    const valueRgb = hexToRgb(valueHex);

    if (valueRgb) {
      const { r, g, b } = valueRgb;

      bodyElement.style.setProperty(cssKey, `${r}, ${g}, ${b}`);
    } else {
      bodyElement.style.setProperty(cssKey, valueHex);
    }
  }

  return theme;
};
