import { useCallback } from 'react';
import { useTypedSelector } from 'store';

import { ApiCharts } from 'api';
import { IChartTimeframe } from 'types/charts';

const useLoadCexCharts = () => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const load = useCallback(
    async ({
      startTime,
      endTime,
      timeframe,
      account_id,
    }: {
      startTime: number;
      endTime: number;
      timeframe: IChartTimeframe;
      account_id: number[];
    }) => {
      if (!cexPair) return { points: [] };

      const { isSuccess, data: _points } = await ApiCharts.getCexCharts({
        symbol: `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`,
        cex: cexPair.cex,
        period: timeframe,
        from: startTime,
        to: endTime,
        account_id,
        pair_id: cexPair.id,
      });

      const points = _points?.points ?? [];

      return { points: isSuccess ? points : [] };
    },
    [cexPair],
  );

  return load;
};

export { useLoadCexCharts };
