import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const SelectArrowSmall: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors['gray-1'],
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      className={className ?? undefined}
    >
      <path
        d="M10.0303 5.03033C10.3232 4.73744 10.3232 4.26256 10.0303 3.96967C9.73744 3.67678 9.26256 3.67678 8.96967 3.96967L10.0303 5.03033ZM6 8L5.46967 8.53033C5.61032 8.67098 5.80109 8.75 6 8.75C6.19891 8.75 6.38968 8.67098 6.53033 8.53033L6 8ZM3.03033 3.96967C2.73744 3.67678 2.26256 3.67678 1.96967 3.96967C1.67678 4.26256 1.67678 4.73744 1.96967 5.03033L3.03033 3.96967ZM8.96967 3.96967L5.46967 7.46967L6.53033 8.53033L10.0303 5.03033L8.96967 3.96967ZM6.53033 7.46967L3.03033 3.96967L1.96967 5.03033L5.46967 8.53033L6.53033 7.46967Z"
        fill={color}
      />
    </svg>
  );
};

export { SelectArrowSmall };
