import { useState, useEffect, useRef } from 'react';

const useResizeObserver = () => {
  const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const elementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setSize({ width, height });
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return [elementRef, size] as const;
};

export { useResizeObserver };
