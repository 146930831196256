import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const CogIcon: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors['gray-1'],
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      className={className ?? undefined}
    >
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1334 13.185C19.4158 13.3275 19.6337 13.5525 19.7869 13.7775C20.0855 14.2425 20.0613 14.8125 19.7708 15.315L19.206 16.215C18.9075 16.695 18.3508 16.995 17.778 16.995C17.4956 16.995 17.181 16.92 16.9228 16.77C16.713 16.6425 16.471 16.5975 16.2128 16.5975C15.414 16.5975 14.7444 17.22 14.7202 17.9625C14.7202 18.825 13.9779 19.5 13.0501 19.5H11.9528C11.0169 19.5 10.2747 18.825 10.2747 17.9625C10.2585 17.22 9.58889 16.5975 8.79015 16.5975C8.5239 16.5975 8.28186 16.6425 8.08015 16.77C7.82198 16.92 7.49925 16.995 7.22494 16.995C6.64404 16.995 6.08734 16.695 5.78882 16.215L5.23212 15.315C4.9336 14.8275 4.91747 14.2425 5.21598 13.7775C5.34507 13.5525 5.58712 13.3275 5.86143 13.185C6.08734 13.08 6.23256 12.9075 6.36972 12.705C6.77313 12.06 6.53108 11.2125 5.8453 10.83C5.04655 10.4025 4.78838 9.45 5.24826 8.7075L5.78882 7.8225C6.25677 7.08 7.25721 6.8175 8.06402 7.2525C8.76594 7.6125 9.67764 7.3725 10.0891 6.735C10.2182 6.525 10.2908 6.3 10.2747 6.075C10.2585 5.7825 10.3473 5.505 10.5006 5.28C10.7991 4.815 11.3397 4.515 11.9286 4.5H13.0662C13.6633 4.5 14.2038 4.815 14.5023 5.28C14.6476 5.505 14.7444 5.7825 14.7202 6.075C14.7041 6.3 14.7767 6.525 14.9058 6.735C15.3172 7.3725 16.2289 7.6125 16.9389 7.2525C17.7377 6.8175 18.7462 7.08 19.206 7.8225L19.7466 8.7075C20.2146 9.45 19.9564 10.4025 19.1496 10.83C18.4638 11.2125 18.2217 12.06 18.6332 12.705C18.7623 12.9075 18.9075 13.08 19.1334 13.185ZM10.2201 12.0089C10.2201 13.1864 11.2447 14.1239 12.5114 14.1239C13.7781 14.1239 14.7786 13.1864 14.7786 12.0089C14.7786 10.8314 13.7781 9.88644 12.5114 9.88644C11.2447 9.88644 10.2201 10.8314 10.2201 12.0089Z"
          fill={color}
          stroke={color}
        />
      </g>
    </svg>
  );
};

export { CogIcon };
