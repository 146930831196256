import React from 'react';

import { ICexOrderbookGridBotTask } from 'types/bots';
import { bn, humanizeBn } from 'tools/math';

interface IProps {
  task: ICexOrderbookGridBotTask;
}

const StartPrice = React.memo(({ task }: IProps) => {
  return <div>{task.starting_price === '0' ? '-' : humanizeBn(bn(task.starting_price), 18)}</div>;
});

export { StartPrice };
