import React from 'react';

import { Tooltip } from 'ui';
import { formatToken } from 'utils/formats';
import { ICexOrderbookGridBotTaskExtended } from 'types/bots';
import { ICexPair } from 'types/pairs';

interface IProps {
  task: ICexOrderbookGridBotTaskExtended;
  cexPair: ICexPair;
}

const Account = React.memo(({ task, cexPair }: IProps) => {
  const { account, avaliableBase, avaliableQuote, lackBase, lackQuote, neededBase, neededQuote } =
    task;

  if (!account) return '-';

  return (
    <div className="account-cell">
      <span>{account.notes}</span>
      <div className="account-balances">
        {lackBase ? (
          <Tooltip
            trigger={
              <span className="warning">
                {formatToken(avaliableBase, 18)} {cexPair.token_base.symbol}
              </span>
            }
            options={{ offset: 10, placement: 'top' }}
            content={`Needed: ${formatToken(neededBase, 18)} ${cexPair.token_base.symbol}`}
          />
        ) : (
          <span>
            {formatToken(avaliableBase, 18)} {cexPair.token_base.symbol}
          </span>
        )}
        {lackQuote ? (
          <Tooltip
            trigger={
              <span className="warning">
                {formatToken(avaliableQuote, 18)} {cexPair.token_quote.symbol}
              </span>
            }
            options={{ offset: 10, placement: 'top' }}
            content={`Needed: ${formatToken(neededQuote, 18)} ${cexPair.token_quote.symbol}`}
          />
        ) : (
          <span>
            {formatToken(avaliableQuote, 18)} {cexPair.token_quote.symbol}
          </span>
        )}
      </div>
    </div>
  );
});

export { Account };
