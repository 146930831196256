import React, { useRef, useEffect, memo } from 'react';
import cn from 'classnames';
import * as echarts from 'echarts';

import { useResizeObserver } from 'hooks/utility';
import { CHARTS_COLORS } from 'constant/charts';

import './pieDiagram.scss';

type PieDiagramData = {
  value: number;
  name: string;
  unit?: string;
};

type IPieDiagramProps = {
  data: PieDiagramData[];
  convertValue?: (v: number) => string;
  className?: string;
};

const PieDiagram: React.FC<IPieDiagramProps> = ({ data, convertValue, className, ...rest }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;
    const pieChart = echarts.init(chartRef.current);

    const filteredData = data.filter(item => item.value !== 0);

    const formattedData = filteredData.map((item, index) => ({
      ...item,
      itemStyle: {
        color: CHARTS_COLORS[index % CHARTS_COLORS.length],
      },
    }));

    const option: echarts.EChartsOption = {
      tooltip: {
        trigger: 'item',
        formatter: params => {
          const { data } = params;

          return `${convertValue ? convertValue(data.value) : data.value} ${data.unit ?? ''}`;
        },
      },
      graphic: {
        elements: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              text: '',
              fontSize: 20,
              fontWeight: 'bold',
              fill: '#FFF',
            },
            id: 'center-text',
          },
        ],
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: 'outside',
            formatter: params => {
              const maxLineLength = 10;
              const words = params.name.split(' ');
              let formattedText = '';
              let currentLine = '';

              words.forEach(word => {
                if ((currentLine + word).length <= maxLineLength) {
                  currentLine += word + ' ';
                } else {
                  formattedText += currentLine.trim() + '\n';
                  currentLine = word + ' ';
                }
              });

              formattedText += currentLine.trim();
              return `{circle${params.dataIndex}|●} ${formattedText}`;
            },
            fontSize: 10,
            fontWeight: 500,
            color: '#FAFAFA',
            rich: CHARTS_COLORS.reduce((acc, color, index) => {
              acc[`circle${index}`] = {
                color,
                fontSize: 18,
                align: 'center',
              };
              return acc;
            }, {}),
          },
          labelLine: {
            show: true,
            length: 15,
            length2: 20,
            smooth: true,
          },
          emphasis: {
            focus: 'self',
            label: {
              show: true,
            },
            itemStyle: {
              borderWidth: 2,
            },
          },
          data: formattedData,
        },
      ],
    };

    pieChart.setOption(option);

    pieChart.on('mouseover', params => {
      if (params.componentType === 'series') {
        const percent = Number(params.percent);
        const displayPercent = Number.isInteger(percent) ? percent.toString() : percent.toFixed(1);

        pieChart.setOption({
          graphic: {
            elements: [
              {
                id: 'center-text',
                style: {
                  text: `${displayPercent}%`,
                  fill: '#FFF',
                  fontSize: 14,
                },
              },
            ],
          },
        });
      }
    });

    pieChart.on('mouseout', () => {
      pieChart.setOption({
        graphic: {
          elements: [
            {
              id: 'center-text',
              style: {
                text: '',
              },
            },
          ],
        },
      });
    });

    return () => {
      pieChart.dispose();
    };
  }, [data, convertValue]);

  const [resizableRef, sizes] = useResizeObserver();

  useEffect(() => {
    const chart = chartRef.current && echarts.getInstanceByDom(chartRef.current);
    chart?.resize({ width: sizes.width, height: 'auto' });
  }, [sizes]);

  return (
    <div
      ref={node => {
        resizableRef.current = node;
      }}
      className="mm-pie-diagram-container"
    >
      <div
        ref={chartRef}
        className={cn({ [className ?? '']: !!className }, 'mm-pie-diagram')}
        {...rest}
      />
    </div>
  );
};

const memoPieDiagram = memo(PieDiagram);

export { memoPieDiagram as PieDiagram };
