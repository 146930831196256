import React, { useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import { Backdrop } from '../Modal/Backdrop/Backdrop';
import { Button } from '../Button/Button';
import { useTheme } from 'hooks/utility';
import {
  SuccessIcon,
  SuccessImgIcon,
  FailedIcon,
  FailedImgIcon,
  SureIcon,
  SureImgIcon,
} from './icons';
import { CloseIcon } from 'assets/icons';
import { MM_MAIN_ELEMENT_ID } from 'constant/dom';

import './alert.scss';

type IAlertType = 'success' | 'success-img' | 'failed' | 'failed-img' | 'sure' | 'sure-img';

export interface IAlertProps {
  type: IAlertType;
  title?: React.ReactNode;
  onClose?: () => void;
  onSubmit?: () => void;
  text: string;
  buttons?: React.ReactNode;
}

const AlertComponent: React.FC<IAlertProps> = ({
  type,
  onClose,
  onSubmit,
  text,
  title,
  buttons,
}) => {
  const { isDark } = useTheme();

  const Icon: React.FC = useMemo(
    () =>
      ({
        success: SuccessIcon,
        'success-img': SuccessImgIcon,
        failed: FailedIcon,
        'failed-img': FailedImgIcon,
        sure: SureIcon,
        'sure-img': SureImgIcon,
      }[type]),
    [type],
  );

  const titleInner = useMemo(() => {
    if (title) return title;

    return {
      success: 'Success',
      'success-img': 'Success',
      failed: 'Failed',
      'failed-img': 'Failed',
      sure: 'Are you sure?',
      'sure-img': 'Are you sure?',
    }[type];
  }, [type, title]);

  const handleSubmit = useCallback((): void => {
    if (onSubmit) {
      onSubmit();
    }
  }, [onSubmit]);

  useEffect(() => {
    const onCloseModalOnEscapePressed = (event: KeyboardEvent) => {
      if (!onClose) return;

      if (type === 'success' || type === 'success-img') {
        if (event.key === 'Escape' || event.key === 'Enter') {
          onClose();
        }
      }

      if (type === 'failed' || type === 'failed-img') {
        if (event.key === 'Escape') {
          onClose();
        }
        if (event.key === 'Enter') {
          handleSubmit();
        }
      }
    };

    document.addEventListener('keydown', e => onCloseModalOnEscapePressed(e));

    return () => {
      document.removeEventListener('keydown', e => onCloseModalOnEscapePressed(e));
    };
  }, [type, onClose, handleSubmit]);

  return (
    <Backdrop>
      <div className={cn('mm-alert-modal', { _is_dark: isDark })}>
        <div className="mm-alert-modal-content scrollable">
          <div className="mm-alert-modal__header">
            <h3
              className={cn({
                _success: type !== 'failed' && type !== 'failed-img',
                _failed: type === 'failed' || type === 'failed-img',
              })}
            >
              {titleInner}
            </h3>
            {!!onClose && (
              <div className="mm-alert-modal__header__close-button" onClick={onClose}>
                <CloseIcon />
              </div>
            )}
          </div>
          <div className="flex items-center justify-center my-4">
            <Icon />
          </div>
          <span className="mm-alert-modal__text">{text}</span>
          {buttons && buttons}
          {!buttons && (
            <>
              {(type === 'success' || type === 'success-img') && (
                <Button onClick={onClose}>Okay</Button>
              )}
              {(type === 'failed' || type === 'failed-img') && (
                <Button color={'error'} onClick={onSubmit}>
                  Try again
                </Button>
              )}
              {(type === 'sure' || type === 'sure-img') && (
                <div className="mm-alert-modal__sure-actions">
                  {onSubmit && (
                    <Button color={'primary'} onClick={onSubmit}>
                      Confirm
                    </Button>
                  )}
                  {onClose && (
                    <Button color={'transparent'} onClick={onClose}>
                      Cancel
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Backdrop>
  );
};

const AlertPortal: React.FC<IAlertProps> = props => {
  const element = document.getElementById(MM_MAIN_ELEMENT_ID);

  return element
    ? createPortal(
        <AlertComponent {...props} />,
        document.getElementById(MM_MAIN_ELEMENT_ID) as HTMLElement,
      )
    : null;
};

export { AlertPortal as Alert };
