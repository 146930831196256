import { flexRender, Table } from '@tanstack/react-table';
import { memo } from 'react';

interface ITableHeadersProps<TableData> {
  table: Table<TableData>;
  isAllChecked?: boolean;
}

const TableHeaders = <T,>({ table }: ITableHeadersProps<T>) => {
  return (
    <thead>
      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map(header => {
            return (
              <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                {header.isPlaceholder ? null : (
                  <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

const TableHeadersMemo = memo(TableHeaders) as typeof TableHeaders;

export { TableHeadersMemo as TableHeaders };
