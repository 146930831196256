import { memo } from 'react';
import { flexRender } from '@tanstack/react-table';

export const CellComponent = ({
  height,
  width,
  columnDef,
  context,
}: {
  height: number;
  width: number;
  columnDef: any;
  context: any;
}) => {
  return <td style={{ height: `${height}px`, width: width }}>{flexRender(columnDef, context)}</td>;
};

export const CellComponentMemo = memo(
  ({
    height,
    width,
    columnDef,
    context,
  }: {
    height: number;
    width: number;
    columnDef: any;
    context: any;
  }) => {
    return (
      <td style={{ height: `${height}px`, width: width }}>{flexRender(columnDef, context)}</td>
    );
  },
);
