import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const AntiFrontRunBotIcon: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors['gray-1'],
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9138 5H15.864C15.5229 4.99999 15.2284 4.99998 14.9848 5.01661C14.7283 5.03411 14.4717 5.07261 14.2182 5.17762C13.6465 5.41444 13.1922 5.86867 12.9554 6.44041C12.8504 6.69391 12.8119 6.9505 12.7944 7.20704C12.7778 7.45067 12.7778 7.74504 12.7778 8.0862V8.13599C12.7778 8.47715 12.7778 8.77155 12.7944 9.01519C12.8119 9.27172 12.8504 9.52832 12.9554 9.78182C13.1922 10.3535 13.6465 10.8078 14.2182 11.0446C14.4717 11.1496 14.7283 11.1881 14.9848 11.2056C15.2284 11.2222 15.5228 11.2222 15.864 11.2222H15.9138C16.2549 11.2222 16.5493 11.2222 16.793 11.2056C17.0495 11.1881 17.3061 11.1496 17.5596 11.0446C18.1313 10.8078 18.5856 10.3535 18.8224 9.78182C18.9274 9.52832 18.9659 9.27172 18.9834 9.01519C19 8.77154 19 8.47716 19 8.13599V8.08623C19 7.74506 19 7.45068 18.9834 7.20704C18.9659 6.9505 18.9274 6.69391 18.8224 6.44041C18.5856 5.86867 18.1313 5.41444 17.5596 5.17762C17.3061 5.07261 17.0495 5.03411 16.793 5.01661C16.5493 4.99998 16.2549 4.99999 15.9138 5ZM14.8135 6.61476C14.8458 6.60135 14.9134 6.58065 15.0907 6.56855C15.275 6.55598 15.5158 6.55556 15.8889 6.55556C16.2619 6.55556 16.5028 6.55598 16.6871 6.56855C16.8644 6.58065 16.9319 6.60135 16.9643 6.61476C17.1549 6.6937 17.3063 6.84511 17.3852 7.03569C17.3986 7.06806 17.4193 7.13559 17.4314 7.31293C17.444 7.49724 17.4444 7.73807 17.4444 8.11111C17.4444 8.48415 17.444 8.72498 17.4314 8.9093C17.4193 9.08663 17.3986 9.15417 17.3852 9.18653C17.3063 9.37711 17.1549 9.52852 16.9643 9.60746C16.9319 9.62087 16.8644 9.64157 16.6871 9.65367C16.5028 9.66625 16.2619 9.66667 15.8889 9.66667C15.5158 9.66667 15.275 9.66625 15.0907 9.65367C14.9134 9.64157 14.8458 9.62087 14.8135 9.60746C14.6229 9.52852 14.4715 9.37711 14.3925 9.18653C14.3791 9.15417 14.3584 9.08663 14.3463 8.9093C14.3338 8.72498 14.3333 8.48415 14.3333 8.11111C14.3333 7.73807 14.3338 7.49724 14.3463 7.31293C14.3584 7.13559 14.3791 7.06806 14.3925 7.03569C14.4715 6.84511 14.6229 6.6937 14.8135 6.61476Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08624 12.7778H8.13599C8.47715 12.7778 8.77155 12.7778 9.01519 12.7944C9.27172 12.8119 9.52832 12.8504 9.78182 12.9554C10.3535 13.1922 10.8078 13.6465 11.0446 14.2182C11.1496 14.4717 11.1881 14.7283 11.2056 14.9848C11.2222 15.2284 11.2222 15.5228 11.2222 15.864V15.9138C11.2222 16.2549 11.2222 16.5493 11.2056 16.793C11.1881 17.0495 11.1496 17.3061 11.0446 17.5596C10.8078 18.1313 10.3535 18.5856 9.78182 18.8224C9.52832 18.9274 9.27172 18.9659 9.01519 18.9834C8.77154 19 8.47716 19 8.13599 19H8.08623C7.74506 19 7.45068 19 7.20704 18.9834C6.9505 18.9659 6.69391 18.9274 6.44041 18.8224C5.86867 18.5856 5.41444 18.1313 5.17762 17.5596C5.07261 17.3061 5.03411 17.0495 5.01661 16.793C4.99998 16.5493 4.99999 16.255 5 15.9138V15.864C4.99999 15.5229 4.99998 15.2284 5.01661 14.9848C5.03411 14.7283 5.07261 14.4717 5.17762 14.2182C5.41444 13.6465 5.86867 13.1922 6.44041 12.9554C6.69391 12.8504 6.9505 12.8119 7.20704 12.7944C7.45067 12.7778 7.74508 12.7778 8.08624 12.7778ZM7.31293 14.3463C7.13559 14.3584 7.06806 14.3791 7.03569 14.3925C6.84511 14.4715 6.6937 14.6229 6.61476 14.8135C6.60135 14.8458 6.58065 14.9134 6.56855 15.0907C6.55598 15.275 6.55556 15.5158 6.55556 15.8889C6.55556 16.2619 6.55598 16.5028 6.56855 16.6871C6.58065 16.8644 6.60135 16.9319 6.61476 16.9643C6.6937 17.1549 6.84511 17.3063 7.03569 17.3852C7.06806 17.3986 7.13559 17.4193 7.31293 17.4314C7.49724 17.444 7.73807 17.4444 8.11111 17.4444C8.48415 17.4444 8.72498 17.444 8.9093 17.4314C9.08663 17.4193 9.15417 17.3986 9.18653 17.3852C9.37711 17.3063 9.52852 17.1549 9.60746 16.9643C9.62087 16.9319 9.64157 16.8644 9.65367 16.6871C9.66625 16.5028 9.66667 16.2619 9.66667 15.8889C9.66667 15.5158 9.66625 15.275 9.65367 15.0907C9.64157 14.9134 9.62087 14.8458 9.60746 14.8135C9.52852 14.6229 9.37711 14.4715 9.18653 14.3925C9.15417 14.3791 9.08663 14.3584 8.9093 14.3463C8.72498 14.3338 8.48415 14.3333 8.11111 14.3333C7.73807 14.3333 7.49724 14.3338 7.31293 14.3463Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.864 12.7778H15.9138C16.2549 12.7778 16.5493 12.7778 16.793 12.7944C17.0495 12.8119 17.3061 12.8504 17.5596 12.9554C18.1313 13.1922 18.5856 13.6465 18.8224 14.2182C18.9274 14.4717 18.9659 14.7283 18.9834 14.9848C19 15.2285 19 15.5228 19 15.864V15.9138C19 16.2549 19 16.5493 18.9834 16.793C18.9659 17.0495 18.9274 17.3061 18.8224 17.5596C18.5856 18.1313 18.1313 18.5856 17.5596 18.8224C17.3061 18.9274 17.0495 18.9659 16.793 18.9834C16.5493 19 16.2549 19 15.9138 19H15.864C15.5228 19 15.2285 19 14.9848 18.9834C14.7283 18.9659 14.4717 18.9274 14.2182 18.8224C13.6465 18.5856 13.1922 18.1313 12.9554 17.5596C12.8504 17.3061 12.8119 17.0495 12.7944 16.793C12.7778 16.5493 12.7778 16.255 12.7778 15.9138V15.864C12.7778 15.5229 12.7778 15.2284 12.7944 14.9848C12.8119 14.7283 12.8504 14.4717 12.9554 14.2182C13.1922 13.6465 13.6465 13.1922 14.2182 12.9554C14.4717 12.8504 14.7283 12.8119 14.9848 12.7944C15.2284 12.7778 15.5229 12.7778 15.864 12.7778ZM15.0907 14.3463C14.9134 14.3584 14.8458 14.3791 14.8135 14.3925C14.6229 14.4715 14.4715 14.6229 14.3925 14.8135C14.3791 14.8458 14.3584 14.9134 14.3463 15.0907C14.3338 15.275 14.3333 15.5158 14.3333 15.8889C14.3333 16.2619 14.3338 16.5028 14.3463 16.6871C14.3584 16.8644 14.3791 16.9319 14.3925 16.9643C14.4715 17.1549 14.6229 17.3063 14.8135 17.3852C14.8458 17.3986 14.9134 17.4193 15.0907 17.4314C15.275 17.444 15.5158 17.4444 15.8889 17.4444C16.2619 17.4444 16.5028 17.444 16.6871 17.4314C16.8644 17.4193 16.9319 17.3986 16.9643 17.3852C17.1549 17.3063 17.3063 17.1549 17.3852 16.9643C17.3986 16.9319 17.4193 16.8644 17.4314 16.6871C17.444 16.5028 17.4444 16.2619 17.4444 15.8889C17.4444 15.5158 17.444 15.275 17.4314 15.0907C17.4193 14.9134 17.3986 14.8458 17.3852 14.8135C17.3063 14.6229 17.1549 14.4715 16.9643 14.3925C16.9319 14.3791 16.8644 14.3584 16.6871 14.3463C16.5028 14.3338 16.2619 14.3333 15.8889 14.3333C15.5158 14.3333 15.275 14.3338 15.0907 14.3463Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08624 5H8.13599C8.47716 4.99999 8.77154 4.99998 9.01519 5.01661C9.27172 5.03411 9.52832 5.07261 9.78182 5.17762C10.3535 5.41443 10.8078 5.86867 11.0446 6.44041C11.1496 6.69391 11.1881 6.9505 11.2056 7.20704C11.2222 7.45068 11.2222 7.74506 11.2222 8.08623V8.13599C11.2222 8.47716 11.2222 8.77154 11.2056 9.01519C11.1881 9.27172 11.1496 9.52832 11.0446 9.78182C10.8078 10.3535 10.3535 10.8078 9.78182 11.0446C9.52832 11.1496 9.27172 11.1881 9.01519 11.2056C8.77154 11.2222 8.47716 11.2222 8.13599 11.2222H8.08623C7.74506 11.2222 7.45068 11.2222 7.20704 11.2056C6.9505 11.1881 6.69391 11.1496 6.44041 11.0446C5.86867 10.8078 5.41443 10.3535 5.17762 9.78182C5.07261 9.52832 5.03411 9.27172 5.01661 9.01519C4.99998 8.77154 4.99999 8.47716 5 8.13599V8.08624C4.99999 7.74506 4.99998 7.45068 5.01661 7.20704C5.03411 6.9505 5.07261 6.69391 5.17762 6.44041C5.41443 5.86867 5.86867 5.41443 6.44041 5.17762C6.69391 5.07261 6.9505 5.03411 7.20704 5.01661C7.45068 4.99998 7.74506 4.99999 8.08624 5ZM7.31292 6.56855C7.13559 6.58065 7.06806 6.60135 7.03569 6.61476C6.84511 6.6937 6.6937 6.84511 6.61476 7.03569C6.60135 7.06806 6.58065 7.13559 6.56855 7.31292C6.55598 7.49724 6.55556 7.73807 6.55556 8.11111C6.55556 8.48415 6.55598 8.72498 6.56855 8.9093C6.58065 9.08663 6.60135 9.15417 6.61476 9.18653C6.6937 9.37711 6.84511 9.52852 7.03569 9.60746C7.06806 9.62087 7.13559 9.64157 7.31292 9.65367C7.49724 9.66624 7.73807 9.66667 8.11111 9.66667C8.48415 9.66667 8.72498 9.66624 8.9093 9.65367C9.08663 9.64157 9.15417 9.62087 9.18653 9.60746C9.37711 9.52852 9.52852 9.37711 9.60746 9.18653C9.62087 9.15417 9.64157 9.08663 9.65367 8.9093C9.66624 8.72498 9.66667 8.48415 9.66667 8.11111C9.66667 7.73807 9.66624 7.49724 9.65367 7.31292C9.64157 7.13559 9.62087 7.06806 9.60746 7.03569C9.52852 6.84511 9.37711 6.6937 9.18653 6.61476C9.15417 6.60135 9.08663 6.58065 8.9093 6.56855C8.72498 6.55598 8.48415 6.55556 8.11111 6.55556C7.73807 6.55556 7.49724 6.55598 7.31292 6.56855Z"
        fill={color}
      />
    </svg>
  );
};

export { AntiFrontRunBotIcon };
