import { useCallback, useState, useEffect, useMemo } from 'react';
import { useTypedSelector, useTypedDispatch } from 'store';
import { isNil } from 'lodash';

import { bnFrom, Bus } from 'tools';
import { useScrollPagination } from 'hooks/utility';
import { IPairWallet } from 'api/apiPairs/models';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { useFilters } from 'store/slices/filters/hooks';
import { EExchange } from 'web3';
import { EDexPairFilters } from 'types/filters';
import { PAIR_WALLETS_RELOAD, STOP_RELOAD_PREFIX } from 'constant/reload';
import { useDexWallets } from '../api';
import { TOTAL_TOKEN_ID } from 'constant/numbers';
import { formatFiat, formatToken } from 'utils/formats';
import { IWalletTableRow } from 'types/tables/dex/walletTableRow';

const useDexWalletsRecords = () => {
  const dispatch = useTypedDispatch();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { settedPairFilters } = useFilters({
    dex: {
      pairId: dexPair.id,
      type: EDexPairFilters.wallets,
    },
  });

  const [connectedWallets, setConnectedWallets] = useState<number>(0);
  const [virtualRecords, setVirtualRecords] = useState<IWalletTableRow[]>([]);
  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});

  const getWallets = useDexWallets();

  const getRecords = useCallback(
    async ({
      limit,
      lastSeenId,
    }: {
      limit: number;
      lastSeenId: number;
    }): Promise<{ records: IPairWallet[]; has_more: boolean } | undefined> => {
      try {
        const { isSuccess, errorMessage, data } = await getWallets({
          lastSeenId,
          limit,
          filters: settedPairFilters,
        });

        if (isSuccess && data) {
          setConnectedWallets(data.total_connected_wallets);

          return {
            records: data.items,
            has_more: data.has_next,
          };
        } else {
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
          return undefined;
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    [dispatch, getWallets, settedPairFilters],
  );

  const { hasMore, records, setRecords, loading, loadMore, remount } = useScrollPagination({
    limit: 200,
    getRecords,
    remountLoad: true,
  });

  const handleRemount = useCallback(() => {
    setRecords(undefined);
    setRowSelection({});
    remount();
  }, [remount, setRecords]);

  const selectedRecords = useMemo(() => {
    const selectedRows: IPairWallet[] = [];

    if (!records) return [];

    for (const key of Object.keys(rowSelection)) {
      selectedRows.push(records[Number(key)]);
    }

    return selectedRows;
  }, [rowSelection, records]);

  const updateVisibleRecords = useCallback(async () => {
    const lastSeenId =
      (records ?? []).length < 20
        ? undefined
        : virtualRecords[0]
        ? virtualRecords[0].id
        : undefined;
    const limit = virtualRecords.length;

    if (limit === 0 || !records) {
      Bus.emit(STOP_RELOAD_PREFIX + PAIR_WALLETS_RELOAD);
      return;
    }

    try {
      const startTime = Date.now();

      const { isSuccess, errorMessage, data } = await getWallets({
        lastSeenId: lastSeenId ? lastSeenId - 1 : undefined,
        limit,
        filters: settedPairFilters,
      });

      const endTime = Date.now();

      if (isSuccess && data) {
        const firstSeenIndex = isNil(lastSeenId)
          ? 0
          : records.findIndex(el => el.id === lastSeenId);
        const newRecords = [...records];

        newRecords.splice(firstSeenIndex, data.items.length, ...data.items);

        setRecords(newRecords);

        if (endTime - startTime >= 1500) {
          Bus.emit(STOP_RELOAD_PREFIX + PAIR_WALLETS_RELOAD);
        } else {
          setTimeout(() => {
            Bus.emit(STOP_RELOAD_PREFIX + PAIR_WALLETS_RELOAD);
          }, 1500 - (endTime - startTime));
        }
      } else {
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage ?? 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        Bus.emit(STOP_RELOAD_PREFIX + PAIR_WALLETS_RELOAD);
      }
    } catch (error) {
      console.log(error);
      Bus.emit(STOP_RELOAD_PREFIX + PAIR_WALLETS_RELOAD);
    }
  }, [virtualRecords, records, dispatch, getWallets, settedPairFilters, setRecords]);

  useEffect(() => {
    const onFilter = () => {
      handleRemount();
    };

    Bus.on(`${EExchange.dex}:${EDexPairFilters.wallets}:FILTER`, onFilter);

    return () => {
      Bus.off(`${EExchange.dex}:${EDexPairFilters.wallets}:FILTER`, onFilter);
    };
  }, [handleRemount]);

  useEffect(() => {
    Bus.on(PAIR_WALLETS_RELOAD, updateVisibleRecords);

    return () => {
      Bus.off(PAIR_WALLETS_RELOAD, updateVisibleRecords);
    };
  }, [updateVisibleRecords]);

  const tableRecords = useMemo(() => {
    return records
      ? records.map(record => {
          const walletAddressCol = { address: record.address };

          const tokenBaseId = dexPair.token_base.id;
          const rowTokenBaseInfo = record.tokens?.find(token => token.token.id === tokenBaseId);
          const tokenBaseCol = {
            value: rowTokenBaseInfo
              ? formatToken(bnFrom(rowTokenBaseInfo.balance), rowTokenBaseInfo.token.decimals)
              : '',
          };

          const tokenQuoteId = dexPair.token_quote.id;
          const rowTokenQuoteInfo = record.tokens?.find(token => token.token.id === tokenQuoteId);
          const tokenQuoteCol = {
            value: rowTokenQuoteInfo
              ? formatToken(bnFrom(rowTokenQuoteInfo.balance), rowTokenQuoteInfo.token.decimals)
              : '',
          };

          const tokenFeeId = dexPair.token_fee.id;
          const rowTokenFeeInfo = record.tokens?.find(token => token.token.id === tokenFeeId);
          const tokenFeeCol = {
            value: rowTokenFeeInfo
              ? formatToken(bnFrom(rowTokenFeeInfo.balance), rowTokenFeeInfo.token.decimals)
              : '',
          };

          const totalInfo = record.tokens?.find(token => token.token.id === TOTAL_TOKEN_ID);
          const totalUsdCol = {
            value: totalInfo ? formatFiat(bnFrom(totalInfo.balance_usd), 6) : '',
          };
          const transactionCount = totalInfo?.transactions_count;
          const txsCol = { transactionCount: transactionCount as number };

          const connectedBotsCol = { row: record };

          return {
            walletAddressCol,
            tokenBaseCol,
            tokenQuoteCol,
            tokenFeeCol,
            totalUsdCol,
            txsCol,
            connectedBotsCol,
            id: record.id,
          } as IWalletTableRow;
        })
      : [];
  }, [records, dexPair]);

  const result = useMemo(
    () => ({
      records: { get: records, set: setRecords },
      connectedWallets,
      virtualRecords: { get: virtualRecords, set: setVirtualRecords },
      tableRecords,
      hasMore,
      loading,
      loadMore,
      handleRemount,
      rowSelection: { get: rowSelection, set: setRowSelection },
      selectedRecords,
    }),
    [
      records,
      setRecords,
      connectedWallets,
      hasMore,
      loading,
      loadMore,
      handleRemount,
      selectedRecords,
      rowSelection,
      setRowSelection,
      virtualRecords,
      setVirtualRecords,
      tableRecords,
    ],
  );

  return result;
};

export { useDexWalletsRecords };
