import { TOTAL_TOKEN_ID } from 'constant/numbers';
import { IFilterSchemeExtended, EFilterEntity, EFilterFormatter } from '../common';

export const generalWalletsFiltersScheme: Record<string, IFilterSchemeExtended> = {
  balance: {
    name: 'balance_usd',
    label: 'Balance USD',
    settedLabel: 'Balance USD',
    field: 'balance_usd',
    type: EFilterEntity.number,
    decimals: 6,
    tokenId: TOTAL_TOKEN_ID,
    formatter: EFilterFormatter.bn_number_token,
  },
  transactions: {
    name: 'transactions',
    label: 'Transactions',
    settedLabel: 'Transactions',
    field: 'transactions_count',
    type: EFilterEntity.natural_number,
    tokenId: TOTAL_TOKEN_ID,
    formatter: EFilterFormatter.natural_number_token,
  },
  not_connected: {
    name: 'not_connected',
    label: 'Not connected',
    settedLabel: 'Not connected',
    field: 'not_connected',
    type: EFilterEntity.boolean,
    formatter: EFilterFormatter.boolean,
    single: true,
  },
};
