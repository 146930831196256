import React, { useCallback, useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import { useTypedSelector } from 'store';

import { PairTableConnectedBots } from 'common/pairs';
import { IPairBot } from 'types/bots';
import { IWalletTableRow } from 'types/tables/dex/walletTableRow';

interface IConnectedBotsProps {
  meta: any;
  row: Row<IWalletTableRow>;
}

export const ConnectedBots: React.FC<IConnectedBotsProps> = React.memo(({ meta, row }) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const { setRecords } = useMemo(() => meta, [meta]);
  const connectedBots = useMemo(() => row.original.connectedBotsCol?.row.connected_bots, [row]);
  const walletId = useMemo(() => row.original.id, [row]);

  const onChangeConnectedBots = useCallback(
    (new_connected_bots: IPairBot[]) => {
      setRecords((records: any) => {
        const newRecords = records === undefined ? [] : [...records];
        const botIndexOf = newRecords.findIndex(el => el.id === row.original.id);

        newRecords[botIndexOf] = {
          ...newRecords[botIndexOf],
          connected_bots: new_connected_bots,
        };

        return newRecords;
      });
    },
    [row.original.id, setRecords],
  );

  return (
    <PairTableConnectedBots
      connected_bots={connectedBots}
      pair={pair!}
      walletId={walletId}
      onChangeConnectedBots={onChangeConnectedBots}
    />
  );
});
