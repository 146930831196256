import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { useTypedSelector, useTypedDispatch } from 'store';
import { NavLink, generatePath } from 'react-router-dom';
import cn from 'classnames';

import { ApiPairs, ApiProjects } from 'api';
import { UDPairModal } from 'modals';
import { IProject } from 'types/project';
import { web3Configs, EExchange } from 'web3';
import {
  setSelectedCexPair,
  setSelectedDexPair,
  setPairLoading,
  setPairError,
} from 'store/slices/pairs';
import { dextoolsLink } from 'utils';
import { calculateScanLink } from 'utils/calculates';
import { getPairLinkedBots } from 'utils/getPairLinkedBots';
import { useTheme } from 'hooks/utility';
import { ROUTE_PATHS } from 'constant/routes';

import ProjectListIconPairs from '../../project/ProjectListWithPairs/PlatformIcon';

import dextoolsLogo from 'assets/images/dextools_logo.png';
import { CogIcon } from 'assets/icons';
import './headerPair.scss';

interface IDexPairHeaderProps {
  pairId: number;
  projectId: number;
}

const DexPairHeader: React.FC<IDexPairHeaderProps> = ({ pairId, projectId }) => {
  const dispatch = useTypedDispatch();
  const { isDark } = useTheme();

  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair);
  const projects = useTypedSelector(store => store.projects.projects);
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);

  const [project, setProject] = useState<IProject | null>(null);

  useEffect(() => {
    const setup = async () => {
      if (projects) {
        try {
          dispatch(setSelectedCexPair(null));
          dispatch(setSelectedDexPair(null));
          dispatch(setPairLoading(true));
          dispatch(setPairError(false));

          const [activeProject, activePair] = await Promise.all([
            ApiProjects.getProjectById(projectId),
            ApiPairs.getDexPairById(pairId),
          ]);

          if (activeProject.isSuccess && activePair.isSuccess) {
            setProject({
              created_at: activeProject.data.created_at,
              id: activeProject.data.id,
              name: activeProject.data.name,
              cexPairs: [],
              dexPairs: [],
              updated_at: '',
              notes: activeProject.data.notes,
            });

            if ('dex' in activePair.data) {
              dispatch(setSelectedDexPair(activePair.data));
            }

            await getPairLinkedBots({
              pairId: activePair.data.id,
              dispatch,
              exchange: EExchange.dex,
            });
          } else {
            dispatch(setPairError(true));
          }
        } catch (error) {
          console.log(error);
        } finally {
          dispatch(setPairLoading(false));
        }
      }
    };

    setup();
  }, [dispatch, projects, pairId, projectId]);

  const [updateModalOpened, setUpdateModalOpened] = useState<boolean>(false);

  const onOpenUpdateModalOpened = useCallback(() => {
    setUpdateModalOpened(true);
  }, []);

  const onCloseUpdateModalOpened = useCallback(() => {
    setUpdateModalOpened(false);
  }, []);

  const dextoolsLinkUrl = useMemo(
    () =>
      dexPair
        ? dextoolsLink({ network: dexPair.network, pairAddress: dexPair.address })
        : undefined,
    [dexPair],
  );

  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { duration: 0.4 } }}
      className="mm-header__header-pair"
    >
      {project && dexPair && (
        <>
          <div className="mm-header__header-pair__info">
            <div className="pair-name">
              <motion.a
                href={calculateScanLink({
                  address: dexPair.token_base.address,
                  network: dexPair.network,
                  addressType: 'token',
                })}
                target="_blank"
                rel="noreferrer"
                whileHover={{ scale: 1.1 }}
              >
                {dexPair.token_base.symbol}
              </motion.a>
              <span>/</span>
              <motion.a
                href={calculateScanLink({
                  address: dexPair.token_quote.address,
                  network: dexPair.network,
                  addressType: 'token',
                })}
                target="_blank"
                rel="noreferrer"
                whileHover={{ scale: 1.1 }}
              >
                {dexPair.token_quote.symbol}
              </motion.a>
            </div>
            {isAdmin && (
              <motion.div
                whileHover={{ rotate: 180, scale: 1.3 }}
                transition={{ duration: 0.3 }}
                className="pair-settings"
                onClick={onOpenUpdateModalOpened}
              >
                <CogIcon />
              </motion.div>
            )}
            <NavLink
              to={generatePath(ROUTE_PATHS.project, { id: project.id.toString() })}
              className="project-name"
            >
              {project.name}
            </NavLink>
            <div
              className={cn(
                'header-trading-pair-logo-header',
                dexPair.dex.includes('uniswap') ? 'uniswap' : dexPair.dex,
                { 'bg-white': isDark, 'bg-bg-primary': !isDark },
              )}
            >
              <ProjectListIconPairs platform={dexPair.dex} className="exchange-icon" />
            </div>
          </div>
          <div className="mm-header__header-pair__bottom">
            <a
              className={cn('scan-link rounded-full p-[2px]', { 'bg-white': isDark })}
              target="_blank"
              rel="noreferref noreferrer"
              href={calculateScanLink({
                address: dexPair.address,
                addressType: 'address',
                network: dexPair.network,
              })}
            >
              <img src={web3Configs[dexPair.network].scanLogo} alt="" />
            </a>
            {dextoolsLinkUrl && (
              <a
                className="trade-pair-link"
                target="_blank"
                rel="noreferrer"
                href={dextoolsLinkUrl}
              >
                <img src={dextoolsLogo} alt="dextools" />
              </a>
            )}
            {dexPair.notes && <span>{dexPair.notes}</span>}
          </div>
          {updateModalOpened && (
            <UDPairModal
              exchange={EExchange.dex}
              onClose={onCloseUpdateModalOpened}
              onOpen={onOpenUpdateModalOpened}
              pairId={dexPair.id}
              projectId={project.id}
              pairSymbol={dexPair.symbol}
              initialNotes={dexPair.notes ?? ''}
            />
          )}
        </>
      )}
    </motion.div>
  );
};

export default DexPairHeader;
