import React, { useState, useCallback, lazy, useContext } from 'react';

import { BotEnableStatus, Panel } from 'ui';
import { IPanelProps } from 'types/pairs';
import { CexPairContext } from 'context/CexPairContext';

import { ColumnChartIcon } from 'assets/icons';

const PanelContent = lazy(() => import('./Content'));

const SmartLiquidity: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(initialOpened ?? false);
  const { botStates, botStatesLoading } = useContext(CexPairContext);

  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);
    },
    [setIsOpened],
  );

  return (
    <Panel
      title="Smart liquidity algorithm"
      icon={ColumnChartIcon}
      initialOpened={initialOpened}
      onChange={handleSwitch}
      headerNode={
        <div className="w-full flex flex-row items-center justify-between">
          {!botStatesLoading && botStates.smart_liquidity && (
            <BotEnableStatus status={botStates.smart_liquidity.status ? 'enabled' : 'disabled'} />
          )}
        </div>
      }
    >
      <PanelContent isPanelOpened={isPanelOpened} />
    </Panel>
  );
};

export { SmartLiquidity };
