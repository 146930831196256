import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { TableAddressChip } from 'ui';
import { ITransfer } from 'types/transfers';

interface IHashProps {
  transfer: ITransfer;
}

const Hash = React.memo(({ transfer }: IHashProps) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const hash = useMemo(() => transfer.hash ?? undefined, [transfer]);

  return hash ? (
    <TableAddressChip
      address={hash}
      network={dexPair.network}
      addressType="transaction"
      format="short"
    />
  ) : (
    <div>-</div>
  );
});

export { Hash };
