import { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { formatFiat, formatToken } from 'utils/formats';
import { calculateSwapsGasPrice } from 'utils/calculates';
import { bn, bnFrom, addBignumbers, divideBignumbers, multiplyBignumbers } from 'tools/math';
import { useGasPrice } from './useGasPrice';

export const useFeeCostsUiString = ({
  totalTokenAmount,
  minAmount,
  maxAmount,
}: {
  totalTokenAmount: string | undefined;
  minAmount: string | undefined;
  maxAmount: string | undefined;
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const gasEntity = useGasPrice({ network: dexPair.network });
  const gasPrice = gasEntity?.price ?? '0';
  const symbol = gasEntity?.token.symbol;

  const tokenPriceInUsd = dexPair.token_fee.price_usd;

  const totalTokenAmountValid = useMemo(
    () => totalTokenAmount && totalTokenAmount !== '' && Number(totalTokenAmount) > 0,
    [totalTokenAmount],
  );

  const minAmountValid = useMemo(
    () => minAmount && minAmount !== '' && Number(minAmount) > 0,
    [minAmount],
  );

  const maxAmountValid = useMemo(
    () => maxAmount && maxAmount !== '' && Number(maxAmount) > 0,
    [maxAmount],
  );

  const transactionFeeBN = useMemo(() => {
    return calculateSwapsGasPrice({
      swap_gas_limit: dexPair.swap_gas_limit,
      gasPrice: gasPrice,
      swaps_count: 1,
    });
  }, [dexPair, gasPrice]);

  const feeCostsBN = useMemo(() => {
    if (!totalTokenAmountValid || !minAmountValid || !maxAmountValid) return bn(0);

    const multiple1 = multiplyBignumbers(
      [transactionFeeBN, 18],
      [bn(totalTokenAmount, dexPair.token_base.decimals), dexPair.token_base.decimals],
    );

    const add1 = addBignumbers(
      [bn(minAmount, dexPair.token_base.decimals), dexPair.token_base.decimals],
      [bn(maxAmount, dexPair.token_base.decimals), dexPair.token_base.decimals],
    );

    const divide1 = divideBignumbers([add1, 18], [bn(2), 18]);

    return divideBignumbers([multiple1, 18], [divide1, 18]);
  }, [
    transactionFeeBN,
    totalTokenAmount,
    minAmount,
    maxAmount,
    totalTokenAmountValid,
    minAmountValid,
    maxAmountValid,
    dexPair,
  ]);

  const feeCostsUsd = useMemo(() => {
    return multiplyBignumbers([feeCostsBN, 18], [bnFrom(tokenPriceInUsd), 6]);
  }, [feeCostsBN, tokenPriceInUsd]);

  const result = useMemo(() => {
    if (!totalTokenAmountValid || !minAmountValid || !maxAmountValid) return null;

    return `${formatToken(feeCostsBN, 18)} ${symbol} (${formatFiat(feeCostsUsd, 18, '$')})`;
  }, [totalTokenAmountValid, minAmountValid, maxAmountValid, feeCostsBN, feeCostsUsd, symbol]);
  feeCostsUsd;

  return result;
};
