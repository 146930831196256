import { createSlice } from '@reduxjs/toolkit';

import { LocalStorage } from 'tools';
import { EGeneralFilters, IFilters } from 'types/filters';
import { FILTERS_KEY } from 'constant/cache';

import {
  setupInitialFiltersReducer,
  addFilterReducer,
  updateFilterReducer,
  deleteFilterReducer,
  clearFiltersReducer,
  saveFiltersReducer,
} from './reducers';

const LSValue = LocalStorage.get<IFilters | undefined>(FILTERS_KEY);

const initialState: IFilters = LSValue ?? {
  dex: {},
  cex: {},
  [EGeneralFilters.wallets]: {},
  [EGeneralFilters.accounts]: {},
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState: initialState,
  reducers: {
    setupInitialFilters: setupInitialFiltersReducer,
    addFilter: addFilterReducer,
    updateFilter: updateFilterReducer,
    deleteFilter: deleteFilterReducer,
    clearFilters: clearFiltersReducer,
    saveFilters: saveFiltersReducer,
  },
});

export const {
  setupInitialFilters,
  addFilter,
  updateFilter,
  deleteFilter,
  clearFilters,
  saveFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
