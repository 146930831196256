import { createSlice } from '@reduxjs/toolkit';

import { LocalStorage } from 'tools';
import { IChartRange, IChartTimeframe } from 'types/charts';
import { ECexPairPanel, EDexPairPanel } from 'types/pairs';

export interface IPairConfig {
  statisticAutorefresh?: boolean;
  walletsAutorefresh?: boolean;
  transactionsAutorefresh?: boolean;
  balanceBotAutorefresh?: boolean;
  buysellBotAutorefresh?: boolean;
  cexAccountsAutorefresh?: boolean;
  cexOrdersAutorefresh?: boolean;
  cexStatisticAutorefresh?: boolean;
  cexBuysellBotAutorefresh?: boolean;
  cexOrderManagerAutorefresh?: boolean;
  statisticRange?: IChartRange;
  chartTimeframe?: IChartTimeframe;
  lastOpenedPanel?: EDexPairPanel | ECexPairPanel;
  poolCreated?: number;
}

interface IPairConfigsState {
  configs: Record<number, IPairConfig>;
}
const pairConfigsInitialState: IPairConfigsState = {
  configs: LocalStorage.get<Record<number, IPairConfig>>('pair-configs') ?? {},
};

const pairConfigsSlice = createSlice({
  name: 'pair_configs',
  initialState: pairConfigsInitialState as IPairConfigsState,
  reducers: {
    setPairConfig: (
      state,
      { payload: { id, config } }: { payload: { id: number; config: IPairConfig } },
    ) => {
      state.configs[id] = config;

      LocalStorage.set('pair-configs', state.configs);
    },
  },
});

export const { setPairConfig } = pairConfigsSlice.actions;

export default pairConfigsSlice.reducer;
