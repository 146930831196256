import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTypedSelector, useTypedDispatch } from 'store';

import { ApiBot } from 'api';
import { ICexOrderManagerBotConfig, ICexOrderManagerBotTaskExtended } from 'types/bots';
import { IAccountBalances, ICexAccount } from 'types/accounts';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { bn, Bus } from 'tools';
import { PAIR_CEX_ORDER_MANAGER_RELOAD, STOP_RELOAD_PREFIX } from 'constant/reload';

interface IOrderManagerReturn {
  tasks: ICexOrderManagerBotTaskExtended[];
  tasksLoading: boolean;
  deletingTaskId: number | undefined;
  getTasks: () => Promise<void>;
  deleteTask: ({ taskId }: { taskId: number }) => Promise<void>;
}

interface IAccountsWithBalance extends ICexAccount, Partial<IAccountBalances> {}

const useOrderManager = ({
  accounts,
  updateBotStates,
}: {
  accounts: IAccountsWithBalance[];
  updateBotStates: () => void;
}): IOrderManagerReturn => {
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const [tasksLoading, setTasksLoading] = useState<boolean>(true);
  const [deletingTaskId, setDeletingTaskId] = useState<number | undefined>(undefined);

  const [apiTasks, setApiTasks] = useState<ICexOrderManagerBotConfig>([]);

  const getTasks = useCallback(
    async (shadowLoading = false) => {
      if (!cexPair?.id) return;
      if (!isAdmin) return;

      try {
        if (!shadowLoading) {
          setTasksLoading(true);
        }

        const { isSuccess, data } = await ApiBot.getOrderManagerConfig({ pairId: cexPair.id });

        if (isSuccess) {
          setApiTasks((data ?? []).sort((a, b) => (b.id ?? 0) - (a.id ?? 0)));
        }
      } catch (error) {
      } finally {
        if (!shadowLoading) {
          setTasksLoading(false);
        }
      }
    },
    [cexPair?.id, isAdmin],
  );

  const deleteTask = useCallback(
    async ({ taskId }: { taskId: number }) => {
      if (!cexPair?.id) return;

      setDeletingTaskId(taskId);

      try {
        const { isSuccess, errorMessage } = await ApiBot.deleteOrderManagerTask({
          pairId: cexPair.id,
          taskId,
        });

        if (isSuccess) {
          setApiTasks(v => v.filter(el => el.id !== taskId));
          updateBotStates();
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        }
      } catch (error) {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      } finally {
        setDeletingTaskId(undefined);
      }
    },
    [dispatch, updateBotStates, cexPair?.id],
  );

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  const handleUpdate = useCallback(async () => {
    const startTime = Date.now();

    try {
      await getTasks(true);
    } catch (e) {}

    const endTime = Date.now();

    if (endTime - startTime >= 1500) {
      Bus.emit(STOP_RELOAD_PREFIX + PAIR_CEX_ORDER_MANAGER_RELOAD);
    } else {
      setTimeout(() => {
        Bus.emit(STOP_RELOAD_PREFIX + PAIR_CEX_ORDER_MANAGER_RELOAD);
      }, 1500 - (endTime - startTime));
    }
  }, [getTasks]);

  useEffect(() => {
    Bus.on(PAIR_CEX_ORDER_MANAGER_RELOAD, handleUpdate);

    return () => {
      Bus.off(PAIR_CEX_ORDER_MANAGER_RELOAD, handleUpdate);
    };
  }, [handleUpdate]);

  const tasks = useMemo(() => {
    return apiTasks.map(task => {
      const account = accounts.find(el => el.id === task.account_id);

      const { avaliableBase, avaliableQuote } = {
        avaliableBase: bn(
          Number(account?.base_balance ?? 0) - Number(account?.base_balance_locked ?? 0),
          18,
        ),
        avaliableQuote: bn(
          Number(account?.quote_balance ?? 0) - Number(account?.quote_balance_locked ?? 0),
          18,
        ),
      };

      const neededQuote =
        task.side === 'buy'
          ? bn(Number(task.price) * (Number(task.total_size) - Number(task.progress)))
          : bn('0');

      const neededBase =
        task.side === 'buy' ? bn('0') : bn(Number(task.total_size) - Number(task.progress));

      const lackBase = avaliableBase.lt(neededBase);
      const lackQuote = avaliableQuote.lt(neededQuote);

      return {
        ...task,
        account,
        avaliableBase,
        avaliableQuote,
        neededQuote,
        neededBase,
        lackBase,
        lackQuote,
      };
    });
  }, [apiTasks, accounts]);

  return {
    tasks,
    tasksLoading,
    deletingTaskId,
    getTasks,
    deleteTask,
  };
};

export { useOrderManager };
