import defaultTheme from 'tailwindcss/defaultTheme';

export default {
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  darkTheme: false,
  safelist: [
    {
      pattern: /grid*/,
      variants: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      pattern: /col-span-*/,
      variants: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      pattern: /gap*/,
    },
    {
      pattern: /text-*/,
      variants: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ],
  theme: {
    screens: {
      xs: '300px',
      ...defaultTheme.screens,
    },
    extend: {
      colors: {
        'bg-primary': 'rgba(var(--bg-primary))',
        'bg-root': 'rgba(var(--bg-root))',
        'primary-light': 'rgba(var(--primary-light))',
        primary: 'rgba(var(--primary))',
        'primary-dark': 'rgba(var(--primary-dark))',
        'light-gray-1': 'rgba(var(--light-gray-1))',
        'light-gray-2': 'rgba(var(--light-gray-2))',
        'gray-1': 'rgba(var(--gray-1))',
        'gray-2': 'rgba(var(--gray-2))',
        'gray-3': 'rgba(var(--gray-3))',
        'light-red-1': 'rgba(var(--light-red-1))',
        'light-red-2': 'rgba(var(--light-red-2))',
        'pink-1': 'rgba(var(--pink-1))',
        'red-1': 'rgba(var(--red-1))',
        'black-1': 'rgba(var(--black-1))',
        'button-hover': 'rgba(var(--button-hover))',
        'backdrop-hover': 'var(--backdrop-hover)',
        'light-blue-1': 'rgba(var(--light-blue-1))',
        'blue-1': 'rgba(var(--blue-1))',
        'successful-1': 'rgba(var(--successful-1))',
        'successful-1-light': 'rgba(var(--successful-1-light))',
        'pending-1': 'rgba(var(--pending-1))',
        'processing-1': 'rgba(var(--processing-1))',
        'error-1': 'rgba(var(--error-1))',
        'failed-1': 'rgba(var(--failed-1))',
      },
      fontFamily: {
        body: ['SF Pro Text'],
        monospace: ['Droid Sans Mono'],
      },
    },
  },
  plugins: [],
};
