import { useCallback, useEffect } from 'react';

import { LocalStorage } from 'tools';

const useAppNewVersion = () => {
  const fetchVersion = useCallback(async () => {
    const resp = await fetch('/index.html', {
      method: 'get',
      mode: 'cors',
    });

    const htmlText = await resp.text();

    const regex =
      /<script\s+type="module"\s+crossorigin\s+src="\/assets\/index-(.+)\.js"><\/script>/;
    const match = htmlText.match(regex);

    return match ? match[1] : '';
  }, []);

  useEffect(() => {
    if (!import.meta.env.DEV) {
      (async () => {
        const appVersion = LocalStorage.get('app-version');
        const currentVersion = await fetchVersion();

        if (appVersion !== currentVersion) {
          LocalStorage.clear();
        }
        LocalStorage.set('app-version', currentVersion);
      })();
    }
  }, [fetchVersion]);
};

export { useAppNewVersion };
