import React, { useCallback, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { RangeCalendar } from 'ui';

import { CalendarIcon } from 'assets/icons';
import './style.scss';

interface IProps {
  value: string | undefined;
  setValue: (v: string | undefined) => void;
}

const FilterDateRangeField: React.FC<IProps> = ({ value, setValue }) => {
  const [isCalendarOpened, setIsCalendarOpened] = useState<boolean>(false);

  const triggerOpenCalendar = useCallback(() => {
    setIsCalendarOpened(v => !v);
  }, []);

  const handleSelectCalendarRange = useCallback(
    ([date1, date2]: [Dayjs | null, Dayjs | null]) => {
      if (!date1 || !date2) return setValue(undefined);

      setValue(`${Math.floor(date1.valueOf() / 1000)}-${Math.floor(date2.valueOf() / 1000)}`);
    },
    [setValue],
  );

  const { startDate, endDate } = useMemo(() => {
    if (!value)
      return {
        startDate: null,
        endDate: null,
      };

    const [_initialStartDate, _initialEndDate] = value.split('-');

    return {
      startDate: dayjs(+_initialStartDate * 1000),
      endDate: dayjs(+_initialEndDate * 1000),
    };
  }, [value]);

  return (
    <>
      <div className="mm-filter-date-range-field" onClick={triggerOpenCalendar}>
        <div className="mm-filter-field-container date-range-filter-container">
          <div className="calendar-icon-holder">
            <CalendarIcon />
          </div>
          <input
            readOnly
            type="text"
            value={
              startDate && endDate ? `${startDate.format('L LT')} - ${endDate.format('L LT')}` : ''
            }
            placeholder="Select range"
          />
        </div>
      </div>
      {isCalendarOpened && (
        <RangeCalendar
          initialStartDate={startDate}
          initialEndDate={endDate}
          onClose={triggerOpenCalendar}
          title="Select range"
          handleFilter={handleSelectCalendarRange}
          withTime
        />
      )}
    </>
  );
};

export { FilterDateRangeField };
