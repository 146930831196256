import React, { HTMLAttributes, useCallback } from 'react';
import cn from 'classnames';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from 'assets/icons';

import './style.scss';

interface ITableActionIconProps extends HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  sideBar?: boolean;
  icon: React.FC<IIconProps>;
  action?: () => void;
}

const TableActionIcon: React.FC<ITableActionIconProps> = ({
  disabled = false,
  icon: Icon,
  action,
  sideBar,
  ...rest
}) => {
  const handleCallAction = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (action) {
        event.stopPropagation();
        action();
      }
    },
    [action],
  );

  return (
    <div
      className={cn('mm-common-table-action-icon', { '__with-action': !!action })}
      onClick={e => handleCallAction(e)}
      {...rest}
    >
      <Icon
        color={
          disabled || sideBar
            ? tailwindConfig.theme.colors['gray-1']
            : tailwindConfig.theme.colors.primary
        }
      />
    </div>
  );
};

export { TableActionIcon };
