import { IUserRole } from 'api/apiUsers/models';
import { ICexAccount } from 'types/accounts';

export enum EFilterEntity {
  number = 'number',
  natural_number = 'natural_number',
  boolean = 'boolean',
  date_range = 'date_range',
  multi_search = 'multi_search',
  colors_multi_select = 'colors_multi_select',
  user_multi_select = 'user_multi_select',
  cex_accounts_multi_select = 'cex_accounts_multi_select',
  colors_single_select = 'single_select',
}

export enum EFilterNumberConnection {
  equal = '=',
  gte = '>=',
  gt = '>',
  lte = '<=',
  lt = '<',
}

export enum EFilterFormatter {
  natural_number_token = 'natural_number_token',
  bn_number_token = 'bn_number_token',
  boolean = 'boolean',
  colors_multi_select = 'colors_multi_select',
  user_multi_select = 'user_multi_select',
  cex_accounts_multi_select = 'cex_accounts_multi_select',
  colors_single_select = 'colors_single_select',
}

export const FILTER_NUMBER_CONNECTIONS = [
  { label: '=', value: EFilterNumberConnection.equal },
  { label: '>=', value: EFilterNumberConnection.gte },
  { label: '>', value: EFilterNumberConnection.gt },
  { label: '<=', value: EFilterNumberConnection.lte },
  { label: '<', value: EFilterNumberConnection.lt },
];

export interface IFilterColorSelectItem {
  label: string;
  value: string;
  color: string;
}

export interface IFilterScheme {
  type: EFilterEntity;
  label: string;
  // settedLabel - non required option, which created to handle cases for multiple amounts, volumes etc.
  //For example amount BUSD
  settedLabel?: string;
  name: string;
  formatter?: EFilterFormatter;
  tokenId?: number;
  decimals?: number;
  field?: string;
  listMultiColors?: IFilterColorSelectItem[];
  listSingleColors?: IFilterColorSelectItem[];
  // this option "single" works only for high level filters. We can't do this to lower filters, as balances
  single?: boolean;
}

export type IFilterSchemeExtended =
  | IFilterScheme
  | IFilterScheme[]
  | { name: string; label: string; filters: IFilterSchemeExtended };

export interface IFilterNumberResult {
  connection: EFilterNumberConnection;
  value: string;
}

export interface IFilterBooleanResult {
  connection: any;
  value: boolean;
}

export interface IFilterDateRangeResult {
  connection: any;
  value: string | undefined;
}

export interface IFilterMultiSearchResult {
  connection: any;
  value: string[];
}

export interface IFilterColorsMultiSelectResult {
  connection: any;
  value: IFilterColorSelectItem[];
}

export interface IFilterColorsSingleSelectResult {
  connection: any;
  value: IFilterColorSelectItem;
}

export interface IUserFlterItem {
  id: number;
  login: string;
  role: IUserRole | 'bot';
}

export interface IFilterUserMultiSelectResult {
  connection: any;
  value: IUserFlterItem[];
}

export interface IFilterCexAccountsMultiSelectResult {
  connection: any;
  value: ICexAccount[];
}

export interface IFilterValue {
  id: string;
  parentNames?: string[];
  name?: string | undefined;
  type?: EFilterEntity;
  scheme?: IFilterScheme;
  value?:
    | IFilterNumberResult
    | IFilterBooleanResult
    | IFilterDateRangeResult
    | IFilterMultiSearchResult
    | IFilterColorsMultiSelectResult
    | IFilterUserMultiSelectResult
    | IFilterCexAccountsMultiSelectResult
    | IFilterColorsSingleSelectResult;
}
