import { AppDispatch } from 'store';

import { ApiBalanceBot } from 'api';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { msToDuration } from 'utils/duration';
import { bn } from 'tools/math';

import { IDexBalanceBotTask } from 'types/bots';
import { IDexPair } from 'types/pairs';
import { IDexBalanceBotContext } from 'context/DexBalanceBotContext';

interface IAddBoostHoldersTaskProps {
  enabled?: boolean;
  dispatch: AppDispatch;
  handleLoadRecords: IDexBalanceBotContext['handleLoadRecords'];
  pairId: number | undefined;
  mode: 'edit' | 'create';
  taskId?: number;
  onClose?: (newCreatedBalanceTask?: IDexBalanceBotTask | undefined) => void;
  typeTask: string;
  min_amount?: string;
  max_amount?: string;
  min_pause?: number | undefined;
  max_pause?: number | undefined;
  buyback_enabled?: boolean;
  buyback_min_pause?: number | undefined;
  buyback_max_pause?: number | undefined;
  buyback_min_swaps?: string;
  buyback_max_swaps?: string;
  source_wallet_ids?: number[] | null;
  interval?: number;
  min_deposit_fee_factor?: number;
  max_deposit_fee_factor?: number;
  min_fee_threshold_factor?: number;
}

export const addBoostHoldersTask = async ({
  task: {
    min_amount,
    max_amount,
    min_pause,
    max_pause,
    enabled,
    buyback_enabled,
    buyback_min_pause,
    buyback_max_pause,
    buyback_min_swaps,
    buyback_max_swaps,
    dispatch,
    handleLoadRecords,
    pairId,
    mode,
    taskId,
    onClose,
    typeTask,
    source_wallet_ids,
    interval,
    min_deposit_fee_factor,
    max_deposit_fee_factor,
    min_fee_threshold_factor,
  },
  onSuccess,
  dexPair,
}: {
  task: IAddBoostHoldersTaskProps;
  onSuccess?: () => void;
  dexPair: IDexPair;
}) => {
  if (pairId) {
    let body: any = {};

    if (typeTask === 'maintain_fee_balance') {
      body = {
        pair_id: pairId,
        ...(mode === 'edit' ? { id: taskId } : {}),
        type: typeTask,
        maintain_fee_balance_options: {
          enabled,
          interval: msToDuration(interval),
          min_deposit_fee_factor: +min_deposit_fee_factor! || 0,
          max_deposit_fee_factor: +max_deposit_fee_factor! || 0,
          min_fee_threshold_factor: +min_fee_threshold_factor! || 0,
          source_wallet_ids,
        },
      };
    }

    if (typeTask === 'add_base_holder' || typeTask === 'add_quote_holder') {
      const taskOptions = {
        buyback_enabled: buyback_enabled,
        enabled,
        ...(buyback_enabled
          ? {
              buyback_options: {
                interval: '10s',
                min_pause: `${buyback_min_pause}ms`,
                max_pause: `${buyback_max_pause}ms`,
                min_swaps: buyback_min_swaps ? +buyback_min_swaps : 0,
                max_swaps: buyback_max_swaps ? +buyback_max_swaps : 0,
              },
            }
          : {}),
        min_pause: `${min_pause}ms`,
        max_pause: `${max_pause}ms`,
        source_wallet_ids,
      };

      body = {
        pair_id: pairId,
        type: typeTask,
        ...(mode === 'edit' ? { id: taskId } : {}),
        add_base_holder_options:
          typeTask === 'add_base_holder'
            ? {
                ...taskOptions,
                min_amount: bn(min_amount, dexPair.token_base.decimals).toString(),
                max_amount: bn(max_amount, dexPair.token_base.decimals).toString(),
              }
            : undefined,
        add_quote_holder_options:
          typeTask === 'add_quote_holder'
            ? {
                ...taskOptions,
                min_amount: bn(min_amount, dexPair.token_quote.decimals).toString(),
                max_amount: bn(max_amount, dexPair.token_quote.decimals).toString(),
              }
            : undefined,
      };
    }

    let isSuccess, errorMessage;
    let newCreatedBalanceTask = undefined;

    if (mode === 'create') {
      const {
        isSuccess: successNewBalanceTask,
        errorMessage: newCreatedBalanceTaskError,
        ...rest
      } = await ApiBalanceBot.addPairBalanceBotTasks({
        body,
      });
      isSuccess = successNewBalanceTask;
      errorMessage = newCreatedBalanceTaskError;

      if (successNewBalanceTask) {
        const { errorMessage, data: dataTaskById } = await ApiBalanceBot.getPairBalanceBotTasksById(
          { task_id: rest?.data?.id ?? 0 },
        );

        !errorMessage && (newCreatedBalanceTask = dataTaskById);
      }
    } else {
      if (!taskId) {
        return;
      }
      const { isSuccess: successUpdatedBalanceTask, errorMessage: updatedBalanceTaskError } =
        await ApiBalanceBot.updatePairBalanceBotTasks({
          taskId,
          body,
        });
      isSuccess = successUpdatedBalanceTask;
      errorMessage = updatedBalanceTaskError;
    }

    if (!isSuccess) {
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: errorMessage || 'Something went wrong',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
      return;
    }

    if (isSuccess) {
      if (onSuccess) {
        onSuccess();
      }

      if (!onSuccess) {
        handleLoadRecords();
        dispatch(
          setAlertState({
            type: 'success',
            text:
              mode === 'create'
                ? 'You successfully create new task!'
                : 'You successfully edit task!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }

      onClose?.(
        newCreatedBalanceTask && Object.keys(newCreatedBalanceTask).length
          ? newCreatedBalanceTask
          : undefined,
      );
    }
  }
};
