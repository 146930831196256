import React, {
  createContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { setAlertState, dropAlertState } from 'store/slices/ui';

import { UTCTimestamp } from 'lightweight-charts';
import { CexPairContext } from 'context/CexPairContext';
import { ChartsControlsContext } from './ChartsControlsContext';
import { handleNewCharts, mergeCandles } from 'context/charts/CexPairChartsContext/utils';
import { useLoadCexCharts } from 'hooks/charts';
import { last } from 'lodash';
import {
  chartTimeframeToInterval,
  getPeriodToAPI,
  INITIAL_LIMIT,
  PAGINATION_LIMIT,
  timeframeToRange,
} from 'utils/charts';
import { ECexOrderSide, ICexActiveOrder } from 'types/orders';
import { ApiOrders } from 'api';
import { bn, Bus, divideBignumbers, humanizeBn } from 'tools';
import { BigNumber } from '@ethersproject/bignumber';
import { useDeepCompareEffect } from 'react-use';
import { ETradingViewEvents, ICexChartPoint, TVSubscribeParams } from 'types/charts';
import { ICexChartsPoint } from 'api/apiCharts/models';
import {
  clearCexChartRecords,
  setCexChartRecords as storeSetCexChartRecords,
} from 'store/slices/cex-charts/charts-records';

interface IChartsLoadContext {
  loadPaginationCharts: ({
    startTime,
    endTime,
  }: {
    startTime: number;
    endTime: number;
  }) => Promise<ICexChartPoint[] | undefined>;
  loadChartsUpdate: () => Promise<void>;

  //records
  candleStickRecords: {
    time: UTCTimestamp;
    close: number;
    high: number;
    low: number;
    open: number;
  }[];
  balancesChartRecords: {
    records: {
      time: UTCTimestamp;
      value: number;
    }[];
    label: string;
  }[];
  totalBalanceUsdRecords: {
    time: UTCTimestamp;
    value: number;
  }[];
  totalVolumeRecords: {
    time: UTCTimestamp;
    value: number;
  }[];
  internalVolumeRecords: {
    time: UTCTimestamp;
    value: number;
  }[];
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  paginationLoading: boolean;
  setPaginationLoading: Dispatch<SetStateAction<boolean>>;
  hasMorePagination: boolean;
  setHasMorePagination: Dispatch<SetStateAction<boolean>>;
  chartArgs: () => {
    interval: number;
    endTime: number;
    startTime: number;
    limit: number;
  };
  chartPaginationArgs: () => {
    limit: number;
  };
  activeOrdersCount: string;
  orders: (ICexActiveOrder & { price: BigNumber; priceNumber: number })[];
  ordersLoading: boolean;
  chartOrders: {
    id: number;
    side: ECexOrderSide;
    amount: number;
  }[];
  onCancelOrder: (v: {
    pair_id: number;
    account_id: number;
    cex_order_id: string;
    cex_client_order_id: string;
  }) => Promise<void>;
  onCancelMany: (orders: ICexActiveOrder[]) => Promise<void>;
  cancelingOrderLoading: string | undefined;
}

export const ChartsLoadContext = createContext<IChartsLoadContext>({
  loadPaginationCharts: async () => undefined,
  loadChartsUpdate: async () => {},

  //records
  candleStickRecords: [],
  balancesChartRecords: [],
  totalBalanceUsdRecords: [],
  totalVolumeRecords: [],
  internalVolumeRecords: [],
  loading: false,
  setLoading: () => {},
  paginationLoading: false,
  setPaginationLoading: () => {},
  hasMorePagination: false,
  setHasMorePagination: () => {},
  chartArgs: () => ({
    interval: 0,
    endTime: 0,
    startTime: 0,
    limit: 0,
  }),
  chartPaginationArgs: () => ({
    limit: 0,
  }),
  activeOrdersCount: '',
  orders: [],
  ordersLoading: false,
  chartOrders: [],
  cancelingOrderLoading: undefined,
  onCancelOrder: async () => {},
  onCancelMany: async () => {},
});

export const ChartsLoadContextProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const dispatch = useTypedDispatch();

  const pairId = cexPair.id;
  const { accounts, accountsLoading } = useContext(CexPairContext);
  const {
    selectedAccounts,
    timeframe,
    priceFormat,
    period,
    startDate,
    endDate,
    resetBalancesShow,
    accountsToAPI,
  } = useContext(ChartsControlsContext);

  const tvSubscribedParams = useRef<TVSubscribeParams | undefined>(undefined);

  useEffect(() => {
    const handleSubscribe = (params: unknown) => {
      const _params = params as TVSubscribeParams;

      tvSubscribedParams.current = _params;
    };
    const handleUnsubscribe = () => {
      tvSubscribedParams.current = undefined;
    };

    Bus.on(ETradingViewEvents.SubscribeBarsEvent, handleSubscribe);
    Bus.on(ETradingViewEvents.UnsubscribeBarsEvent, handleUnsubscribe);

    return () => {
      Bus.off(ETradingViewEvents.SubscribeBarsEvent, handleSubscribe);
      Bus.off(ETradingViewEvents.UnsubscribeBarsEvent, handleUnsubscribe);
    };
  }, []);

  const [cancelingOrderLoading, setCancelingOrderLoading] = useState<string | undefined>(undefined);

  const [records, setRecords] = useState<ICexChartPoint[]>([]);

  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<ICexActiveOrder[]>([]);
  const [activeOrdersCount, setActiveOrdersCount] = useState<string>('');

  useEffect(() => {
    if (records.length >= 2) {
      dispatch(storeSetCexChartRecords(records));
    }

    return () => {
      dispatch(clearCexChartRecords());
    };
  }, [dispatch, records]);

  const [loading, setLoading] = useState<boolean>(false);
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);
  const [hasMorePagination, setHasMorePagination] = useState<boolean>(true);

  const periodToAPI = useMemo(() => getPeriodToAPI(period.get), [period.get]);

  const chartArgs = useCallback(() => {
    const interval = chartTimeframeToInterval(timeframe.get);
    const { endTime, startTime } = timeframeToRange(timeframe.get);
    const limit = INITIAL_LIMIT;

    return { interval, endTime, startTime, limit };
  }, [timeframe.get]);

  const chartPaginationArgs = useCallback(() => {
    const limit = PAGINATION_LIMIT;

    return { limit };
  }, []);

  const getOrders = useCallback(
    async (options?: { silent: boolean }) => {
      const isSilent = options?.silent;

      try {
        if (!isAdmin) return;

        if (!isSilent) {
          setOrdersLoading(true);
        }

        const { data } = await ApiOrders.getCexActiveOrders({
          pair_id: pairId,
          limit: 100,
          offset: 0,
          account_id: accountsToAPI(selectedAccounts.get).array,
        });

        setOrders(data?.orders ?? []);
        setActiveOrdersCount(data?.total_count.toString() ?? '');
      } catch (error) {
        console.log('error: ', error);
      } finally {
        if (!isSilent) {
          setOrdersLoading(false);
        }
      }
    },
    [accountsToAPI, selectedAccounts, pairId, isAdmin],
  );

  const activeOrders = useMemo(
    () =>
      orders
        .map(el => {
          const findAccount = accounts.find(account => account.id === el.account_id);

          const quoteAmountBN = bn(el.quote_amount);
          const baseAmountBN = bn(el.base_amount);

          const price = divideBignumbers([quoteAmountBN, 18], [baseAmountBN, 18]);
          const priceNumber = Number(humanizeBn(price, 18));

          return {
            ...el,
            account_notes: findAccount ? findAccount.notes : undefined,
            price,
            priceNumber,
          };
        })
        .sort((a, b) => {
          if (a.priceNumber >= b.priceNumber) return -1;

          if (b.priceNumber >= a.priceNumber) return 1;

          return 0;
        }),
    [orders, accounts],
  );

  const loadCexCharts = useLoadCexCharts();

  const loadComboCexCharts = useCallback(
    async ({ startTime, endTime }: { startTime: number; endTime: number }) => {
      const accountsResult = selectedAccounts.get.length === 0 ? accounts : selectedAccounts.get;

      const { points } = await loadCexCharts({
        startTime: startTime * 1000,
        endTime: endTime * 1000,
        timeframe: timeframe.get,
        account_id: accountsResult.map(el => el.id),
      });

      return { points };
    },
    [loadCexCharts, timeframe.get, accounts, selectedAccounts.get],
  );

  const loadCharts = useCallback(async () => {
    try {
      resetBalancesShow();

      setLoading(true);
      setHasMorePagination(true);

      const { startTime, endTime, limit } = chartArgs();

      const { points } = await loadComboCexCharts({ startTime, endTime });

      const data = handleNewCharts({ points });

      if (data) {
        setRecords(data);

        if (data.length < limit) setHasMorePagination(false);
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }, [loadComboCexCharts, chartArgs, resetBalancesShow, setHasMorePagination, setLoading]);

  const loadPaginationCharts = useCallback(
    async ({ startTime, endTime }: { startTime: number; endTime: number }) => {
      setPaginationLoading(true);

      try {
        const { limit } = chartPaginationArgs();

        const { points } = await loadComboCexCharts({ startTime: startTime, endTime });

        const data = handleNewCharts({ points });

        if (data) {
          if (data.length < limit) setHasMorePagination(false);

          setRecords(v => mergeCandles(v, data));

          setPaginationLoading(false);

          return data;
        }
      } catch (error) {
        console.log('error: ', error);
      }
    },
    [chartPaginationArgs, loadComboCexCharts, setHasMorePagination, setPaginationLoading],
  );

  const handleTvUpdate = useCallback(
    (points: ICexChartsPoint[]) => {
      if (!tvSubscribedParams.current) return;

      const lastPairRecord = records[-2];

      const newBar = points.find(point => point.timestamp !== lastPairRecord?.time);

      if (newBar) {
        tvSubscribedParams.current.onTick({
          high: Number(newBar.kline.high),
          open: Number(newBar.kline.open),
          close: Number(newBar.kline.close),
          low: Number(newBar.kline.low),
          volume: Number(newBar.kline.volume),
          time: newBar.timestamp,
        });
      }

      const barsToUpdate = points
        .filter(point => point.timestamp < lastPairRecord?.time)
        .map(point => ({
          high: Number(point.kline.high),
          open: Number(point.kline.open),
          close: Number(point.kline.close),
          low: Number(point.kline.low),
          volume: Number(point.kline.volume),
          time: point.timestamp,
        }));

      if (barsToUpdate) {
        tvSubscribedParams.current.onResetCacheNeededCallback();
        Bus.emit(ETradingViewEvents.UpdateRecentBars, { bars: barsToUpdate });
      }
    },
    [records],
  );

  const loadChartsUpdate = useCallback(async () => {
    if (loading || paginationLoading) return;

    try {
      setPaginationLoading(true);

      const { interval } = chartArgs();

      const endTime = Date.now();
      const startTime = endTime - 3 * interval * 1000;

      const endTimeSeconds = Math.ceil(endTime / 1000);
      const startTimeSeconds = Math.floor(startTime / 1000);

      const { points } = await loadComboCexCharts({
        startTime: startTimeSeconds,
        endTime: endTimeSeconds,
      });

      handleTvUpdate(points);

      const _data = handleNewCharts({ points });

      if (_data) {
        setRecords(v => {
          const lastPairRecord = last(_data);

          const newV = [...v];

          if (lastPairRecord) {
            const lastV = last(newV);

            if (lastV?.time === lastPairRecord.time) {
              newV[newV.length - 1] = lastPairRecord;
            } else if (lastV?.time && lastV.time < lastPairRecord.time) {
              newV.push(lastPairRecord);
            }

            _data.forEach(newRecord => {
              const findIndex = newV.findIndex(el => el.time === newRecord.time);

              if (findIndex >= 0) {
                newV[findIndex] = newRecord;
              }
            });
          }

          return newV;
        });
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setPaginationLoading(false);
    }
  }, [chartArgs, loadComboCexCharts, handleTvUpdate, loading, paginationLoading]);

  const [initialAccountsLoading, setInitialAccountsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!accountsLoading) {
      setInitialAccountsLoading(false);
    }
  }, [accountsLoading]);

  useEffect(() => {
    if (!initialAccountsLoading) {
      loadCharts();
    }

    //eslint-disable-next-line
  }, [cexPair, timeframe.get, selectedAccounts.get, initialAccountsLoading]);

  const onCancelOrder = useCallback(
    async ({
      pair_id,
      account_id,
      cex_order_id,
      cex_client_order_id,
    }: {
      pair_id: number;
      account_id: number;
      cex_order_id: string;
      cex_client_order_id: string;
    }) => {
      try {
        dispatch(dropAlertState());
        setCancelingOrderLoading(cex_order_id);

        const { isSuccess, errorMessage } = await ApiOrders.cancelOrder({
          pair_id,
          account_id,
          cex_order_id,
          cex_client_order_id,
        });

        if (isSuccess) {
          setOrders(orders => [...orders].filter(el => el.cex_order_id !== cex_order_id));
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        }

        setCancelingOrderLoading(undefined);
      } catch (error) {
        console.log('error: ', error);
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    },
    [dispatch],
  );

  const onCancelMany = useCallback(
    async (orders: ICexActiveOrder[]) => {
      try {
        dispatch(dropAlertState());

        const result = await Promise.all(
          orders.map(
            async ({ pair_id, account_id, cex_order_id, cex_client_order_id }) =>
              await ApiOrders.cancelOrder({
                pair_id,
                account_id,
                cex_order_id,
                cex_client_order_id,
              }),
          ),
        );

        if (result.filter(el => el.isSuccess).length === result.length) {
          setOrders(v =>
            [...v].filter(el => !orders.find(order => el.cex_order_id === order.cex_order_id)),
          );
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: result.find(el => !el.isSuccess)?.errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        }

        setCancelingOrderLoading(undefined);
      } catch (error) {
        console.log('error: ', error);
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    },
    [dispatch],
  );

  const chartOrders = useMemo(
    () =>
      activeOrders.map(order => {
        return {
          id: order.id,
          side: order.side,
          amount: order.priceNumber,
        };
      }),
    [activeOrders],
  );

  useDeepCompareEffect(() => {
    getOrders();

    const interval = setInterval(() => {
      getOrders({ silent: true });
    }, 10 * 1000); // 10 seconds

    return () => {
      clearInterval(interval);
    };
  }, [cexPair, accountsToAPI, selectedAccounts.get, startDate, endDate, periodToAPI]);

  return (
    <ChartsLoadContext.Provider
      value={{
        loadPaginationCharts,
        loadChartsUpdate,

        //records
        candleStickRecords: records.map(el => ({
          time: el.time as UTCTimestamp,
          close: el.close,
          high: el.high,
          low: el.low,
          open: el.open,
        })),
        balancesChartRecords: [
          {
            label: cexPair.token_base.symbol,
            records: records.map(el => ({
              time: el.time as UTCTimestamp,
              value: priceFormat === 'token' ? el.baseBalance : el.baseBalanceUsd,
            })),
          },
          {
            label: cexPair.token_quote.symbol,
            records: records.map(el => ({
              time: el.time as UTCTimestamp,
              value: priceFormat === 'token' ? el.quoteBalane : el.quoteBalanceUsd,
            })),
          },
        ],
        totalBalanceUsdRecords: records.map(el => ({
          time: el.time,
          value: el.balanceTotalUsd,
        })) as {
          time: UTCTimestamp;
          value: number;
        }[],
        totalVolumeRecords: records.map(el => ({
          time: el.time,
          value: el.volume,
        })) as {
          time: UTCTimestamp;
          value: number;
        }[],
        internalVolumeRecords: records.map(el => ({
          time: el.time,
          value: el.internalVolume,
        })) as {
          time: UTCTimestamp;
          value: number;
        }[],
        loading,
        setLoading,
        paginationLoading,
        setPaginationLoading,
        hasMorePagination,
        setHasMorePagination,
        chartArgs,
        chartPaginationArgs,
        activeOrdersCount,
        orders: activeOrders,
        ordersLoading,
        chartOrders,
        onCancelOrder,
        onCancelMany,
        cancelingOrderLoading,
      }}
    >
      {children}
    </ChartsLoadContext.Provider>
  );
};
