import React, { useMemo, useState, useCallback, lazy } from 'react';
import { useTypedSelector } from 'store';

import { Panel, BotEnableStatus, AutoRefresh } from 'ui';
import { EDexBot } from 'types/bots';
import { IPanelProps } from 'types/pairs';
import { PAIR_BUYSELL_BOT_RELOAD } from 'constant/reload';

import { TradingIcon } from 'assets/icons';

const PanelContent = lazy(() => import('./components/Content/Content'));

const BuySellBot: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const pairId = useTypedSelector(store => store.pairs.selectedDexPair)!.id;
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(initialOpened ?? false);

  const pairConnectedBots = useTypedSelector(
    store => store.pairConnectedBots.pairsConnectedBots[pairId],
  );
  const isBotEnabled = useMemo(
    () => (pairConnectedBots ? pairConnectedBots[EDexBot.buy_sell_bot] : undefined),
    [pairConnectedBots],
  );

  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);
    },
    [setIsOpened],
  );

  return (
    <Panel
      title="BuyAndSellTokens algorithm"
      icon={TradingIcon}
      initialOpened={initialOpened}
      onChange={handleSwitch}
      headerNode={
        <div className="w-full flex flex-row items-center justify-between">
          {isBotEnabled !== undefined && (
            <BotEnableStatus status={isBotEnabled ? 'enabled' : 'disabled'} />
          )}
          {!!isPanelOpened && (
            <AutoRefresh
              busEvent={PAIR_BUYSELL_BOT_RELOAD}
              tooltipText="Auto refresh"
              pairId={pairId}
              cacheName="buysell-bot"
            />
          )}
        </div>
      }
    >
      <PanelContent />
    </Panel>
  );
};

export { BuySellBot };
