import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTypedSelector } from 'store';
import dayjs from 'dayjs';

import { ApiBalanceBot } from 'api';
import { useDexBotConfig } from 'hooks/dex';
import { IDexBalanceBotTask, EDexBot, IDexBotSettings } from 'types/bots';
import { Bus } from 'tools';
import { PAIR_BALANCE_BOT_RELOAD, STOP_RELOAD_PREFIX } from 'constant/reload';
import { IEstimateFee } from '../api/apiBalanceBot/models/IGetBalanceBotResponse';

export interface IDexBalanceBotContext {
  loading: { get: boolean; set: (v: boolean) => void };
  records: {
    get: IDexBalanceBotTask[] | undefined | null;
    set: (v: IDexBalanceBotTask[] | undefined | null) => void;
  };
  estimateFee: IEstimateFee | undefined | null;
  botSettings: IDexBotSettings | undefined;
  handleLoadRecords: () => Promise<void>;
  handleLoadBotSettings: () => Promise<void>;
  handleSortRecords: (records?: IDexBalanceBotTask[]) => void;
  extendedRow: number | undefined;
  setExtendedRow: (v: number | undefined) => void;
  errorMessage: string | undefined;
  taskEnablingLoading: { get: number | undefined; set: (v: number | undefined) => void };
}

export const DexBalanceBotContext = createContext<IDexBalanceBotContext>({
  loading: { get: false, set: () => {} },
  records: { get: undefined, set: () => {} },
  estimateFee: undefined,
  extendedRow: undefined,
  setExtendedRow: () => {},
  botSettings: undefined,
  handleLoadRecords: async () => {},
  handleLoadBotSettings: async () => {},
  handleSortRecords: () => {},
  errorMessage: undefined,
  taskEnablingLoading: { get: undefined, set: () => {} },
});

interface IDexBalanceBotContextProviderProps {
  children?: React.ReactNode;
}

export const DexBalanceBotContextProvider: React.FC<IDexBalanceBotContextProviderProps> = ({
  children,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const [loading, setLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<IDexBalanceBotTask[] | undefined | null>(undefined);
  const [estimateFee, setEstimateFee] = useState<IEstimateFee | undefined | null>(undefined);
  const [botSettings, setBotSettings] = useState<IDexBotSettings | undefined>(undefined);

  const [taskEnablingLoading, setTaskEnablingLoading] = useState<number | undefined>(undefined);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [extendedRow, setExtendedRow] = useState<number | undefined>(undefined);

  const { getBotConfig } = useDexBotConfig();

  const handleSetExtendedRow = useCallback((index: number | undefined) => {
    if (index == undefined) {
      setExtendedRow(undefined);
    } else {
      setExtendedRow(v => (v === index ? undefined : index));
    }
  }, []);

  const handleSortRecords = useCallback(
    (recordsParam?: IDexBalanceBotTask[]) => {
      try {
        const _records = recordsParam || records || [];

        const newRecords = _records
          .sort((a, b) => {
            if (dayjs(a.created_at).valueOf() > dayjs(b.created_at).valueOf()) return -1;

            if (dayjs(a.created_at).valueOf() === dayjs(b.created_at).valueOf()) return 0;

            return 1;
          })
          .sort((a, b) => {
            if (a.is_active && !b.is_active) return -1;

            if (!a.is_active && b.is_active) return 1;

            return 0;
          });

        setRecords(newRecords);
      } catch (error) {
        console.log('error: ', error);
      }
    },
    [records],
  );

  const handleLoadRecords = useCallback(
    async (params?: { silent: boolean }) => {
      const isSilent = params?.silent ?? false;

      if (!isSilent) {
        setLoading(true);
        setExtendedRow(undefined);
        setErrorMessage(undefined);
      }

      try {
        const { isSuccess, errorMessage, data } = await ApiBalanceBot.getPairBalanceBotTasks({
          pairs_ids: dexPair.id,
        });
        if (isSuccess && data) {
          handleSortRecords(data.items);

          setErrorMessage(undefined);
        } else {
          setRecords(undefined);
          setErrorMessage(errorMessage ?? undefined);
        }
      } catch (error) {
        console.log(error);
        setErrorMessage(
          'You get an error while trying to get balance bot records. Try to refresh your page...',
        );
      } finally {
        if (!isSilent) {
          setLoading(false);
        }
      }
    },
    [dexPair, handleSortRecords],
  );

  const handleLoadBotSettings = useCallback(
    async (params?: { silent: boolean }) => {
      const isSilent = params?.silent ?? false;

      if (!isSilent) {
        setLoading(true);
      }

      const botConfig = await getBotConfig({ pairId: dexPair.id, bot: EDexBot.balance_bot });

      setBotSettings({
        is_enabled: botConfig.is_enabled,
        send_private_transactions: botConfig.send_private_transactions,
        reserve_wallets_priority: botConfig.reserve_wallets_priority ?? 'default',
        slippage_percent: botConfig.slippage_percent,
      });

      if (!isSilent) {
        setLoading(false);
      }
    },
    [dexPair, getBotConfig],
  );

  useEffect(() => {
    handleLoadBotSettings();
  }, [handleLoadBotSettings]);

  const handleLoadEstimateFee = useCallback(
    async (params?: { silent: boolean }) => {
      const isSilent = params?.silent ?? false;

      if (!isSilent) {
        setLoading(true);
        setExtendedRow(undefined);
        setErrorMessage(undefined);
      }

      try {
        const { isSuccess, errorMessage, data } = await ApiBalanceBot.getEstimateFeeById({
          pairs_id: dexPair.id,
        });
        if (isSuccess && data) {
          setEstimateFee(data);

          setErrorMessage(undefined);
        } else {
          setRecords(undefined);
          setErrorMessage(errorMessage ?? undefined);
        }
      } catch (error) {
        console.log(error);
        setErrorMessage(
          'You get an error while trying to get Estimate fee records. Try to refresh your page...',
        );
      } finally {
        if (!isSilent) {
          setLoading(false);
        }
      }
    },
    [dexPair.id],
  );

  useEffect(() => {
    handleLoadRecords();
    handleLoadEstimateFee();
  }, [dexPair.id]);

  const handleUpdate = useCallback(async () => {
    const startTime = Date.now();

    try {
      await Promise.all([
        handleLoadBotSettings({ silent: true }),
        handleLoadRecords({ silent: true }),
        handleLoadEstimateFee({ silent: true }),
      ]);
    } catch (e) {}

    const endTime = Date.now();

    if (endTime - startTime >= 1500) {
      Bus.emit(STOP_RELOAD_PREFIX + PAIR_BALANCE_BOT_RELOAD);
    } else {
      setTimeout(() => {
        Bus.emit(STOP_RELOAD_PREFIX + PAIR_BALANCE_BOT_RELOAD);
      }, 1500 - (endTime - startTime));
    }
  }, [handleLoadBotSettings, handleLoadRecords, handleLoadEstimateFee]);

  useEffect(() => {
    Bus.on(PAIR_BALANCE_BOT_RELOAD, handleUpdate);

    return () => {
      Bus.off(PAIR_BALANCE_BOT_RELOAD, handleUpdate);
    };
  }, [handleUpdate]);

  return (
    <DexBalanceBotContext.Provider
      value={{
        loading: { get: loading, set: setLoading },
        records: { get: records, set: setRecords },
        estimateFee,
        botSettings,
        handleLoadRecords,
        handleLoadBotSettings,
        handleSortRecords,
        errorMessage,
        extendedRow: extendedRow,
        setExtendedRow: handleSetExtendedRow,
        taskEnablingLoading: { get: taskEnablingLoading, set: setTaskEnablingLoading },
      }}
    >
      {children}
    </DexBalanceBotContext.Provider>
  );
};
