import { useCallback, useMemo } from 'react';
import { useEffectOnce } from 'react-use';
import { isNil } from 'lodash';

import {
  FILTER_NUMBER_CONNECTIONS,
  EFilterNumberConnection,
  IFilterValue,
} from 'types/filters/common';

import { FilterSelectField } from '../fields';

interface INumbericProps {
  filter: IFilterValue;
  updateFilter: (id: string, filter?: IFilterValue) => void;
}

function Numeric({ filter, updateFilter }: INumbericProps) {
  const selectedItem = useMemo(
    () => FILTER_NUMBER_CONNECTIONS.find(el => el.value === filter.value?.connection) ?? undefined,
    [filter.value],
  );

  const handleSelectItem = useCallback(
    (item: { label: string; value: EFilterNumberConnection }) => {
      //@ts-ignore
      updateFilter(filter.id, {
        ...filter,
        value: { connection: item.value, value: (filter.value?.value as string) ?? undefined },
      });
    },
    [filter, updateFilter],
  );

  useEffectOnce(() => {
    if (isNil(selectedItem)) {
      //@ts-ignore
      updateFilter(filter.id, {
        ...filter,
        value: {
          connection: EFilterNumberConnection.equal,
          value: (filter.value?.value as string) ?? undefined,
        },
      });
    }
  });

  return (
    <FilterSelectField
      items={FILTER_NUMBER_CONNECTIONS}
      itemToString={v => v.label}
      selected={selectedItem}
      onSelectItem={handleSelectItem}
      placeholder="Select"
    />
  );
}

export { Numeric };
