import React from 'react';
import { PathMatch, matchPath, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store';

import { ConnectWalletButton, WalletButton } from 'ui';
import { ROUTE_PATHS } from 'constant/routes';
import { PageHeaderPiece } from '../PageHeaderPiece/PageHeaderPiece';
import { MoreActionsMenu } from '../MoreActionsMenu';

import './header.scss';

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);

  const dexPathMatch: PathMatch<'projectId' | 'pairId'> | null = matchPath(
    ROUTE_PATHS.dexPair,
    pathname,
  );

  return (
    <header className="mm-header">
      <div className="mm-header__left">
        <PageHeaderPiece />
      </div>
      <div className="mm-header__right">
        {isAdmin && !!dexPathMatch && (
          <>
            <ConnectWalletButton />
            <WalletButton />
          </>
        )}
        <MoreActionsMenu />
      </div>
    </header>
  );
};

export { Header };
