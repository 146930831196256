import React, { useCallback, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { motion } from 'framer-motion';
import cn from 'classnames';

import { useResizeObserver } from 'hooks/utility';

import './backdrop.scss';

interface IBackdrop {
  children: React.ReactNode;
  onClickBackdrop?: () => void;
  className?: string;
}

const Backdrop: React.FC<IBackdrop> = ({ children, onClickBackdrop, className }) => {
  const backdropRef = useRef<HTMLElement | null>(null);
  const [maxWidth, setMaxWidth] = useState<string>('0px');

  const [resizableRef, sizes] = useResizeObserver();

  useDebounce(() => setMaxWidth(`${sizes.width - 40}px`), 200, [sizes]);

  const handleClickBackdrop = useCallback(() => {
    if (onClickBackdrop) {
      onClickBackdrop();
    }
  }, [onClickBackdrop]);

  return (
    <div className={cn(className)}>
      <div
        className="mm-general-modal-backdrop-holder"
        ref={node => {
          backdropRef.current = node;
          resizableRef.current = node;
        }}
      >
        <motion.div
          key="general-modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={cn('mm-general-modal-backdrop', { _clickable: onClickBackdrop })}
          onClick={handleClickBackdrop}
        />
      </div>
      <div className="mm-general-modal-backdrop-children-holder">
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          className="mm-general-modal-backdrop__children-wrapper"
          style={{ maxWidth }}
        >
          {children}
        </motion.div>
      </div>
      <div className="mm-general-modal-backdrop__sidebar-cover" onClick={handleClickBackdrop} />
    </div>
  );
};

export { Backdrop };
