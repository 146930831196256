import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { Spinner } from 'ui';
import { eBotMapping, ECexBot, EDexBot, IPairBot, pairBots } from 'types/bots';
import { IDexPair } from 'types/pairs';
import { ApiPairs } from 'api';

import './style.scss';

interface IInnerConnectedBot extends IPairBot {
  is_connected: boolean;
}

interface IRowConnectedBotsProps {
  connected_bots?: IPairBot[];
  pair: IDexPair;
  walletId: number;
  onChangeConnectedBots?: (new_connected_bots: IPairBot[]) => void;
}

const PairTableConnectedBots: React.FC<IRowConnectedBotsProps> = React.memo(
  ({ connected_bots = [], pair, walletId, onChangeConnectedBots }) => {
    const [connectedBots, setConnectedBots] = useState<IPairBot[] | undefined>(undefined);
    const [innerBots, setInnerBots] = useState<IInnerConnectedBot[]>([]);
    const [loadingBotId, setLoadingBotId] = useState<number | undefined>(undefined);

    useEffect(() => {
      if (JSON.stringify(connectedBots) !== JSON.stringify(connected_bots)) {
        setConnectedBots(connected_bots);
        setInnerBots(
          new Array(5)
            .fill(0)
            .map((_, _idx) => _idx + 1)
            .map(el => {
              const connectedBotById = connected_bots.find(bot => bot.id === el);

              if (connectedBotById) return { ...connectedBotById, is_connected: true };

              const notConnectedBotById = pairBots.find(bot => bot.id === el);

              if (notConnectedBotById) return { ...notConnectedBotById, is_connected: false };

              return null;
            })
            .filter(el => el !== null) as IInnerConnectedBot[],
        );
      }
    }, [connected_bots, connectedBots]);

    const handleDisconnectBot = useCallback(
      async (botId: number) => {
        if (loadingBotId) return;

        const newInnerBots = innerBots.map(el =>
          el.id === botId ? { ...el, is_connected: false } : el,
        );
        const newConnectedBots = newInnerBots
          .filter(el => el.is_connected)
          .map(el => ({ ...el, is_connected: undefined }));

        const newWallets = [
          {
            id: walletId,
            bots_ids: newInnerBots.filter(el => el.is_connected).map(el => el.id),
          },
        ];

        if (onChangeConnectedBots) {
          onChangeConnectedBots(newConnectedBots);
        }

        try {
          setInnerBots(newInnerBots);
          setLoadingBotId(botId);

          await ApiPairs.addWalletsToPair({ pairId: pair.id, wallets: newWallets });
        } catch (error) {
        } finally {
          setLoadingBotId(undefined);
        }
      },
      [pair, walletId, innerBots, onChangeConnectedBots, loadingBotId],
    );

    const handleConnectBot = useCallback(
      async (botId: number) => {
        if (loadingBotId) return;

        const newInnerBots = innerBots.map(el =>
          el.id === botId ? { ...el, is_connected: true } : el,
        );
        const newConnectedBots = newInnerBots
          .filter(el => el.is_connected)
          .map(el => ({ ...el, is_connected: undefined }));

        const newWallets = [
          {
            id: walletId,
            bots_ids: newInnerBots.filter(el => el.is_connected).map(el => el.id),
          },
        ];

        if (onChangeConnectedBots) {
          onChangeConnectedBots(newConnectedBots);
        }

        try {
          setInnerBots(newInnerBots);
          setLoadingBotId(botId);

          await ApiPairs.addWalletsToPair({ pairId: pair.id, wallets: newWallets });
        } catch (error) {
        } finally {
          setLoadingBotId(undefined);
        }
      },
      [walletId, pair, innerBots, onChangeConnectedBots, loadingBotId],
    );

    return (
      <div className="mm-pair-table-connected-bots">
        {innerBots.map(bot => {
          const { id, is_connected } = bot;

          if (loadingBotId && loadingBotId === id) {
            return <Spinner key={id} size="mini" />;
          }

          return (
            <div
              key={id}
              className={cn('bot-circle', { _connected: !!is_connected })}
              onClick={() => (is_connected ? handleDisconnectBot(id) : handleConnectBot(id))}
            >
              {eBotMapping[id as EDexBot | ECexBot]}
            </div>
          );
        })}
      </div>
    );
  },
);

export { PairTableConnectedBots };
