import { createSlice } from '@reduxjs/toolkit';

import { ICexChartPoint } from 'types/charts';

interface IChartRecordsState {
  chartRecords: ICexChartPoint[];
}

const chartRecordsInitialState: IChartRecordsState = {
  chartRecords: [],
};

const chartRecordsSlice = createSlice({
  name: 'cex-chart-records',
  initialState: chartRecordsInitialState,
  reducers: {
    setCexChartRecords: (state, { payload }: { payload: ICexChartPoint[] }) => {
      state.chartRecords = payload;
    },
    clearCexChartRecords: state => {
      state.chartRecords = [];
    },
  },
});

export const { setCexChartRecords, clearCexChartRecords } = chartRecordsSlice.actions;

export default chartRecordsSlice.reducer;
