import React, { useMemo, lazy } from 'react';
import { useTypedSelector } from 'store';

import { Panel, BotEnableStatus } from 'ui';
import { EDexBot } from 'types/bots';
import { IPanelProps } from 'types/pairs';

import { AntiFrontRunBotIcon } from 'assets/icons';

const Content = lazy(() => import('./components/Content/Content'));

const AntiFrontRunBot: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const pairId = useTypedSelector(store => store.pairs.selectedDexPair)!.id;
  const pairConnectedBots = useTypedSelector(
    store => store.pairConnectedBots.pairsConnectedBots[pairId],
  );

  const isBotEnabled = useMemo(
    () => (pairConnectedBots ? pairConnectedBots[EDexBot.anti_font_run] : undefined),
    [pairConnectedBots],
  );

  return (
    <Panel
      title={'AntiFrontRun algorithm'}
      icon={AntiFrontRunBotIcon}
      initialOpened={initialOpened}
      onChange={setIsOpened}
      headerNode={
        isBotEnabled !== undefined && (
          <BotEnableStatus status={isBotEnabled ? 'enabled' : 'disabled'} />
        )
      }
    >
      <Content />
    </Panel>
  );
};

export { AntiFrontRunBot };
