import * as cexTypes from './cex';
import * as dexTypes from './dex';

export enum EDexBot {
  anti_font_run = 1,
  sl_bot = 2,
  balance_bot = 3,
  buy_sell_bot = 4,
  liquidity_bot = 5,
}

export enum ECexBot {
  smart_liquidity = 6,
  fill_the_gaps = 7,
  buy_sell_bot = 9,
  orderbook_grid = 10,
  order_manager = 11,
}

export type IDexBotSettings = {
  slippage_percent?: string;
  send_private_transactions: boolean;
  reserve_wallets_priority: 'default' | 'random';
  is_enabled: boolean;
  [key: string]: any;
};

export const eBotMapping: Record<ECexBot | EDexBot, string> = {
  [EDexBot.anti_font_run]: 'AF',
  [EDexBot.sl_bot]: 'SL',
  [EDexBot.balance_bot]: 'BB',
  [EDexBot.buy_sell_bot]: 'BS',
  [EDexBot.liquidity_bot]: 'LQ',
  [ECexBot.smart_liquidity]: 'SL',
  [ECexBot.fill_the_gaps]: 'FG',
  [ECexBot.buy_sell_bot]: 'BS',
  [ECexBot.orderbook_grid]: 'OG',
  [ECexBot.order_manager]: 'OM',
};

export interface IAuthBot {
  created_at: string;
  description: string;
  id: number;
  name: string;
  type: 'dex' | 'cex';
}

export type IPairBot = {
  id: number;
  name: string;
  description: string;
};

export const pairBots: IPairBot[] = [
  { id: 1, name: 'mm-afrbot', description: 'Anti front-run bot' },
  { id: 2, name: 'mm-wtbot', description: 'Smart liquidity bot' },
  { id: 3, name: 'mm-balancebot', description: 'Balance bot' },
  { id: 4, name: 'mm-buysellbot', description: 'Buy sell bot' },
  { id: 5, name: 'mm-liquiditybot', description: 'Liquidity bot' },
];
export const cexPairBots: IPairBot[] = [
  { id: 6, name: 'mm-cexwtbot', description: 'Smart liquidity bot' },
  { id: 7, name: 'mm-cexfillgapsbot', description: 'CEX Fill the gaps bot' },
  { id: 9, name: 'mm-cexbuysellbot', description: 'CEX Buy-sell bot' },
  { id: 10, name: 'mm-cexorderbookv2bot', description: 'CEX Order Book V2 bot' },
  { id: 11, name: 'mm-cexordermanagerbot', description: 'CEX Order Manager bot' },
];

export type IBotStatus = 'enabled' | 'disabled';

export type IDexBotConfig<V extends EDexBot> = V extends EDexBot.balance_bot
  ? dexTypes.IDexBalanceBotConfigV1
  : V extends EDexBot.anti_font_run
  ? dexTypes.IDexAFRBotConfig
  : V extends EDexBot.buy_sell_bot
  ? dexTypes.IDexBuySellBotConfig
  : V extends EDexBot.sl_bot
  ? dexTypes.IDexSLBotConfig
  : undefined;

export type ICexBotConfig<V extends ECexBot> = V extends ECexBot.smart_liquidity
  ? cexTypes.ICexSLConfig
  : V extends ECexBot.fill_the_gaps
  ? cexTypes.ICexFGBotConfig
  : V extends ECexBot.orderbook_grid
  ? cexTypes.ICexOrderbookGridBotConfig
  : undefined;

export * from './cex';
export * from './dex';
