import React, { useMemo, useCallback } from 'react';

import './style.scss';

interface IFilterInputFiedProps {
  value: string;
  setValue: (v: string) => void;
  placeholder: string;
  type: 'decimal-number' | 'natural-number' | 'text';
}

const FilterInputField: React.FC<IFilterInputFiedProps> = ({
  setValue,
  value,
  placeholder,
  type,
}) => {
  const inputType = useMemo(() => {
    if (['natural-number', 'decimal-number'].includes(type)) {
      return 'text';
    }
    return type;
  }, [type]);

  const inputMode = useMemo(() => {
    return type === 'decimal-number'
      ? 'decimal'
      : type === 'natural-number'
      ? 'numeric'
      : undefined;
  }, [type]);

  const normalizeDecimalNumber = useCallback(
    (_value: string) => {
      const sanitizedValue = _value.replace('-', '');
      return isNaN(Number(sanitizedValue)) ? value : sanitizedValue;
    },
    [value],
  );

  const normalizeNaturalNumber = useCallback(
    (_value: string) => {
      const sanitizedValue = _value.replace('-', '');
      const result = isNaN(Number(sanitizedValue)) ? value : sanitizedValue;

      return result.split('.')?.[0] ?? '';
    },
    [value],
  );

  const handleInput = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      let resultValue = event.currentTarget.value;

      if (type === 'decimal-number') {
        resultValue = normalizeDecimalNumber(resultValue);
      }
      if (type === 'natural-number') {
        resultValue = normalizeNaturalNumber(resultValue);
      }

      if (setValue) {
        setValue(resultValue);
      }
    },
    [setValue, type, normalizeDecimalNumber, normalizeNaturalNumber],
  );

  return (
    <div className="mm-filter-input-field">
      <input
        className="mm-filter-field-container"
        type={inputType}
        inputMode={inputMode}
        onInput={handleInput}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

export { FilterInputField };
