import { IFilterScheme, IFilterSchemeExtended, IFilterValue } from 'types/filters/common';
import { filtersFormatter } from './formatters';

export function filtersToValues({ filters }: { filters: IFilterValue[] }) {
  const mapper = (node: IFilterSchemeExtended): IFilterScheme[] => {
    if ('name' in node && 'type' in node) {
      return [node];
    }

    if ('filters' in node) {
      return (node.filters as IFilterScheme[]).map(el => mapper(el)).flat();
    }

    return [];
  };

  const reversedFilters = [...filters].reverse();

  const values = reversedFilters.map(el =>
    filtersFormatter({
      filter: el,
      formatter: el.scheme?.formatter,
    }),
  );

  const valuesReduced = values.reduce((acc, val) => {
    const key = Object.keys(val)[0];

    if (!key || !val[key]) return acc;

    return { ...acc, [key]: acc[key] ? acc[key].concat([val[key]]) : [val[key]] };
  }, {}) as Record<string, any[]>;

  return valuesReduced;
}
