import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse } from '../apiBase';
import { IWithdrawWhitelistItem, IPrepareConfirmationResponse } from './models';

class ApiWithdrawWhitelist extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiWithdrawWhitelist | undefined;
  private static instance = (() => {
    if (ApiWithdrawWhitelist.__instance === undefined) {
      ApiWithdrawWhitelist.__instance = new ApiWithdrawWhitelist();
    }
    return ApiWithdrawWhitelist.__instance;
  })();

  public static getWithdrawWhitelist = async ({
    limit,
    offset,
    projectIds,
    pairsIds,
  }: {
    limit: number;
    offset: number;
    projectIds?: string[];
    pairsIds?: string[];
  }) => {
    type Return = {
      items: IWithdrawWhitelistItem[] | null;
      total_items: number;
    };

    try {
      const urlParams = new URLSearchParams();
      urlParams.append('limit', limit.toString());
      urlParams.append('offset', offset.toString());

      if (projectIds && projectIds.length !== 0) {
        for (const projectId of projectIds) {
          urlParams.append('project_ids', projectId.toString());
        }
      }

      if (pairsIds && pairsIds.length !== 0) {
        for (const pairId of pairsIds) {
          urlParams.append('pair_ids', pairId.toString());
        }
      }

      const response = await ApiWithdrawWhitelist.instance.get<IBaseResponse<Return>>(
        '/withdrawal-whitelist?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<Return>({ error });
    }
  };

  public static getWithdrawWhitelistItem = async ({ id }: { id: number }) => {
    try {
      const response = await ApiWithdrawWhitelist.instance.get<
        IBaseResponse<IWithdrawWhitelistItem>
      >(`/withdrawal-whitelist/${id}`);

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IWithdrawWhitelistItem>({ error });
    }
  };

  public static addWithdrawWhitelist = async ({
    pair_id,
    wallet_address,
  }: {
    pair_id: number;
    wallet_address: string;
  }) => {
    try {
      const response = await ApiWithdrawWhitelist.instance.post<IBaseResponse<null>>(
        '/withdrawal-whitelist',
        {
          pair_id,
          wallet_address,
        },
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<null>({ error });
    }
  };

  public static deleteWithdrawWhitelist = async ({ id }: { id: number }) => {
    try {
      const response = await ApiWithdrawWhitelist.instance.delete<IBaseResponse<null>>(
        `/withdrawal-whitelist/${id}`,
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<null>({ error });
    }
  };

  public static confirm = async ({ id, signature }: { id: number; signature: string }) => {
    try {
      const response = await ApiWithdrawWhitelist.instance.post<IBaseResponse<any>>(
        `/withdrawal-whitelist/${id}/confirm`,
        { signature },
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static prepareConfirmation = async ({ id }: { id: number }) => {
    try {
      const response = await ApiWithdrawWhitelist.instance.post<
        IBaseResponse<IPrepareConfirmationResponse>
      >(`/withdrawal-whitelist/${id}/prepare-confirmation`, {});

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IPrepareConfirmationResponse>({ error });
    }
  };
}

export { ApiWithdrawWhitelist };
