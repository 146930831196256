import { useCallback, useMemo } from 'react';
import { useTypedSelector, useTypedDispatch } from 'store';
import { RootStore } from 'store';

import { EExchange } from 'web3';
import {
  EDexPairFilters,
  ECexPairFilters,
  EGeneralFilters,
  dexPairFiltersScheme,
  cexPairFiltersScheme,
  generalWalletsFiltersScheme,
} from 'types/filters';
import { IFilterValue } from 'types/filters/common';
import { Bus } from 'tools';

import { useGetFilters } from './useGetFilters';
import { useIsFiltersSet } from './useIsFiltersSet';
import {
  addFilter as addStoreFilter,
  updateFilter as updateStoreFilter,
  deleteFilter as deleteStoreFilter,
  clearFilters as clearStoreFilter,
  saveFilters as saveStoreFilters,
} from '..';

interface IProps {
  general?: EGeneralFilters;
  cex?: {
    pairId: number | undefined;
    type: ECexPairFilters;
  };
  dex?: {
    pairId: number | undefined;
    type: EDexPairFilters;
  };
}

export function useFilters({ cex, dex, general }: IProps) {
  const dispatch = useTypedDispatch();

  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair);
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);

  const getDexPairFilters = useGetFilters({ cex, dex, general });
  const isFilterSetted = useIsFiltersSet({ cex, dex, general });

  const pairFiltersScheme = useMemo(
    () =>
      general && general === EGeneralFilters.wallets
        ? generalWalletsFiltersScheme
        : dex
        ? dexPairFiltersScheme({ pair: dexPair!, type: dex.type })
        : cex
        ? cexPairFiltersScheme({ pair: cexPair!, type: cex.type })
        : {},
    [dexPair, cexPair, cex, dex, general],
  );

  const pairFilters = useMemo(() => getDexPairFilters(), [getDexPairFilters]);

  const settedPairFiltersSelector = useCallback(
    (store: RootStore) => {
      if (general) return store.filters[general]?.setted ?? [];

      if (dex) return store.filters.dex[dex.pairId ?? 0]?.filters[dex.type]?.setted ?? [];

      if (cex) return store.filters.cex[cex.pairId ?? 0]?.filters[cex.type]?.setted ?? [];

      return [];
    },
    [general, dex, cex],
  );

  const settedPairFilters = useTypedSelector(settedPairFiltersSelector);

  const addFilter = useCallback(
    (filter?: IFilterValue) => {
      dispatch(addStoreFilter({ filter, general, dex, cex }));
    },
    [dispatch, general, dex, cex],
  );

  const updateFilter = useCallback(
    function (id: string, filter?: IFilterValue | undefined) {
      dispatch(
        updateStoreFilter({
          id,
          filter,
          general,
          cex,
          dex,
        }),
      );
    },
    [dispatch, general, dex, cex],
  );

  const deleteFilter = useCallback(
    (id: string) => {
      dispatch(deleteStoreFilter({ id, general, cex, dex }));
    },
    [dispatch, general, cex, dex],
  );

  const clearFilters = useCallback(() => {
    dispatch(clearStoreFilter({ general, cex, dex }));
  }, [dispatch, general, cex, dex]);

  const saveFilters = useCallback(
    (opts?: { update: boolean }) => {
      const update = opts?.update ?? false;

      dispatch(saveStoreFilters({ general, cex, dex }));

      setTimeout(() => {
        if (update) {
          if (general) {
            Bus.emit(`${general}:FILTER`);
          }
          if (cex) {
            Bus.emit(`${EExchange.cex}:${cex.type}:FILTER`);
          }
          if (dex) {
            Bus.emit(`${EExchange.dex}:${dex.type}:FILTER`);
          }
        }
      }, 0);
    },
    [dispatch, general, cex, dex],
  );

  return {
    pairFilters,
    settedPairFilters: settedPairFilters as IFilterValue[],
    addFilter,
    updateFilter,
    deleteFilter,
    isFilterSetted,
    scheme: pairFiltersScheme,
    clearFilters,
    saveFilters,
  };
}
