import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { motion } from 'framer-motion';
import { useTypedSelector } from 'store';

import './smallRowListSelector.scss';

interface ISmallRowListSelector<T> {
  list: T[];
  selected?: T | undefined;
  onSelect: (item: T) => void;
  field?: keyof T;
}

function SmallRowListSelector<T>({
  list,
  selected,
  onSelect,
  field,
}: ISmallRowListSelector<T>): JSX.Element {
  const palette = useTypedSelector(store => store.ui.selectedThemeColors);
  const extendedList = useMemo(() => list.map(item => ({ item: item, id: uuid() })), [list]);

  return (
    <div className="mm-small-row-list-selector">
      {extendedList.map(item => {
        const itemSelected = JSON.stringify(item.item) === JSON.stringify(selected);

        return (
          <motion.div
            key={item.id}
            animate={{
              borderColor: itemSelected ? palette.primary : 'rgba(89, 50, 234, 0.01)',
              color: itemSelected ? palette.primary : palette.gray_1,
            }}
            className="mm-small-row-list-selector__item"
            onClick={() => onSelect(item.item)}
          >
            {field ? `${item.item[field]}` : `${item}`}
          </motion.div>
        );
      })}
    </div>
  );
}

export { SmallRowListSelector };
