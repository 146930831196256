import React, { useMemo } from 'react';
import cn from 'classnames';
import { useTypedSelector } from 'store';

interface ICurrenciesProps {
  selected: string;
  onSelect: (v: string) => void;
  currencies?: string[];
}

const Currencies: React.FC<ICurrenciesProps> = ({
  selected,
  onSelect,
  currencies: _currencies,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const currencies = useMemo(
    () => _currencies ?? [dexPair.token_base.symbol, dexPair.token_fee.symbol, 'USD'],
    [dexPair, _currencies],
  );

  return (
    <div className="select-currencies">
      {currencies.map((el, idx) => (
        <button
          key={idx}
          className={cn('currency', { _selected: selected === el })}
          onClick={() => onSelect(el)}
        >
          {el}
        </button>
      ))}
    </div>
  );
};

export { Currencies };
