import { IPairWallet, IDexToken } from 'api/apiPairs/models';
import { bn, bnFrom } from 'tools/math';

export const calculateWalletTokenBalance = (wallet?: IPairWallet, token?: IDexToken) => {
  try {
    if (!wallet || !wallet.tokens || !token) return bn(0);

    const walletToken = wallet.tokens.find(walletToken => walletToken.token.id === token.id);

    if (!walletToken) return bn(0);

    return bnFrom(walletToken.balance);
  } catch (error) {
    return bn(0);
  }
};
