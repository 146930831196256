import { bn, bnFrom, divideBignumbers, multiplyBignumbers } from 'tools';
import { ICexPair, IDexPair } from 'types/pairs';

import { convertBnToDecimals } from 'tools';

export const convertTokenDEX = ({
  from,
  to,
  pair,
  amount,
  decimals,
}: {
  from: string;
  to: string;
  pair: IDexPair;
  amount: string;
  decimals: number;
}) => {
  if (isNaN(Number(amount))) return bn(0);

  const pricesUSD = {
    [pair.token_quote.symbol]: bnFrom(pair.token_quote.price_usd),
    [pair.token_base.symbol]: bnFrom(pair.token_base.price_usd),
    [pair.token_fee.symbol]: bnFrom(pair.token_fee.price_usd),
    USD: bn('1', 6),
  };

  if (!pricesUSD[from] || !pricesUSD[to]) return bn(0);

  if (pricesUSD[to].eq(0)) return bn(0);

  const convertPrice = divideBignumbers([pricesUSD[from], 6], [pricesUSD[to], 6]);

  const result = convertBnToDecimals(
    multiplyBignumbers([bn(amount, 18), 18], [convertPrice, 18]),
    18,
    decimals,
  );

  return result;
};

export const convertTokenCEX = ({
  from,
  to,
  pair,
  amount,
}: {
  from: string;
  to: string;
  pair: ICexPair;
  amount: string;
}) => {
  if (isNaN(Number(amount))) return bn(0);

  const pricesUSD = {
    [pair.token_quote.symbol]: bnFrom(pair.token_quote.price_usd),
    [pair.token_base.symbol]: bnFrom(pair.token_base.price_usd),
    USD: bn('1', 6),
  };

  if (!pricesUSD[from] || !pricesUSD[to]) return bn(0);

  if (pricesUSD[to].eq(0)) return bn(0);

  const convertPrice = divideBignumbers([pricesUSD[from], 6], [pricesUSD[to], 6]);

  const result = multiplyBignumbers([bn(amount, 18), 18], [convertPrice, 18]);

  return result;
};
