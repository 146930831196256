import { createSlice } from '@reduxjs/toolkit';

import { LocalStorage } from 'tools';
import { IColorsTheme, themeColors, ETheme } from 'types/theme';
import { applyTheme } from 'utils/applyTheme';
import { IAlert } from './models';

interface IUiSliceState {
  alert: IAlert | undefined;
  copyPopupShow: boolean;
  triggerCopying: boolean;
  isSidebarOpened: boolean;
  selectedTheme: ETheme;
  selectedThemeColors: IColorsTheme;
  searchProjectText: string;
}

const uiSliceInitialState: IUiSliceState = {
  alert: undefined,
  copyPopupShow: false,
  triggerCopying: false,
  isSidebarOpened: true,
  selectedTheme: LocalStorage.get('theme') ?? ETheme.light,
  selectedThemeColors: applyTheme(
    themeColors[(LocalStorage.get('theme') as ETheme) ?? ETheme.light],
  ),
  searchProjectText: '',
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: uiSliceInitialState as IUiSliceState,
  reducers: {
    setAlertState: (state, { payload }: { payload: IAlert }) => {
      state.alert = payload;
    },
    dropAlertState: state => {
      state.alert = undefined;
    },
    setCopyPopupShow: (state, { payload }: { payload: boolean }) => {
      state.copyPopupShow = payload;
      state.triggerCopying = !state.triggerCopying;
    },
    setIsSidebarOpened: (state, { payload }: { payload: boolean }) => {
      state.isSidebarOpened = payload;
    },
    setTheme: (state, { payload }: { payload: ETheme }) => {
      const newThemeColors = themeColors[payload];

      applyTheme(newThemeColors);

      LocalStorage.set('theme', payload);

      state.selectedTheme = payload;
      state.selectedThemeColors = newThemeColors;
    },
    setSearchProjectText: (state, { payload }: { payload: string }) => {
      state.searchProjectText = payload;
    },
  },
});

export const {
  setAlertState,
  dropAlertState,
  setCopyPopupShow,
  setIsSidebarOpened,
  setTheme,
  setSearchProjectText,
} = uiSlice.actions;

export default uiSlice.reducer;
