import React, { createContext, useState, SetStateAction, Dispatch } from 'react';

import { IChartRange } from 'types/charts';

type ISelectedDocuments = {
  id: string;
  label: string;
  enabled: boolean;
}[];

interface IInventoryContext {
  period: { get: IChartRange | undefined; set: Dispatch<SetStateAction<IChartRange | undefined>> };
  startDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  endDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  selectedDocuments: {
    get: ISelectedDocuments;
    set: Dispatch<SetStateAction<ISelectedDocuments>>;
  };
}

export const InventoryContext = createContext<IInventoryContext>({
  period: { get: 'ALL', set: () => {} },
  startDate: { get: 0, set: () => {} },
  endDate: { get: 0, set: () => {} },
  selectedDocuments: { get: [] as ISelectedDocuments, set: () => {} },
});

export const InventoryContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [period, setPeriod] = useState<IChartRange | undefined>('1D');
  const [startDate, setStartDate] = useState<number | undefined>(undefined);
  const [endDate, setEndDate] = useState<number | undefined>(undefined);
  const [selectedDocuments, setSelectedDocuments] = useState<ISelectedDocuments>([
    { id: '1', label: 'Document1', enabled: true },
    { id: '2', label: 'Document2', enabled: true },
    { id: '3', label: 'Document3', enabled: true },
    { id: '4', label: 'Document4', enabled: true },
  ]);

  return (
    <InventoryContext.Provider
      value={{
        period: { get: period, set: setPeriod },
        startDate: { get: startDate, set: setStartDate },
        endDate: { get: endDate, set: setEndDate },
        selectedDocuments: { get: selectedDocuments, set: setSelectedDocuments },
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
