import React, { useState, useCallback } from 'react';
import cn from 'classnames';

import { CreateIcon } from 'assets/icons';
import { ProjectModal } from 'modals';
import { tailwindConfig } from 'tailwind-config';

const CreateNewProject: React.FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const onClickCreateNewProject = useCallback((): void => {
    setIsActive(v => !v);
  }, []);

  const onCloseCreateNewProject = useCallback((): void => {
    setIsActive(false);
  }, []);

  const onReopenCreateNewProject = useCallback((): void => {
    setIsActive(true);
  }, []);

  return (
    <>
      <div
        className={cn('mm-sidebar__menu__item', { _active: isActive })}
        style={{ marginTop: '20px' }}
        onClick={onClickCreateNewProject}
      >
        <div className="mm-sidebar__menu__item__left">
          <div className="mm-sidebar__menu__item__icon">
            <CreateIcon color={isActive ? tailwindConfig.theme.colors.primary : undefined} />
          </div>
          <span className="mm-sidebar__menu__item__text">Create new project</span>
        </div>
      </div>
      {isActive && (
        <ProjectModal
          type="create"
          onClose={onCloseCreateNewProject}
          onOpen={onReopenCreateNewProject}
          initialTitle={undefined}
          initialNotes={undefined}
          initialUsers={[]}
        />
      )}
    </>
  );
};

export { CreateNewProject };
