import { useMemo } from 'react';
import { isNil } from 'lodash';

import { EExchange } from 'web3';
import {
  EFilterEntity,
  EFilterNumberConnection,
  IFilterValue,
  IFilterColorSelectItem,
} from 'types/filters/common';
import { IAuthUser } from 'api/apiUsers/models';
import { ICexAccount } from 'types/accounts';

import {
  FilterInputField,
  FilterSelectField,
  FilterDateRangeField,
  MultiSearchField,
  ColorsMultiSelectField,
  UsersMultiSelectField,
  CexAccountsMultiSelectField,
  ColorsSingleSelectField,
} from '../fields';

const BOOLEAN_ITEMS = [
  { label: 'True', value: true },
  { label: 'False', value: false },
];

interface IFilterValueProps {
  filter: IFilterValue;
  type: EFilterEntity | undefined;
  updateFilter: (id: string, filter?: IFilterValue) => void;
  exchange?: EExchange | undefined;
}

function FilterValue({ filter, type, updateFilter, exchange }: IFilterValueProps) {
  const content = useMemo(() => {
    switch (type) {
      case EFilterEntity.natural_number:
        return (
          <div className="value-container">
            <FilterInputField
              placeholder="Enter number"
              type="natural-number"
              value={(filter.value?.value as string) ?? ''}
              setValue={value => {
                //@ts-ignore
                updateFilter(filter.id, {
                  ...filter,
                  value: {
                    connection: filter.value?.connection as EFilterNumberConnection,
                    value,
                  },
                });
              }}
            />
          </div>
        );
      case EFilterEntity.number:
        return (
          <div className="value-container">
            <FilterInputField
              placeholder="Enter number"
              type="decimal-number"
              value={(filter.value?.value as string) ?? ''}
              setValue={value => {
                //@ts-ignore
                updateFilter(filter.id, {
                  ...filter,
                  value: {
                    connection: filter.value?.connection as EFilterNumberConnection,
                    value,
                  },
                });
              }}
            />
          </div>
        );
      case EFilterEntity.boolean:
        return (
          <div className="boolean-container">
            <FilterSelectField
              items={BOOLEAN_ITEMS}
              itemToString={v => v.label}
              placeholder="Select"
              selected={
                !isNil(filter.value?.value)
                  ? BOOLEAN_ITEMS.find(el => el.value === filter.value?.value)
                  : undefined
              }
              onSelectItem={({ value }) => {
                //@ts-ignore
                updateFilter(filter.id, {
                  ...filter,
                  value: {
                    connection: undefined,
                    value,
                  },
                });
              }}
            />
          </div>
        );
      case EFilterEntity.date_range:
        return (
          <div className="date-range-container">
            <FilterDateRangeField
              value={(filter.value?.value ?? undefined) as string | undefined}
              setValue={(value: string | undefined) =>
                //@ts-ignore
                updateFilter(filter.id, {
                  ...filter,
                  value: {
                    connection: filter.value?.connection,
                    value,
                  },
                })
              }
            />
          </div>
        );
      case EFilterEntity.multi_search:
        return (
          <div className="multi-search-container">
            <MultiSearchField
              values={(filter.value?.value ?? []) as string[]}
              setValues={vals =>
                //@ts-ignore
                updateFilter(filter.id, {
                  ...filter,
                  value: {
                    connection: filter.value?.connection,
                    value: vals,
                  },
                })
              }
            />
          </div>
        );
      case EFilterEntity.colors_multi_select:
        return (
          <div className="multi-search-container">
            <ColorsMultiSelectField
              value={(filter?.value?.value ?? []) as IFilterColorSelectItem[]}
              setValue={vals => {
                //@ts-ignore
                updateFilter(filter.id, {
                  ...filter,
                  value: {
                    connection: filter.value?.connection,
                    value: vals,
                  },
                });
              }}
              items={filter.scheme?.listMultiColors ?? []}
            />
          </div>
        );
      case EFilterEntity.user_multi_select:
        return (
          <div className="users-search-container">
            <UsersMultiSelectField
              exchange={exchange}
              value={(filter?.value?.value ?? []) as IAuthUser[]}
              setValue={vals =>
                //@ts-ignore
                updateFilter(filter.id, {
                  ...filter,
                  value: {
                    connection: filter.value?.connection,
                    value: vals,
                  },
                })
              }
            />
          </div>
        );
      case EFilterEntity.cex_accounts_multi_select:
        return (
          <div className="cex-accounts-search-container">
            <CexAccountsMultiSelectField
              value={(filter?.value?.value ?? []) as ICexAccount[]}
              setValue={vals =>
                //@ts-ignore
                updateFilter(filter.id, {
                  ...filter,
                  value: {
                    connection: filter.value?.connection,
                    value: vals,
                  },
                })
              }
            />
          </div>
        );
      case EFilterEntity.colors_single_select:
        return (
          <div className="single-search-container">
            <ColorsSingleSelectField
              value={filter?.value?.value as IFilterColorSelectItem}
              setValue={val => {
                updateFilter(filter.id, {
                  ...filter,
                  value: {
                    connection: filter.value?.connection,
                    value: val,
                  },
                });
              }}
              items={filter.scheme?.listSingleColors ?? []}
            />
          </div>
        );
      default:
        return null;
    }
  }, [type, filter, updateFilter, exchange]);

  return content === null ? null : content;
}

export { FilterValue };
