import React from 'react';

import { ICexOrderbookGridBotTask } from 'types/bots';

interface IProps {
  task: ICexOrderbookGridBotTask;
}

const CountOfOrders = React.memo(({ task }: IProps) => {
  return (
    <div className="orders-count">
      <div className="orders-count-row">
        <span className="ask">Ask: </span>
        <span>{task.ask_grid_config.order_number}</span>
      </div>
      <div className="orders-count-row">
        <span className="bid">Bid: </span>
        <span>{task.bid_grid_config.order_number}</span>
      </div>
    </div>
  );
});

export { CountOfOrders };
