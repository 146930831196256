import React from 'react';

import './projectForm.scss';

interface IProjectFormProps {
  children: React.ReactNode;
}

const ProjectForm: React.FC<IProjectFormProps> = ({ children }) => {
  return <div className="mm-project-form">{children}</div>;
};

export { ProjectForm };
