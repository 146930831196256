import React, {
  createContext,
  useCallback,
  useState,
  SetStateAction,
  Dispatch,
  useMemo,
  useContext,
} from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTypedSelector } from 'store';
import { Time } from 'lightweight-charts';

import { getPeriodToAPI } from 'utils/charts';
import { IChartRange } from 'types/charts';
import { ICexAccount } from 'types/accounts';
import { ApiStatistic, ApiCharts } from 'api';
import { ChartsControlsContext } from './ChartsControlsContext';

interface IVolumeContext {
  period: { get: IChartRange | undefined; set: Dispatch<SetStateAction<IChartRange | undefined>> };
  startDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  endDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  volumes: {
    total_volume_quote: string;
    total_volume_base: string;
    our_volume_quote: string;
    our_volume_base: string;
  };
  loading: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
  volumeChartPoints: { time: Time; base: number; quote: number }[];
  selectedAccounts: {
    get: ICexAccount[];
    set: Dispatch<SetStateAction<ICexAccount[]>>;
  };
  totalFees: string;
  selectedToken: {
    get: 'base' | 'quote';
    set: Dispatch<SetStateAction<'base' | 'quote'>>;
  };
  selectedTokenSymbol: string;
  selectedMode: {
    get: 'delta' | 'absolute';
    set: Dispatch<SetStateAction<'delta' | 'absolute'>>;
  };
  deltaVolumesBuyPoints: { time: Time; base_buy: number; quote_buy: number }[];
  deltaVolumesSellPoints: { time: Time; base_sell: number; quote_sell: number }[];
}

export const VolumeContext = createContext<IVolumeContext>({
  period: { get: 'ALL', set: () => {} },
  startDate: { get: 0, set: () => {} },
  endDate: { get: 0, set: () => {} },
  volumes: {
    total_volume_quote: '',
    total_volume_base: '',
    our_volume_quote: '',
    our_volume_base: '',
  },
  loading: { get: false, set: () => {} },
  volumeChartPoints: [],
  selectedAccounts: { get: [], set: () => {} },
  totalFees: '',
  selectedToken: { get: 'base', set: () => {} },
  selectedTokenSymbol: '',
  selectedMode: { get: 'delta', set: () => {} },
  deltaVolumesBuyPoints: [],
  deltaVolumesSellPoints: [],
});

export const VolumeContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const pairId = cexPair.id;
  const { accountsToAPI } = useContext(ChartsControlsContext);

  const [period, setPeriod] = useState<IChartRange | undefined>('1D');
  const [startDate, setStartDate] = useState<number | undefined>(undefined);
  const [endDate, setEndDate] = useState<number | undefined>(undefined);

  const [volumes, setVolumes] = useState<{
    total_volume_quote: string;
    total_volume_base: string;
    our_volume_quote: string;
    our_volume_base: string;
  }>({ total_volume_quote: '', total_volume_base: '', our_volume_quote: '', our_volume_base: '' });
  const [totalFees, setTotalFees] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [volumeChartPoints, setVolumeChartPoints] = useState<
    { time: Time; base: number; quote: number }[]
  >([]);
  const [selectedToken, setSelectedToken] = useState<'quote' | 'base'>('quote');
  const [selectedAccounts, setSelectedAccounts] = useState<ICexAccount[]>([]);
  const [selectedMode, setSelectedMode] = useState<'delta' | 'absolute'>('delta');

  const [deltaVolumesBuyPoints, setDeltaVolumesBuyPoints] = useState<
    { time: Time; base_buy: number; quote_buy: number }[]
  >([]);
  const [deltaVolumesSellPoints, setDeltaVolumesSellPoints] = useState<
    { time: Time; base_sell: number; quote_sell: number }[]
  >([]);

  const periodToAPI = useMemo(() => getPeriodToAPI(period), [period]);

  const selectedTokenSymbol = useMemo(() => {
    return selectedToken === 'base' ? cexPair.token_base.symbol : cexPair.token_quote.symbol;
  }, [selectedToken, cexPair]);

  const getChart = useCallback(async () => {
    try {
      const period = periodToAPI;
      const account_id = accountsToAPI(selectedAccounts).optional;

      const [{ data: cexStatisticsChartDelta }, { data: cexStatisticsChartVolume }] =
        await Promise.all([
          isAdmin
            ? ApiCharts.getCexStatisticsChartDelta({
                pairId,
                account_id,
                period,
                startDate,
                endDate,
              })
            : { data: undefined },
          isAdmin
            ? ApiCharts.getCexStatisticsChartVolume({
                pairId,
                account_id,
                period,
                startDate,
                endDate,
              })
            : { data: undefined },
        ]);

      if (isAdmin) {
        setVolumeChartPoints(
          cexStatisticsChartVolume?.points.map((el: any) => ({
            time: (el.time / 1000) as Time,
            base: Number(el.base),
            quote: Number(el.quote),
          })) ?? [],
        );

        setDeltaVolumesBuyPoints(
          cexStatisticsChartDelta?.points.map(el => ({
            time: (el.time / 1000) as Time,
            base_buy: Number(el.buy_value_base),
            quote_buy: Number(el.buy_value_quote),
          })) ?? [],
        );

        setDeltaVolumesSellPoints(
          cexStatisticsChartDelta?.points.map(el => ({
            time: (el.time / 1000) as Time,
            base_sell: -Number(el.sell_value_base),
            quote_sell: -Number(el.sell_value_quote),
          })) ?? [],
        );
      }
    } catch (error) {
      console.log('Error setting points:', error);
    }
  }, [selectedAccounts, accountsToAPI, endDate, pairId, periodToAPI, startDate, isAdmin]);

  const getTotalVolumes = useCallback(async () => {
    try {
      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;
      const period = periodToAPI;
      const account_id = accountsToAPI(selectedAccounts).optional;

      const [
        { data: totalVolumeData },
        { data: cexTotalFeesData },
        { data: cexOurTradesTotalVolumeData },
      ] = await Promise.all([
        ApiStatistic.geCexMarketTotalVolume({ symbol, cex, period, startDate, endDate }),
        ApiStatistic.getCexTotalFees({
          pairId,
          period,
          startDate,
          endDate,
          account_id,
        }),
        isAdmin
          ? ApiStatistic.getCexOurTradesTotalVolume({
              pairId,
              period,
              startDate,
              endDate,
              account_id,
            })
          : { data: undefined },
      ]);

      setVolumes({
        total_volume_base: totalVolumeData?.total_volume_base ?? '',
        total_volume_quote: totalVolumeData?.total_volume_quote ?? '',
        our_volume_quote: cexOurTradesTotalVolumeData?.total_quote ?? '',
        our_volume_base: cexOurTradesTotalVolumeData?.total ?? '',
      });
      setTotalFees(cexTotalFeesData?.fee ?? '');
    } catch (error) {
      console.log('error: ', error);
    }
  }, [selectedAccounts, cexPair, startDate, endDate, periodToAPI, pairId, accountsToAPI, isAdmin]);

  const getInfo = useCallback(async () => {
    try {
      setLoading(true);

      await Promise.all([getTotalVolumes(), getChart()]);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }, [getChart, getTotalVolumes]);

  useDeepCompareEffect(() => {
    getInfo();
  }, [cexPair, accountsToAPI, startDate, endDate, periodToAPI]);

  return (
    <VolumeContext.Provider
      value={{
        period: { get: period, set: setPeriod },
        startDate: { get: startDate, set: setStartDate },
        endDate: { get: endDate, set: setEndDate },
        loading: { get: loading, set: setLoading },
        volumes,
        volumeChartPoints,
        selectedAccounts: { get: selectedAccounts, set: setSelectedAccounts },
        selectedToken: { get: selectedToken, set: setSelectedToken },
        totalFees,
        selectedTokenSymbol,
        selectedMode: { get: selectedMode, set: setSelectedMode },
        deltaVolumesBuyPoints,
        deltaVolumesSellPoints,
      }}
    >
      {children}
    </VolumeContext.Provider>
  );
};
