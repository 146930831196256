import { useCallback } from 'react';
import { isNil } from 'lodash';
import { useTypedSelector } from 'store';

import { ApiPairs } from 'api';
import { filtersToValues } from 'utils/filters';
import { IFilterValue } from 'types/filters/common';
import { TOTAL_TOKEN_ID } from 'constant/numbers';

const useDexWallets = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const getter = useCallback(
    async ({
      limit,
      lastSeenId,
      filters,
    }: {
      limit: number;
      lastSeenId?: number;
      filters: IFilterValue[];
    }) => {
      const filterValues = filtersToValues({
        filters: filters,
      });

      const balance = filterValues.balance as {
        connection: string;
        value: string;
        tokenId: number | undefined;
        name: string;
      }[];
      const volume = filterValues.volume as {
        connection: string;
        value: string;
        tokenId: number | undefined;
        name: string;
      }[];
      const transactions_count = filterValues.transactions_count ?? undefined;
      const is_disabled = filterValues.is_disabled?.[0] ?? undefined;
      const connectedBotsIds = filterValues.connectedBots?.[0] ?? undefined;

      const balanceSerialized: string[] = [];
      const balanceUsdSerialized: string[] = [];

      if (balance) {
        for (const _balance of balance) {
          if (isNil(_balance.tokenId)) continue;

          const val = `token${_balance.tokenId}${_balance.connection}${_balance.value}`;

          if (_balance.tokenId === TOTAL_TOKEN_ID) {
            balanceUsdSerialized.push(val);
          } else {
            balanceSerialized.push(val);
          }
        }
      }

      const volumeSerialized: string[] = [];
      const volumeUsdSerialized: string[] = [];

      if (volume) {
        for (const _volume of volume) {
          if (isNil(_volume.tokenId)) continue;

          const val = `token${_volume.tokenId}${_volume.connection}${_volume.value}`;

          if (_volume.tokenId === TOTAL_TOKEN_ID) {
            volumeUsdSerialized.push(val);
          } else {
            volumeSerialized.push(val);
          }
        }
      }

      const { isSuccess, errorMessage, data } = await ApiPairs.getWalletsConnectedToPair({
        pairId: dexPair.id,
        lastSeenId,
        bots_ids: connectedBotsIds,
        limit,
        balance: balanceSerialized,
        balance_usd: balanceUsdSerialized,
        volume: volumeSerialized,
        volume_usd: volumeUsdSerialized,
        transactions_count,
        is_disabled: isNil(is_disabled) ? undefined : `${!(is_disabled === 'true')}`,
      });

      return { isSuccess, errorMessage, data };
    },
    [dexPair],
  );

  return getter;
};

export { useDexWallets };
