import { bn, bnFrom, multiplyBignumbers } from 'tools/math';

interface ICalculateSwapsGasPriceArgs {
  swap_gas_limit: number;
  swaps_count: number;
  gasPrice: string;
}

export const calculateSwapsGasPrice = ({
  swap_gas_limit,
  swaps_count,
  gasPrice,
}: ICalculateSwapsGasPriceArgs) => {
  const swapsFeeBN = multiplyBignumbers(
    [multiplyBignumbers([bn(swap_gas_limit), 18], [bnFrom(gasPrice), 18]), 18],
    [bn(swaps_count), 18],
  );

  return swapsFeeBN;
};
