import axios, { ResponseType as responseType, AxiosResponse as axiosResponse } from 'axios';

import { LocalStorage } from 'tools';
import store from 'store';
import { logout } from 'store/slices/auth';

import { baseUrl, metricsBaseUrl } from './baseUrl';

const axiosInstance = axios.create({ baseURL: baseUrl });

const axiosInstanceWithToken = axios.create({ baseURL: baseUrl });
axiosInstanceWithToken.interceptors.request.use(config => {
  const token = LocalStorage.get<string>('jwt');

  config.headers = {
    ...config.headers,
    Authorization: token ? `Bearer ${token}` : '',
  };

  return config;
});

axiosInstanceWithToken.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 && error.config.url !== 'api/auth/sessions') {
      store.dispatch(logout());
    }
    throw error;
  },
);

const metricsAxiosInstance = axios.create({ baseURL: metricsBaseUrl });
metricsAxiosInstance.interceptors.request.use(config => {
  const token = LocalStorage.get<string>('jwt');

  config.headers = {
    ...config.headers,
    Authorization: token ? `Bearer ${token}` : '',
  };

  return config;
});

metricsAxiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 && error.config.url !== 'api/auth/sessions') {
      store.dispatch(logout());
    }
    throw error;
  },
);

export type ResponseType = responseType;
export type AxiosResponse<T> = axiosResponse<T>;
export {
  axiosInstance as axios,
  axiosInstanceWithToken as axiosToken,
  metricsAxiosInstance as metricsAxios,
};
