import React, { useState, useCallback, lazy, useContext, useMemo } from 'react';
import { useTypedSelector } from 'store';

import { AutoRefresh, BotEnableStatus, Panel } from 'ui';
import { IPanelProps } from 'types/pairs';
import { CexPairContext } from 'context/CexPairContext';
import { PAIR_CEX_BUYSELL_BOT_RELOAD } from 'constant/reload';

import { TriangleWarning, ColumnChartIcon } from 'assets/icons';
import './style.scss';

const PanelContent = lazy(() => import('./Content'));

const BuySell: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const {
    buySell: { tasks, getTasks },
    updateAccounts,
  } = useContext(CexPairContext);

  const { botStates, botStatesLoading } = useContext(CexPairContext);

  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(initialOpened ?? false);

  const handleSwitch = useCallback(
    async (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);

      if (opened) {
        Promise.all([updateAccounts(), getTasks()]);
      }
    },
    [setIsOpened, updateAccounts, getTasks],
  );

  const isWarning = useMemo(
    () =>
      tasks && tasks.length !== 0
        ? !!tasks.find(el => (el.lackBase || el.lackQuote) && el.active && el.account)
        : false,
    [tasks],
  );

  return (
    <Panel
      title="Buy/Sell algorithm"
      icon={ColumnChartIcon}
      initialOpened={initialOpened}
      onChange={handleSwitch}
      className="cex-buy-sell-grid-panel"
      headerNode={
        <div className="w-full flex flex-row items-center justify-between">
          <div className="warning-container">
            {isWarning && (
              <div className="warning">
                <TriangleWarning />
                <span>Warning!</span>
              </div>
            )}
            {!botStatesLoading && (
              <BotEnableStatus status={botStates?.buy_sell?.status ? 'enabled' : 'disabled'} />
            )}
          </div>
          {!!isPanelOpened && (
            <AutoRefresh
              busEvent={PAIR_CEX_BUYSELL_BOT_RELOAD}
              tooltipText="Auto refresh"
              pairId={cexPair.id}
              cacheName="cex-buysell-bot"
            />
          )}
        </div>
      }
    >
      <PanelContent isPanelOpened={isPanelOpened} />
    </Panel>
  );
};

export { BuySell };
