import React, { useContext } from 'react';

import { Spinner } from 'ui';
import { CexPairContext } from 'context/CexPairContext';
import { Toolbar } from '../Toolbar';
import { Table } from '../Table';

import './style.scss';

const Content: React.FC = () => {
  const {
    orderbook: { loading },
  } = useContext(CexPairContext);

  return (
    <div className="mm-orderbook-grid-content">
      {loading ? (
        <Spinner size="small" />
      ) : (
        <>
          <Toolbar />
          <Table />
        </>
      )}
    </div>
  );
};

export { Content };
