import axios from 'axios';
import { isNil } from 'lodash';

import { IRange, isTestnet, web3Configs } from 'web3';

import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';
import { AApiBase, IBaseResponse } from '../apiBase';
import { ECexName } from 'web3';
import { metricsAxios } from '../apiBase';
import {
  IChartItem,
  IKattanaDexChartArgs,
  IKattanaDexChartResponse,
  ICexChartsPoint,
  ICexVWAPPoint,
} from './models';
import { BotFilter } from 'types/bots';

interface IGetBalancesResponse {
  items: IChartItem[];
  total_items: number;
}

class ApiCharts extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiCharts | undefined;
  private static instance = (() => {
    if (ApiCharts.__instance === undefined) {
      ApiCharts.__instance = new ApiCharts();
    }
    return ApiCharts.__instance;
  })();

  public static getBalances = async ({
    projects_ids,
    pair_ids,
    cex_pair_ids,
    token_ids,
    ranges,
    timeframe,
  }: {
    projects_ids?: number[];
    pair_ids?: number[];
    cex_pair_ids?: number[];
    token_ids?: number[];
    ranges: (IRange | string)[];
    timeframe?: string;
  }) => {
    try {
      const urlParams = new URLSearchParams();
      if (projects_ids && projects_ids.length !== 0) {
        for (const projectId of projects_ids) {
          urlParams.append('project_ids', projectId.toString());
        }
      }

      if (pair_ids && pair_ids.length !== 0) {
        for (const pairId of pair_ids) {
          urlParams.append('pair_ids', pairId.toString());
        }
      } else {
        //TODO remove this shit
        urlParams.append('pair_ids', '');
      }

      if (cex_pair_ids && cex_pair_ids.length !== 0) {
        for (const cexPairId of cex_pair_ids) {
          urlParams.append('cex_pair_ids', cexPairId.toString());
        }
      } else {
        //TODO remove this shit
        urlParams.append('cex_pair_ids', '');
      }

      if (token_ids && token_ids.length !== 0) {
        for (const tokenId of token_ids) {
          urlParams.append('token_ids', tokenId.toString());
        }
      }

      for (const range of ranges) {
        urlParams.append('range', range.toString());
      }

      if (timeframe) {
        urlParams.append('timeframe', timeframe);
      }

      const response = await ApiCharts.instance.get<IBaseResponse<IGetBalancesResponse>>(
        '/charts/balances' + '?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<IGetBalancesResponse>({ error });
    }
  };

  public static getKattanaDexPairChart = async ({
    network,
    pair,
    startTime,
    endTime,
    timeframe,
    limit,
    precision,
    flipped,
    converted,
    poolCreated,
  }: IKattanaDexChartArgs) => {
    try {
      const baseUrl = import.meta.env.VITE_APP_KATTANA_API_URL;
      const slug = web3Configs[network].kattanaSlug;

      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append('precision', precision.toString());
      urlSearchParams.append('flipped', `${flipped}`);
      urlSearchParams.append('volume', 'base');

      if (converted) {
        urlSearchParams.append('converted', converted);
      }
      if (isTestnet) {
        urlSearchParams.append('liquidityFilter', '0');
      }
      if (!isNil(poolCreated)) {
        urlSearchParams.append('poolCreated', poolCreated.toString());
      }

      const response = await axios.get(
        baseUrl +
          `/chart/${slug}/${pair}/${timeframe}/${startTime}/${endTime}/${limit}` +
          '?' +
          urlSearchParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<IKattanaDexChartResponse>({ error });
    }
  };

  public static getCexCharts = async ({
    symbol,
    account_id,
    cex,
    period,
    from,
    to,
    pair_id,
  }: {
    symbol: string;
    account_id: number[];
    cex: ECexName;
    period: string;
    from: number;
    to: number;
    pair_id: number;
  }) => {
    type Return = { points: ICexChartsPoint[] };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('symbol', symbol);
      urlParams.append('cex', cex);
      urlParams.append('period', period === '24h' ? '1d' : period === '168h' ? '1w' : period);
      urlParams.append('from', from.toString());
      urlParams.append('to', to.toString());
      urlParams.append('pair_id', pair_id.toString());

      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get(
        '/api/v1/balance/chart-klines?' + urlParams.toString(),
      );

      return mapResponse<Return>({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexInternalVolumes = async ({
    pair_id,
    account_id,
    step_minutes,
    from,
    to,
  }: {
    pair_id: number;
    account_id?: number[];
    step_minutes: string;
    from: number;
    to: number;
  }) => {
    type Return = { points: { time: number; value: string }[] };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pair_id', pair_id.toString());
      urlParams.append(
        'step_minutes',
        step_minutes === '24h' ? '1d' : step_minutes === '168h' ? '1w' : step_minutes,
      );
      urlParams.append('from', from.toString());
      urlParams.append('to', to.toString());

      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get('/api/v1/chart/trade?' + urlParams.toString());

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexStatisticsChartFee = async ({
    pairId,
    account_id,
    period,
    startDate,
    endDate,
  }: {
    pairId: number;
    account_id?: number[];
    period?: string;
    startDate?: number;
    endDate?: number;
  }) => {
    type Return = { points: { time: number; value: string }[] };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pair_id', pairId.toString());
      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }
      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get<Return>('/api/v1/chart/fee?' + urlParams.toString());

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexStatisticsChartDelta = async ({
    pairId,
    account_id,
    period,
    startDate,
    endDate,
  }: {
    pairId: number;
    account_id?: number[];
    period?: string;
    startDate?: number;
    endDate?: number;
  }) => {
    type Return = {
      points: {
        time: number;
        buy_value_base: string;
        buy_value_quote: string;
        sell_value_base: string;
        sell_value_quote: string;
      }[];
    };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pair_id', pairId.toString());
      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }
      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/chart/delta?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexStatisticsChartVolume = async ({
    pairId,
    account_id,
    period,
    startDate,
    endDate,
  }: {
    pairId: number;
    account_id?: number[];
    period?: string;
    startDate?: number;
    endDate?: number;
  }) => {
    type Return = { points: { time: number; base: string; quote: string }[] };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('pair_id', pairId.toString());
      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }
      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/chart/volume?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexStatisticsChartLiquidity = async ({
    symbol,
    cex,
    period,
    startDate,
    endDate,
    percentage,
  }: {
    symbol: string;
    cex: ECexName;
    period?: string;
    startDate?: number;
    endDate?: number;
    percentage: number;
  }) => {
    type Return = {
      points: { time: number; value_in_base: string; value_in_quote: string }[];
    };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('symbol', symbol);
      urlParams.append('cex', cex);
      urlParams.append('percentage', percentage.toString());
      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/market/liquidity?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getCexStatisticsChartSpread = async ({
    symbol,
    cex,
    period,
    startDate,
    endDate,
  }: {
    symbol: string;
    cex: ECexName;
    period?: string;
    startDate?: number;
    endDate?: number;
  }) => {
    type Return = { points: { time: number; value: string }[] };

    try {
      const urlParams = new URLSearchParams();

      urlParams.append('symbol', symbol);
      urlParams.append('cex', cex);
      if (period) {
        urlParams.append('period', period);
      }
      if (!isNil(startDate) && !isNil(endDate)) {
        urlParams.append('from', startDate.toString());
        urlParams.append('to', endDate.toString());
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/market/spread?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };

  public static getVWAPChart = async ({
    account_id,
    bots,
    period,
    from,
    to,
    pair_id,
  }: {
    account_id: number[];
    period?: string;
    from?: number;
    to?: number;
    pair_id: number;
    bots: BotFilter[];
  }) => {
    type Return = { points: ICexVWAPPoint[] };

    try {
      const urlParams = new URLSearchParams();

      if (period) {
        urlParams.append('period', period === '24h' ? '1d' : period === '168h' ? '1w' : period);
      }

      if (from && to) {
        urlParams.append('from', from.toString());
        urlParams.append('to', to.toString());
      }

      urlParams.append('pair_id', pair_id.toString());

      if (bots) {
        for (const bot of bots) {
          urlParams.append('bots', bot);
        }
      }

      if (account_id) {
        for (const accountId of account_id) {
          urlParams.append('account_id', accountId.toString());
        }
      }

      const response = await metricsAxios.get<Return>(
        '/api/v1/chart/vwapr?' + urlParams.toString(),
      );

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<Return>({ error });
    }
  };
}

export { ApiCharts };
