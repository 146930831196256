import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse } from '../apiBase';
import { IDexBuySellBotTaskItem } from 'types/bots';

interface IBuySellStatisticsResponse {
  items: IDexBuySellBotTaskItem[] | undefined | null;
  total_items: number;
}

class ApiDexBuySellBot extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiDexBuySellBot | undefined;
  private static instance = (() => {
    if (ApiDexBuySellBot.__instance === undefined) {
      ApiDexBuySellBot.__instance = new ApiDexBuySellBot();
    }
    return ApiDexBuySellBot.__instance;
  })();

  public static buySellStatistics = async ({ pairId }: { pairId: number }) => {
    try {
      const response = await ApiDexBuySellBot.instance.get<
        IBaseResponse<IBuySellStatisticsResponse>
      >(`/pairs/${pairId}/buysell-statistics`);

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<IBuySellStatisticsResponse>({ error });
    }
  };
}

export { ApiDexBuySellBot };
