import React, { useCallback, useState, useRef, useEffect, ReactNode, Suspense } from 'react';
import { useWindowSize } from 'react-use';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import { useTypedSelector } from 'store';

import { Spinner } from 'ui';
import { SelectArrow, IIconProps } from 'assets/icons';
import { tailwindConfig } from 'tailwind-config';
import PanelErrorBoundary from './PanelErrorBoundary';

import './panel.scss';

export interface IPanelProps {
  children: React.ReactNode;
  initialOpened?: boolean;
  icon?: React.FC<IIconProps>;
  title: string;
  switcher?: ReactNode;
  className?: string | undefined;
  headerNode?: React.ReactNode;
  onChange?: (opened: boolean) => void;
}

const Panel: React.FC<IPanelProps> = ({
  children,
  initialOpened,
  icon: Icon,
  title,
  switcher,
  className,
  headerNode,
  onChange,
}) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair);

  const [isOpened, setIsOpened] = useState<boolean>(initialOpened ?? false);
  const panelContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsOpened(initialOpened ?? false);

    //eslint-disable-next-line
  }, [cexPair?.id, dexPair?.id]);

  const togglePanel = useCallback(() => {
    const newOpened = !isOpened;

    setIsOpened(newOpened);

    if (onChange) {
      onChange(newOpened);
    }
  }, [isOpened, onChange]);

  const { width } = useWindowSize();

  useEffect(() => {
    if (width >= 1024 && isOpened && panelContentRef && panelContentRef.current) {
      setTimeout(() => {
        panelContentRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }

    //eslint-disable-next-line
  }, [isOpened]);

  return (
    <motion.section
      className={cn('mm-panel', { [className ?? '']: !!className })}
      ref={panelContentRef}
    >
      <div className={cn('mm-panel__toolbar', { _opened: isOpened })} onClick={togglePanel}>
        <div className="mm-panel__toolbar__title">
          {Icon && (
            <div className={'mm-panel__toolbar__title__icon'}>
              <Icon
                color={
                  !isOpened
                    ? tailwindConfig.theme.colors['gray-1']
                    : tailwindConfig.theme.colors.primary
                }
              />
            </div>
          )}
          <span className={cn('mm-panel__toolbar__title__inner', { _opened: isOpened })}>
            {title}
          </span>
        </div>
        {headerNode && <div className="mm-panel__toolbar__custom-header">{headerNode}</div>}
        <div className="switcher-arrow-container">
          {switcher && <span onClick={event => event.stopPropagation()}>{switcher}</span>}
          <motion.div
            animate={{ rotate: isOpened ? 180 : 0 }}
            className="mm-panel__toolbar__open-icon"
          >
            <SelectArrow
              color={
                !isOpened
                  ? tailwindConfig.theme.colors['gray-1']
                  : tailwindConfig.theme.colors.primary
              }
            />
          </motion.div>
        </div>
      </div>
      <AnimatePresence>
        {isOpened && (
          <PanelErrorBoundary>
            <motion.div
              className={cn('mm-panel__content', { _opened: isOpened })}
              transition={{ easings: ['easeInOut'], duration: 0.3 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <Suspense
                fallback={
                  <div className="mm-panel__content__loading">
                    <Spinner size="small" />
                  </div>
                }
              >
                {children}
              </Suspense>
            </motion.div>
          </PanelErrorBoundary>
        )}
      </AnimatePresence>
    </motion.section>
  );
};

export { Panel };
