import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const PairIcon: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors.primary,
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <path
        d="M13.25 11.25H15.8161C16.1938 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1938 12.75 15.8161 12.75H13.25V15.3161C13.25 15.6938 12.9142 16 12.5 16C12.0858 16 11.75 15.6938 11.75 15.3161V12.75H9.18394C8.80621 12.75 8.5 12.4142 8.5 12C8.5 11.5858 8.80621 11.25 9.18394 11.25H11.75V8.68394C11.75 8.30621 12.0858 8 12.5 8C12.9142 8 13.25 8.30621 13.25 8.68394V11.25Z"
        fill={color}
      />
      <circle cx="12.5" cy="12" r="10.9" fill="white" stroke={color} strokeWidth="1.8" />
      <path
        d="M16.166 14.9161L17.8025 13.2796C18.0954 12.9867 18.5703 12.9867 18.8631 13.2796C19.156 13.5725 19.156 14.0474 18.8631 14.3403L15.9463 17.2571C15.6534 17.55 15.1786 17.55 14.8857 17.2571L11.9689 14.3403C11.676 14.0474 11.676 13.5725 11.9689 13.2796C12.2617 12.9867 12.7366 12.9867 13.0295 13.2796L14.666 14.9161L14.666 7.97634C14.666 7.56213 15.0018 7.22634 15.416 7.22634C15.8302 7.22634 16.166 7.56213 16.166 7.97634L16.166 14.9161ZM8.83237 9.787L7.19588 11.4235C6.90299 11.7164 6.42812 11.7164 6.13522 11.4235C5.84233 11.1306 5.84233 10.6557 6.13522 10.3628L9.05204 7.44601C9.34493 7.15312 9.8198 7.15312 10.1127 7.44601L13.0295 10.3628C13.3224 10.6557 13.3224 11.1306 13.0295 11.4235C12.7366 11.7164 12.2617 11.7164 11.9689 11.4235L10.3324 9.787L10.3324 16.7268C10.3324 17.141 9.99658 17.4768 9.58237 17.4768C9.16815 17.4768 8.83237 17.141 8.83237 16.7268L8.83237 9.787Z"
        fill={color}
      />
    </svg>
  );
};

export { PairIcon };
