import React, { useCallback, useMemo } from 'react';
import { useTypedSelector } from 'store';

import { IPairBot } from 'types/bots';

import { PairCexTableConnectedBots } from '../PairCexTableConnectedBots';
import { ICexAccount } from 'types/accounts';

interface IConnectedBotsCell {
  meta: any;
  row: {
    original: {
      id: number;
      connected_bots?: {
        description: string;
        id: number;
        name: string;
      }[];
    };
  };
}

const ConnectedBotsCell: React.FC<IConnectedBotsCell> = React.memo(({ meta, row }) => {
  const pair = useTypedSelector(store => store.pairs.selectedCexPair);
  const { setAccounts } = useMemo(() => meta, [meta]);
  const connectedBots: IPairBot[] | undefined = useMemo(() => row.original.connected_bots, [row]);
  const accountId = useMemo(() => row.original.id, [row]);

  const onChangeConnectedBots = useCallback(
    (new_connected_bots: IPairBot[]) => {
      setAccounts((records: ICexAccount[] | undefined) => {
        const newRecords = records === undefined ? [] : [...records];
        const botIndexOf = newRecords.findIndex(el => el.id === row.original.id);

        newRecords[botIndexOf] = {
          ...newRecords[botIndexOf],
          connected_bots: new_connected_bots,
        };

        return newRecords;
      });
    },
    [setAccounts, row.original.id],
  );
  return (
    <PairCexTableConnectedBots
      connected_bots={connectedBots}
      pair={pair!}
      accountId={accountId}
      onChangeConnectedBots={onChangeConnectedBots}
    />
  );
});

export { ConnectedBotsCell };
