import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import store from 'store';

import { isTestnet } from 'web3';

import { useAppNewVersion, useTimeLocaleResolver } from 'hooks/utility';
import { AlertProvider } from 'ui';
import { Routing } from './Routing';

import prodLogo from 'assets/images/favicon.ico';
import devLogo from 'assets/images/favicon-dev.ico';

const App: React.FC = () => {
  useTimeLocaleResolver();
  useAppNewVersion();

  return (
    <BrowserRouter>
      <Provider store={store}>
        <AlertProvider>
          <Helmet>
            <title>Market Maker</title>
            <link rel="icon" href={isTestnet ? devLogo : prodLogo} />
          </Helmet>
          <Routing />
        </AlertProvider>
      </Provider>
    </BrowserRouter>
  );
};

export { App };
