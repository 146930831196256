import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const ColumnChartIcon: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors['gray-1'],
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <path
        d="M10.1103 11.1523H7.4603C6.8303 11.1523 6.32031 11.6623 6.32031 12.2923V17.4123H10.1103V11.1523V11.1523Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7616 6.60156H11.2415C10.6115 6.60156 10.1016 7.11158 10.1016 7.74158V17.4016H13.8916V7.74158C13.8916 7.11158 13.3916 6.60156 12.7616 6.60156Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5484 12.8516H13.8984V17.4016H17.6884V13.9916C17.6784 13.3616 17.1684 12.8516 16.5484 12.8516Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { ColumnChartIcon };
