import { useEffect } from 'react';
import { useTypedSelector } from 'store';

import { Bus } from 'tools';
import { EExchange } from 'web3';
import { EDexPairFilters, ECexPairFilters, EGeneralFilters } from 'types/filters';
import { useFilters } from 'store/slices/filters/hooks';

import FilterItem from '../FilterItem';

import './style.scss';

interface IFiltersListProps<V> {
  id: string;
  exchange?: V;
  general?: EGeneralFilters;
  type?: V extends EExchange.cex ? ECexPairFilters : EDexPairFilters;
}

function FiltersList<V extends EExchange>({ id, exchange, type, general }: IFiltersListProps<V>) {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair);
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);

  const { scheme, pairFilters, addFilter, updateFilter, deleteFilter } = useFilters({
    general,
    cex:
      exchange === EExchange.cex && type
        ? { pairId: cexPair?.id, type: type as ECexPairFilters }
        : undefined,
    dex:
      exchange === EExchange.dex && type
        ? { pairId: dexPair?.id, type: type as EDexPairFilters }
        : undefined,
  });

  useEffect(() => {
    const handleAddFilter = () => {
      addFilter();
    };

    Bus.on(`${id}-FILTERS-ADD`, handleAddFilter);

    return () => {
      Bus.off(`${id}-FILTERS-ADD`, handleAddFilter);
    };
  }, [id, addFilter]);

  if (pairFilters.length === 0) return null;

  return (
    <div className="mm-filters-container scrollable">
      {scheme &&
        pairFilters.map((filter, idx) => (
          <FilterItem
            key={idx}
            scheme={scheme}
            filter={filter}
            filters={pairFilters}
            exchange={exchange}
            updateFilter={updateFilter}
            deleteFilter={deleteFilter}
          />
        ))}
    </div>
  );
}

export default FiltersList;
