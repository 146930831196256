import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { dayjs_locales } from 'constant/locales';

dayjs.extend(UTC);
dayjs.extend(localizedFormat);

const useTimeLocaleResolver = () => {
  const [locale, setLocale] = useState<string | undefined>(undefined);

  useEffectOnce(() => {
    const nav_lang =
      navigator.languages.length !== 0 ? navigator.languages[0] : navigator.language ?? false;

    if (!nav_lang) return;

    const lang = nav_lang.toLowerCase();

    const checkLocale = (locale: string) => {
      if (['en', 'en-us'].includes(locale)) return true;
      if (locale === 'zn') return 'zh-cn';
      if (locale === 'no') return 'nb';
      if (dayjs_locales.includes(locale)) return locale;

      return locale.includes('-') ? locale.split('-')[0] : true;
    };

    const localeStatus = checkLocale(lang);

    if (localeStatus === true) return;

    setLocale(localeStatus);
  });

  useEffect(() => {
    const check = () => {
      if (locale) {
        //TODO think about more light solution, this code is dirty by Vite impossibility to manage dynamic imports
        if (locale === 'uk') {
          import('dayjs/locale/uk.js').then(() => {
            dayjs.locale('uk');
          });
        }
        if (locale === 'ru') {
          import('dayjs/locale/ru.js').then(() => {
            dayjs.locale('ru');
          });
        } else {
          import('dayjs/locale/en.js').then(() => {
            dayjs.locale('en');
          });
        }
      }
    };

    check();
  }, [locale]);
};

export { useTimeLocaleResolver };
