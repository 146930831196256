import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse } from '../apiBase';
import { ITransactionItem } from './models';

class ApiTransactions extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiTransactions | undefined;
  private static instance = (() => {
    if (ApiTransactions.__instance === undefined) {
      ApiTransactions.__instance = new ApiTransactions();
    }
    return ApiTransactions.__instance;
  })();

  public static getTransactions = async ({
    limit,
    lastSeenId,
    pair_ids,
    actions,
    range,
    hashes,
    wallets,
    statuses,
    user_ids,
    amounts,
    amounts_usd,
  }: {
    limit: number;
    lastSeenId: number;
    pair_ids?: number[];
    actions?: string[];
    range?: string;
    hashes?: string[];
    wallets?: string[];
    statuses?: string[];
    user_ids?: number[];
    amounts?: string[];
    amounts_usd?: string[];
  }) => {
    try {
      const urlParams = new URLSearchParams();
      urlParams.append('limit', limit.toString());
      urlParams.append('last_seen_id', lastSeenId.toString());

      if (pair_ids) {
        for (const pairId of pair_ids) {
          urlParams.append('pair_ids', pairId.toString());
        }
      }

      if (actions) {
        for (const action of actions) {
          urlParams.append('actions', action.toString());
        }
      }

      if (range) {
        urlParams.append('range', range);
      }

      if (hashes) {
        for (const hash of hashes) {
          urlParams.append('hashes', hash);
        }
      }

      if (wallets) {
        for (const wallet of wallets) {
          urlParams.append('wallet_addresses', wallet);
        }
      }

      if (statuses) {
        for (const status of statuses) {
          urlParams.append('statuses', status);
        }
      }

      if (user_ids) {
        for (const userId of user_ids) {
          urlParams.append('user_ids', userId.toString());
        }
      }

      if (amounts) {
        for (const amount of amounts) {
          urlParams.append('amounts', amount);
        }
      }

      if (amounts_usd) {
        for (const amount of amounts_usd) {
          urlParams.append('amounts_usd', amount);
        }
      }

      const response = await ApiTransactions.instance.get<
        IBaseResponse<{ has_next: boolean; items: ITransactionItem[] }>
      >('/transactions?' + urlParams.toString());

      return mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      return mapError<{ has_next: boolean; items: ITransactionItem[] }>({ error });
    }
  };
}

export { ApiTransactions };
