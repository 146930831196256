import { useMemo } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import cn from 'classnames';

import { tailwindConfig } from 'tailwind-config';
import { ROUTE_PATHS } from 'constant/routes';

import { WalletIcon } from 'assets/icons';

const WalletsSideBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const walletsPathIsActive: boolean = useMemo(
    () => Boolean(matchPath(`${ROUTE_PATHS.wallets}/*`, pathname)),
    [pathname],
  );

  return (
    <div
      className={cn('mm-sidebar__menu__item', { _active: walletsPathIsActive })}
      onClick={() => navigate(ROUTE_PATHS.wallets)}
    >
      <div className="mm-sidebar__menu__item__left">
        <div className="mm-sidebar__menu__item__icon">
          <WalletIcon
            color={walletsPathIsActive ? tailwindConfig.theme.colors.primary : undefined}
          />
        </div>
        <span className="mm-sidebar__menu__item__text">Wallets</span>
      </div>
    </div>
  );
};

export { WalletsSideBar };
