import React, { useCallback } from 'react';
import { differenceWith } from 'lodash';

import { readFromClipboard } from 'utils/clipboard';
import { tailwindConfig } from 'tailwind-config';

import { CloseIcon } from 'assets/icons';

const shortenText = (text: string) => {
  if (text.length <= 10) return text;

  return `${text.slice(0, 4)}...${text.slice(-4)}`;
};

interface IMultiSearchFieldProps {
  values: string[];
  setValues: (v: string[]) => void;
}

const MultiSearchField: React.FC<IMultiSearchFieldProps> = ({ values, setValues }) => {
  const handleInsertValues = useCallback(async () => {
    const text = await readFromClipboard();

    if (!text) return;

    // split values by "," | " " | "\n"
    const splittedText = text.split(/[,\s\n]+/);
    const validValues = splittedText.map(el => el.trim());

    const differenceValues = differenceWith(validValues, values);

    setValues(values.concat(differenceValues));
  }, [values, setValues]);

  const removeSingleValue = useCallback(
    (idx: number) => {
      const newValues = [...values].filter((_, index) => index !== idx);

      setValues(newValues);
    },
    [setValues, values],
  );

  return (
    <div className="mm-filter-multi-search-field">
      <div className="mm-filter-field-container multi-search-filter-container">
        <div className="search-values">
          {values.length === 0 && (
            <span className="mm-filter-field-placeholder">Insert records</span>
          )}
          {values.length !== 0 &&
            values.map((el, idx) => (
              <div className="search-value-container" key={idx}>
                <span>{shortenText(el)}</span>
                <button className="delete-value-button" onClick={() => removeSingleValue(idx)}>
                  <CloseIcon color={tailwindConfig.theme.colors['black-1']} />
                </button>
              </div>
            ))}
        </div>
        <button className="insert-button" onClick={handleInsertValues}>
          Insert
        </button>
      </div>
    </div>
  );
};

export { MultiSearchField };
