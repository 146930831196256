import { useState, useEffect, useCallback } from 'react';
import { useTypedSelector } from 'store';

import { ICexAccount, IAccountBalances } from 'types/accounts';
import { ICexPair } from 'types/pairs';

import { ApiCexAccounts, ApiStatistic } from 'api';

interface IAccountWithBalance extends ICexAccount, Partial<IAccountBalances> {}

const loadAccounts = async (cexPair: ICexPair): Promise<IAccountWithBalance[]> => {
  try {
    const { data } = await ApiCexAccounts.getAccounts({ limit: 1000, pairId: cexPair.id });

    const withBalances = await Promise.all(
      (data?.items ?? []).map(async account => {
        try {
          const { data } = await ApiStatistic.geCexActualBalance({
            cex: cexPair.cex,
            symbol: `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`,
            account_id: [account.id],
          });

          return { ...account, ...(data ? data : {}) };
        } catch (error) {
          console.log('error: ', error);
          return account;
        }
      }),
    );

    return withBalances as IAccountWithBalance[];
  } catch (error) {
    console.log('error: ', error);
    return [];
  }
};

const useAccounts = () => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const [accounts, setAccounts] = useState<IAccountWithBalance[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const load = useCallback(async () => {
    setLoading(true);

    if (!cexPair?.id) return;

    const accountsData = await loadAccounts(cexPair);
    setAccounts(accountsData);

    setLoading(false);

    //eslint-disable-next-line
  }, [cexPair?.id]);

  useEffect(() => {
    setAccounts([]);
    load();
  }, [load]);

  return { accounts, setAccounts, loading, load };
};

export { useAccounts };
