import dayjs from 'dayjs';
import { IChartRange, IChartTimeframe } from 'types/charts';
import { normalizeNumber } from 'utils/formats';

export const INITIAL_LIMIT = 990;
export const PAGINATION_LIMIT = 990;

export const chartTimeframeToInterval = (timeframe: IChartTimeframe) => {
  switch (timeframe) {
    case '15m':
      return 900;
    case '1m':
      return 60;
    case '5m':
      return 300;
    case '1h':
      return 3600;
    case '4h':
      return 14400;
    case '24h':
      return 86400;
    case '168h':
      return 604800;
  }
};

export const chartTimeframeToKattanaInterval = (timeframe: IChartTimeframe) => {
  switch (timeframe) {
    case '15m':
      return '15m';
    case '1m':
      return '1m';
    case '5m':
      return '5m';
    case '1h':
      return '1h';
    case '4h':
      return '4h';
    case '24h':
      return '1D';
    case '168h':
      return '1W';
  }
};

export const timeframeToRange = (timeframe: IChartTimeframe) => {
  const limit = INITIAL_LIMIT + 1;
  const interval = chartTimeframeToInterval(timeframe);

  const rangeInSeconds = Math.floor(limit * interval);

  const endTime = Math.floor(dayjs().valueOf() / 1000);

  return {
    startTime: endTime - rangeInSeconds,
    endTime,
  };
};

export const chartFormatter = (num: number): string => {
  const numStr = normalizeNumber(num, 7);
  const [integerPart, decimalPart] = numStr.split('.');

  if (!decimalPart) {
    return numStr;
  }

  if (num > 10000) {
    return integerPart;
  }

  const roundDecimal = (digits: number): string => {
    const factor = Math.pow(10, digits);
    return (Math.round(num * factor) / factor).toString();
  };

  if (num > 100) {
    return roundDecimal(2);
  }

  if (num > 1) {
    return roundDecimal(3);
  }

  const firstNonZeroIndex = decimalPart.search(/[1-9]/);
  const digitsAfterZero = Math.min(firstNonZeroIndex + 4, decimalPart.length);

  const result = roundDecimal(digitsAfterZero);

  return normalizeNumber(result);
};

export const getPeriodToAPI = (period: IChartRange | undefined) => {
  return period ? period.toLowerCase() : undefined;
};
