import React, { createContext, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTypedSelector } from 'store';

import { ApiDexBuySellBot } from 'api';
import { Bus } from 'tools';
import { useDexBotConfig } from 'hooks/dex';
import { EDexBot, IDexBotConfig, IDexBuySellBotTaskItem } from 'types/bots';
import { IBuySellTaskStatus, IBuySellBotTaskItemInner } from 'types/buy-sell-bot';
import { PAIR_BUYSELL_BOT_RELOAD, STOP_RELOAD_PREFIX } from 'constant/reload';

interface IDexBuySellBotContext {
  loading: { get: boolean; set: (v: boolean) => void };
  records: {
    get: IBuySellBotTaskItemInner[] | undefined;
    set: (v: IBuySellBotTaskItemInner[] | undefined) => void;
  };
  botSettings: IDexBotConfig<EDexBot.buy_sell_bot> | undefined;
  handleLoadRecords: () => void;
  handleLoadBotSettings: () => void;
  extendedRow: number | undefined;
  setExtendedRow: (v: number | undefined) => void;
  errorMessage: string | undefined;
}

export const DexBuySellBotContext = createContext<IDexBuySellBotContext>({
  loading: { get: false, set: () => {} },
  records: { get: undefined, set: () => {} },
  botSettings: undefined,
  handleLoadRecords: () => {},
  handleLoadBotSettings: () => {},
  extendedRow: undefined,
  setExtendedRow: () => {},
  errorMessage: undefined,
});

interface IDexBuySellBotContextProviderProps {
  children?: React.ReactNode;
}

export const DexBuySellBotContextProvider: React.FC<IDexBuySellBotContextProviderProps> = ({
  children,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const [loading, setLoading] = useState<boolean>(true);
  const [records, setRecords] = useState<IBuySellBotTaskItemInner[] | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [extendedRow, setExtendedRow] = useState<number | undefined>(undefined);
  const [botSettings, setBotSettings] = useState<IDexBotConfig<EDexBot.buy_sell_bot> | undefined>(
    undefined,
  );
  const { getBotConfig } = useDexBotConfig();

  const handleSetExtendedRow = useCallback((index: number | undefined) => {
    if (index == undefined) {
      setExtendedRow(undefined);
    } else {
      setExtendedRow(v => (v === index ? undefined : index));
    }
  }, []);

  const parseRecords = useCallback((records: IDexBuySellBotTaskItem[]) => {
    const inProgressRecord = records.find(el => el.is_current);

    if (!inProgressRecord)
      return records.map(el => ({ ...el, status: 'future' as IBuySellTaskStatus }));

    return records
      .map(el => {
        if (Number(el.total.amount_percent) === 100)
          return { ...el, status: 'past' as IBuySellTaskStatus };

        if (el.is_current) return { ...el, status: 'current' as IBuySellTaskStatus };

        if (
          !el.is_current &&
          dayjs(el.task.start_time).diff(dayjs(inProgressRecord.task.start_time)) <= 0
        )
          return {
            ...el,
            status: 'past' as IBuySellTaskStatus,
          };

        return {
          ...el,
          status: 'future' as IBuySellTaskStatus,
        };
      })
      .sort((a, b) => {
        if (dayjs(a.task.start_time).valueOf() > dayjs(b.task.start_time).valueOf()) return -1;

        if (dayjs(a.task.start_time).valueOf() === dayjs(b.task.start_time).valueOf()) return 0;

        return 1;
      })
      .sort((a, b) => {
        if (a.is_current && b.status === 'past') return -1;

        if (a.status === 'future' && b.status !== 'future') return -1;

        if (a.status === 'past' && b.is_current) return 1;

        if (a.status !== 'future' && b.status === 'future') return 1;

        return 0;
      });
  }, []);

  const handleLoadRecords = useCallback(
    async (params?: { silent: boolean }) => {
      const isSilent = params?.silent ?? false;

      if (!isSilent) {
        setLoading(true);
        setExtendedRow(undefined);
        setErrorMessage(undefined);
      }

      try {
        const { isSuccess, errorMessage, data } = await ApiDexBuySellBot.buySellStatistics({
          pairId: dexPair.id,
        });

        if (isSuccess && data) {
          setRecords(data.items ? parseRecords(data.items) : undefined);
          setErrorMessage(undefined);
        } else {
          setRecords(undefined);
          setErrorMessage(errorMessage ?? undefined);
        }
      } catch (error) {
        console.log(error);
        setErrorMessage(
          'You get an error while trying to get buy sell bot records. Try to refresh your page...',
        );
      } finally {
        if (!isSilent) {
          setLoading(false);
        }
      }
    },
    [dexPair, parseRecords],
  );

  const handleLoadBotSettings = useCallback(
    async (params?: { silent: boolean }) => {
      const isSilent = params?.silent ?? false;

      if (!isSilent) {
        setLoading(true);
      }

      const botConfig = await getBotConfig({ pairId: dexPair.id, bot: EDexBot.buy_sell_bot });

      setBotSettings(botConfig);

      if (!isSilent) {
        setLoading(false);
      }
    },
    [dexPair, getBotConfig],
  );

  useEffect(() => {
    handleLoadRecords();

    //eslint-disable-next-line
  }, []);

  const handleUpdate = useCallback(async () => {
    const startTime = Date.now();

    try {
      await Promise.all([
        handleLoadBotSettings({ silent: true }),
        handleLoadRecords({ silent: true }),
      ]);
    } catch (e) {}

    const endTime = Date.now();

    if (endTime - startTime >= 1500) {
      Bus.emit(STOP_RELOAD_PREFIX + PAIR_BUYSELL_BOT_RELOAD);
    } else {
      setTimeout(() => {
        Bus.emit(STOP_RELOAD_PREFIX + PAIR_BUYSELL_BOT_RELOAD);
      }, 1500 - (endTime - startTime));
    }
  }, [handleLoadBotSettings, handleLoadRecords]);

  useEffect(() => {
    Bus.on(PAIR_BUYSELL_BOT_RELOAD, handleUpdate);

    return () => {
      Bus.off(PAIR_BUYSELL_BOT_RELOAD, handleUpdate);
    };
  }, [handleUpdate]);

  return (
    <DexBuySellBotContext.Provider
      value={{
        loading: { get: loading, set: setLoading },
        records: { get: records, set: setRecords },
        botSettings,
        handleLoadRecords,
        handleLoadBotSettings,
        errorMessage,
        extendedRow: extendedRow,
        setExtendedRow: handleSetExtendedRow,
      }}
    >
      {children}
    </DexBuySellBotContext.Provider>
  );
};
