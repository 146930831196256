import React, { useCallback, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  useFloating,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  offset,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react';

import { IFilterColorSelectItem } from 'types/filters/common';
import { tailwindConfig } from 'tailwind-config';

import { ArrowDropDown } from 'assets/icons';
import '../style.scss';
import './colorsSingleSelect.scss';

interface IProps {
  value: IFilterColorSelectItem | null;
  setValue: (v: IFilterColorSelectItem) => void;
  items: IFilterColorSelectItem[];
}

const ColorsSingleSelectField: React.FC<IProps> = ({ setValue, value, items }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const { refs, context, floatingStyles } = useFloating({
    open: isOpened,
    onOpenChange: setIsOpened,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
            width: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([dismiss, role]);

  const handleSelectItem = useCallback(
    (item: IFilterColorSelectItem) => {
      setValue(item);
      setIsOpened(false);
    },
    [setValue],
  );

  return (
    <div className="mm-filter-colors-single-select-field">
      <div
        className="mm-filter-field-container"
        tabIndex={0}
        ref={refs.setReference}
        aria-labelledby="select-label"
        aria-autocomplete="none"
        {...getReferenceProps()}
        onClick={() => setIsOpened(v => !v)}
      >
        {!value && <span className="mm-filter-field-placeholder">Select</span>}
        {value && (
          <div className="selected-value" style={{ color: value.color }}>
            <span>{value.label}</span>
          </div>
        )}
        <motion.div animate={isOpened ? { rotate: 180 } : undefined}>
          <ArrowDropDown color={tailwindConfig.theme.colors['black-1']} />
        </motion.div>
      </div>
      <AnimatePresence>
        {isOpened && (
          <FloatingPortal>
            <FloatingFocusManager context={context} modal={false}>
              <div
                ref={refs.setFloating}
                style={floatingStyles}
                className="mm-filter-select-field__dropdown colors-single-select-dropdown scrollable"
                {...getFloatingProps()}
              >
                {items.map((item, i) => (
                  <div
                    className="dropdown__item"
                    key={i}
                    role="option"
                    tabIndex={-1}
                    {...getItemProps({
                      onClick() {
                        handleSelectItem(item);
                      },
                      onKeyDown(event) {
                        if (event.key === 'Enter' || event.key === ' ') {
                          event.preventDefault();
                          handleSelectItem(item);
                        }
                      },
                    })}
                  >
                    <span style={{ color: item.color }}>{item.label}</span>
                  </div>
                ))}
              </div>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </div>
  );
};

export { ColorsSingleSelectField };
