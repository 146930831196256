import React, { useState } from 'react';
import * as Sentry from '@sentry/react';

import { PanelError } from './PanelError';

interface PanelErrorBoundaryProps {
  children?: React.ReactNode;
}

const PanelErrorBoundary: React.FC<PanelErrorBoundaryProps> = ({ children }) => {
  const [errorTitle, setErrorTitle] = useState<string | undefined>(undefined);

  return (
    <Sentry.ErrorBoundary
      onError={error => setErrorTitle(`${error.message} \n\n ${error.stack}`)}
      fallback={
        <div className="bg-bg-primary">
          <PanelError title="Error" text={errorTitle ?? 'Something went wrong'} />
        </div>
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default PanelErrorBoundary;
