import { IDexPair, ICexPair } from 'types/pairs';
import {
  dexPairWalletsFiltersScheme,
  dexBoostHoldersWalletsFiltersScheme,
  dexPairTransactionsFiltersScheme,
  dexPairConnectWalletsFiltersScheme,
} from './dex_pair';
import { cexPairOrdersFiltersScheme, newCexPairOrdersFiltersScheme } from './cex_pair';
import { IFilterValue } from './common';

export enum EDexPairFilters {
  wallets = 'wallets',
  boostHoldersWallets = 'boostHoldersWallets',
  transactions = 'transactions',
  connect_wallets = 'connect_wallets',
}

export enum ECexPairFilters {
  orders = 'orders',
  newOrders = 'newOrders',
}

export enum EGeneralFilters {
  wallets = 'wallets',
  accounts = 'accounts',
}

export const dexPairFiltersScheme = ({
  pair,
  type,
}: {
  pair: IDexPair | undefined;
  type: EDexPairFilters;
}) => {
  return pair
    ? {
        [EDexPairFilters.wallets]: dexPairWalletsFiltersScheme(pair),
        [EDexPairFilters.boostHoldersWallets]: dexBoostHoldersWalletsFiltersScheme(pair),
        [EDexPairFilters.transactions]: dexPairTransactionsFiltersScheme(pair),
        [EDexPairFilters.connect_wallets]: dexPairConnectWalletsFiltersScheme(pair),
      }[type]
    : undefined;
};

export const cexPairFiltersScheme = ({
  pair,
  type,
}: {
  pair: ICexPair | undefined;
  type: ECexPairFilters;
}) => {
  return pair
    ? {
        [ECexPairFilters.orders]: cexPairOrdersFiltersScheme(pair),
        [ECexPairFilters.newOrders]: newCexPairOrdersFiltersScheme(),
      }[type]
    : undefined;
};

export { generalWalletsFiltersScheme } from './general/wallets';

type IPairId = number;

export type IDexPairsFilters = Record<
  IPairId,
  | {
      updated_at: string;
      filters: {
        [key in EDexPairFilters]: {
          filters: IFilterValue[];
          setted: IFilterValue[];
        };
      };
    }
  | undefined
>;

export type ICexPairsFilters = Record<
  IPairId,
  | {
      updated_at: string;
      filters: {
        [key in ECexPairFilters]: {
          filters: IFilterValue[];
          setted: IFilterValue[];
        };
      };
    }
  | undefined
>;

export type IFilters = {
  dex: IDexPairsFilters;
  cex: ICexPairsFilters;
  [EGeneralFilters.wallets]: { filters?: IFilterValue[]; setted?: IFilterValue[] };
  [EGeneralFilters.accounts]: { filters?: IFilterValue[]; setted?: IFilterValue[] };
};
