import React, { useCallback, useMemo, useRef, memo } from 'react';
import { ColumnDef, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { v4 as uuid } from 'uuid';

import { useTypedSelector } from 'store';
import {
  FiltersPopup,
  TableToolbar,
  TableSelectedCaption,
  TableWrapper,
  TableCheckbox,
  TableAddressChip,
} from 'ui';
import { useDebouncedCallback } from 'hooks/utility';
import { IBalanceBotWallet } from 'api/apiBalanceBot/models';
import { EExchange } from 'web3';
import { EDexPairFilters } from 'types/filters';

import { WalletsHead, TokenBaseHead, TokenQuoteHead, TokenFeeHead, TotalHead } from './headers';
import { RowComponent, StaticTable, TableHeaders, TBody } from '../../../common';
import { IConnectWalletBalanceTaskRow } from 'types/tables/dex/connectWalletBalanceTaskRow';

import './style.scss';

interface IConnectWalletBalanceTaskTableProps {
  mode: 'disconnect' | 'connect';
  readOnlyMode: boolean | undefined;
  selectedRecords: IBalanceBotWallet[];
  records: IConnectWalletBalanceTaskRow[];
  rowSelection: {
    get: Record<number, boolean>;
    set: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
  };
  hasMore: boolean;
  loading: boolean;
  loadMore: () => void;
}

const ConnectWalletBalanceTaskTable: React.FC<IConnectWalletBalanceTaskTableProps> = ({
  readOnlyMode = false,
  records,
  mode,
  rowSelection,
  selectedRecords,
  hasMore,
  loading,
  loadMore,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const columns = useMemo<ColumnDef<IConnectWalletBalanceTaskRow>[]>(
    () => [
      {
        id: 'select',
        accessorFn: row => ({ value: row.addressCol?.address }),
        header: ({ table }) => <WalletsHead table={table} readOnlyMode={readOnlyMode} />,
        cell: ({ row, getValue }) => {
          const data = getValue() as { value: string };

          if (!data) return <></>;

          const checkboxId = uuid();

          return (
            <div className="checkbox-area">
              {!readOnlyMode && (
                <TableCheckbox
                  id={checkboxId}
                  checked={row.getIsSelected()}
                  indeterminate={row.getIsSomeSelected()}
                  onChange={row.getToggleSelectedHandler()}
                />
              )}
              <TableAddressChip
                address={data.value}
                network={dexPair.network}
                addressType={'address'}
              />
            </div>
          );
        },
      },
      {
        id: 'token_base',
        accessorFn: row => ({ value: row.tokenBaseCol?.value }),
        header: () => <TokenBaseHead />,
        cell: memo(({ getValue }) => {
          const data = getValue() as { value: string };

          if (!data) return <></>;

          return <div>{data.value}</div>;
        }),
      },
      {
        id: 'token_quote',
        accessorFn: row => ({ value: row.tokenQuoteCol?.value }),
        header: () => <TokenQuoteHead />,
        cell: memo(({ getValue }) => {
          const data = getValue() as { value: string };

          if (!data) return <></>;

          return <div>{data.value}</div>;
        }),
      },
      {
        id: 'token_fee',
        accessorFn: row => ({ value: row.tokenFeeCol?.value }),
        header: () => <TokenFeeHead />,
        cell: memo(({ getValue }) => {
          const data = getValue() as { value: string };

          if (!data) return <></>;

          return <div>{data.value}</div>;
        }),
      },
      {
        id: 'total',
        accessorFn: row => ({ value: row.totalCol?.value }),
        header: () => <TotalHead />,
        cell: memo(({ getValue }) => {
          const data = getValue() as { value: string };

          if (!data) return <></>;

          return (
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
              <div>{data.value}</div>
            </div>
          );
        }),
      },
    ],
    [readOnlyMode, dexPair],
  );

  const table = useReactTable({
    data: useMemo(() => records ?? [], [records]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: rowSelection.set,
    state: {
      rowSelection: rowSelection.get,
    },
  });

  const { rows } = table.getRowModel();
  const virtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: useMemo(() => rows.length, [rows]),
    overscan: 5,
    estimateSize: useCallback(() => 44, []),
  });

  const fetchMoreOnBottomReached = useCallback(
    async (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        //once the user has scrolled within 200px of the bottom of the table, fetch more data if we can
        if (scrollHeight - scrollTop - clientHeight < 200 && !loading && hasMore) {
          await loadMore();
        }
      }
    },
    [hasMore, loadMore, loading],
  );

  const fetchMoreOnBottomReachedDebounced = useDebouncedCallback(fetchMoreOnBottomReached, 100);

  return (
    <div className="mm-connect-dex-wallets-bb-container">
      {!readOnlyMode && (
        <TableToolbar
          customFilters={
            <FiltersPopup exchange={EExchange.dex} type={EDexPairFilters.boostHoldersWallets} />
          }
          customActions={
            <TableSelectedCaption
              selected={selectedRecords.length}
              totalRecords={records?.length ?? 0}
            />
          }
        />
      )}
      <TableWrapper
        hasMore={hasMore}
        loading={loading}
        records={records}
        cRef={tableContainerRef}
        className="table-container"
        heightLimit={300}
        onScroll={e => fetchMoreOnBottomReachedDebounced(e.target as HTMLDivElement)}
        notFoundText={
          mode === 'connect' ? 'Not found wallets to connect' : 'Not found wallets to disconnect'
        }
      >
        <StaticTable className="mm-common-table">
          <TableHeaders isAllChecked={table.getIsAllRowsSelected()} table={table} />
          <TBody
            paddingTop={virtualizer.getVirtualItems()?.[0]?.start || 0}
            paddingBottom={
              virtualizer.getTotalSize() -
              (virtualizer.getVirtualItems()?.[virtualizer.getVirtualItems().length - 1]?.end || 0)
            }
          >
            {virtualizer.getVirtualItems().map(virtualRow => {
              const row = rows[virtualRow.index];

              return (
                <RowComponent
                  isChecked={row.getIsSelected()}
                  row={row}
                  virtualRow={virtualRow}
                  key={virtualRow.index}
                />
              );
            })}
          </TBody>
        </StaticTable>
      </TableWrapper>
    </div>
  );
};

export { ConnectWalletBalanceTaskTable };
