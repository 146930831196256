import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import { useTypedSelector, useTypedDispatch } from 'store';

import { setSearchProjectText } from 'store/slices/ui';

import { SearchIcon } from 'assets/icons';
import './style.scss';

const SearchProjectField: React.FC = () => {
  const dispatch = useTypedDispatch();
  const searchProjectText = useTypedSelector(store => store.ui.searchProjectText);

  const [localText, setLocalText] = useState<string>(searchProjectText);

  useDebounce(
    () => {
      dispatch(setSearchProjectText(localText));
    },
    200,
    [localText],
  );

  return (
    <div className="mm-sidebar__search-project-field">
      <input
        value={localText}
        onInput={e => setLocalText(e.currentTarget.value)}
        placeholder="Find project or pair"
      />
      <SearchIcon />
    </div>
  );
};

export { SearchProjectField };
