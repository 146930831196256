import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Calendar as ReactCalendar } from 'react-calendar';
import dayjs, { Dayjs } from 'dayjs';
import TimePicker, { TimePickerValue } from 'react-time-picker';

import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';

import './rangeCalendar.scss';
import 'react-calendar/dist/Calendar.css';

interface IRangeCalendarProps {
  onClose: () => void;
  title: string;
  handleDefaultTotal?: () => void;
  handleFilter: ([date1, date2]: [Dayjs | null, Dayjs | null]) => void;
  initialStartDate: Dayjs | null;
  initialEndDate: Dayjs | null;
  withTime?: boolean;
}

const RangeCalendar: React.FC<IRangeCalendarProps> = ({
  onClose,
  title,
  handleDefaultTotal,
  handleFilter,
  initialStartDate,
  initialEndDate,
  withTime = false,
}) => {
  const [startDate, setStartDate] = useState<Dayjs | null>(initialStartDate);
  const [endDate, setEndDate] = useState<Dayjs | null>(initialEndDate);
  const calendarModalRef = useRef<HTMLDivElement>(null);

  const onHandleFilter = useCallback(() => {
    onClose();
    handleFilter([startDate, endDate]);
  }, [onClose, handleFilter, startDate, endDate]);

  const onHandleDefaultTotal = useCallback(() => {
    onClose();
    if (handleDefaultTotal) {
      handleDefaultTotal();
    }
  }, [onClose, handleDefaultTotal]);

  useEffect(() => {
    setStartDate(initialStartDate);
  }, [initialStartDate]);

  useEffect(() => {
    setEndDate(initialEndDate);
  }, [initialEndDate]);

  const keyDownEvent = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (event.key === 'Escape' || event.key === 'Enter') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (calendarModalRef.current) {
      calendarModalRef.current.focus();
    }
  }, [calendarModalRef]);

  const onChangeTime = useCallback(
    (value: TimePickerValue, type: 'start' | 'end') => {
      if (!value) return;

      const hours = Number(value.toString().split(':')[0]);
      const minutes = Number(value.toString().split(':')[1]);

      const date = type === 'start' ? startDate : endDate;

      let newDateResult = date ? date.startOf('day') : null;
      if (newDateResult) {
        newDateResult = newDateResult.add(hours, 'hours').add(minutes, 'minutes');
      }

      if (type === 'start') {
        setStartDate(newDateResult);
      } else {
        setEndDate(newDateResult);
      }
    },
    [endDate, startDate],
  );

  const timeFromDate = useCallback((date: Dayjs | null) => {
    if (date === null) return '00:00';

    return date.format('HH:mm');
  }, []);

  return (
    <Modal onClose={onClose} title={title} backdropClassName="mm-range-calendar-modal-backdrop">
      <div
        className="mm-range-calendar-modal"
        ref={calendarModalRef}
        tabIndex={0}
        onKeyDown={keyDownEvent}
      >
        <ReactCalendar
          locale="en"
          selectRange
          value={[startDate ? startDate.toDate() : null, endDate ? endDate.toDate() : null]}
          onChange={(values: [Date] | [Date, Date]) => {
            setStartDate(dayjs(values[0]));
            setEndDate(values[1] ? dayjs(values[1]) : dayjs(values[0]));
          }}
        />
        <div className="mm-range-calendar-modal__selected-dates">
          <div className="selected-date-container">
            <div className="selected-date-side">
              <span className="date-caption">Start date: </span>
              <span>{startDate ? dayjs(startDate).format('L') : ''}</span>
            </div>
            {withTime && (
              <>
                <div className="date-divider" />
                <div className="selected-date-side">
                  <span className="date-caption">Time: </span>
                  {startDate && (
                    <TimePicker
                      className="date-time-picker"
                      onChange={value => onChangeTime(value, 'start')}
                      value={timeFromDate(startDate)}
                      clockIcon={null}
                      clearIcon={null}
                      disableClock
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="selected-date-container">
            <div className="selected-date-side">
              <span className="date-caption">End date: </span>
              <span>{endDate ? dayjs(endDate).format('L') : ''}</span>
            </div>
            {withTime && (
              <>
                <div className="date-divider" />
                <div className="selected-date-side">
                  <span className="date-caption">Time: </span>
                  {endDate && (
                    <TimePicker
                      className="date-time-picker"
                      onChange={value => onChangeTime(value, 'end')}
                      value={timeFromDate(endDate)}
                      clockIcon={null}
                      clearIcon={null}
                      disableClock
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mm-range-calendar-modal__buttons">
          <Button color="primary" onClick={onHandleFilter}>
            Submit
          </Button>
          {handleDefaultTotal && (
            <Button color="secondary" onClick={onHandleDefaultTotal}>
              Default (Total)
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export { RangeCalendar };
