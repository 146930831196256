import { useMemo } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import cn from 'classnames';

import { tailwindConfig } from 'tailwind-config';
import { AccountsIcon } from 'assets/icons/AccountsIcon';
import { ROUTE_PATHS } from 'constant/routes';

const Accounts = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const accountsPathIsActive: boolean = useMemo(
    () => Boolean(matchPath(`${ROUTE_PATHS.accounts}/*`, pathname)),
    [pathname],
  );

  return (
    <div
      className={cn('mm-sidebar__menu__item', { _active: accountsPathIsActive })}
      onClick={() => {
        navigate(ROUTE_PATHS.accounts);
      }}
    >
      <div className="mm-sidebar__menu__item__left">
        <div className="mm-sidebar__menu__item__icon">
          <AccountsIcon
            color={accountsPathIsActive ? tailwindConfig.theme.colors.primary : undefined}
          />
        </div>
        <span className="mm-sidebar__menu__item__text">Accounts</span>
      </div>
    </div>
  );
};

export { Accounts };
