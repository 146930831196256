import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import cn from 'classnames';

import { IChartTimeframe, mapChartTimeframeToCaption } from 'types/charts';

import './index.scss';

dayjs.extend(duration);

interface IChartTimeframeControllerProps {
  supportedTimeframes: IChartTimeframe[];
  className?: string;
  selectedTimeframe?: IChartTimeframe;
  setSelectedTimeframe?: (v: IChartTimeframe) => void;
  disabled?: boolean;
}

const ChartTimeframeController: React.FC<IChartTimeframeControllerProps> = ({
  supportedTimeframes,
  className,
  selectedTimeframe,
  setSelectedTimeframe,
  disabled = false,
}) => {
  const handleSetDefaultTimeframe = useCallback(
    (newTimeframe: IChartTimeframe) => {
      if (setSelectedTimeframe) {
        setSelectedTimeframe(newTimeframe);
      }
    },
    [setSelectedTimeframe],
  );

  return (
    <div
      className={cn({ [className ?? '']: !!className }, 'mm-chart-timeframe-controller-wrapper')}
    >
      <div className="mm-chart-timeframe-controller__default-timeframes">
        {supportedTimeframes.map(el => {
          const caption = mapChartTimeframeToCaption(el);

          const timeframeIsSelected = selectedTimeframe === el;
          const isDisabled = disabled;

          return (
            <div
              key={el}
              onClick={() => !isDisabled && handleSetDefaultTimeframe(el)}
              className={cn('timeframe', {
                _is_selected: !!timeframeIsSelected,
                _is_disabled: isDisabled,
              })}
            >
              {caption}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChartTimeframeController;
