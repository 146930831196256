import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, matchPath, PathMatch } from 'react-router';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { isNil } from 'lodash';
import { useTypedSelector } from 'store';

import { Spinner } from 'ui';
import { tailwindConfig } from 'tailwind-config';
import { ListIcon } from 'assets/icons';
import { ProjectListWithPairs } from 'common/project';
import { SearchProjectField } from '../SearchProjectField';
import { ROUTE_PATHS } from 'constant/routes';

import './projects.scss';

const Projects: React.FC = () => {
  const userIsAdmin = useTypedSelector(store => store.auth.isAdmin);
  const projects = useTypedSelector(store => store.projects.projects);
  const searchProjectText = useTypedSelector(store => store.ui.searchProjectText);

  const [listOpened, setListOpened] = useState<boolean>(true);
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(null);
  const { pathname } = useLocation();

  const onOpenProjectsList = useCallback((): void => {
    setListOpened(v => !v);
  }, []);

  const onSelectProject = useCallback(
    (projectId: number): void => {
      setSelectedProjectId(projectId === selectedProjectId ? null : projectId);
    },
    [selectedProjectId],
  );

  useEffect(() => {
    if (projects && projects.length !== 0) {
      const _matchPath: PathMatch<'id'> | null = matchPath(`${ROUTE_PATHS.project}/*`, pathname);

      const projectId = _matchPath ? _matchPath?.params?.id ?? undefined : undefined;

      if (!isNil(projectId) && !isNaN(Number(projectId))) {
        setSelectedProjectId(Number(projectId));
        document?.getElementById(`sidebar-project-${projectId}`)?.scrollIntoView();
      }
    }
  }, [pathname, projects]);

  const searchedProjects = useMemo(() => {
    const _projects = projects ?? [];

    if (searchProjectText === '') return _projects;

    return _projects.filter(el => {
      const searchValue = searchProjectText.toLowerCase();

      return (
        el.name.toLowerCase().includes(searchValue) ||
        !!el.dexPairs.find(pair => pair.symbol.toLowerCase().includes(searchValue)) ||
        !!el.cexPairs.find(pair => pair.symbol.toLowerCase().includes(searchValue))
      );
    });
  }, [projects, searchProjectText]);

  return (
    <>
      <div
        className={cn('mm-sidebar__menu__item', 'projects-item', { _active: listOpened })}
        onClick={onOpenProjectsList}
      >
        <div className="mm-sidebar__menu__item__left">
          <div className="mm-sidebar__menu__item__icon">
            <ListIcon color={listOpened ? tailwindConfig.theme.colors.primary : undefined} />
          </div>
          <span className="mm-sidebar__menu__item__text">Projects</span>
        </div>
      </div>
      {listOpened && projects && projects.length !== 0 && <SearchProjectField />}
      <motion.div
        initial={{ height: 50, overflow: 'hidden' }}
        animate={{
          height: listOpened ? 'auto' : 0,
          overflow: listOpened ? 'auto' : 'hidden',
        }}
        transition={{ easings: ['easeIn', 'easeInOut'] }}
        className={cn('mm-sidebar__projects', 'scrollable')}
      >
        {isNil(projects) && (
          <div className="mm-sidebar__projects__spinner">
            <Spinner size="mini" />
          </div>
        )}
        {projects && projects.length === 0 && (
          <span className="mm-sidebar__projects__no-info">
            {userIsAdmin
              ? "Projects don't exist yet. Please create your first one!"
              : "You aren't invited to any project. Ask 111PG admins for it"}
          </span>
        )}
        {projects && projects.length !== 0 && (
          <>
            {searchedProjects.length === 0 && (
              <span className="mm-sidebar__projects__no-info">
                {'Projects or pairs not found...'}
              </span>
            )}
            {searchedProjects.length > 0 &&
              searchedProjects.map(project => (
                <ProjectListWithPairs
                  id={`sidebar-project-${project.id}`}
                  project={project}
                  onSelectProject={onSelectProject}
                  selectedProjectId={selectedProjectId}
                  key={project.id}
                />
              ))}
          </>
        )}
      </motion.div>
    </>
  );
};

export { Projects };
