import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { PairPageWrapper, Spinner, PageNotFound } from 'ui';
import { EDexPairPanel, IPanelProps } from 'types/pairs';
import { usePairConfig } from 'store/slices/pair_configs/hooks';

import { AntiFrontRunBot } from './AntiFrontRunBot';
import { BalanceBot } from './BalanceBot';
import { BuySellBot } from './BuySellBot';
import { DexCharts } from '../DexCharts';
import { Statistic } from './Statistic';
import { Transactions } from './Transactions';
import { Wallets } from './Wallets';
import { SmartLiquidityBot } from './SmartLiquidityBot';
import { EExchange } from 'web3';

const DexPair: React.FC = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair);
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const pairError = useTypedSelector(store => store.pairs.pairError);
  const pairLoading = useTypedSelector(store => store.pairs.pairLoading);

  const pairId = useMemo(() => dexPair?.id ?? 0, [dexPair]);

  const { pairConfig, setPairConfig } = usePairConfig({
    exchange: EExchange.dex,
    id: pairId,
  });

  const panels = useMemo(() => {
    const adminPanel = [
      EDexPairPanel.wallets,
      EDexPairPanel.afr_bot,
      EDexPairPanel.sl_bot,
      EDexPairPanel.balance_bot,
      EDexPairPanel.buy_sell_bot,
      EDexPairPanel.transactions,
    ];

    const panelValues = Object.values(EDexPairPanel).filter(
      el => isAdmin || !adminPanel.includes(el),
    );

    return panelValues.map(value => {
      const props: IPanelProps = {
        initialOpened: pairConfig.lastOpenedPanel === value,
        setIsOpened: (opened: boolean) =>
          setPairConfig(pairId, { ...pairConfig, lastOpenedPanel: opened ? value : undefined }),
      };

      const mapper: Record<EDexPairPanel, React.ReactNode> = {
        [EDexPairPanel.charts]: <DexCharts key="charts" {...props} />,
        [EDexPairPanel.statistics]: <Statistic key="statistic" {...props} />,
        [EDexPairPanel.wallets]: <Wallets key="wallets" {...props} />,
        [EDexPairPanel.afr_bot]: <AntiFrontRunBot key="afr-bot" {...props} />,
        [EDexPairPanel.sl_bot]: <SmartLiquidityBot key="sl-bot" {...props} />,
        [EDexPairPanel.balance_bot]: <BalanceBot key="balance-bot" {...props} />,
        [EDexPairPanel.buy_sell_bot]: <BuySellBot key="buy-sell-bot" {...props} />,
        [EDexPairPanel.transactions]: <Transactions key="transactions" {...props} />,
      };

      return mapper[value];
    });
  }, [isAdmin, pairConfig, setPairConfig, pairId]);

  if (!pairLoading && pairError) {
    return <PageNotFound />;
  }

  return (
    <PairPageWrapper>
      {dexPair && panels}
      {!dexPair && (
        <div className="w-full h-52 flex items-center justify-center">
          <Spinner size="small" />
        </div>
      )}
    </PairPageWrapper>
  );
};

export default DexPair;
