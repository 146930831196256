import React, {
  createContext,
  useCallback,
  useState,
  SetStateAction,
  Dispatch,
  useMemo,
  useContext,
} from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTypedDispatch, useTypedSelector } from 'store';
import { Time } from 'lightweight-charts';
import { BigNumber } from '@ethersproject/bignumber';

import { getPeriodToAPI } from 'utils/charts';
import { IChartRange } from 'types/charts';
import { ICexActiveOrder, ECexOrderSide } from 'types/orders';
import { ICexAccount } from 'types/accounts';
import { IPnl } from 'types/pnl';
import { ApiStatistic, ApiOrders, ApiCharts } from 'api';
import { CexPairContext } from 'context/CexPairContext';
import { bn, humanizeBn, divideBignumbers } from 'tools/math';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { ChartsControlsContext } from 'context/cex-charts-panel';

interface IMarketStatistics {
  our_volume_base: string;
  our_volume_quote: string;
  ourTradesCount: string;
  avg_base_trade_size: string;
  avg_quote_trade_size: string;
  totalFees: string;
  activeOrdersCount: string;
  base_volume: string;
  change24h: string;
  high24h: string;
  last_price: string;
  low24h: string;
  quote_volume: string;
  spread_percentage: string;
  total_volume_base: string;
  total_volume_quote: string;
  total_base_income: string;
  total_quote_income: string;
  uptime: string;
  balance_pnl: IPnl;
  ending_balance_delta: {
    base: {
      amount: string;
      amount_usd: string;
      coin: string;
    };
    quote: {
      amount: string;
      amount_usd: string;
      coin: string;
    };
  };
  starting_balance_delta: {
    base: {
      amount: string;
      amount_usd: string;
      coin: string;
    };
    quote: {
      amount: string;
      amount_usd: string;
      coin: string;
    };
  };
}

const INITIAL_MARKET_STATISTICS: IMarketStatistics = {
  our_volume_base: '',
  our_volume_quote: '',
  ourTradesCount: '',
  avg_base_trade_size: '',
  avg_quote_trade_size: '',
  totalFees: '',
  activeOrdersCount: '',
  base_volume: '',
  change24h: '',
  high24h: '',
  last_price: '',
  low24h: '',
  quote_volume: '',
  spread_percentage: '',
  total_volume_base: '',
  total_volume_quote: '',
  total_base_income: '',
  total_quote_income: '',
  uptime: '',
  balance_pnl: {
    net_pnl: '',
    net_pnl_percentage: '',
    realised_pnl: '',
    realised_pnl_percentage: '',
    unrealised_pnl: '',
    unrealised_pnl_percentage: '',
  },
  ending_balance_delta: {
    base: {
      amount: '',
      amount_usd: '',
      coin: '',
    },
    quote: {
      amount: '',
      amount_usd: '',
      coin: '',
    },
  },
  starting_balance_delta: {
    base: {
      amount: '',
      amount_usd: '',
      coin: '',
    },
    quote: {
      amount: '',
      amount_usd: '',
      coin: '',
    },
  },
};

interface ICexMarketOverviewContext {
  period: { get: IChartRange | undefined; set: Dispatch<SetStateAction<IChartRange | undefined>> };
  startDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  endDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  loading: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
  orders: (ICexActiveOrder & { price: BigNumber; priceNumber: number })[];
  chartOrders: {
    id: number;
    side: ECexOrderSide;
    amount: number;
  }[];
  ordersLoading: boolean;
  onCancelOrders: (orders: ICexActiveOrder[]) => Promise<{ isSuccess: boolean }>;
  onCancelByAccount: (accountId: number) => Promise<{ isSuccess: boolean }>;
  cancelingOrderLoading: string | undefined;
  selectedAccounts: {
    get: ICexAccount[];
    set: Dispatch<SetStateAction<ICexAccount[]>>;
  };
  balanceSelectedAccounts: {
    get: ICexAccount[];
    set: Dispatch<SetStateAction<ICexAccount[]>>;
  };
  marketStatistics: IMarketStatistics;
  liquidityPercent: { get: number; set: Dispatch<SetStateAction<number>> };
  liquidityChartLoading: boolean;
  spreadChartPoints: { time: Time; value: number }[];
  liquidityChartPoints: { time: Time; value_in_base: number; value_in_quote: number }[];
  volumeChartPoints: { time: Time; base: number; quote: number }[];
  feeChartPoints: { time: Time; value: number }[];
  deltaVolumesBuyPoints: { time: Time; base_buy: number; quote_buy: number }[];
  deltaVolumesSellPoints: { time: Time; base_sell: number; quote_sell: number }[];
  getLiquidityChart: () => Promise<any>;
}

export const CexMarketOverviewContext = createContext<ICexMarketOverviewContext>({
  period: { get: 'ALL', set: () => {} },
  startDate: { get: 0, set: () => {} },
  endDate: { get: 0, set: () => {} },
  loading: { get: false, set: () => {} },
  orders: [],
  cancelingOrderLoading: undefined,
  chartOrders: [],
  ordersLoading: false,
  onCancelOrders: async () => {
    return { isSuccess: false };
  },
  onCancelByAccount: async () => {
    return { isSuccess: false };
  },
  selectedAccounts: { get: [], set: () => {} },
  balanceSelectedAccounts: {
    get: [],
    set: () => {},
  },
  marketStatistics: INITIAL_MARKET_STATISTICS,
  liquidityPercent: { get: 20, set: () => {} },
  liquidityChartLoading: false,
  spreadChartPoints: [],
  liquidityChartPoints: [],
  volumeChartPoints: [],
  feeChartPoints: [],
  deltaVolumesBuyPoints: [],
  deltaVolumesSellPoints: [],
  getLiquidityChart: async () => {},
});

interface ICexMarketOverviewContextProviderProps {
  children?: React.ReactNode;
}

export const CexMarketOverviewContextProvider: React.FC<ICexMarketOverviewContextProviderProps> = ({
  children,
}) => {
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const isAdmin = useTypedSelector(store => store.auth.isAdmin);
  const pairId = cexPair.id;
  const { accountsToAPI } = useContext(ChartsControlsContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [cancelingOrderLoading, setCancelingOrderLoading] = useState<string | undefined>(undefined);

  const [period, setPeriod] = useState<IChartRange | undefined>('1D');
  const [startDate, setStartDate] = useState<number | undefined>(undefined);
  const [endDate, setEndDate] = useState<number | undefined>(undefined);
  const [liquidityPercent, setLiquidityPercent] = useState<number>(2);
  const [liquidityChartLoading, setLiquidityChartLoading] = useState<boolean>(false);

  const { accounts } = useContext(CexPairContext);
  const [selectedAccounts, setSelectedAccounts] = useState<ICexAccount[]>([]);
  const [balanceSelectedAccounts, setBalanceSelectedAccounts] = useState<ICexAccount[]>([]);
  const [marketStatistics, setMarketStatistics] =
    useState<IMarketStatistics>(INITIAL_MARKET_STATISTICS);
  const [orders, setOrders] = useState<ICexActiveOrder[]>([]);

  const [spreadChartPoints, setSpreadChartPoints] = useState<{ time: Time; value: number }[]>([]);
  const [liquidityChartPoints, setLiquidityChartPoints] = useState<
    { time: Time; value_in_base: number; value_in_quote: number }[]
  >([]);
  const [volumeChartPoints, setVolumeChartPoints] = useState<
    { time: Time; base: number; quote: number }[]
  >([]);
  const [feeChartPoints, setFeeChartPoints] = useState<{ time: Time; value: number }[]>([]);
  const [deltaVolumesBuyPoints, setDeltaVolumesBuyPoints] = useState<
    { time: Time; base_buy: number; quote_buy: number }[]
  >([]);
  const [deltaVolumesSellPoints, setDeltaVolumesSellPoints] = useState<
    { time: Time; base_sell: number; quote_sell: number }[]
  >([]);

  const periodToAPI = useMemo(() => getPeriodToAPI(period), [period]);

  const activeOrders = useMemo(
    () =>
      orders
        .map(el => {
          const findAccount = accounts.find(account => account.id === el.account_id);

          const quoteAmountBN = bn(el.quote_amount);
          const baseAmountBN = bn(el.base_amount);

          const price = divideBignumbers([quoteAmountBN, 18], [baseAmountBN, 18]);
          const priceNumber = Number(humanizeBn(price, 18));

          return {
            ...el,
            account_notes: findAccount ? findAccount.notes : undefined,
            price,
            priceNumber,
          };
        })
        .sort((a, b) => {
          if (a.priceNumber >= b.priceNumber) return -1;

          if (b.priceNumber >= a.priceNumber) return 1;

          return 0;
        }),
    [orders, accounts],
  );

  const chartOrders = useMemo(
    () =>
      activeOrders.map(order => {
        return {
          id: order.id,
          side: order.side,
          amount: order.priceNumber,
        };
      }),
    [activeOrders],
  );

  const getOrders = useCallback(
    async (options?: { silent: boolean }) => {
      const isSilent = options?.silent;

      try {
        if (!isAdmin) return;

        if (!isSilent) {
          setOrdersLoading(true);
        }

        const { data } = await ApiOrders.getCexActiveOrders({
          pair_id: pairId,
          limit: 100,
          offset: 0,
          account_id: accountsToAPI(selectedAccounts).optional,
        });

        setOrders(data?.orders ?? []);
        setMarketStatistics(v => ({
          ...v,
          activeOrdersCount: data?.total_count.toString() ?? '',
        }));
      } catch (error) {
        console.log('error: ', error);
      } finally {
        if (!isSilent) {
          setOrdersLoading(false);
        }
      }
    },
    [selectedAccounts, accountsToAPI, pairId, isAdmin],
  );

  const onCancelOrders = useCallback(
    async (orders: ICexActiveOrder[]) => {
      try {
        dispatch(dropAlertState());

        const mappedOrders = orders.map(order => ({
          cex_order_id: order.cex_order_id,
          cex_client_order_id: order.cex_order_id,
        }));

        const result = await ApiOrders.cancelOrders({
          account_id: orders[0].account_id,
          pair_id: pairId,
          orders: mappedOrders,
        });

        if (result.isSuccess) {
          setOrders(value => value.filter(el => !orders.find(o => o.id === el.id)));
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: result.errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        }

        setCancelingOrderLoading(undefined);

        return { isSuccess: result.isSuccess };
      } catch (error) {
        console.log('error: ', error);
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
        return { isSuccess: false };
      }
    },
    [pairId, dispatch],
  );

  const onCancelByAccount = useCallback(
    async (accountId: number) => {
      try {
        const { isSuccess, errorMessage } = await ApiOrders.cancelOrders({
          account_id: accountId,
          pair_id: pairId,
        });

        if (isSuccess) {
          setOrders(value => value.filter(el => el.account_id !== accountId));
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        }

        return { isSuccess };
      } catch (error) {
        console.log('error: ', error);
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );

        return { isSuccess: false };
      }
    },
    [pairId, dispatch],
  );

  const getMarketOverview = useCallback(async () => {
    try {
      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;
      const period = periodToAPI;
      const account_id = accountsToAPI(selectedAccounts).optional;

      const [
        { data: tickerData },
        { data: totalVolumeData },
        { data: cexAvgOrderSizeData },
        { data: cexOurTradesCountData },
        { data: cexOurTradesTotalVolumeData },
        { data: cexTotalFeesData },
        { data: cexOurIncome },
        { data: cexUptimeData },
      ] = await Promise.all([
        ApiStatistic.getCexTickerInfo({
          symbol,
          cex,
        }),
        ApiStatistic.geCexMarketTotalVolume({ symbol, cex, period, startDate, endDate }),
        isAdmin
          ? ApiStatistic.getCexAvgOrderSize({
              pairId,
              period,
              startDate,
              endDate,
              account_id,
            })
          : { data: undefined },
        isAdmin
          ? ApiStatistic.getCexOurTradesCount({
              pairId,
              period,
              startDate,
              endDate,
              account_id,
            })
          : { data: undefined },
        isAdmin
          ? ApiStatistic.getCexOurTradesTotalVolume({
              pairId,
              period,
              startDate,
              endDate,
              account_id,
            })
          : { data: undefined },
        ApiStatistic.getCexTotalFees({
          pairId,
          period,
          startDate,
          endDate,
          account_id,
        }),
        isAdmin
          ? ApiStatistic.getCexOurIncome({ pairId, account_id, period, startDate, endDate })
          : { data: undefined },
        isAdmin ? ApiStatistic.getCexUptime({ pairId }) : { data: undefined },
      ]);

      setMarketStatistics(v => ({
        ...v,
        base_volume: tickerData?.base_volume ?? '',
        change24h: tickerData?.change24h ?? '',
        high24h: tickerData?.high24h ?? '',
        last_price: tickerData?.last_price ?? '',
        low24h: tickerData?.low24h ?? '',
        quote_volume: tickerData?.quote_volume ?? '',
        spread_percentage: tickerData?.spread_percentage ?? '',
        total_volume_base: totalVolumeData?.total_volume_base ?? '',
        total_volume_quote: totalVolumeData?.total_volume_quote ?? '',
        avg_base_trade_size: cexAvgOrderSizeData?.base ?? '',
        avg_quote_trade_size: cexAvgOrderSizeData?.quote ?? '',
        ourTradesCount: cexOurTradesCountData?.count ?? '',
        our_volume_base: cexOurTradesTotalVolumeData?.total ?? '',
        our_volume_quote: cexOurTradesTotalVolumeData?.total_quote ?? '',
        totalFees: cexTotalFeesData?.fee ?? '',
        total_base_income: cexOurIncome?.income.base ?? '',
        total_quote_income: cexOurIncome?.income.quote ?? '',
        uptime: cexUptimeData?.uptime ?? '',
      }));
    } catch (error) {
      console.log('error: ', error);
    }

    //eslint-disable-next-line
  }, [cexPair.id, startDate, endDate, periodToAPI, pairId, isAdmin, accountsToAPI]);

  const getLiquidityChart = useCallback(async () => {
    try {
      setLiquidityChartLoading(true);

      const period = periodToAPI;
      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;

      const { data } = await ApiCharts.getCexStatisticsChartLiquidity({
        cex,
        symbol,
        period,
        startDate,
        endDate,
        percentage: liquidityPercent,
      });

      setLiquidityChartPoints(
        data?.points.map(el => ({
          time: (el.time / 1000) as Time,
          value_in_base: Number(el.value_in_base),
          value_in_quote: Number(el.value_in_quote),
        })) ?? [],
      );
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLiquidityChartLoading(false);
    }

    return {};

    //eslint-disable-next-line
  }, [cexPair.id, endDate, startDate, liquidityPercent, periodToAPI]);

  const getCharts = useCallback(async () => {
    try {
      const period = periodToAPI;
      const account_id = accountsToAPI(selectedAccounts).optional;
      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;

      const [
        { data: cexStatisticsChartSpread },
        { data: cexStatisticsChartFeeData },
        { data: cexStatisticsChartDelta },
        { data: cexStatisticsChartVolume },
        {},
      ] = await Promise.all([
        ApiCharts.getCexStatisticsChartSpread({
          cex,
          symbol,
          period,
          startDate,
          endDate,
        }),
        isAdmin
          ? ApiCharts.getCexStatisticsChartFee({
              pairId,
              account_id,
              period,
              startDate,
              endDate,
            })
          : { data: undefined },
        isAdmin
          ? ApiCharts.getCexStatisticsChartDelta({
              pairId,
              account_id,
              period,
              startDate,
              endDate,
            })
          : { data: undefined },
        isAdmin
          ? ApiCharts.getCexStatisticsChartVolume({
              pairId,
              account_id,
              period,
              startDate,
              endDate,
            })
          : { data: undefined },
        getLiquidityChart(),
      ]);

      setSpreadChartPoints(
        cexStatisticsChartSpread?.points.map(el => ({
          time: (el.time / 1000) as Time,
          value: Number(el.value),
        })) ?? [],
      );

      if (isAdmin) {
        setVolumeChartPoints(
          cexStatisticsChartVolume?.points.map(el => ({
            time: (el.time / 1000) as Time,
            base: Number(el.base),
            quote: Number(el.quote),
          })) ?? [],
        );

        setFeeChartPoints(
          cexStatisticsChartFeeData?.points.map(el => ({
            time: (el.time / 1000) as Time,
            value: Number(el.value),
          })) ?? [],
        );

        setDeltaVolumesBuyPoints(
          cexStatisticsChartDelta?.points.map(el => ({
            time: (el.time / 1000) as Time,
            base_buy: Number(el.buy_value_base),
            quote_buy: Number(el.buy_value_quote),
          })) ?? [],
        );
        setDeltaVolumesSellPoints(
          cexStatisticsChartDelta?.points.map(el => ({
            time: (el.time / 1000) as Time,
            base_sell: -Number(el.sell_value_base),
            quote_sell: -Number(el.sell_value_quote),
          })) ?? [],
        );
      }
    } catch (error) {
      console.log('error: ', error);
    }

    //eslint-disable-next-line
  }, [
    cexPair.id,
    accountsToAPI,
    endDate,
    pairId,
    periodToAPI,
    startDate,
    isAdmin,
    getLiquidityChart,
  ]);

  const getBalances = useCallback(async () => {
    try {
      if (accounts.length === 0) {
        return;
      }

      const account_id = accountsToAPI(selectedAccounts).array;

      const symbol = `${cexPair.token_base.symbol}-${cexPair.token_quote.symbol}`;
      const cex = cexPair.cex;
      const period = periodToAPI;

      const [{ data: cexBalancesDeltaData }, { data: cexBalancesPnl }] = await Promise.all([
        ApiStatistic.geCexBalancesDelta({ symbol, cex, account_id, period, startDate, endDate }),
        ApiStatistic.getBalancePnl({
          account_id,
          symbol,
          cex,
          pairId,
          period: period,
          endDate,
          startDate,
        }),
      ]);

      setMarketStatistics(v => ({
        ...v,
        ...(cexBalancesDeltaData?.starting_balance
          ? { starting_balance_delta: cexBalancesDeltaData?.starting_balance }
          : {}),
        ...(cexBalancesDeltaData?.ending_balance
          ? { ending_balance_delta: cexBalancesDeltaData?.ending_balance }
          : {}),
        balance_pnl: cexBalancesPnl ?? ({} as IPnl),
      }));
    } catch (error) {
      console.log('error: ', error);
    }

    //eslint-disable-next-line
  }, [cexPair.id, pairId, periodToAPI, startDate, endDate, accounts, accountsToAPI]);

  const getInfo = useCallback(async () => {
    try {
      setLoading(true);

      await Promise.all([getMarketOverview(), getCharts()]);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }, [getCharts, getMarketOverview]);

  useDeepCompareEffect(() => {
    getOrders();

    const interval = setInterval(() => {
      getOrders({ silent: true });
    }, 10 * 1000); // 10 seconds

    return () => {
      clearInterval(interval);
    };
  }, [accountsToAPI || [], pairId, isAdmin]);

  useDeepCompareEffect(() => {
    getInfo();
    getBalances();

    //eslint-disable-next-line
  }, [cexPair.id, accountsToAPI, startDate, endDate, periodToAPI]);

  return (
    <CexMarketOverviewContext.Provider
      value={{
        period: { get: period, set: setPeriod },
        startDate: { get: startDate, set: setStartDate },
        endDate: { get: endDate, set: setEndDate },
        loading: { get: loading, set: setLoading },
        orders: activeOrders,
        cancelingOrderLoading,
        chartOrders,
        ordersLoading,
        onCancelOrders,
        onCancelByAccount,
        selectedAccounts: { get: selectedAccounts, set: setSelectedAccounts },
        balanceSelectedAccounts: { get: balanceSelectedAccounts, set: setBalanceSelectedAccounts },
        marketStatistics,
        liquidityPercent: { get: liquidityPercent, set: setLiquidityPercent },
        liquidityChartLoading,
        spreadChartPoints,
        liquidityChartPoints,
        volumeChartPoints,
        feeChartPoints,
        deltaVolumesBuyPoints,
        deltaVolumesSellPoints,
        getLiquidityChart,
      }}
    >
      {children}
    </CexMarketOverviewContext.Provider>
  );
};
