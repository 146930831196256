export enum ETheme {
  light = 'light',
  dark = 'dark',
}

export interface IColorsTheme {
  bg_primary: string;
  bg_root: string;
  primary_light: string;
  primary: string;
  primary_dark: string;
  light_gray_1: string;
  light_gray_2: string;
  gray_1: string;
  gray_2: string;
  gray_3: string;
  light_red_1: string;
  light_red_2: string;
  pink_1: string;
  red_1: string;
  black_1: string;
  light_blue_1: string;
  blue_1: string;
  button_hover: string;
  backdrop_hover: string;
  successful_1: string;
  successful_1_light: string;
  pending_1: string;
  processing_1: string;
  error_1: string;
  failed_1: string;
  chart_violet: string;
  chart_turquoise: string;
  chart_border_color: string;
  chart_line_color: string;
  buy_trade_color: string;
  sell_trade_color: string;
}

export const lightThemeColors: IColorsTheme = {
  bg_primary: '#ffffff',
  bg_root: '#f7f7f7',
  primary_light: '#e5ddff',
  primary: '#5932ea',
  primary_dark: '#4123b3',
  light_gray_1: '#f7f7f7',
  light_gray_2: '#f9f9ff',
  gray_1: '#7f91bb',
  gray_2: '#838da3',
  gray_3: '#d9dbe9',
  light_red_1: '#f5e8f0',
  light_red_2: '#eccece',
  pink_1: '#ff7c99',
  red_1: '#ce4966',
  black_1: '#191c1f',
  light_blue_1: '#cffafe',
  blue_1: '#47b6d4',
  button_hover: '#191c1f',
  backdrop_hover: 'linear-gradient(180deg, rgb(217 217 217 / 0%) 0%, rgb(217 217 217 / 20%) 100%)',
  successful_1: '#64e0a5',
  successful_1_light: '#e1f4f1',
  pending_1: '#f3f31e',
  processing_1: '#f2b91d',
  error_1: '#f21d31',
  failed_1: '#f21d87',
  chart_violet: '#7879ee',
  chart_turquoise: '#48ccf7',
  chart_border_color: '#d8dade',
  chart_line_color: '#ebedf2',
  buy_trade_color: '#64e0a5',
  sell_trade_color: '#f21d31',
};

export const darkThemeColors: IColorsTheme = {
  bg_primary: '#18181c',
  bg_root: '#0c0c0d',
  primary_light: '#cebaf7',
  primary: '#af8cf6',
  primary_dark: '#9b6ef5',
  light_gray_1: '#1f1f23',
  light_gray_2: '#292932',
  gray_1: '#b2b2c2',
  gray_2: '#9c9cb9',
  gray_3: '#3b3c3f',
  light_red_1: '#443f42',
  light_red_2: '#41111e',
  pink_1: '#ff7c99',
  red_1: '#ce4966',
  black_1: '#dbdbe8',
  light_blue_1: '#2eb2be',
  blue_1: '#1a4753',
  button_hover: '#191c1f',
  backdrop_hover: 'linear-gradient(180deg, rgb(21 21 23 / 60%) 0%, rgb(12 8 28 / 80%) 100%)',
  successful_1: '#64e0a5',
  successful_1_light: '#1e584f',
  pending_1: '#f3f31e',
  processing_1: '#f2b91d',
  error_1: '#f21d31',
  failed_1: '#f21d87',
  chart_violet: '#7879ee',
  chart_turquoise: '#48ccf7',
  chart_border_color: '#242433',
  chart_line_color: '#242433',
  buy_trade_color: '#64e0a5',
  sell_trade_color: '#f21d31',
};

export const themeColors: Record<ETheme, IColorsTheme> = {
  [ETheme.light]: lightThemeColors,
  [ETheme.dark]: darkThemeColors,
};
