import { BigNumber } from '@ethersproject/bignumber';
import store from 'store';

import { IDexPair } from 'types/pairs';
import { calculateSwapsGasPrice } from 'utils/calculates';
import { bn, bnFrom, addBignumbers, multiplyBignumbers } from 'tools/math';
import { dexConfigs } from 'web3';

interface DexDailyFeesValues {
  daily_volume: BigNumber;
  daily_txs: number;
  dexPair: IDexPair;
}

export const dexDailyFees = ({ daily_txs, daily_volume, dexPair }: DexDailyFeesValues) => {
  const gasPrice = store.getState().auth.gasPrices[dexPair.network]?.price ?? '0';

  const pairFeePercentage = (dexConfigs[dexPair.dex].fee / 100).toString();

  const baseAmount = multiplyBignumbers([daily_volume, 18], [bn(pairFeePercentage), 18]);
  const baseUsd = multiplyBignumbers([baseAmount, 18], [bnFrom(dexPair.token_base.price_usd), 6]);

  const feeAmount = calculateSwapsGasPrice({
    swap_gas_limit: dexPair.swap_gas_limit,
    gasPrice: gasPrice,
    swaps_count: daily_txs,
  });
  const feeUsd = multiplyBignumbers([feeAmount, 18], [bnFrom(dexPair.token_fee.price_usd), 6]);

  const totalUsd = addBignumbers([baseUsd, 18], [feeUsd, 18]);

  return {
    base: {
      amount: baseAmount,
      usd: baseUsd,
    },
    fee: {
      amount: feeAmount,
      usd: feeUsd,
    },
    total: {
      usd: totalUsd,
    },
  };
};
