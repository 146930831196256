import React, { useCallback, useMemo } from 'react';
import { useTypedSelector } from 'store';

import { PairCexTableConnectedBotsHead } from '../PairCexTableConnectedBotsHead';
import { ICexAccount } from 'types/accounts';

interface IConnectedBotsHeadProps {
  meta: any;
}

const ConnectedBotsHead: React.FC<IConnectedBotsHeadProps> = React.memo(({ meta }) => {
  const pair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const _meta = useMemo(() => meta, [meta]);
  const isActive = useMemo(() => _meta.selectedAccounts.length !== 0, [_meta]);

  const { records, setRecords } = _meta;

  const changeAccounts = useCallback(
    (newSelectedRecords: ICexAccount[]) => {
      const newRecords: ICexAccount[] = [...records].map(el => {
        const selectedRecord = newSelectedRecords.find(
          selectedRecord => selectedRecord.id === el.id,
        );

        if (selectedRecord) return selectedRecord;

        return el;
      });

      setRecords(newRecords);
    },
    [records, setRecords],
  );

  return (
    <PairCexTableConnectedBotsHead
      isActive={isActive}
      selectedAccounts={_meta.selectedAccounts}
      pair={pair}
      onChangeSelectedAccounts={changeAccounts}
    />
  );
});

export { ConnectedBotsHead };
