import { useMemo } from 'react';
import { isArray } from 'lodash';

import { IFilterScheme, IFilterSchemeExtended } from 'types/filters/common';
import { EFilterEntity, IFilterValue } from 'types/filters/common';

import { FilterSelectField } from '../fields';

interface ISchemeTreeNode {
  name: string;
  label: string;
  depth: number;
  single: boolean;
  scheme?: IFilterScheme;
  type?: EFilterEntity;
  paths?: ISchemeTreeNode[];
}

interface ISelection {
  depth: number;
  items: ISchemeTreeNode[];
  selectedItem: ISchemeTreeNode | undefined;
  onSelectItem: (item: ISchemeTreeNode) => void;
}

const schemeToTree = (scheme: Record<string, IFilterSchemeExtended>) => {
  const paths = (val: IFilterSchemeExtended, depth: number): ISchemeTreeNode => {
    if ('filters' in val && 'name' in val && isArray(val.filters)) {
      return {
        name: val.name,
        label: val.label,
        single: 'single' in val ? (val.single as boolean) : false,
        paths: val.filters.map(el => paths(el, depth + 1)),
        depth,
      };
    }

    return {
      name: (val as any).name,
      label: (val as any).label,
      type: (val as any).type,
      single: 'single' in val ? (val.single as boolean) : false,
      depth,
      scheme: val as any,
    };
  };

  return Object.keys(scheme).reduce(
    (acc, key) => ({
      ...acc,
      [key]: paths(scheme[key], 0),
    }),
    {},
  ) as Record<string, ISchemeTreeNode>;
};

interface IFilterSelectTypeProps {
  filter: IFilterValue;
  filters: IFilterValue[];
  scheme: Record<string, IFilterSchemeExtended>;
  updateFilter: (id: string, filter?: IFilterValue) => void;
}

function FilterSelectType({ filter, filters, scheme, updateFilter }: IFilterSelectTypeProps) {
  const schemeTree = useMemo(() => schemeToTree(scheme), [scheme]);

  const types = useMemo(() => {
    const { parentNames } = filter;

    const selection: ISelection[] = [];

    // working only for high level filters
    const items = Object.keys(schemeTree)
      .map(key => schemeTree[key])
      .filter(scheme => {
        const isFilterSingle = scheme?.single;
        const isFilterSelected = scheme.name === filter.name;

        const moreCount = filters.filter(el => el.name === scheme.name).length;
        const isFiltersContainsMore = moreCount >= 1;

        if (isFilterSingle && !isFilterSelected && isFiltersContainsMore) return false;

        return true;
      });

    selection.push({
      depth: 0,
      items,
      selectedItem: items.find(el => el.name === (parentNames?.[0] ?? filter.name)),
      onSelectItem: item => {
        const isChildrenNotExist = !item.paths || item.paths.length === 0;

        //@ts-ignore
        updateFilter(filter.id, {
          id: filter.id,
          name: isChildrenNotExist ? item.name : undefined,
          parentNames: isChildrenNotExist ? undefined : [item.name],
          type: isChildrenNotExist && item.type ? item.type : undefined,
          scheme: item.scheme,
          value: undefined,
        });
      },
    });

    const pathInitialName = parentNames?.[0] ?? filter.name ?? undefined;

    let schemeTreeNode: ISchemeTreeNode | undefined = pathInitialName
      ? schemeTree[pathInitialName]
      : undefined;

    if (parentNames && parentNames.length !== 0) {
      for (let idx = 0; idx <= parentNames.length; idx++) {
        const parentName = parentNames[idx];
        const items = schemeTreeNode
          ? schemeTreeNode.paths
          : Object.keys(schemeTree).map(key => schemeTree[key]);

        if (!items) break;

        selection.push({
          depth: idx,
          items,
          selectedItem: items.find(el => el.name === parentName || el.name === filter.name),
          onSelectItem: item => {
            const isChildrenNotExist = !item.paths || item.paths.length === 0;
            const parentNewNames = parentNames;
            if (!isChildrenNotExist) {
              parentNewNames.push(item.name);
            }

            //@ts-ignore
            updateFilter(filter.id, {
              id: filter.id,
              name: isChildrenNotExist ? item.name : undefined,
              type: isChildrenNotExist && item.type ? item.type : undefined,
              parentNames: parentNewNames,
              scheme: item.scheme,
              value: undefined,
            });
          },
        });

        if (schemeTreeNode && schemeTreeNode.paths) {
          const nextNode = schemeTreeNode.paths.find(el => el.name === parentName);

          if (!nextNode) break;

          schemeTreeNode = nextNode;
        } else {
          schemeTreeNode = schemeTree[parentName];
        }
      }
    }

    return selection;
  }, [filter, filters, schemeTree, updateFilter]);

  return types.length === 0 ? null : (
    <>
      {types.map((type, idx) => (
        <div style={{ width: `calc(40% / ${types.length})`, flexShrink: 0 }} key={idx}>
          <FilterSelectField
            items={type.items}
            selected={type.selectedItem}
            onSelectItem={type.onSelectItem}
            itemToString={i => i.label}
            placeholder="Select filter"
          />
        </div>
      ))}
    </>
  );
}

export { FilterSelectType };
