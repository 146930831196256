import { IDexName } from 'web3';
import { IDexToken, ICexToken } from 'api/apiPairs/models';

import { ENetwork, EExchange, ECexName } from 'web3';

export interface IDexPairShort {
  created_at: string;
  dex: IDexName;
  id: number;
  network: ENetwork;
  notes: string;
  project_id: number;
  symbol: string;
  updated_at: string;
}

export interface ICexPairShort {
  cex: ECexName;
  created_at: string;
  id: number;
  notes: string;
  project_id: number;
  symbol: string;
}

export interface IProjectPairShort {
  dex_pair?: IDexPairShort;
  cex_pair?: ICexPairShort;
  type: EExchange;
}

export interface IDexPair {
  address: string;
  created_at: string;
  dex: IDexName;
  fee?: number;
  id: number;
  notes: string;
  project_id: number;
  symbol: string;
  network: ENetwork;
  token_base: IDexToken;
  token_fee: IDexToken;
  token_quote: IDexToken;
  updated_at: string;
  swap_gas_limit: number;
}

export interface ICexPair {
  cex: ECexName;
  cex_id: string;
  created_at: string;
  id: number;
  is_reversed: boolean;
  notes: string;
  project_id: number;
  symbol: string;
  token_base: ICexToken;
  token_native: ICexToken;
  token_quote: ICexToken;
}

export enum EDexPairPanel {
  charts = 'charts',
  statistics = 'statistics',
  wallets = 'wallets',
  afr_bot = 'afr_bot',
  sl_bot = 'sl_bot',
  balance_bot = 'balance_bot',
  buy_sell_bot = 'buy_sell_bot',
  transactions = 'transactions',
}

export enum ECexPairPanel {
  charts = 'charts',
  charts_demo = 'charts_demo',
  accounts = 'accounts',
  sl_bot = 'sl_bot',
  orderbook_grid_bot_new = 'orderbook_grid_bot_new',
  order_manager = 'order_manager',
  buy_sell_bot = 'buy_sell_bot',
  order_history = 'order_history',
  new_order_history = 'new_order_history',
}

export interface IPanelProps {
  initialOpened?: boolean;
  setIsOpened?: (v: boolean) => any;
}

export interface IPairTicker {
  pool_created: number;
}
