import React from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { useTypedSelector } from 'store';

import { CheckedIcon } from '../../Checkbox/CheckedIcon';

import './tableCheckbox.scss';
import { Tooltip } from 'ui/Tooltip';

const TableCheckbox = React.forwardRef<
  HTMLInputElement,
  {
    indeterminate: boolean;
    id: string;
    checked: boolean;
    onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    not_connected?: boolean;
    disabled?: boolean;
    disabledTooltip?: string;
  }
>(
  (
    {
      indeterminate,
      id,
      checked,
      onChange,
      not_connected,
      disabled = false,
      disabledTooltip,
      ...rest
    },
    ref,
  ) => {
    const palette = useTypedSelector(store => store.ui.selectedThemeColors);

    const defaultRef = React.useRef<HTMLInputElement>(null);
    const resolvedRef = ref || defaultRef;

    return (
      <Tooltip
        options={{ offset: 10, placement: 'top' }}
        trigger={
          <>
            <input
              className="mm-table-checkbox-input"
              type="checkbox"
              id={id}
              checked={checked}
              ref={resolvedRef}
              onChange={onChange}
              disabled={disabled}
              {...rest}
            />
            <motion.label
              initial={{
                borderColor: palette.gray_1,
                backgroundColor: palette.bg_primary,
              }}
              animate={{
                borderColor: !checked ? palette.gray_1 : palette.primary,
                backgroundColor: !checked ? palette.bg_primary : palette.primary,
              }}
              className={cn('mm-table-checkbox', { _checked: checked })}
              htmlFor={id}
            >
              <CheckedIcon />
            </motion.label>
          </>
        }
        content={disabled ? disabledTooltip : undefined}
      />
    );
  },
);

export { TableCheckbox };
