import { useEffect, useState } from 'react';
import { useTypedSelector, useTypedDispatch } from 'store';

import { ENetwork } from 'web3';
import { ApiDictionary } from 'api';
import { IGasPriceResponse } from 'api/apiDictionary/models';
import { setGasPrice } from 'store/slices/auth';

interface IUseGasPriceArgs {
  network: ENetwork;
}

const useGasPrice = ({ network }: IUseGasPriceArgs) => {
  const dispatch = useTypedDispatch();
  const storeGasPrice = useTypedSelector(store => store.auth.gasPrices[network]);

  const [innerGasPrice, setInnerGasPrice] = useState<IGasPriceResponse | undefined>(storeGasPrice);

  useEffect(() => {
    setInnerGasPrice(storeGasPrice);
  }, [storeGasPrice]);

  useEffect(() => {
    (async () => {
      if (!storeGasPrice) {
        const { data } = await ApiDictionary.getGasPrice(network);

        setInnerGasPrice(data);
        dispatch(setGasPrice({ gasPrice: data, network }));
      }
    })();
  }, [dispatch, storeGasPrice, network]);

  return innerGasPrice;
};

export { useGasPrice };
