import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { isNil } from 'lodash';

import { Button, Modal } from 'ui';
import { SelectNetworkField, SelectExchangeField } from 'fields';
import { getDexs } from 'store/slices/dictionary';
import { IDex } from 'api/apiDictionary/models';
import { web3Configs, ENetwork } from 'web3';

import './style.scss';

interface ISelectPairDexModalProps {
  dex: IDex | undefined;
  setDex: (dex: IDex | undefined) => void;
  openAddPairWindow: () => void;
  onClose: () => void;
}

const SelectPairDexModal: React.FC<ISelectPairDexModalProps> = ({
  dex,
  setDex,
  openAddPairWindow,
  onClose,
}) => {
  const dispatch = useTypedDispatch();
  const dexs = useTypedSelector(store => store.dictionary.dexs);

  const [selectedNetwork, setSelectedNetwork] = useState<ENetwork | undefined>(undefined);

  useEffect(() => {
    if (!dexs) {
      dispatch(getDexs());
    }
  }, [dexs, dispatch]);

  const handleNext = useCallback(() => {
    openAddPairWindow();
  }, [openAddPairWindow]);

  const items = useMemo(
    () =>
      Object.keys(web3Configs).map(key => ({
        network: key as ENetwork,
        label: web3Configs[key as ENetwork].label,
      })),
    [],
  );

  const dexsByNetwork = useMemo(() => {
    if (!selectedNetwork || !dexs) return [];

    return dexs
      .filter(el => el.network === selectedNetwork)
      .map(el => ({ label: el.description, exchange: el.dex }));
  }, [dexs, selectedNetwork]);

  return (
    <Modal
      title={'Select DEX'}
      onClose={onClose}
      className={'mm-select-dex-pair-modal'}
      customButtons={
        <Button onClick={handleNext} disabled={isNil(dex)}>
          Next
        </Button>
      }
    >
      <div className="mm-select-dex-pair-modal__container">
        <SelectNetworkField
          items={items}
          onSelectItem={item => setSelectedNetwork(item ? item.network : undefined)}
          label="Select network"
          selectedItem={
            selectedNetwork ? items.find(el => el.network === selectedNetwork) : undefined
          }
        />
        <SelectExchangeField
          items={dexsByNetwork}
          onSelectItem={item =>
            setDex(item ? dexs?.find(el => el.dex === item.exchange) ?? undefined : undefined)
          }
          label="Select DEX"
          selectedItem={dexsByNetwork.find(el => el.exchange === dex?.dex) ?? undefined}
          disabled={!selectedNetwork}
        />
      </div>
    </Modal>
  );
};

export { SelectPairDexModal };
