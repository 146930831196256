import {
  IDexSLBotRandomStrategy,
  IDexSLBotVolumesStrategy,
  IDexSLBotOrganicStrategy,
} from 'types/bots';

export type IDexSLBotTabs = 'quick-start' | 'advanced';

export const mapDexSLBotTabToName = (tab: IDexSLBotTabs) => {
  return { 'quick-start': 'Quick start', advanced: 'Advanced' }[tab];
};

export const mapDexSLBotNameToTab = (tabName: string) => {
  return { 'Quick start': 'quick-start' as IDexSLBotTabs, Advanced: 'advanced' as IDexSLBotTabs }[
    tabName
  ] as IDexSLBotTabs;
};

export interface IChartFormInfo {
  tokenPrice: string;
  pooledBaseToken: string;
  poolLiquidity: string;
  pooledFeeToken: string;
  volumeDaily: string | undefined;
  feesDaily: string | undefined;
  baseAmount: string | undefined;
  quoteAmount: string | undefined;
  dexFeePercentage: number;
  dexFee: string | undefined;
}

export interface IDexSLShortPreset {
  created_at: string;
  id: number;
  name: string;
}

export interface IPreset {
  random_strategy: IDexSLBotRandomStrategy;
  volumes_strategy?: IDexSLBotVolumesStrategy;
  organic_volumes_tasks?: IDexSLBotOrganicStrategy;
}

export interface IDexSLConfigClientOptions {
  trade_size_coefficient?: number;
  volatility_coefficient?: number;
  trade_frequency_coefficient?: number;
}

export const PERIOD_DEFAULT = '1440h';
export const INTERVAL_DEFAULT = '15m';
export const SEED_DEFAULT = -1;

export const MIN_TRADE_FREQUENCY = 0.001;
export const MAX_TRADE_FREQUENCY = 5;
export const DEFAULT_TRADE_FREQUENCY = 1;

export const MIN_VOLATILITY = 0.001;
export const MAX_VOLATILITY = 2;
export const DEFAULT_VOLATILITY = 1;

export const MIN_TRADE_SIZE = 0.2;
export const MAX_TRADE_SIZE = 5;
export const DEFAULT_TRADE_SIZE = 1;

export const SL_TIMEFRAMES = [
  {
    label: '1 minute',
    value: '1m',
  },
  {
    label: '5 minutes',
    value: '5m',
  },
  {
    label: '15 minutes',
    value: '15m',
  },
  {
    label: '30 minutes',
    value: '30m',
  },
  {
    label: '1 hour',
    value: '1h',
  },
  {
    label: '4 hours',
    value: '4h',
  },
  {
    label: '12 hours',
    value: '12h',
  },
  {
    label: '1 day',
    value: '24h',
  },
  {
    label: '1 week',
    value: '168h',
  },
];

export const FG_TIMEFRAMES = [
  {
    label: '1 minute',
    value: '1m',
  },
  {
    label: '3 minutes',
    value: '3m',
  },
  {
    label: '5 minutes',
    value: '5m',
  },
  {
    label: '10 minutes',
    value: '10m',
  },
  {
    label: '15 minutes',
    value: '15m',
  },
  {
    label: '30 minutes',
    value: '30m',
  },
  {
    label: '1 hour',
    value: '1h',
  },
  {
    label: '4 hours',
    value: '4h',
  },
  {
    label: '12 hours',
    value: '12h',
  },
];
