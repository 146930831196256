import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

import { LocalStorage } from 'tools';
import { ECexPairFilters, EDexPairFilters, EGeneralFilters, IFilters } from 'types/filters';
import { FILTERS_KEY } from 'constant/cache';

interface IPayload {
  cex?: {
    pairId?: number;
    type: ECexPairFilters;
  };
  dex?: {
    pairId?: number;
    type: EDexPairFilters;
  };
  general?: EGeneralFilters;
}

export const saveFiltersReducer: CaseReducer<IFilters, PayloadAction<IPayload>> = (
  state,
  { payload },
) => {
  const { dex, cex, general } = payload;

  if (general) {
    const filters = (state[general]!.filters ?? []).filter(el => el.value !== undefined);

    if (state[general]) {
      state[general] = {
        filters: filters,
        setted: filters,
      };
    }

    LocalStorage.set(FILTERS_KEY, state);

    return;
  }

  const key = dex ? 'dex' : 'cex';
  const pairId = dex ? dex.pairId : cex ? cex.pairId : undefined;
  const type = dex ? dex.type : cex ? cex.type : undefined;

  if (isNil(pairId) || isNil(type) || !state[key][pairId]) return;

  //@ts-ignore
  const filters = (state[key][pairId]!.filters[type].filters ?? []).filter(
    //@ts-ignore
    el => el.value !== undefined,
  );

  state[key][pairId]!.updated_at = new Date(Date.now()).toISOString();
  state[key][pairId]!.filters = {
    ...state[key][pairId]!.filters,
    [type]: {
      filters: filters,
      setted: filters,
    },
  };

  LocalStorage.set(FILTERS_KEY, state);
};
