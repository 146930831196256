import { capitalize } from 'lodash';

import { ETransactionAction } from 'api/apiTransactions/models';
import { ETransactionsStatuses } from 'api/apiTransactions/models/ITransactionItem';
import { IFilterColorSelectItem } from 'types/filters/common';
import { ECexOrderSide, ECexOrderStatus, ECexOrderBot } from 'types/orders';
import { pairBots } from 'types/bots';

export enum EFilterColor {
  blue_1 = 'rgb(129, 177, 255)',
  blue_2 = 'rgb(4, 169, 244)',
  blue_3 = 'rgb(2, 188, 212)',
  blue_4 = 'rgb(2, 49, 232)',
  blue_5 = 'rgb(48, 130, 183)',
  blue_6 = 'rgb(59, 130, 246)',
  violet_1 = 'rgb(128, 119, 241)',
  violet_2 = 'rgb(106, 133, 255)',
  violet_3 = 'rgb(191, 85, 236)',
  green_1 = 'rgb(27, 188, 156)',
  green_2 = 'rgb(46, 205, 111)',
  green_3 = 'rgb(100, 224, 165)',
  pink_1 = 'rgb(234, 128, 252)',
  pink_2 = 'rgb(249, 0, 234)',
  pink_3 = 'rgb(255, 127, 171)',
  pink_4 = 'rgb(242, 29, 135)',
  brown_1 = 'rgb(128, 0, 0)',
  yellow_1 = 'rgb(243, 243, 30)',
  orange_1 = 'rgb(230, 81, 0)',
  orange_2 = 'rgb(175, 126, 46)',
  orange_3 = 'rgb(172, 150, 0)',
  orange_4 = 'rgb(242, 185, 29)',
  red_1 = 'rgb(229, 0, 0)',
  red_2 = 'rgb(206, 73, 102)',
}

export const TX_ACTIONS: IFilterColorSelectItem[] = [
  {
    value: ETransactionAction.ActionAFRBuy,
    label: ETransactionAction.ActionAFRBuy,
    color: EFilterColor.blue_1,
  },
  {
    value: ETransactionAction.ActionAFRSell,
    label: ETransactionAction.ActionAFRSell,
    color: EFilterColor.blue_2,
  },
  {
    value: ETransactionAction.ActionAddLiquidity,
    label: ETransactionAction.ActionAddLiquidity,
    color: EFilterColor.blue_3,
  },
  {
    value: ETransactionAction.ActionApproval,
    label: ETransactionAction.ActionApproval,
    color: EFilterColor.blue_4,
  },
  {
    value: ETransactionAction.ActionBaseBuyEth,
    label: ETransactionAction.ActionBaseBuyEth,
    color: EFilterColor.brown_1,
  },
  {
    value: ETransactionAction.ActionBaseBuyEthHolder,
    label: ETransactionAction.ActionBaseBuyEthHolder,
    color: EFilterColor.pink_1,
  },
  {
    value: ETransactionAction.ActionBaseSellEth,
    label: ETransactionAction.ActionBaseSellEth,
    color: EFilterColor.pink_2,
  },
  {
    value: ETransactionAction.ActionBulkBuy,
    label: ETransactionAction.ActionBulkBuy,
    color: EFilterColor.violet_1,
  },
  {
    value: ETransactionAction.ActionBulkBuyMempool,
    label: ETransactionAction.ActionBulkBuyMempool,
    color: EFilterColor.violet_2,
  },
  {
    value: ETransactionAction.ActionBulkSell,
    label: ETransactionAction.ActionBulkSell,
    color: EFilterColor.violet_3,
  },
  {
    value: ETransactionAction.ActionBulkSellMempool,
    label: ETransactionAction.ActionBulkSellMempool,
    color: EFilterColor.pink_3,
  },
  {
    value: ETransactionAction.ActionBuy,
    label: ETransactionAction.ActionBuy,
    color: EFilterColor.green_3,
  },
  {
    value: ETransactionAction.ActionDeposit,
    label: ETransactionAction.ActionDeposit,
    color: EFilterColor.orange_1,
  },
  {
    value: ETransactionAction.ActionQuoteBuyEthHolder,
    label: ETransactionAction.ActionQuoteBuyEthHolder,
    color: EFilterColor.green_2,
  },
  {
    value: ETransactionAction.ActionQuoteSellEth,
    label: ETransactionAction.ActionQuoteSellEth,
    color: EFilterColor.red_1,
  },
  {
    value: ETransactionAction.ActionRemoveLiquidity,
    label: ETransactionAction.ActionRemoveLiquidity,
    color: EFilterColor.blue_5,
  },
  {
    value: ETransactionAction.ActionSell,
    label: ETransactionAction.ActionSell,
    color: EFilterColor.red_2,
  },
  {
    value: ETransactionAction.ActionWithdrawal,
    label: ETransactionAction.ActionWithdrawal,
    color: EFilterColor.orange_3,
  },
];

export const TX_STATUSES: IFilterColorSelectItem[] = [
  {
    value: ETransactionsStatuses.Canceled,
    label: capitalize(ETransactionsStatuses.Canceled),
    color: EFilterColor.blue_6,
  },
  {
    value: ETransactionsStatuses.Error,
    label: capitalize(ETransactionsStatuses.Error),
    color: EFilterColor.red_1,
  },
  {
    value: ETransactionsStatuses.Failed,
    label: capitalize(ETransactionsStatuses.Failed),
    color: EFilterColor.pink_4,
  },
  {
    value: ETransactionsStatuses.Pending,
    label: capitalize(ETransactionsStatuses.Pending),
    color: EFilterColor.orange_4,
  },
  {
    value: ETransactionsStatuses.Processing,
    label: capitalize(ETransactionsStatuses.Processing),
    color: EFilterColor.yellow_1,
  },
  {
    value: ETransactionsStatuses.Successful,
    label: capitalize(ETransactionsStatuses.Successful),
    color: EFilterColor.green_2,
  },
];

export const ORDER_SIDE = [
  {
    value: ECexOrderSide.buy,
    label: capitalize(ECexOrderSide.buy),
    color: EFilterColor.green_2,
  },
  {
    value: ECexOrderSide.sell,
    label: capitalize(ECexOrderSide.sell),
    color: EFilterColor.red_1,
  },
];

export const ORDER_STATUS = [
  {
    value: ECexOrderStatus.active,
    label: capitalize(ECexOrderStatus.active),
    color: EFilterColor.blue_1,
  },
  {
    value: ECexOrderStatus.canceled,
    label: capitalize(ECexOrderStatus.canceled),
    color: EFilterColor.blue_6,
  },
  {
    value: ECexOrderStatus.error,
    label: capitalize(ECexOrderStatus.error),
    color: EFilterColor.red_1,
  },
  {
    value: ECexOrderStatus.filled,
    label: capitalize(ECexOrderStatus.filled),
    color: EFilterColor.green_2,
  },
  {
    value: ECexOrderStatus.partially_filled,
    label: capitalize(ECexOrderStatus.partially_filled),
    color: EFilterColor.yellow_1,
  },
];

export const ORDER_BOT = [
  {
    value: ECexOrderBot.organic,
    label: capitalize(ECexOrderBot.organic),
    color: EFilterColor.blue_1,
  },
  {
    value: ECexOrderBot.cm,
    label: capitalize(ECexOrderBot.cm),
    color: EFilterColor.blue_2,
  },
  {
    value: ECexOrderBot.fg,
    label: capitalize(ECexOrderBot.fg),
    color: EFilterColor.blue_3,
  },
  {
    value: ECexOrderBot.order_manager_bot,
    label: capitalize(ECexOrderBot.order_manager_bot),
    color: EFilterColor.violet_1,
  },
  {
    value: ECexOrderBot.orderbook,
    label: capitalize(ECexOrderBot.orderbook),
    color: EFilterColor.blue_4,
  },
  {
    value: ECexOrderBot.buysell,
    label: capitalize(ECexOrderBot.buysell),
    color: EFilterColor.blue_5,
  },
  {
    value: ECexOrderBot.orderbook_dynamic,
    label: capitalize(ECexOrderBot.orderbook_dynamic),
    color: EFilterColor.blue_6,
  },
  {
    value: ECexOrderBot.counter_order,
    label: capitalize(ECexOrderBot.counter_order),
    color: EFilterColor.blue_1,
  },
  {
    value: ECexOrderBot.abuse_protection,
    label: 'Abuse protection',
    color: EFilterColor.blue_2,
  },
  {
    value: ECexOrderBot.arbitrage,
    label: 'Arbitrage',
    color: EFilterColor.blue_3,
  },
];

export const CONNECTED_BOT = pairBots.map(bot => {
  return {
    value: bot.id.toString(),
    label: capitalize(bot.description),
    color: EFilterColor.blue_1,
  };
});
