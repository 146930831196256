import React, { FC, InputHTMLAttributes, memo, useMemo } from 'react';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

import { Tooltip } from 'ui/Tooltip';

import './toggleRadioDark.scss';

type Props = {
  label?: string;
  titles: string[];
  color?: 'primary';
  disabledTooltip?: string;
} & Exclude<InputHTMLAttributes<HTMLInputElement>, 'type' | 'id' | 'value' | 'checked'>;

const ToggleRadioComponent: FC<Props> = ({
  titles,
  color = 'primary',
  disabled,
  label,
  disabledTooltip,
  ...props
}) => {
  const innerTitles = useMemo(() => titles.map(el => ({ label: el, id: uuid() })), [titles]);

  const content = useMemo(
    () => (
      <div className="mm-toggle-radio-dark-wrapper">
        {label && (
          <div className={cn(disabled ? 'text-gray-2' : 'text-primary', 'mb-1.5 text-sm')}>
            {label}
          </div>
        )}
        <div
          className={cn('mm-toggle-radio-dark', {
            '_is-disabled': disabled,
          })}
        >
          {innerTitles.map(({ label, id }) => (
            <React.Fragment key={id}>
              <input
                {...props}
                disabled={disabled}
                id={id}
                type="radio"
                value={label}
                checked={props.value === label}
              />
              <label
                className={cn('mm-toggle-radio-dark__text', {
                  '_is-selected': props.value === label,
                  '_is-disabled': disabled && props.value === label,
                  '!cursor-not-allowed': disabled,
                })}
                htmlFor={id}
              >
                <span className="mm-toggle-radio-dark__text-node">{label}</span>
              </label>
            </React.Fragment>
          ))}
        </div>
      </div>
    ),
    [disabled, innerTitles, label, props],
  );

  return disabledTooltip ? (
    <Tooltip
      content={disabledTooltip}
      trigger={content}
      options={{ offset: 10, placement: 'top' }}
    />
  ) : (
    content
  );
};

const ToggleRadioDark = memo(ToggleRadioComponent);

export { ToggleRadioDark };
