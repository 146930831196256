import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const TradingIcon: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors['gray-1'],
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6857 16.3125H15C15.4142 16.3125 15.75 16.6483 15.75 17.0625C15.75 17.4767 15.4142 17.8125 15 17.8125H10.875C10.4608 17.8125 10.125 17.4767 10.125 17.0625V12.9375C10.125 12.5233 10.4608 12.1875 10.875 12.1875C11.2892 12.1875 11.625 12.5233 11.625 12.9375V15.2518L16.5322 10.3447C16.8251 10.0518 17.2999 10.0518 17.5928 10.3447C17.8857 10.6376 17.8857 11.1124 17.5928 11.4053L12.6857 16.3125ZM11.1268 7.5H8.8125C8.39829 7.5 8.0625 7.16421 8.0625 6.75C8.0625 6.33579 8.39829 6 8.8125 6H12.9375C13.3517 6 13.6875 6.33579 13.6875 6.75V10.875C13.6875 11.2892 13.3517 11.625 12.9375 11.625C12.5233 11.625 12.1875 11.2892 12.1875 10.875V8.56066L7.28033 13.4678C6.98744 13.7607 6.51256 13.7607 6.21967 13.4678C5.92678 13.1749 5.92678 12.7001 6.21967 12.4072L11.1268 7.5Z"
        fill={color}
      />
    </svg>
  );
};

export { TradingIcon };
