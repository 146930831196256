import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { ICexOrder } from 'types/orders';
import { formatToken } from 'utils/formats';
import { bn, bnFrom } from 'tools/math';

interface IFilledAmountProps {
  order: ICexOrder;
}

const FilledAmount = React.memo(({ order }: IFilledAmountProps) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const baseTokenSymbol = useMemo(() => cexPair.token_base.symbol, [cexPair]);
  const decimals = useMemo(() => 18, []);

  const baseAmountInTrades = useMemo(
    () =>
      order.trades
        ?.filter(el => el.token.symbol === baseTokenSymbol && !el.is_fee)
        .reduce((acc, val) => acc.add(bnFrom(val.amount).abs()), bn(0)) ?? bn(0),
    [order, baseTokenSymbol],
  );

  const totalBaseAmount = useMemo(() => bnFrom(order.base_amount), [order]);

  return (
    <div style={{ textAlign: 'left', textOverflow: 'ellipsis', minWidth: 0, overflow: 'hidden' }}>
      <span className="text-primary">{formatToken(baseAmountInTrades, decimals)} / </span>
      <span className="text-secondary">
        {formatToken(totalBaseAmount, decimals)} {baseTokenSymbol}
      </span>
    </div>
  );
});

FilledAmount.displayName = 'FilledAmount';

export { FilledAmount };
