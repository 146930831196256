import { useCallback, useContext, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { isNil } from 'lodash';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { useTypedSelector, useTypedDispatch } from 'store';

import { ApiBot } from 'api';
import { convertTokenCEX } from 'utils';
import { ICexBuySellBotTask } from 'types/bots';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { ICexAccount } from 'types/accounts';
import { CexPairContext } from 'context/CexPairContext';
import { bn, humanizeBn, multiplyBignumbers } from 'tools';

const validationSchema = (intervalMode: 'price' | 'percent') =>
  Yup.object({
    active: Yup.boolean(),
    account: Yup.object().required('Field is required'),
    aggressive_mode_enabled: Yup.boolean(),
    aggressive_mode_trigger_delay: Yup.number()
      .nullable()
      .when('aggressive_mode_enabled', {
        is: true,
        then: schema => schema.required('Field is required').moreThan(0, 'Field is required'),
      }),
    aggressive_mode_step: Yup.number().when('aggressive_mode_enabled', {
      is: true,
      then: schema =>
        schema
          .required('Field is required')
          .moreThan(0, 'Value should be > 0')
          .lessThan(100, 'Value should be < 100'),
    }),
    replace_interval: Yup.number()
      .nullable()
      .required('Field is required')
      .moreThan(0, 'Field is required'),
    interval_base:
      intervalMode === 'price'
        ? Yup.number().required('Field is required').moreThan(0, 'Value should be > 0')
        : Yup.number(),
    interval_in_perc:
      intervalMode === 'percent'
        ? Yup.number()
            .required('Field is required')
            .moreThan(0, 'Value should be > 0')
            .lessThan(100, 'Value should be < 100')
        : Yup.number(),
    min_amount: Yup.number().required('Field is required').moreThan(0, 'Value should be > 0'),
    max_amount: Yup.number()
      .required('Field is required')
      .test(
        'max_amount',
        'Should be more than min amount',
        (_max_amount: number | undefined, context) => {
          const min_amount = bn(context.parent.min_amount);
          const max_amount = bn(_max_amount);

          return max_amount.gt(min_amount);
        },
      ),
    min_price: Yup.number(),
    max_price: Yup.number().test(
      'max_price',
      'Should be more than min price',
      (max_price: number | undefined, context) => {
        const min_price = context.parent.min_price;

        return isNaN(Number(min_price)) || bn(max_price).gt(bn(min_price));
      },
    ),
    orders_count: Yup.number().required('Field is required').moreThan(0, 'Field is required'),
    start_time: Yup.number().nullable().required('Field is required'),
    end_time: Yup.number()
      .nullable()
      .test(
        'end_time',
        'Should be more than start date',
        (end_time: number | null | undefined, context) => {
          const start_time = context.parent.start_time;

          return isNil(end_time) || isNil(start_time) || Number(end_time) > Number(start_time);
        },
      ),
    total_amount: Yup.number()
      .required('Field is required')
      .positive('Field is required')
      .min(0, 'Should be more than 0')
      .test(
        'total_amount',
        'Should be more than max price times order count',
        (total_amount: number | undefined, context) => {
          const max_amount = context.parent.max_amount;
          const orders_count = context.parent.orders_count;
          return (
            isNaN(Number(total_amount)) ||
            bn(total_amount).gte(multiplyBignumbers([bn(max_amount), 18], [bn(orders_count), 18]))
          );
        },
      ),
    side: Yup.string(),
    progress: Yup.string(),
    price_offset_percent: Yup.number()
      .required('Field is required')
      .moreThan(0, 'Value should be > 0')
      .lessThan(100, 'Value should be < 100'),
  });

interface IForm {
  active: boolean;
  account: ICexAccount | null;
  aggressive_mode_enabled: boolean;
  aggressive_mode_trigger_delay: number | null;
  aggressive_mode_step: string;
  replace_interval: number | null;
  interval_base: string;
  interval_in_perc: string;
  min_amount: string;
  max_amount: string;
  min_price: string;
  max_price: string;
  orders_count: string;
  start_time: number | null;
  end_time: number | null;
  total_amount: string;
  side: 'buy' | 'sell';
  progress: string;
  price_offset_percent: string;
}

const INITIAL_FORM: IForm = {
  active: true,
  account: null,
  aggressive_mode_enabled: false,
  aggressive_mode_trigger_delay: null,
  aggressive_mode_step: '',
  replace_interval: null,
  interval_base: '',
  interval_in_perc: '',
  min_amount: '',
  max_amount: '',
  min_price: '',
  max_price: '',
  orders_count: '',
  start_time: dayjs().second(0).millisecond(0).valueOf(),
  end_time: null,
  total_amount: '',
  side: 'buy',
  progress: '0',
  price_offset_percent: '',
};

interface IUseTaskModalProps {
  task?: ICexBuySellBotTask;
  id: number;
  mode: 'create' | 'edit';
  onClose: () => void;
  onSuccess: () => void;
}

const useTaskModal = ({ task, id, mode, onClose, onSuccess }: IUseTaskModalProps) => {
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const { updateBotStates } = useContext(CexPairContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);
  const [intervalMode, setIntervalMode] = useState<'percent' | 'price'>('percent');

  const availableCurrencies = useMemo(
    () => [cexPair.token_base.symbol, cexPair.token_quote.symbol],
    [cexPair.token_base.symbol, cexPair.token_quote.symbol],
  );

  const [selectedMinMaxOrderCurrency, setSelectedMinMaxOrderCurrency] = useState(
    availableCurrencies[0],
  );

  const { accounts, accountsLoading } = useContext(CexPairContext);

  const initialValues = useMemo<IForm>(() => {
    if (task && mode === 'edit') {
      const _intervalMode = Number(task.interval_base) > 0 ? 'price' : 'percent';

      setIntervalMode(_intervalMode);

      return {
        active: task.active,
        account: accounts.find(el => el.id === task.account_id) ?? null,
        aggressive_mode_enabled: task.aggressive_mode.enabled,
        aggressive_mode_trigger_delay: task.aggressive_mode.delay,
        aggressive_mode_step: task.aggressive_mode.step,
        replace_interval: task.replace_interval,
        interval_base: _intervalMode === 'price' ? task.interval_base : '',
        interval_in_perc: _intervalMode === 'percent' ? task.interval_in_perc : '',
        min_amount: task.order_size.min,
        max_amount: task.order_size.max,
        min_price: Number(task.price.min) === 0 ? '' : task.price.min,
        max_price: Number(task.price.max) === 0 ? '' : task.price.max,
        orders_count: task.orders_number.toString(),
        start_time: task.period.start ?? null,
        end_time: task.period.end !== 0 ? task.period.end : null,
        total_amount: task.total_amount,
        side: task.side,
        progress: task.progress,
        price_offset_percent: task.start_price_offset,
      };
    }

    return INITIAL_FORM;
  }, [task, mode, accounts]);

  const onSubmit = useCallback(
    async ({
      active,
      account,
      aggressive_mode_enabled,
      aggressive_mode_trigger_delay,
      aggressive_mode_step,
      replace_interval,
      interval_base,
      interval_in_perc,
      min_amount,
      max_amount,
      min_price,
      max_price,
      orders_count,
      start_time,
      end_time,
      total_amount,
      side,
      progress,
      price_offset_percent,
    }: IForm) => {
      try {
        if (!account) return;

        setLoading(true);

        const taskOptions: ICexBuySellBotTask = {
          active,
          account_id: account.id,
          aggressive_mode: {
            enabled: aggressive_mode_enabled,
            delay: aggressive_mode_enabled ? aggressive_mode_trigger_delay || 0 : 0,
            step: aggressive_mode_enabled ? aggressive_mode_step || '0' : '0',
          },
          id,
          replace_interval: replace_interval ?? 0,
          interval_base: intervalMode === 'price' ? interval_base : '0',
          interval_in_perc: intervalMode === 'percent' ? interval_in_perc : '0',
          order_size: {
            min: humanizeBn(
              convertTokenCEX({
                from: selectedMinMaxOrderCurrency,
                to: cexPair.token_base.symbol,
                amount: min_amount,
                pair: cexPair,
              }),
              18,
            ),
            max: humanizeBn(
              convertTokenCEX({
                from: selectedMinMaxOrderCurrency,
                to: cexPair.token_base.symbol,
                amount: max_amount,
                pair: cexPair,
              }),
            ),
          },
          price: {
            min: min_price || '0',
            max: max_price || '0',
          },
          orders_number: Number(orders_count),
          pair_id: cexPair.id,
          period: {
            start: start_time ?? 0,
            end: end_time ?? 0,
          },
          total_amount: humanizeBn(
            convertTokenCEX({
              from: selectedMinMaxOrderCurrency,
              to: cexPair.token_base.symbol,
              amount: total_amount,
              pair: cexPair,
            }),
            18,
          ),
          side,
          progress,
          start_price_offset: price_offset_percent,
        };

        const { isSuccess, errorMessage } =
          mode === 'create'
            ? await ApiBot.addCexBuySellTask({ task: taskOptions })
            : await ApiBot.updateCexBuySellTask({ task: taskOptions });

        if (!isSuccess) {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        } else {
          onSuccess();
          updateBotStates();
          dispatch(
            setAlertState({
              type: 'success',
              text:
                mode === 'create'
                  ? 'You successfully create new task!'
                  : 'You successfully edit task!',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
          onClose();
        }
      } catch (error) {
        console.log('error: ', error);
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      } finally {
        setLoading(false);
      }
    },
    [
      id,
      intervalMode,
      cexPair,
      mode,
      selectedMinMaxOrderCurrency,
      dispatch,
      onClose,
      onSuccess,
      updateBotStates,
    ],
  );

  const { handleSubmit, values, errors, touched, setFieldValue } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validationSchema(intervalMode),
    enableReinitialize: true,
  });

  const _setFieldValue = useCallback(
    (field: keyof IForm, value: any) => {
      setEdited(true);

      setFieldValue(field, value);
    },
    [setFieldValue],
  );

  return {
    loading,
    edited,
    handleSubmit,
    values,
    intervalMode,
    setIntervalMode,
    errors,
    touched,
    setFieldValue: _setFieldValue,
    accounts,
    accountsLoading,
    availableCurrencies,
    selectedMinMaxOrderCurrency,
    setSelectedMinMaxOrderCurrency,
  };
};

export { useTaskModal };
