import React, { useContext } from 'react';
import { PairWalletsContext } from 'context/PairWalletsContext/PairWalletsContext';

const TotalUsdHead: React.FC = React.memo(() => {
  const { totalBalances } = useContext(PairWalletsContext);

  return (
    <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column' }}>
      <span>Total USD</span>
      <span>({totalBalances.usd})</span>
    </div>
  );
});

export default TotalUsdHead;
