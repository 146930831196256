import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { IBaseResponse } from '../apiBase';
import { AApiBase } from '../apiBase/AApiBase';
import { ICexNotConnectedAccounts } from './models';
import { ICexAccount } from 'types/accounts';

class ApiCexAccounts extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiCexAccounts | undefined;
  private static instance = (() => {
    if (ApiCexAccounts.__instance === undefined) {
      ApiCexAccounts.__instance = new ApiCexAccounts();
    }
    return ApiCexAccounts.__instance;
  })();

  public static getAccounts = async ({
    limit,
    lastSeenId,
    pairId,
  }: {
    limit: number;
    lastSeenId?: number;
    pairId: number;
  }) => {
    try {
      const urlParams = new URLSearchParams();
      urlParams.append('limit', limit.toString());
      lastSeenId && urlParams.append('last_seen_id', lastSeenId.toString());

      const response = await ApiCexAccounts.instance.get<
        IBaseResponse<{
          has_next: boolean;
          items: ICexAccount[];
        }>
      >(`/cex-pairs/${pairId}/accounts` + '?' + urlParams.toString());

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{
        has_next: boolean;
        items: ICexAccount[];
      }>({ error });
    }
  };

  public static getNotConnectedCexAccounts = async ({
    cexId,
    limit,
    lastSeenId,
  }: {
    cexId: number;
    limit: number;
    lastSeenId: number;
  }) => {
    try {
      const urlParams = new URLSearchParams();
      urlParams.append('limit', limit.toString());
      urlParams.append('last_seen_id', lastSeenId.toString());

      const response = await ApiCexAccounts.instance.get<
        IBaseResponse<{
          has_next: boolean;
          items: ICexNotConnectedAccounts[];
        }>
      >(`/cex-pairs/${cexId}/accounts/not-connected` + '?' + urlParams.toString());

      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<{
        has_next: boolean;
        items: ICexNotConnectedAccounts[];
      }>({ error });
    }
  };

  public static connectAccountToCexPair = async ({
    cexId,
    accounts,
  }: {
    cexId: number;
    accounts: { bots_ids: number[]; id: number; is_disabled: boolean }[];
  }) => {
    try {
      const response = await ApiCexAccounts.instance.put<IBaseResponse<any>>(
        `/cex-pairs/${cexId}/accounts`,
        {
          accounts,
        },
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };

  public static deleteAccountFromCexPair = async ({
    cexId,
    accounts_ids,
  }: {
    cexId: number;
    accounts_ids: number[];
  }) => {
    try {
      const response = await ApiCexAccounts.instance.delete<IBaseResponse<any>>(
        `/cex-pairs/${cexId}/accounts`,
        {
          data: {
            accounts_ids: accounts_ids,
          },
        },
      );
      return mapResponse({
        data: response.data,
      });
    } catch (error) {
      return mapError<any>({ error });
    }
  };
}

export { ApiCexAccounts };
