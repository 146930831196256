import React, { useMemo } from 'react';
import cn from 'classnames';

import { Tooltip } from 'ui';
import { ITransfer } from 'types/transfers';

import { CircleWarningIcon } from 'assets/icons';

interface IStatusProps {
  transfer: ITransfer;
}

const Status = React.memo(({ transfer }: IStatusProps) => {
  const isSuccess = useMemo(() => !!transfer.hash, [transfer]);

  return (
    <div className="status">
      <span className={cn({ 'success-status': isSuccess, 'error-status': !isSuccess })}>
        {isSuccess ? 'Success' : 'Error'}
      </span>
      {!isSuccess && (
        <Tooltip
          trigger={
            <div className="status-icon-holder">
              <CircleWarningIcon />
            </div>
          }
          content={transfer.error}
          options={{ offset: 10, placement: 'top' }}
        />
      )}
    </div>
  );
});

export { Status };
