import React, { useCallback } from 'react';

import { Panel } from 'ui';
import { IPanelProps } from 'types/pairs';

import { AccountsIcon } from 'assets/icons/AccountsIcon';

const CexAccountsTable = React.lazy(() =>
  import('tables').then(module => ({ default: module.CexAccountsTable })),
);

const Accounts: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsOpened?.(opened);
    },
    [setIsOpened],
  );

  return (
    <Panel
      title="Accounts"
      icon={AccountsIcon}
      initialOpened={initialOpened}
      onChange={handleSwitch}
    >
      <CexAccountsTable />
    </Panel>
  );
};

export { Accounts };
