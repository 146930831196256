import React, { useMemo } from 'react';

import { IDexName, ECexName, dexConfigs, cexConfigs } from 'web3';

import './style.scss';

interface IPlatformIconProps {
  platform: IDexName | ECexName;
  isIcon?: boolean;
  className?: string;
}

const PlatformIcon: React.FC<IPlatformIconProps> = ({ platform, isIcon, className }) => {
  const iconTradingPair = useMemo(() => {
    const config =
      platform in ECexName ? cexConfigs[platform as ECexName] : dexConfigs[platform as IDexName];

    if (isIcon) return config?.icon;

    return config?.iconText;
  }, [isIcon, platform]);

  return <img className={className} alt="" src={iconTradingPair} />;
};

export default PlatformIcon;
