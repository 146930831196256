import React, { useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTypedSelector, useTypedDispatch } from 'store';

import { setCopyPopupShow } from 'store/slices/ui';

import './copyPopup.scss';

const CopyPopup: React.FC = () => {
  const dispatch = useTypedDispatch();
  const copyPopupShow = useTypedSelector(store => store.ui.copyPopupShow);
  const copyingTriggered = useTypedSelector(store => store.ui.triggerCopying);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (copyPopupShow) {
        dispatch(setCopyPopupShow(false));
      }
    }, 1500);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [copyPopupShow, copyingTriggered, dispatch]);

  return (
    <AnimatePresence>
      {copyPopupShow && (
        <motion.div
          className="mm-copy-popup"
          initial={{ bottom: -50, opacity: 0 }}
          animate={{ bottom: 10, opacity: 1 }}
          exit={{ bottom: -50, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          Copied!
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export { CopyPopup };
