import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { ApiDictionary } from 'api';
import { IDex } from 'api/apiDictionary/models';
import { ICex } from 'api/apiDictionary/models';

interface IDictionarySliceState {
  dexs: null | IDex[];
  cexs: null | ICex[];
}
const dictionarySliceInitialState: IDictionarySliceState = {
  dexs: null,
  cexs: null,
};

const getDexs = createAsyncThunk('dictionary/get-dexs', async (_, thunkAPI) => {
  try {
    const { isSuccess, errorMessage, data } = await ApiDictionary.getDexs();

    if (isSuccess) {
      thunkAPI.dispatch(setDexs(data.items));
    } else {
      //TODO ERROR HANDLING
    }
  } catch (error) {
    console.log(error);
  }
});

const getCexs = createAsyncThunk('dictionary/get-cexs', async (_, thunkAPI) => {
  try {
    const { isSuccess, data } = await ApiDictionary.getCexs();
    if (isSuccess) {
      thunkAPI.dispatch(setCexs(data.items));
    } else {
      //TODO ERROR HANDLING
    }
  } catch (error) {
    console.log(error);
  }
});

const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState: dictionarySliceInitialState as IDictionarySliceState,
  reducers: {
    setDexs: (state, { payload }: { payload: IDex[] }) => {
      state.dexs = payload;
    },
    setCexs: (state, { payload }: { payload: ICex[] }) => {
      state.cexs = payload;
    },
  },
});

export const { setDexs, setCexs } = dictionarySlice.actions;
export { getDexs, getCexs };

export default dictionarySlice.reducer;
