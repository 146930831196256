import React from 'react';

import './pairForm.scss';

interface IPairFormProps {
  children: React.ReactNode;
}

const PairForm: React.FC<IPairFormProps> = ({ children }) => {
  return <form className="mm-pair-form">{children}</form>;
};

export { PairForm };
