import React, { useMemo } from 'react';
import { isNil } from 'lodash';
import { useTypedSelector } from 'store';

import { ITransfer } from 'types/transfers';
import { bnFrom, humanizeBn } from 'tools/math';

interface IAmountProps {
  transfer: ITransfer;
}

const Amount = React.memo(({ transfer }: IAmountProps) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const token = useMemo(() => {
    const findToken = [dexPair.token_base, dexPair.token_quote, dexPair.token_fee].find(
      el => el.id === transfer.token_id,
    );

    return findToken;
  }, [dexPair, transfer]);

  const decimals = useMemo(() => (token ? token.decimals : 18), [token]);

  const amountString = useMemo(
    () => (isNil(transfer.amount) ? '-' : humanizeBn(bnFrom(transfer.amount), decimals)),
    [transfer, decimals],
  );

  return <div>{amountString}</div>;
});

export { Amount };
