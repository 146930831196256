import { BigNumber } from '@ethersproject/bignumber';

import { bn, bnFrom, convertBnToDecimals, divideBignumbers, subtractBignumbers } from 'tools/math';

export const calculateWithdrawAmounts = (
  total: BigNumber,
  balances: { address: string; balance: BigNumber }[],
  decimal: number,
) => {
  const balancesLength = balances.length;

  const sortedDescBalances = balances.sort((a, b) => {
    const bnABalance = bnFrom(a.balance);
    const bnBBalance = bnFrom(b.balance);

    if (bnABalance.gt(bnBBalance)) return 1;

    if (bnBBalance.gt(bnABalance)) return -1;

    return 0;
  });

  let remnantWallets = balancesLength;
  let remnantBalance = total;
  let averageBalance = convertBnToDecimals(
    divideBignumbers([total, decimal], [bn(balancesLength), 18]),
    18,
    decimal,
  );

  const withdrawWalletBalances = {} as Record<string, BigNumber>;

  for (const wallet of sortedDescBalances) {
    const walletWithdrawBalance = wallet.balance.lt(averageBalance)
      ? wallet.balance
      : averageBalance;

    withdrawWalletBalances[wallet.address] = walletWithdrawBalance;

    remnantWallets = remnantWallets - 1;
    remnantBalance = convertBnToDecimals(
      subtractBignumbers([remnantBalance, decimal], [walletWithdrawBalance, decimal]),
      18,
      decimal,
    );
    averageBalance = convertBnToDecimals(
      divideBignumbers([remnantBalance, decimal], [bn(remnantWallets), 18]),
      18,
      decimal,
    );
  }

  return withdrawWalletBalances;
};
