import React from 'react';

import { tailwindConfig } from 'tailwind-config';
import { IIconProps } from './types';

const ArrowFromIcon: React.FC<IIconProps> = ({
  className,
  color = tailwindConfig.theme.colors['gray-1'],
  onClick,
}) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <path
        d="M4.58579 0.814214C4.25442 0.814214 3.98579 1.08284 3.98579 1.41421C3.98579 1.74558 4.25442 2.01421 4.58579 2.01421L4.58579 0.814214ZM7.41421 1.41421L8.01421 1.41421C8.01421 1.25508 7.951 1.10247 7.83848 0.98995C7.72596 0.877428 7.57334 0.814214 7.41421 0.814214L7.41421 1.41421ZM6.81421 4.24264C6.81421 4.57401 7.08284 4.84264 7.41421 4.84264C7.74558 4.84264 8.01421 4.57401 8.01421 4.24264L6.81421 4.24264ZM4.58579 2.01421L7.41421 2.01421L7.41421 0.814214L4.58579 0.814214L4.58579 2.01421ZM6.81421 1.41421L6.81421 4.24264L8.01421 4.24264L8.01421 1.41421L6.81421 1.41421Z"
        fill={color}
      />
      <path
        d="M1.33294 6.64665C1.09863 6.88097 1.09863 7.26087 1.33294 7.49518C1.56726 7.7295 1.94716 7.7295 2.18147 7.49518L1.33294 6.64665ZM7.83833 1.83833C8.07264 1.60401 8.07264 1.22411 7.83833 0.989798C7.60401 0.755484 7.22411 0.755484 6.9898 0.989798L7.83833 1.83833ZM2.18147 7.49518L7.83833 1.83833L6.9898 0.989798L1.33294 6.64665L2.18147 7.49518Z"
        fill={color}
      />
    </svg>
  );
};

export { ArrowFromIcon };
