import React, { useMemo } from 'react';
import { useTypedDispatch } from 'store';

import { Tooltip } from 'ui';
import { ICexOrder } from 'types/orders';
import { copyToClipboard } from 'utils/clipboard';
import { setCopyPopupShow } from 'store/slices/ui';
import { ORDER_STATUS } from 'constant/filters';

interface IOrderIdProps {
  order: ICexOrder;
}

const OrderId = React.memo(({ order }: IOrderIdProps) => {
  const orderId = useMemo(() => order.cex_order_id, [order]);

  const status = ORDER_STATUS.find(el => el.value === order.status) ?? undefined;

  const dispatch = useTypedDispatch();

  const handleCopyOrderId = async () => {
    try {
      await copyToClipboard(orderId);
      dispatch(setCopyPopupShow(true));
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1px' }}>
      <Tooltip
        trigger={
          <div className="status-indicator">
            <div
              className="indicator-inner"
              style={{ backgroundColor: status?.color ?? undefined }}
            />
          </div>
        }
        content={status?.label ?? ''}
        options={{ offset: 10, placement: 'top' }}
      />
      <div
        style={{
          textAlign: 'left',
          textOverflow: 'ellipsis',
          minWidth: 0,
          overflow: 'hidden',
          direction: 'rtl',
        }}
      >
        <span className="order-id-text" onClick={handleCopyOrderId}>
          {orderId}
        </span>
      </div>
    </div>
  );
});

OrderId.displayName = 'OrderId';

export { OrderId };
